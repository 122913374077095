import { FC } from 'react';

export interface ICountCard {
  number?: number | string;
  subText?: string;
  color?: string;
  secondarySubText?: string;
}

export const CountCard: FC<ICountCard> = ({
  number,
  subText,
  color,
  secondarySubText,
}) => {
  return (
    <div className="rounded-2xl shadow-xl h-32 w-48 flex">
      <div className={`rounded-l-2xl w-4 h-full ${color}`} />
      <div className="w-full h-full flex flex-col justify-center pl-6">
        {secondarySubText && (
          <div className="text-gray-600 text-xs">{secondarySubText || `-`}</div>
        )}
        <div className="text-5xl font-semibold">{number || `-`}</div>
        <div className="mt-3 text-gray-600">{subText || `-`}</div>
      </div>
    </div>
  );
};
