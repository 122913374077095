import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ISearchableSelect, SearchableSelect } from '../../Components';

export interface ISearchableSelectField extends ISearchableSelect {
  formHook: UseFormReturn<any>;
  name: string;
}

export const SearchableSelectField: FC<ISearchableSelectField> = ({
  formHook,
  name,
  ...props
}) => {
  const { control } = formHook;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <SearchableSelect
          onChange={onChange}
          defaultValue={value}
          value={value}
          onBlur={onBlur}
          error={error}
          {...props}
        />
      )}
    />
  );
};
