export const LoginVector = () => (
  <svg viewBox="0 0 687 729" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M473.299 405.145H628.565V702.171H473.299V405.145Z"
      fill="#175E74"
    />
    <path
      opacity="0.6"
      d="M499.506 405.115H628.565V702.171H499.506V405.115Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M473.275 405.115H499.506V702.171H473.275V405.115Z"
      fill="white"
    />
    <path
      d="M589.316 420.228H617.473V455.281H589.316V420.228Z"
      fill="#175E74"
    />
    <path
      d="M549.971 420.228H578.128V455.281H549.971V420.228Z"
      fill="#175E74"
    />
    <path
      d="M510.599 420.228H538.756V455.281H510.599V420.228Z"
      fill="#175E74"
    />
    <path
      d="M589.316 465.904H617.473V500.957H589.316V465.904Z"
      fill="#175E74"
    />
    <path
      d="M549.971 465.904H578.128V500.957H549.971V465.904Z"
      fill="#175E74"
    />
    <path
      d="M510.599 465.904H538.756V500.957H510.599V465.904Z"
      fill="#175E74"
    />
    <path
      d="M589.316 511.579H617.473V546.632H589.316V511.579Z"
      fill="#175E74"
    />
    <path
      d="M549.971 511.579H578.128V546.632H549.971V511.579Z"
      fill="#175E74"
    />
    <path
      d="M510.599 511.579H538.756V546.632H510.599V511.579Z"
      fill="#175E74"
    />
    <path
      d="M589.316 557.254H617.473V592.308H589.316V557.254Z"
      fill="#175E74"
    />
    <path
      d="M549.971 557.254H578.128V592.308H549.971V557.254Z"
      fill="#175E74"
    />
    <path
      d="M510.599 557.254H538.756V592.308H510.599V557.254Z"
      fill="#175E74"
    />
    <path d="M589.316 602.93H617.473V637.983H589.316V602.93Z" fill="#175E74" />
    <path d="M549.971 602.93H578.128V637.983H549.971V602.93Z" fill="#175E74" />
    <path d="M510.599 602.93H538.756V637.983H510.599V602.93Z" fill="#175E74" />
    <path
      d="M589.316 660.744H617.473V702.171H589.316V660.744Z"
      fill="#175E74"
    />
    <path
      d="M549.971 648.605H578.128V702.171H549.971V648.605Z"
      fill="#175E74"
    />
    <path
      d="M510.599 660.744H538.756V702.171H510.599V660.744Z"
      fill="#175E74"
    />
    <path
      opacity="0.4"
      d="M589.318 420.228H615.159V455.281H589.318V420.228Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M549.945 420.228H575.786V455.281H549.945V420.228Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M510.575 420.228H536.416V455.281H510.575V420.228Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M589.318 465.904H615.159V500.957H589.318V465.904Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M549.945 465.904H575.786V500.957H549.945V465.904Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M510.575 465.904H536.416V500.957H510.575V465.904Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M589.318 511.579H615.159V546.632H589.318V511.579Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M549.945 511.579H575.786V546.632H549.945V511.579Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M510.575 511.579H536.416V546.632H510.575V511.579Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M589.318 557.254H615.159V592.308H589.318V557.254Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M549.945 557.254H575.786V592.308H549.945V557.254Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M510.575 557.254H536.416V592.308H510.575V557.254Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M589.318 602.93H615.159V637.983H589.318V602.93Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M549.945 602.93H575.786V637.983H549.945V602.93Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M510.575 602.93H536.416V637.983H510.575V602.93Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M589.318 660.744H615.159V702.171H589.318V660.744Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M549.945 648.605H575.786V702.171H549.945V648.605Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M510.575 660.744H536.416V702.171H510.575V660.744Z"
      fill="white"
    />
    <path
      d="M638 392.277H446.678L452.358 407.36H632.295L638 392.277Z"
      fill="#175E74"
    />
    <g opacity="0.2">
      <path
        d="M496.679 392.277H446.679L452.359 407.36H499.409L496.679 392.277Z"
        fill="#323130"
      />
      <path
        d="M496.679 392.277H446.679L452.359 407.36H499.409L496.679 392.277Z"
        fill="black"
        fill-opacity="0.2"
      />
    </g>
    <path
      d="M67.3726 227.451H224.077V702.171H67.3726V227.451Z"
      fill="#175E74"
    />
    <path
      opacity="0.4"
      d="M104.867 227.451H224.077V702.171H104.867V227.451Z"
      fill="white"
    />
    <path d="M60.0367 213.4H231.441V228.544H60.0367V213.4Z" fill="#175E74" />
    <g opacity="0.2">
      <path d="M60.0363 213.4H104.844V228.544H60.0363V213.4Z" fill="#323130" />
      <path
        d="M60.0363 213.4H104.844V228.544H60.0363V213.4Z"
        fill="black"
        fill-opacity="0.2"
      />
    </g>
    <path
      opacity="0.3"
      d="M67.3497 228.544H104.844V702.171H67.3497V228.544Z"
      fill="white"
    />
    <path
      d="M187.828 246.389H208.086V284.599H187.828V246.389Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M187.828 248.908H208.086V284.568H187.828V248.908Z"
      fill="white"
    />
    <path
      d="M205.185 246.389H208.086V284.599H205.185V246.389Z"
      fill="#175E74"
    />
    <path
      d="M155.917 246.389H176.176V284.599H155.917V246.389Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M155.917 248.908H176.176V284.568H155.917V248.908Z"
      fill="white"
    />
    <path
      d="M173.275 246.389H176.176V284.599H173.275V246.389Z"
      fill="#175E74"
    />
    <path
      d="M124.004 246.389H144.263V284.599H124.004V246.389Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M124.004 248.908H144.263V284.568H124.004V248.908Z"
      fill="white"
    />
    <path
      d="M141.362 246.389H144.263V284.599H141.362V246.389Z"
      fill="#175E74"
    />
    <path d="M187.828 303.931H208.086V342.14H187.828V303.931Z" fill="#175E74" />
    <path
      opacity="0.2"
      d="M187.828 306.45H208.086V342.11H187.828V306.45Z"
      fill="white"
    />
    <path d="M205.185 303.931H208.086V342.14H205.185V303.931Z" fill="#175E74" />
    <path d="M155.917 303.931H176.176V342.14H155.917V303.931Z" fill="#175E74" />
    <path
      opacity="0.2"
      d="M155.917 306.45H176.176V342.11H155.917V306.45Z"
      fill="white"
    />
    <path d="M173.275 303.931H176.176V342.14H173.275V303.931Z" fill="#175E74" />
    <path d="M124.004 303.931H144.263V342.14H124.004V303.931Z" fill="#175E74" />
    <path
      opacity="0.2"
      d="M124.004 306.45H144.263V342.11H124.004V306.45Z"
      fill="white"
    />
    <path d="M141.362 303.931H144.263V342.14H141.362V303.931Z" fill="#175E74" />
    <path
      d="M187.828 361.443H208.086V399.652H187.828V361.443Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M187.828 363.992H208.086V399.652H187.828V363.992Z"
      fill="white"
    />
    <path
      d="M205.185 361.443H208.086V399.652H205.185V361.443Z"
      fill="#175E74"
    />
    <path
      d="M155.917 361.443H176.176V399.652H155.917V361.443Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M155.917 363.992H176.176V399.652H155.917V363.992Z"
      fill="white"
    />
    <path
      d="M173.275 361.443H176.176V399.652H173.275V361.443Z"
      fill="#175E74"
    />
    <path
      d="M124.004 361.443H144.263V399.652H124.004V361.443Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M124.004 363.992H144.263V399.652H124.004V363.992Z"
      fill="white"
    />
    <path
      d="M141.362 361.443H144.263V399.652H141.362V361.443Z"
      fill="#175E74"
    />
    <path
      d="M187.828 418.984H208.086V457.194H187.828V418.984Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M187.828 421.534H208.086V457.194H187.828V421.534Z"
      fill="white"
    />
    <path
      d="M205.185 418.984H208.086V457.194H205.185V418.984Z"
      fill="#175E74"
    />
    <path
      d="M155.917 418.984H176.176V457.194H155.917V418.984Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M155.917 421.534H176.176V457.194H155.917V421.534Z"
      fill="white"
    />
    <path
      d="M173.275 418.984H176.176V457.194H173.275V418.984Z"
      fill="#175E74"
    />
    <path
      d="M124.004 418.984H144.263V457.194H124.004V418.984Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M124.004 421.534H144.263V457.194H124.004V421.534Z"
      fill="white"
    />
    <path
      d="M141.362 418.984H144.263V457.194H141.362V418.984Z"
      fill="#175E74"
    />
    <path
      d="M187.828 476.526H208.086V514.735H187.828V476.526Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M187.828 479.045H208.086V514.706H187.828V479.045Z"
      fill="white"
    />
    <path
      d="M205.185 476.526H208.086V514.735H205.185V476.526Z"
      fill="#175E74"
    />
    <path
      d="M155.917 476.526H176.176V514.735H155.917V476.526Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M155.917 479.045H176.176V514.706H155.917V479.045Z"
      fill="white"
    />
    <path
      d="M173.275 476.526H176.176V514.735H173.275V476.526Z"
      fill="#175E74"
    />
    <path
      d="M124.004 476.526H144.263V514.735H124.004V476.526Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M124.004 479.045H144.263V514.706H124.004V479.045Z"
      fill="white"
    />
    <path
      d="M141.362 476.526H144.263V514.735H141.362V476.526Z"
      fill="#175E74"
    />
    <path
      d="M187.828 534.037H208.086V572.247H187.828V534.037Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M187.828 536.587H208.086V572.247H187.828V536.587Z"
      fill="white"
    />
    <path
      d="M205.185 534.037H208.086V572.247H205.185V534.037Z"
      fill="#175E74"
    />
    <path
      d="M155.917 534.037H176.176V572.247H155.917V534.037Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M155.917 536.587H176.176V572.247H155.917V536.587Z"
      fill="white"
    />
    <path
      d="M173.275 534.037H176.176V572.247H173.275V534.037Z"
      fill="#175E74"
    />
    <path
      d="M124.004 534.037H144.263V572.247H124.004V534.037Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M124.004 536.587H144.263V572.247H124.004V536.587Z"
      fill="white"
    />
    <path
      d="M141.362 534.037H144.263V572.247H141.362V534.037Z"
      fill="#175E74"
    />
    <path d="M187.828 591.58H208.086V629.789H187.828V591.58Z" fill="#175E74" />
    <path
      opacity="0.2"
      d="M187.828 594.128H208.086V629.788H187.828V594.128Z"
      fill="white"
    />
    <path d="M205.185 591.58H208.086V629.789H205.185V591.58Z" fill="#175E74" />
    <path d="M155.917 591.58H176.176V629.789H155.917V591.58Z" fill="#175E74" />
    <path
      opacity="0.2"
      d="M155.917 594.128H176.176V629.788H155.917V594.128Z"
      fill="white"
    />
    <path d="M173.275 591.58H176.176V629.789H173.275V591.58Z" fill="#175E74" />
    <path d="M124.004 591.58H144.263V629.789H124.004V591.58Z" fill="#175E74" />
    <path
      opacity="0.2"
      d="M124.004 594.128H144.263V629.788H124.004V594.128Z"
      fill="white"
    />
    <path d="M141.362 591.58H144.263V629.789H141.362V591.58Z" fill="#175E74" />
    <path
      d="M187.828 649.121H208.086V687.331H187.828V649.121Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M187.828 651.64H208.086V687.3H187.828V651.64Z"
      fill="white"
    />
    <path
      d="M205.185 649.121H208.086V687.331H205.185V649.121Z"
      fill="#175E74"
    />
    <path
      d="M155.917 649.121H176.176V687.331H155.917V649.121Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M155.917 651.64H176.176V687.3H155.917V651.64Z"
      fill="white"
    />
    <path
      d="M173.275 649.121H176.176V687.331H173.275V649.121Z"
      fill="#175E74"
    />
    <path
      d="M124.004 649.121H144.263V687.331H124.004V649.121Z"
      fill="#175E74"
    />
    <path
      opacity="0.2"
      d="M124.004 651.64H144.263V687.3H124.004V651.64Z"
      fill="white"
    />
    <path
      d="M141.362 649.121H144.263V687.331H141.362V649.121Z"
      fill="#175E74"
    />
    <path
      d="M256.38 0.835449H374.152V78.3165H256.38V0.835449Z"
      fill="#175E74"
    />
    <path
      opacity="0.7"
      d="M256.38 0.835449H374.152V78.3165H256.38V0.835449Z"
      fill="white"
    />
    <path
      d="M324.493 0.835449H442.266V78.3165H324.493V0.835449Z"
      fill="#175E74"
    />
    <path
      opacity="0.4"
      d="M324.493 0.835449H442.266V78.3165H324.493V0.835449Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M453.455 119.864H459.354V150.213H453.455V119.864Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M453.455 198.226H459.354V228.575H453.455V198.226Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M453.455 251.275H459.354V281.624H453.455V251.275Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M453.455 304.356H459.354V334.705H453.455V304.356Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M453.455 357.406H459.354V387.755H453.455V357.406Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M453.455 410.456H459.354V440.805H453.455V410.456Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M453.455 468.939H459.354V499.288H453.455V468.939Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M453.455 527.421H459.354V557.77H453.455V527.421Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M453.455 585.874H459.354V616.223H453.455V585.874Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M453.455 644.356H459.354V674.705H453.455V644.356Z"
      fill="white"
    />
    <path
      d="M223.224 79.4392H407.257V702.171H223.224V79.4392Z"
      fill="#175E74"
    />
    <path d="M291.387 79.4392H475.42V702.171H291.387V79.4392Z" fill="#175E74" />
    <path
      opacity="0.3"
      d="M223.249 87.9976H291.362V702.201H223.249V87.9976Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M291.387 79.4392H475.42V702.171H291.387V79.4392Z"
      fill="white"
    />
    <path d="M309.097 150.8H331.962V687.518H309.097V150.8Z" fill="#175E74" />
    <path d="M352.42 150.8H375.284V687.518H352.42V150.8Z" fill="#175E74" />
    <path d="M395.742 150.8H418.606V687.518H395.742V150.8Z" fill="#175E74" />
    <path d="M439.065 150.8H461.93V687.518H439.065V150.8Z" fill="#175E74" />
    <path
      opacity="0.2"
      d="M309.097 158.021H331.962V687.518H309.097V158.021Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M352.42 158.021H375.284V687.518H352.42V158.021Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M395.742 158.021H418.606V687.518H395.742V158.021Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M439.065 158.021H461.93V687.518H439.065V158.021Z"
      fill="white"
    />
    <path d="M231.1 106.662H240.559V687.3H231.1V106.662Z" fill="#175E74" />
    <path d="M249.336 106.662H258.795V687.3H249.336V106.662Z" fill="#175E74" />
    <path d="M267.594 106.662H277.053V687.3H267.594V106.662Z" fill="#175E74" />
    <g opacity="0.3">
      <path
        opacity="0.4"
        d="M231.1 114.007H240.559V687.3H231.1V114.007Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M249.336 114.007H258.795V687.3H249.336V114.007Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M267.594 114.007H277.053V687.3H267.594V114.007Z"
        fill="white"
      />
    </g>
    <path d="M227.174 61.7456H291.362V73.43H227.174V61.7456Z" fill="#175E74" />
    <path d="M227.174 58.8926H475.42V73.4297H227.174V58.8926Z" fill="#EDEBE9" />
    <path d="M218.47 73.4604H480.611V87.9976H218.47V73.4604Z" fill="#175E74" />
    <path d="M218.47 47.2085H480.611V61.7457H218.47V47.2085Z" fill="#175E74" />
    <g opacity="0.1">
      <path
        d="M218.47 73.4604H480.611V87.9976H218.47V73.4604Z"
        fill="#323130"
      />
      <path
        d="M218.47 73.4604H480.611V87.9976H218.47V73.4604Z"
        fill="black"
        fill-opacity="0.2"
      />
    </g>
    <g opacity="0.1">
      <path
        d="M218.47 47.2085H480.611V61.7457H218.47V47.2085Z"
        fill="#323130"
      />
      <path
        d="M218.47 47.2085H480.611V61.7457H218.47V47.2085Z"
        fill="black"
        fill-opacity="0.2"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M218.471 73.4604H291.362V87.9976H218.471V73.4604Z"
        fill="#323130"
      />
      <path
        d="M218.471 73.4604H291.362V87.9976H218.471V73.4604Z"
        fill="black"
        fill-opacity="0.2"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M218.471 47.2085H291.362V61.7457H218.471V47.2085Z"
        fill="#323130"
      />
      <path
        d="M218.471 47.2085H291.362V61.7457H218.471V47.2085Z"
        fill="black"
        fill-opacity="0.2"
      />
    </g>
    <path
      opacity="0.3"
      d="M369.252 0.835449H397.385V35.8583H369.252V0.835449Z"
      fill="white"
    />
    <path d="M9.15527e-05 701H279.83V715.742H9.15527e-05V701Z" fill="#175E74" />
    <g opacity="0.7">
      <path
        opacity="0.7"
        d="M9.15527e-05 701H279.83V715.742H9.15527e-05V701Z"
        fill="white"
      />
    </g>
    <path d="M264.738 701H265.404V716.789H264.738V701Z" fill="white" />
    <path d="M202.146 701H202.812V716.789H202.146V701Z" fill="white" />
    <path d="M139.555 701H140.221V716.789H139.555V701Z" fill="white" />
    <path d="M76.9639 701H77.6299V716.789H76.9639V701Z" fill="white" />
    <path d="M14.3721 701H15.0381V716.789H14.3721V701Z" fill="white" />
    <path
      d="M9.15527e-05 721.521H279.83V728.362H9.15527e-05V721.521Z"
      fill="#175E74"
    />
    <path
      opacity="0.9"
      d="M9.15527e-05 721.521H279.83V728.362H9.15527e-05V721.521Z"
      fill="white"
    />
    <path
      d="M279.622 701.053H559.452V715.795H279.622V701.053Z"
      fill="#175E74"
    />
    <g opacity="0.7">
      <path
        opacity="0.7"
        d="M279.622 701.053H559.452V715.795H279.622V701.053Z"
        fill="white"
      />
    </g>
    <path d="M544.36 701.053H545.026V716.842H544.36V701.053Z" fill="white" />
    <path d="M481.769 701.053H482.435V716.842H481.769V701.053Z" fill="white" />
    <path d="M419.177 701.053H419.843V716.842H419.177V701.053Z" fill="white" />
    <path d="M356.586 701.053H357.252V716.842H356.586V701.053Z" fill="white" />
    <path d="M293.994 701.053H294.66V716.842H293.994V701.053Z" fill="white" />
    <path
      d="M279.622 721.574H559.452V728.415H279.622V721.574Z"
      fill="#175E74"
    />
    <path
      opacity="0.9"
      d="M279.622 721.574H559.452V728.415H279.622V721.574Z"
      fill="white"
    />
    <path d="M559.619 701H687V716H559.619V701Z" fill="#175E74" />
    <g opacity="0.7">
      <path opacity="0.7" d="M559.619 701H687V716H559.619V701Z" fill="white" />
    </g>
    <path d="M636.416 701.053H637.082V716.842H636.416V701.053Z" fill="white" />
    <path d="M573.824 701.053H574.49V716.842H573.824V701.053Z" fill="white" />
    <path d="M559.619 722H687V728H559.619V722Z" fill="#175E74" />
    <path opacity="0.9" d="M559.619 722H687V728H559.619V722Z" fill="white" />
  </svg>
);
