interface FeatureHeaderProps {
  scrollToSection: (index: number) => void;
  sectionTitles: string[];
}

const FeatureHeader: React.FC<FeatureHeaderProps> = ({
  scrollToSection,
  sectionTitles,
}) => {
  return (
    <div className="sticky z-50 bg-white top-[-36px]">
      <div>
        <div className="flex flex-col items-center mt-6">
          <div className="text-4xl font-medium text-center">
            Drive Productivity & Engagement
          </div>
          <div className="border border-[#FF9900] text-center w-fit p-2 bg-[#FDF9F3] m-4 rounded-md">
            Boost Productivity: Simplify your workflow by managing and
            visualizing everything in one place.
          </div>
        </div>
        <div className="flex gap-3 text-base font-normal justify-center items-center mt-4 shadow-2xl p-3">
          {sectionTitles.map((title, index) => (
            <div
              className="flex items-center justify-between gap-3 cursor-pointer"
              key={index}
              onClick={() => scrollToSection(index)}
            >
              <div className="text-center w-[110px] hover:text-[#FF9900]">
                {title}
              </div>
              {index !== sectionTitles.length - 1 && (
                <div className="w-[2px] h-[40px] bg-gray-200"></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureHeader;
