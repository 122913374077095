import AwardsListTable from './AwardsListTable';

const AwardsList = ({ awards }: { awards: any }) => {
  return (
    <div className="grid grid-cols-5 gap-16">
      <div className="col-span-1">
        <div className="flex flex-col gap-4">
          <strong>General Setting</strong>
          <p className="text-gray-400">
            Add/remove/edit awards for your organisation
          </p>
        </div>
      </div>
      <div className="col-span-4">
        <div className="flex flex-col gap-4">
          <AwardsListTable awards={awards} />
        </div>
      </div>
    </div>
  );
};

export default AwardsList;
