import { IDropdownOption } from '@fluentui/react';
import moment from 'moment';
import { Enum_Live_Emote_Type_Enum } from './shared-graphql';
import { IRegLiveEmotes } from './shared-types';

export const hourPickerOptions: IDropdownOption[] = [
  {
    key: moment('00:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '12:00 AM',
  },
  {
    key: moment('00:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '12:15 AM',
  },
  {
    key: moment('00:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '12:30 AM',
  },
  {
    key: moment('00:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '12:45 AM',
  },
  {
    key: moment('01:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '01:00 AM',
  },
  {
    key: moment('01:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '01:15 AM',
  },
  {
    key: moment('01:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '01:30 AM',
  },
  {
    key: moment('01:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '01:45 AM',
  },
  {
    key: moment('02:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '02:00 AM',
  },
  {
    key: moment('02:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '02:15 AM',
  },
  {
    key: moment('02:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '02:30 AM',
  },
  {
    key: moment('02:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '02:45 AM',
  },
  {
    key: moment('03:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '03:00 AM',
  },
  {
    key: moment('03:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '03:15 AM',
  },
  {
    key: moment('03:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '03:30 AM',
  },
  {
    key: moment('03:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '03:45 AM',
  },
  {
    key: moment('04:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '04:00 AM',
  },
  {
    key: moment('04:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '04:15 AM',
  },
  {
    key: moment('04:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '04:30 AM',
  },
  {
    key: moment('04:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '04:45 AM',
  },
  {
    key: moment('05:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '05:00 AM',
  },
  {
    key: moment('05:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '05:15 AM',
  },
  {
    key: moment('05:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '05:30 AM',
  },
  {
    key: moment('05:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '05:45 AM',
  },
  {
    key: moment('06:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '06:00 AM',
  },
  {
    key: moment('06:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '06:15 AM',
  },
  {
    key: moment('06:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '06:30 AM',
  },
  {
    key: moment('06:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '06:45 AM',
  },
  {
    key: moment('07:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '07:00 AM',
  },
  {
    key: moment('07:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '07:15 AM',
  },
  {
    key: moment('07:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '07:30 AM',
  },
  {
    key: moment('07:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '07:45 AM',
  },
  {
    key: moment('08:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '08:00 AM',
  },
  {
    key: moment('08:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '08:15 AM',
  },
  {
    key: moment('08:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '08:30 AM',
  },
  {
    key: moment('08:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '08:45 AM',
  },
  {
    key: moment('09:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '09:00 AM',
  },
  {
    key: moment('09:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '09:15 AM',
  },
  {
    key: moment('09:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '09:30 AM',
  },
  {
    key: moment('09:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '09:45 AM',
  },
  {
    key: moment('10:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '10:00 AM',
  },
  {
    key: moment('10:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '10:15 AM',
  },
  {
    key: moment('10:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '10:30 AM',
  },
  {
    key: moment('10:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '10:45 AM',
  },
  {
    key: moment('11:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '11:00 AM',
  },
  {
    key: moment('11:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '11:15 AM',
  },
  {
    key: moment('11:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '11:30 AM',
  },
  {
    key: moment('11:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '11:45 AM',
  },
  {
    key: moment('12:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '12:00 PM',
  },
  {
    key: moment('12:15', 'HH:mm:ss').format('HH:mm:ss'),
    text: '12:15 PM',
  },
  {
    key: moment('12:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '12:30 PM',
  },
  {
    key: moment('12:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '12:45 PM',
  },
  {
    key: moment('13:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '01:00 PM',
  },
  {
    key: moment('13:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '01:15 PM',
  },
  {
    key: moment('13:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '01:30 PM',
  },
  {
    key: moment('13:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '01:45 PM',
  },
  {
    key: moment('14:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '02:00 PM',
  },
  {
    key: moment('14:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '02:15 PM',
  },
  {
    key: moment('14:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '02:30 PM',
  },
  {
    key: moment('14:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '02:45 PM',
  },
  {
    key: moment('15:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '03:00 PM',
  },
  {
    key: moment('15:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '03:15 PM',
  },
  {
    key: moment('15:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '03:30 PM',
  },
  {
    key: moment('15:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '03:45 PM',
  },
  {
    key: moment('16:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '04:00 PM',
  },
  {
    key: moment('16:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '04:15 PM',
  },
  {
    key: moment('16:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '04:30 PM',
  },
  {
    key: moment('16:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '04:45 PM',
  },
  {
    key: moment('17:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '05:00 PM',
  },
  {
    key: moment('17:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '05:15 PM',
  },
  {
    key: moment('17:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '05:30 PM',
  },
  {
    key: moment('17:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '05:45 PM',
  },
  {
    key: moment('18:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '06:00 PM',
  },
  {
    key: moment('18:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '06:15 PM',
  },
  {
    key: moment('18:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '06:30 PM',
  },
  {
    key: moment('18:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '06:45 PM',
  },
  {
    key: moment('19:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '07:00 PM',
  },
  {
    key: moment('19:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '07:15 PM',
  },
  {
    key: moment('19:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '07:30 PM',
  },
  {
    key: moment('19:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '07:45 PM',
  },
  {
    key: moment('20:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '08:00 PM',
  },
  {
    key: moment('20:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '08:15 PM',
  },
  {
    key: moment('20:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '08:30 PM',
  },
  {
    key: moment('20:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '08:45 PM',
  },
  {
    key: moment('21:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '09:00 PM',
  },
  {
    key: moment('21:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '09:15 PM',
  },
  {
    key: moment('21:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '09:30 PM',
  },
  {
    key: moment('21:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '09:45 PM',
  },
  {
    key: moment('22:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '10:00 PM',
  },
  {
    key: moment('22:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '10:15 PM',
  },
  {
    key: moment('22:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '10:30 PM',
  },
  {
    key: moment('22:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '10:45 PM',
  },
  {
    key: moment('23:00:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '11:00 PM',
  },
  {
    key: moment('23:15:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '11:15 PM',
  },
  {
    key: moment('23:30:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '11:30 PM',
  },
  {
    key: moment('23:45:00', 'HH:mm:ss').format('HH:mm:ss'),
    text: '11:45 PM',
  },
];

export const registeredLiveEmotes: IRegLiveEmotes[] = [
  {
    emote: Enum_Live_Emote_Type_Enum.PartyingFace,
    path: 'assets/liveEmotes/emote-partying-face.gif',
  },
  {
    emote: Enum_Live_Emote_Type_Enum.Clap,
    path: 'assets/liveEmotes/emote-clap.gif',
  },
];
