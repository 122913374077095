import {
  MessageBarType,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { isEmpty } from 'lodash';
import Multiselect from 'multiselect-react-dropdown';
import { useEffect, useState } from 'react';
import { VscClose } from 'react-icons/vsc';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import {
  useEditCompetencyMutation,
  useViewCompetencyQuery,
} from './Competency.generated';
interface IModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  children?: React.ReactNode;
  createCompetency: any;
  setCreateCompetency: any;
  listOfCompetenciesData: () => void;
  selectedID: string;
  setSelectedInfo: any;
  skillList: any;
}

const EditCompetencyPopup = (props: IModalProps) => {
  const {
    isOpen,
    onDismiss,
    createCompetency,
    setCreateCompetency,
    listOfCompetenciesData,
    selectedID,
    setSelectedInfo,
    skillList,
  } = props;

  const { organization } = useOrganizationContext();
  const { notification } = useNotification();
  const [isDisabled, setIsDisabled] = useState(false);

  const [, editCompetency] = useEditCompetencyMutation();

  const handleSkillOnCompetency = (field: string, value: any) => {
    if (field === 'name') {
      setCreateCompetency({
        ...createCompetency,
        name: value,
      });
    }
    if (field === 'skills') {
      setCreateCompetency({
        ...createCompetency,
        skills: value,
      });
    }
  };

  const editCompetencyHandler = async () => {
    const allBlank = createCompetency.skills.every(
      (skill: any) => skill.label === '',
    );
    const fromattedSkillList = createCompetency.skills.map((item: any) => ({
      id: item.id,
    }));
    if (!isEmpty(createCompetency.name)) {
      setIsDisabled(true);
      const { data: responseData, error: responseError } = await editCompetency(
        {
          object: {
            id: selectedID,
            name: createCompetency.name,
            editCompetencySkill: allBlank ? null : fromattedSkillList,
          },
        },
      );
      if (responseData?.editCompetency?.response === 'Updated') {
        setIsDisabled(false);
        notification({
          message: `Competency updated successfully!`,
          type: MessageBarType.success,
        });
        onDismiss();
        listOfCompetenciesData();
        setSelectedInfo({
          index: -1,
          visible: false,
          id: '',
        });
      }
      if (responseError) {
        setIsDisabled(false);
        notification({
          message: responseError.message,
          type: MessageBarType.error,
        });
      }
    } else {
      notification({
        type: MessageBarType.info,
        message: 'Please enter name',
      });
    }
  };

  const [{ data: viewCompetency }] = useViewCompetencyQuery({
    variables: {
      _eq: selectedID,
      _eq1: organization?.id,
    },
    pause: !selectedID,
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (selectedID !== '') {
      const updatedCompetence = viewCompetency?.main_skills[0].sub_skills.map(
        (item: any) => ({
          id: item.list_Of_Skill.id,
          label: item.list_Of_Skill.name,
          value: item.list_Of_Skill.name,
        }),
      );
      setCreateCompetency({
        ...createCompetency,
        name: viewCompetency?.main_skills[0].name,
        skills: updatedCompetence,
      });
    } else {
      setCreateCompetency({
        name: '',
        skills: [],
      });
    }
  }, [viewCompetency, selectedID]);

  return (
    <Modal {...props}>
      <Stack
        className="p-6 pt-0"
        style={{ width: 518, height: 359, overflowY: 'auto' }}
      >
        <div>
          <div className="flex justify-between items-center pb-3  pt-6 sticky top-0 bg-white z-10">
            <span>
              <label className="text-[#201F1E] text-xl leading-7 font-semibold">
                Edit Competency
              </label>
            </span>
            <span
              onClick={onDismiss}
              className="flex justify-end cursor-pointer"
            >
              <VscClose size={25} />
            </span>
          </div>
          <TextField
            autoFocus={false}
            label="Name*"
            value={createCompetency.name}
            placeholder={'Type competency here..'}
            onChange={(e, newValue) => {
              handleSkillOnCompetency('name', newValue);
            }}
            className="mb-8"
          />
          <label className={'text-sm font-semibold'}>
            Skills({createCompetency?.skills?.length})
          </label>
          <Multiselect
            options={skillList}
            showCheckbox={true}
            displayValue="label"
            style={{
              chips: {
                background: '#F3F2F1',
                color: '#201F1E',
                borderRadius: 0,
                marginBottom: 0,
                marginRight: 4,
              },
              searchWrappers: {
                padding: 30,
                border: '1px solid #8A8886',
              },
              searchBox: {
                border: '1px solid #8A8886',
                borderRadius: '0px',
              },
              optionContainer: {
                backgroundColor: 'white',
                height: '120px',
              },
            }}
            customCloseIcon={<span className="cursor-pointer px-1">X</span>}
            selectedValues={createCompetency.skills}
            onSelect={(newValue) => handleSkillOnCompetency('skills', newValue)}
            onRemove={(newValue) => handleSkillOnCompetency('skills', newValue)}
          />
          <PrimaryButton
            text="Save Changes"
            style={{
              flexWrap: 'wrap',
              position: 'absolute',
              bottom: 20,
              right: 35,
            }}
            onClick={editCompetencyHandler}
            disabled={isDisabled}
            styles={{
              root: {
                selectors: {
                  ':disabled': {
                    backgroundColor: '#F3F2F1 !important',
                    color: '#A19F9D',
                  },
                },
              },
            }}
          />
        </div>
      </Stack>
    </Modal>
  );
};

export default EditCompetencyPopup;
