import { IMessageBarProps, MessageBar } from '@fluentui/react';
import { ReactNode } from 'react';

export interface NotificationProps extends IMessageBarProps {
  children?: ReactNode;
  onDismiss: () => void;
}

export const Notification = ({
  children,
  onDismiss,
  ...props
}: NotificationProps) => {
  return (
    <MessageBar
      className="shadow-md"
      styles={{
        root: {
          width: 'auto',
          position: 'fixed',
          top: 20,
          right: '50%',
          transform: 'translateX(50%)',
          textAlign: 'center',
          zIndex: 9999999999,
        },
        text: {
          fontSize: 16,
        },
        content: {
          alignItems: 'center',
        },
      }}
      {...props}
      onDismiss={onDismiss}
    >
      {children}
    </MessageBar>
  );
};
