import {
  DefaultButton,
  IconButton,
  IModalProps,
  MessageBarType,
  Modal,
  Text,
  TextField,
} from '@fluentui/react';
import {
  PrimaryButtonWithLoader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { FC, useEffect, useState } from 'react';
import { useUpdatePostMessageMutation } from './announcement.generated';

interface IEditAnnouncementProps extends IModalProps {
  announcementId: string | undefined;
  announcementMessage: string | undefined;
}

const EditAnnouncement: FC<IEditAnnouncementProps> = ({
  announcementId,
  announcementMessage,
  ...props
}) => {
  const { notification } = useNotification();
  const [message, setMessage] = useState<string | undefined>(
    announcementMessage,
  );
  const [loading, setLoading] = useState(false);

  const [, updateMessage] = useUpdatePostMessageMutation();

  useEffect(() => {
    setMessage(announcementMessage);
  }, [announcementMessage]);

  const updateAnnouncement = async () => {
    try {
      if (!message) throw new Error('Message is required');
      setLoading(true);
      const res = await updateMessage({
        message: message,
        postId: announcementId,
      });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
        return;
      }
      notification({
        message: 'Announcement updated',
        type: MessageBarType.success,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      props.onDismiss?.();
      window.dispatchEvent(new CustomEvent('refetch-announcements'));
    }
  };
  return (
    <Modal {...props}>
      <div className="w-96 h-72 flex gap-2 flex-col p-4">
        <div className="flex justify-between align-middle">
          <div className="text-base font-semibold">Edit Announcement</div>
          <div>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              onClick={() => props.onDismiss?.()}
            />
          </div>
        </div>
        <div className="grow flex gap-1 flex-col">
          <Text>Enter new announcement message:</Text>
          <TextField
            multiline
            autoAdjustHeight
            maxLength={70}
            placeholder="Enter updated announcement.."
            value={message}
            onChange={(e, newMessage) => {
              setMessage(newMessage);
            }}
          />
        </div>
        <div className="flex justify-end gap-1">
          <PrimaryButtonWithLoader
            loading={loading}
            onClick={updateAnnouncement}
            text="Update"
          />
          <DefaultButton onClick={() => props?.onDismiss?.()} text="Cancel" />
        </div>
      </div>
    </Modal>
  );
};

export default EditAnnouncement;
