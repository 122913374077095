import { IconButton } from '@fluentui/react';
import classNames from 'classnames';
import {
  addWeeks,
  format,
  getWeek,
  getYear,
  lastDayOfWeek,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import moment from 'moment';
import { useState } from 'react';
import { SArrowButton } from './styles';

interface IBtnType {
  btnType: 'next' | 'prev';
}

interface IWeeklyCellProps {
  year: number;
  week: number;
  startOfWeek: string;
  endOfWeek: string;
  month: string;
  monthNumber: string;
}
interface IWeeklyCalendar {
  onSelect: ({
    week,
    month,
    startOfWeek,
    endOfWeek,
    year,
  }: IWeeklyCellProps) => void;
}

export const WeeklyCalendar = ({ onSelect }: IWeeklyCalendar) => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [selectedWeek, setSelectedWeek] = useState<number>(
    getWeek(currentMonth),
  );

  const handleNext = () => {
    setCurrentMonth(addWeeks(currentMonth, 1));
    // setSelectedWeek(selectedWeek + 1);
  };
  const handleBack = () => {
    setCurrentMonth(subWeeks(currentMonth, 1));
    // setSelectedWeek(selectedWeek - 1);
  };

  const WeeklyCell = ({
    week,
    startOfWeek,
    endOfWeek,
    month,
    year,
    monthNumber,
  }: IWeeklyCellProps) => {
    return (
      <div
        onClick={() => {
          setSelectedWeek(week);
          onSelect({
            week,
            month,
            startOfWeek,
            endOfWeek,
            year,
            monthNumber,
          });
        }}
        className={classNames(
          'flex flex-col pb-1 px-2 rounded-md cursor-pointer',
          {
            'text-white bg-primary ': selectedWeek === week,
          },
        )}
      >
        <div className="text-center font-normal">
          {`${startOfWeek} - ${endOfWeek}`}
        </div>
        <div className="text-center text-xs font-normal">{month}</div>
        <div className="text-center text-xs font-normal">{year}</div>
      </div>
    );
  };

  const WeeklyCells = () =>
    [-4, -3, -2, -1, 0].map((i) => {
      if (i < 0) {
        const week = subWeeks(currentMonth, Math.abs(i));
        return (
          <WeeklyCell
            year={getYear(week)}
            week={getWeek(week)}
            endOfWeek={format(lastDayOfWeek(week, { weekStartsOn: 1 }), 'd')}
            startOfWeek={format(startOfWeek(week, { weekStartsOn: 1 }), 'd')}
            month={format(week, 'MMMM')}
            monthNumber={format(week, 'MM')}
          />
        );
      } else if (i > 0) {
        const week = addWeeks(currentMonth, i);
        return (
          <WeeklyCell
            year={getYear(week)}
            week={getWeek(week)}
            endOfWeek={format(lastDayOfWeek(week, { weekStartsOn: 1 }), 'd')}
            startOfWeek={format(startOfWeek(week, { weekStartsOn: 1 }), 'd')}
            month={format(week, 'MMMM')}
            monthNumber={format(week, 'MM')}
          />
        );
      }
      return (
        <WeeklyCell
          year={getYear(currentMonth)}
          week={getWeek(currentMonth)}
          endOfWeek={format(
            lastDayOfWeek(currentMonth, { weekStartsOn: 1 }),
            'd',
          )}
          startOfWeek={format(
            startOfWeek(currentMonth, { weekStartsOn: 1 }),
            'd',
          )}
          monthNumber={format(currentMonth, 'MM')}
          month={format(currentMonth, 'MMMM')}
        />
      );
    });

  return (
    <div className="flex bg-white rounded-md p-3 border items-center">
      <div className="pr-2">
        <IconButton
          iconProps={{ iconName: 'NavigateBack' }}
          styles={SArrowButton}
          onClick={handleBack}
        />
      </div>
      <div className="flex justify-between gap-2">{WeeklyCells()}</div>
      <div className="pl-2">
        <IconButton
          iconProps={{ iconName: 'NavigateBackMirrored' }}
          styles={SArrowButton}
          onClick={handleNext}
          disabled={moment().subtract(1, 'week').isBefore(currentMonth)}
        />
      </div>
    </div>
  );
};
