import { Icon, PrimaryButton, SearchBox } from '@fluentui/react';
import { Pagination } from '@fluentui/react-experiments';
import { useBoolean } from 'ahooks';
import { useEffect, useState } from 'react';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import { useGetListOfSkillsQuery } from '../Skill/Skill.generated';
import AddNewSkill from './AddNewSkill';
import DeleteSkillPopup from './DeleteSkillPopup';
import EditSKill from './EditSkill';

const SkillList = () => {
  const { organization } = useOrganizationContext();
  const PER_PAGE_COUNT = 10;

  const [page, setPage] = useState<number>(1);
  const [totalPageCount, setTotalPageCount] = useState<any>(1);
  const [offset, setOffset] = useState<number>(0);
  const [selectedInfo, setSelectedInfo] = useState<any>({
    index: -1,
    visible: false,
    collapsible: false,
    id: '',
  });
  const [searchText, setSearchText] = useState('');
  const [createSkill, setCreateSkill] = useState({
    show: false,
    edit: false,
    name: '',
    rating1: '',
    rating2: '',
    rating3: '',
    rating4: '',
    rating5: '',
  });
  const [deleteVisibleClose, { toggle: toggledeleteVisibleCloseModal }] =
    useBoolean(false);

  const handlePaginate = (index: number) => {
    setOffset(index * PER_PAGE_COUNT);
    setPage(index + 1);
  };

  const [{ data: listOfSkills }, listOfSkillsData] = useGetListOfSkillsQuery({
    variables: {
      _eq: organization?.id,
      _ilike: searchText !== '' ? `%${searchText}%` : '%%',
      limit: PER_PAGE_COUNT,
      offset: offset,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setTotalPageCount(
      Math.ceil(
        (listOfSkills?.list_Of_Skills_aggregate.aggregate?.count || 1) /
          PER_PAGE_COUNT,
      ),
    );
  }, [listOfSkills]);

  return (
    <div className={'space-y-4'}>
      {listOfSkills?.list_Of_Skills.length === 0 &&
        !createSkill.show &&
        searchText === '' && (
          <div className="flex flex-col h-auto items-center mt-12">
            <img src="../../../../assets/NoSkillsAdded.png" alt="q" />
            <p className="text-sm font-semibold text-black mt-4">
              No Skills Added Yet
            </p>
            <p className="text-sm font-normal text-black mt-2 mb-4 w-80 text-center">
              You will see a list of skills along with details once you add a
              few skills
            </p>
            <PrimaryButton
              primaryDisabled={createSkill.show || createSkill.edit}
              onClick={() => {
                setSelectedInfo({
                  ...selectedInfo,
                  index: -1,
                  visible: false,
                  id: '',
                });
                setCreateSkill({ ...createSkill, show: true });
              }}
              text="Add New Skill"
            />
          </div>
        )}
      {(listOfSkills?.list_Of_Skills.length !== 0 || searchText !== '') && (
        <div className="flex justify-between items-center mt-3 w-[69.5%]">
          <SearchBox
            placeholder="Search"
            value={searchText}
            onChange={(e, value: any) => setSearchText(value)}
          />
          <PrimaryButton
            primaryDisabled={createSkill.show || createSkill.edit}
            onClick={() => {
              setSelectedInfo({
                ...selectedInfo,
                index: -1,
                visible: false,
                id: '',
              });
              setCreateSkill({ ...createSkill, show: true });
            }}
            text="Add New Skill"
          />
        </div>
      )}
      {(listOfSkills?.list_Of_Skills.length !== 0 ||
        searchText !== '' ||
        createSkill.show ||
        createSkill.edit) && (
        <div className="flex gap-x-2 mt-3">
          <ul className="space-y-4 w-[70%]" id="ulList">
            {createSkill.show && (
              <AddNewSkill
                createSkill={createSkill}
                setCreateSkill={setCreateSkill}
                listOfSkillsData={listOfSkillsData}
              />
            )}
            {createSkill.edit && (
              <EditSKill
                createSkill={createSkill}
                setCreateSkill={setCreateSkill}
                listOfSkillsData={listOfSkillsData}
                selectedID={selectedInfo.id}
                setSelectedInfo={setSelectedInfo}
              />
            )}

            {listOfSkills?.list_Of_Skills.map((info: any, index: number) => (
              <li
                className="flex flex-col border rounded border-[#dddddd] "
                key={index}
                onClick={() => {
                  setSelectedInfo({
                    ...selectedInfo,
                    index: selectedInfo.index === index ? -1 : index,
                    collapsible:
                      selectedInfo.index === index && selectedInfo.collapsible
                        ? false
                        : true,
                  });
                }}
              >
                <div className="flex flex-row px-8 py-3 items-center justify-between cursor-pointer hover:bg-[#F6F6F6]">
                  <div className="flex item-center flex-col w-[95%]">
                    <span className="font-semibold text-base text-black">
                      {info.name}{' '}
                    </span>
                    <div>
                      <span className="font-normal text-sm text-black">
                        Competencies :{' '}
                        {info?.sub_skills.length === 0 &&
                          'No competencies added'}
                      </span>
                      {info?.sub_skills?.map(
                        (subskill: any, subSkillIdx: number) => (
                          <span
                            className="font-normal text-sm text-black"
                            key={subSkillIdx}
                          >
                            {subskill.main_skill.name}
                            {subSkillIdx !== info.sub_skills.length - 1
                              ? ' , '
                              : ''}
                          </span>
                        ),
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-x-6">
                    <div
                      className="relative z-30"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedInfo({
                          ...selectedInfo,
                          index: index,
                          visible:
                            selectedInfo.index === index && selectedInfo.visible
                              ? false
                              : true,
                          id: info.id,
                        });
                      }}
                    >
                      <Icon
                        iconName="MoreVertical"
                        className="cursor-pointer"
                      />
                      <div
                        className={`${selectedInfo.index === index && selectedInfo.visible ? 'flex' : 'hidden'} w-52 h-20 bg-white border border-gray-300 rounded-sm shadow-md absolute right-2 top-5 flex-col`}
                      >
                        <label
                          className="text-sm font-normal cursor-pointer hover:bg-[#F6F6F6] px-4 py-2"
                          onClick={() =>
                            setCreateSkill({ ...createSkill, edit: true })
                          }
                        >
                          Edit
                        </label>
                        <label
                          className="text-sm font-normal cursor-pointer hover:bg-[#F6F6F6] px-4 py-2"
                          onClick={toggledeleteVisibleCloseModal}
                        >
                          Delete
                        </label>
                      </div>
                    </div>
                    {selectedInfo.index === index &&
                    selectedInfo.collapsible ? (
                      <IoChevronUpOutline />
                    ) : (
                      <IoChevronDownOutline />
                    )}
                  </div>
                </div>

                {selectedInfo.index === index && selectedInfo.collapsible && (
                  <div className="px-8 pt-2 pb-3">
                    <div className="flex flex-col gap-y-2">
                      {info.skills_details.map(
                        (skillInfo: any, skillIdx: number) => (
                          <div className="flex flex-col gap-y-1" key={skillIdx}>
                            <label className="font-semibold text-sm">
                              Rating {skillInfo.rating}
                            </label>
                            {skillInfo.description === '' ? (
                              <label className="font-normal text-sm text-[#605E5C]">
                                No description added
                              </label>
                            ) : (
                              <label className="font-normal text-sm">
                                {skillInfo.description}
                              </label>
                            )}
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div className="border border-[#E0E3E1] rounded-lg p-4 flex flex-col gap-y-2 w-[30%] self-start">
            <label className="text-sm font-semibold text-black">Skill</label>
            <label className="text-sm font-normal text-black">
              A skill is a specific ability or expertise that can be developed
              through training and practice.{' '}
            </label>
            <label className="text-sm font-normal text-black">
              Skill expertise level is rated from 1 to 5. 1 being the lowest and
              5 being the highest level of expertise.
            </label>
            <label className="text-sm font-normal text-black">
              Please add description against each skill rating, to better
              understand the expertise level requirements.
            </label>
            <img src="../../../../assets/SkillRatingScale.svg" alt="NoSkill" />
          </div>
        </div>
      )}
      {searchText !== '' && listOfSkills?.list_Of_Skills.length === 0 && (
        <div
          className="flex flex-col items-center"
          style={{ marginTop: '10%' }}
        >
          <label className="text-sm font-semibold text-black mt-4">
            No Skills found
          </label>
        </div>
      )}
      {listOfSkills?.list_Of_Skills.length !== 0 && (
        <div className="flex justify-center w-[70%]">
          <Pagination
            selectedPageIndex={page - 1}
            pageCount={totalPageCount}
            itemsPerPage={PER_PAGE_COUNT}
            onPageChange={handlePaginate}
            format="buttons"
            numberOfPageButton={4}
          />
        </div>
      )}
      {deleteVisibleClose && (
        <DeleteSkillPopup
          isOpen={deleteVisibleClose}
          onDismiss={toggledeleteVisibleCloseModal}
          selectedID={selectedInfo.id}
          listOfSkillsData={listOfSkillsData}
        />
      )}
    </div>
  );
};

export default SkillList;
