import {
  IconButton,
  Link,
  MessageBarType,
  Modal,
  Stack,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useNotification } from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { ClientsData } from './ClientsList';
import NewClientForm from './NewClientForm';
import { useUpdateClientMutation } from './projects-and-clients.generated';

const EditClient = ({ client }: { client: ClientsData }) => {
  const [, updateClient] = useUpdateClientMutation();
  const { notification } = useNotification();
  const [editModalOpen, { toggle: toggleEditModal }] = useBoolean(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Link onClick={toggleEditModal}>Edit</Link>
      <Modal isOpen={editModalOpen}>
        <Stack className="p-4" style={{ minWidth: 400 }}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Text variant="xLarge">Edit {client.client_name}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              onClick={toggleEditModal}
            />
          </Stack>
          <Stack>
            <NewClientForm
              loading={loading}
              data={client}
              onSubmit={async (data) => {
                try {
                  setLoading(true);
                  const res = await updateClient({
                    clientId: client.id,
                    data: {
                      client_name: data.name,
                    },
                  });

                  if (res.error) {
                    notification({
                      message: res.error.message
                        ?.replace('[GraphQL]', '')
                        .trim(),
                      type: MessageBarType.error,
                    });
                    setLoading(false);
                    return;
                  }
                  notification({
                    message: 'Client updated successfully',
                    type: MessageBarType.success,
                  });
                  toggleEditModal();
                  setLoading(false);
                } catch (error) {
                  console.error(error);
                  setLoading(false);
                }
              }}
              onCancel={toggleEditModal}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default EditClient;
