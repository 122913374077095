import { Auth } from '@worx.squad/hbp-sdk';
import { AuthAsync } from '@worx.squad/shared';
import { useMount } from 'ahooks';
import constate from 'constate';
import { GraphQLClient } from 'graphql-request';
import { useState } from 'react';
import { getSdk, Sdk } from '../../../generated/org-admin-sdk';

export const createHasura = async (
  gqlEndPoint: string,
  auth: Auth | AuthAsync,
) => {
  const token = await auth.getJWTToken();

  const client = new GraphQLClient(gqlEndPoint, {
    headers: token ? { authorization: `Bearer ${token}` } : undefined,
  });

  const sdk = getSdk(client);

  return sdk;
};

interface useSdkContextProps {
  auth: Auth | AuthAsync;
  url: string;
}

const useSdkContext = ({ auth, url }: useSdkContextProps) => {
  const [sdk, setSDK] = useState<Sdk>();

  useMount(() => {
    const setSdk = async () => {
      const hasura = await createHasura(url, auth);
      setSDK(hasura);
    };
    auth.onTokenChanged(setSdk);
    setSdk();
  });

  return sdk;
};

export const [SDKProvider, useClientSDK] = constate(useSdkContext);
