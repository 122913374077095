import { IconButton, Modal, Text } from '@fluentui/react';
import { OperationContext } from '@urql/core';
import { FC } from 'react';
import AddNewAdminForm from './AddNewAdminForm';

interface IAddNewAdminModal {
  isModalOpen: boolean;
  hideModal: () => void;
  refetch: (opts?: Partial<OperationContext>) => void;
}

const AddNewAdminModal: FC<IAddNewAdminModal> = ({
  isModalOpen,
  hideModal,
  refetch,
}) => {
  const onCancel = () => {
    hideModal();
    refetch();
  };
  return (
    <Modal isOpen={isModalOpen} onDismiss={onCancel}>
      <div className="p-5" style={{ minWidth: 600 }}>
        <div className="flex  justify-between mb-6">
          <div>
            <Text className="text-2xl">Add new admin</Text>
          </div>
          <div>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={hideModal}
            />
          </div>
        </div>
        <div>
          <AddNewAdminForm onCancel={onCancel} />
        </div>
      </div>
    </Modal>
  );
};

export default AddNewAdminModal;
