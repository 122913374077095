import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import yupPassword from 'yup-password';

yupPassword(yup);

const schema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .min(6)
    .minLowercase(1)
    .minUppercase(1)
    .minNumbers(1)
    .required()
    .label('Password'),
  confirmPassword: yup
    .string()
    .trim()
    .min(6)
    .minLowercase(1)
    .minUppercase(1)
    .minNumbers(1)
    .oneOf([yup.ref('password'), undefined], "Passwords don't match!")
    .required()
    .label('Confirm password'),
});

export const changePasswordSchema = yupResolver(schema);
