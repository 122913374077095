import { useTheme } from '@fluentui/react';
import { FieldError } from 'react-hook-form';
import { StylesConfig } from 'react-select';

export const useCreateReactSelectFluentTheme = (
  error?: FieldError | undefined,
  fixedHeight?: boolean,
) => {
  const theme = useTheme();
  const height = 32;
  return {
    control: (base) => ({
      ...base,
      boxShadow: 'none',
      boxSizing: 'border-box',
      border: `1px solid ${
        error ? theme.palette.redDark : 'rgb(162, 161, 161)'
      }`,
      borderRadius: 2,
      background: 'rgb(255, 255, 255)',
      cursor: 'pointer',
      height: fixedHeight ? height : base.height,
      minHeight: height,
      ':hover': {
        borderColor: theme.palette.black,
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: fixedHeight ? height : base.height,
      minHeight: height,
      padding: '0 6px',
    }),

    input: (base) => ({
      ...base,
      margin: 0,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: fixedHeight ? height : base.height,
      minHeight: height,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 999999999999999,
      wordBreak: 'break-all',
      borderRadius: 0,
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 1,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'rgba(0,0,0,0.2)'
        : provided.backgroundColor,
    }),
  } as StylesConfig;
};
