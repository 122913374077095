import { DefaultButton, Icon, TooltipHost } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import LinkSvg from '../Home/assets/LinkSvg';
import { calculatePrice, planDetails } from './Utils';

const PlanDetails = (option: any) => {
  const navigate = useNavigate();
  return (
    <div className="flex gap-8 justify-center">
      {planDetails.map((item) => {
        return (
          <div
            className={` ${item.planName === 'Productivity Plus' ? 'border border-[#FF9900]' : 'border border-[#5F5F5F]'}  px-6 py-8 rounded-lg w-[32%] my-8 cursor-pointer`}
          >
            {item.label && (
              <span className="text-[#FF9900] bg-[#FF99001A] p-2 rounded-lg py-2">
                {item.label}
              </span>
            )}
            <div
              className={`text-2xl text-white font-bold py-2 mt-2 ${item.label === '' && 'mt-7'}`}
            >
              {item.planName}
            </div>
            <div className="text-base font-normal text-[#FFFFFFB2] py-2">
              {item.planDes}
            </div>
            <div className=" py-2">
              <span className="text-[28px] text-white font-bold">
                {item.planType === 'Free'
                  ? 'Free'
                  : '$' + calculatePrice(item.planType, option?.option)}
              </span>
              {item.planType !== 'Free' && (
                <span className="text-[#FFFFFFB2] text-xl px-2">
                  per user &nbsp;/
                </span>
              )}
              <span className="text-[#FFFFFFB2] text-xl">
                {item.planType !== 'Free' &&
                  (option === 'Monthly'
                    ? 'month'
                    : option === 'Yearly'
                      ? 'year'
                      : 'life time')}
              </span>
            </div>
            <div className="w-full sm:w-auto py-2">
              <DefaultButton
                onClick={() => navigate('/contact-us')}
                className={`border border-[#FF9900] rounded-lg w-full  text-white hover:text-white px-6 py-6 ${item.label === 'MOST POPULAR' ? 'bg-[#FF9900] hover:bg-[#fca41f]' : 'hover:bg-[#FF9900]'}`}
              >
                <span className="flex gap-4 justify-center items-center">
                  <span className="text-[1rem]">Get started</span>
                  <span>
                    <LinkSvg />
                  </span>
                </span>
              </DefaultButton>
            </div>
            <div className="text-lg text-white font-semibold py-2">
              {item.planTag}
            </div>
            {item.planFeature.map((feat) => (
              <div className="py-2 flex justify-between">
                <div className="text-[#FFFFFFE5] text-base flex gap-3">
                  <Icon
                    iconName="Accept"
                    aria-label="Accept"
                    className="AcceptIcon font-semibold"
                  />
                  {feat.feature}
                </div>
                <div className="text-[#7A7A7A]">
                  <TooltipHost content={feat.featureDes}>
                    <Icon
                      iconName="Info"
                      aria-label="Info"
                      className="InfoIcon font-semibold"
                    />
                  </TooltipHost>
                </div>
              </div>
            ))}
            <div className="flex items-center gap-3 text-white ">
              <Icon
                iconName="Checklist"
                aria-label="Checklist"
                className="ChecklistIcon font-semibold"
              />
              <span className="text-base font-bold text-white underline">
                Compare plans
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PlanDetails;
