import {
  DefaultButton,
  IconButton,
  IModalProps,
  MessageBarType,
  Modal,
  Stack,
  Text,
} from '@fluentui/react';
import {
  PrimaryButtonWithLoader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { FC } from 'react';
import { useDeleteEmployeeMutation } from './employee.generated';

interface IEmployeeDeleteModal extends IModalProps {
  id: string;
}

const EmployeeDeleteModal: FC<IEmployeeDeleteModal> = ({ id, ...props }) => {
  const [{ fetching: loading }, deleteEmployee] = useDeleteEmployeeMutation();
  const { notification } = useNotification();
  const onDelete = async () => {
    try {
      await deleteEmployee({ userId: id });
      props?.onDismiss && props?.onDismiss();
      notification({
        message: 'Employee deactivated successfully',
        type: MessageBarType.success,
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal {...props}>
      <div className="p-5">
        <div className="flex justify-between mb-5">
          <div>
            <Text className="text-2xl">
              Are you sure you want to delete this user?
            </Text>
          </div>
          <div>
            <IconButton
              onClick={() => props?.onDismiss && props?.onDismiss()}
              iconProps={{ iconName: 'Cancel' }}
            />
          </div>
        </div>
        <div className="mb-5">
          On delete the account of this user will be deactivated, and license of
          this user will be cancelled.
        </div>
        <Stack
          horizontal
          horizontalAlign="end"
          grow
          tokens={{ childrenGap: 20 }}
        >
          <Stack.Item>
            <PrimaryButtonWithLoader
              loading={loading}
              onClick={onDelete}
              text="Delete"
            />
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              text="Cancel"
              onClick={() => props?.onDismiss && props?.onDismiss()}
            />
          </Stack.Item>
        </Stack>
      </div>
    </Modal>
  );
};

export default EmployeeDeleteModal;
