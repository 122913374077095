import {
  IconButton,
  MessageBarType,
  Modal,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { useEffect } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import {
  useGetGoalNoPerRolesMutation,
  usePerformanceReviewNotificationForManagerMutation,
} from './PerformanceReview.generated';

export const ReminderModal = ({ ...props }: any) => {
  const state = { ...props };
  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const [{ data: getGoalNoPerRolesData }, getGoalNoPerRoles] =
    useGetGoalNoPerRolesMutation();

  const [, performanceReviewNotificationForManager] =
    usePerformanceReviewNotificationForManagerMutation();

  useEffect(() => {
    getGoalNoPerRoles({
      object: {
        orgId: organization?.id,
        timePeriod: `${props.selectedTimePrd}`,
      },
    });
  }, [props.isOpen]);

  return (
    <div>
      <Modal
        {...props}
        onDismiss={() => {
          state.onDismiss(false);
        }}
      >
        <Stack
          className="overscroll-x-none "
          style={{ width: 600, height: 500 }}
        >
          <div className="flex flex-row pb-3 p-5 z-10 w-[600px] fixed justify-between bg-white">
            <span>
              <label className="text-[#323130] text-xl leading-7 font-semibold">
                Performance Review
              </label>
            </span>
            <span className="flex ml-auto justify-end cursor-pointer">
              <div>
                <IconButton
                  iconProps={{ iconName: 'Cancel' }}
                  ariaLabel="Close popup modal"
                  onClick={() => {
                    props.setSelectedTimePrd('');
                    state.onDismiss(false);
                  }}
                />
              </div>
            </span>
          </div>
          <div className="mt-16 px-5 pr-0 max-h-[350px] overflow-y-scroll">
            <table className="w-full">
              <thead className="sticky top-0">
                <tr className="border-b bg-white">
                  <th className="text-sm text-[#323130] font-semibold w-[15%] text-left p-1.5">
                    Role
                  </th>
                  <th className="text-sm text-[#323130] font-semibold w-[10%] text-left p-1.5">
                    No. of Goal
                  </th>
                  <th className="text-sm text-[#323130] font-semibold w-[14%] text-left p-1.5">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className=" ">
                {getGoalNoPerRolesData?.getGoalNoPerRoles?.response.map(
                  (info: any) => {
                    return (
                      <tr>
                        <td>{info.roleTitle}</td>
                        <td>{info.count}</td>
                        <td className="p-1.5">
                          <button
                            className="h-[35px] px-2 py-1 border border-[#8A8886]"
                            onClick={async () => {
                              try {
                                const { error } =
                                  await performanceReviewNotificationForManager(
                                    {
                                      object: {
                                        message: 'manager notification',
                                        userId: organization?.owned_by,
                                        peerId: info.managerId,
                                        roleTitle: info.roleTitle,
                                      },
                                    },
                                  );
                                if (error) {
                                  notification({
                                    message: error.message,
                                    type: MessageBarType.error,
                                  });
                                } else {
                                  notification({
                                    message: 'Reminder Sent successfully!',
                                    type: MessageBarType.success,
                                  });
                                }
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          >
                            Send reminder to manager
                          </button>
                        </td>
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
          <div className="flex p-5 pl-7 pt-0 w-[600px] space-x-2 absolute bottom-0  bg-white">
            <PrimaryButton
              text="Close"
              onClick={() => {
                state.onDismiss(false);
              }}
              form="goal"
              type="submit"
            ></PrimaryButton>
          </div>
        </Stack>
      </Modal>
    </div>
  );
};
