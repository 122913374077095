import { DefaultButton } from '@fluentui/react';
import {
  CheckboxField,
  CreatableSearchableSelectField,
  Form,
  ISearchableSelectOption,
  PrimaryButtonWithLoader,
  SearchableSelectField,
  TextField,
} from '@worx.squad/shared-frontend';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import { ProjectsData } from './ProjectsList';
import { newProjectSchema } from './new-project.schema';
import { useGetClientsByOrganizationIdQuery } from './projects-and-clients.generated';

interface INewProjectFormData {
  client: ISearchableSelectOption | undefined;
  projectTitle: string | undefined;
  description: string | undefined;
  nonBillable: boolean | undefined;
}

const NewProjectForm = ({
  onSubmit,
  onCancel,
  data,
  loading,
}: {
  onSubmit: (data: INewProjectFormData) => void;
  onCancel: () => void;
  data?: ProjectsData;
  loading: boolean;
}) => {
  const { organization } = useOrganizationContext();

  const newProjectForm = useForm<INewProjectFormData>({
    mode: 'all',
    resolver: newProjectSchema,
  });

  const [clientName, setClientName] = useState('');
  const [newClient, setNewClient] = useState<string>();
  const [projectClientOptions, setProjectClientOptions] =
    useState<ISearchableSelectOption[]>();

  const [clientNameLazy] = useDebounce(clientName, 400);

  const [{ data: projectClientsRaw }] = useGetClientsByOrganizationIdQuery({
    variables: {
      organizationId: organization?.id,
      clientName: clientNameLazy ? `%${clientNameLazy}%` : undefined,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setProjectClientOptions(() => {
      const mappedData = projectClientsRaw?.project_client.map((client) => ({
        label: client.client_name,
        value: client.id,
      }));
      if (newClient) {
        if (mappedData) return [{ label: newClient }, ...mappedData];
        return [{ label: newClient }];
      }
      return mappedData;
    });
  }, [newClient, projectClientsRaw]);

  useEffect(() => {
    if (data) {
      newProjectForm.setValue('projectTitle', data?.title || '');
      newProjectForm.setValue('description', data?.description || '');
      newProjectForm.setValue('nonBillable', !data?.is_billable || false);
      newProjectForm.setValue(
        'client',
        projectClientOptions?.find(
          ({ value }) => value === data?.project_client?.id,
        ),
      );
    }
  }, [data, projectClientOptions]);

  return (
    <Form
      formHook={newProjectForm}
      onSubmit={onSubmit}
      className="flex flex-col gap-6"
    >
      {data ? (
        <SearchableSelectField
          formHook={newProjectForm}
          name="client"
          label="Client"
          placeholder="Search or select client"
          options={projectClientOptions}
          inputValue={clientName}
          onInputChange={setClientName}
        />
      ) : (
        <CreatableSearchableSelectField
          formHook={newProjectForm}
          name="client"
          label="Client"
          placeholder="Search or select client"
          noOptionsMessage={() => 'Create new client'}
          onCreateOption={(inputValue) => setNewClient(inputValue)}
          options={projectClientOptions}
          inputValue={clientName}
          onInputChange={setClientName}
        />
      )}
      <TextField
        formHook={newProjectForm}
        maxLength={50}
        name="projectTitle"
        label="Project Title"
      />
      <TextField
        formHook={newProjectForm}
        name="description"
        label="Description"
        multiline
      />
      <CheckboxField
        formHook={newProjectForm}
        defaultChecked={data && !data?.is_billable}
        name="nonBillable"
        label="Non-Billable Project"
      />
      <div className="flex gap-4">
        <PrimaryButtonWithLoader loading={loading} type="submit" text="Add" />
        <DefaultButton onClick={onCancel} text="Cancel" />
      </div>
    </Form>
  );
};

export default NewProjectForm;
