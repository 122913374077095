import { Pivot, PivotItem, Stack } from '@fluentui/react';
import { useHeader } from '@worx.squad/shared-frontend';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CompetencyList from '../components/Competencies/Competency/CompetencyList';
import SkillList from '../components/Competencies/Skill/SkillList';

export const Competencies = () => {
  const { setTitle } = useHeader();
  useEffect(() => {
    setTitle?.('Competencies');
  }, [setTitle]);

  return (
    <>
      <Helmet>
        <title>Competencies | Worx Squad</title>
      </Helmet>
      <Stack tokens={{ childrenGap: 30 }} className="mainContainer">
        <Pivot>
          <PivotItem headerText="Skill">
            <SkillList />
          </PivotItem>
          <PivotItem headerText="Competency">
            <CompetencyList />
          </PivotItem>
        </Pivot>
      </Stack>
    </>
  );
};
