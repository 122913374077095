import { IconButton, Text } from '@fluentui/react';
import { useState } from 'react';
import { productivityItemsTwo } from './Utils';
import KudosImg from './assets/KudosImg.png';
import ProductivityImg from './assets/ProductivityImg.png';
import WallOfMoments from './assets/WallOfMoments.png';

const FeatureProductivityTwo = () => {
  const [disabledOne, setDisabledOne] = useState(true);
  const items = productivityItemsTwo.map((i, key) => {
    return {
      key: key,
      content: i.content,
      title: i.title,
    };
  });

  return (
    <div>
      <div className="flex justify-between ga">
        <div className="text-center w-[50%] mt-8 sm:mt-2">
          <div className="py-2">
            <div
              className="text-left mr-1 flex justify-between items-center gap-10 cursor-pointer"
              onClick={() => {
                setDisabledOne(!disabledOne);
              }}
            >
              <Text className="text-lg font-semibold text-white">
                Chat, Call, Meet, Broadcast
              </Text>
              <IconButton
                iconProps={
                  disabledOne
                    ? { iconName: 'CalculatorSubtract' }
                    : { iconName: 'Add' }
                }
              />
            </div>
            <div
              className="mr-5 sm:mr-0"
              style={{ maxWidth: 800, textAlign: 'left' }}
            >
              <Text
                className="font-medium text-lg text-white"
                style={!disabledOne ? { display: 'none' } : {}}
              >
                Employees can easily fill timesheets with intuitive UX and
                Managers will be alert for approval
              </Text>
            </div>
          </div>
          <div className="mx-auto">
            {items.map((item, index) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const [disabled, setDisabled] = useState(false);
              if (index === item.key && index !== 0) {
                return (
                  <div className="py-2 my-1" key={index}>
                    <div
                      className="text-left mr-1 flex justify-between items-center gap-10 cursor-pointer"
                      onClick={() => {
                        setDisabled(!disabled);
                      }}
                    >
                      <Text className="text-lg font-semibold text-white">
                        {item.title}
                      </Text>
                      <IconButton
                        iconProps={
                          disabled
                            ? { iconName: 'CalculatorSubtract' }
                            : { iconName: 'Add' }
                        }
                      />
                    </div>
                    <div
                      className="mr-5 sm:mr-0"
                      style={{ maxWidth: 800, textAlign: 'left' }}
                    >
                      <Text
                        className="font-medium text-lg text-white"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {item.content}
                      </Text>
                    </div>
                  </div>
                );
              } else {
                return '';
              }
            })}
          </div>
        </div>{' '}
        <div className="w-[45%]">
          <img src={ProductivityImg} alt="ProductivityImg" />
        </div>
      </div>
      <div className="flex justify-between my-4 text-white">
        <div className="w-[50%]">
          <div className="text-4xl font-medium">
            A social space built for you and your organisation
          </div>
          <div className="pt-16">
            <img src={KudosImg} alt="KudosImg" />
          </div>
        </div>
        <div className="w-[43%]">
          <img src={WallOfMoments} alt="WallOfMoments" />
        </div>
      </div>
    </div>
  );
};

export default FeatureProductivityTwo;
