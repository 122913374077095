import { Link } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import AddLocationPanel from './AddLocationPanel';

const EditLocationActions = ({ locationId }: { locationId: string }) => {
  const [
    editLocationModalVisible,
    { setTrue: showEditLocationModal, setFalse: hideEditLocationModal },
  ] = useBoolean(false);

  return (
    <>
      <Link onClick={showEditLocationModal}>Edit</Link>
      <AddLocationPanel
        isModalOpen={editLocationModalVisible}
        hideModal={hideEditLocationModal}
        edit
        locationId={locationId}
      />
    </>
  );
};

export default EditLocationActions;
