import { IconButton, IModalProps, Modal, Text } from '@fluentui/react';
import { OperationContext } from '@urql/core';
import AssessmentTypeAddForm from './AssessmentTypeAddForm';

interface IRoleAddModal extends IModalProps {
  refetchTypes?: (opts?: Partial<OperationContext> | undefined) => void;
}

const AssessmentAddModal = ({ refetchTypes, ...props }: IRoleAddModal) => {
  return (
    <Modal {...props}>
      <div className="p-5 w-96 h-72">
        <div className="flex  justify-between mb-6">
          <div>
            <Text className="text-2xl">Add new Assessment type</Text>
          </div>
          <div>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={() => props?.onDismiss && props?.onDismiss()}
            />
          </div>
        </div>
        <div>
          <AssessmentTypeAddForm
            refetch={refetchTypes}
            onCancel={() => props?.onDismiss && props?.onDismiss()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AssessmentAddModal;
