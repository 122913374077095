import { IconButton } from '@fluentui/react';
import classNames from 'classnames';
import { addDays, format, getDate, getYear, subDays } from 'date-fns';
import moment from 'moment';
import { useState } from 'react';
import { SArrowButton } from './styles';

interface IDayCellProps {
  dateNum: number;
  month: string;
  year: number;
  monthNumber: string;
}
interface IDailyCalendar {
  onSelect: ({ dateNum, month, year }: IDayCellProps) => void;
}

export const DailyCalendar = ({ onSelect }: IDailyCalendar) => {
  const [currentDay, setCurrentDay] = useState<Date>(new Date());
  const [selectedMonth, setSelectedMonth] = useState<Date>(currentDay);

  const handleNext = () => {
    // setSelectedMonth(moment(selectedMonth).add(1, 'day').toDate());

    setCurrentDay(addDays(currentDay, 1));
  };

  const handleBack = () => {
    // setSelectedMonth(moment(selectedMonth).subtract(1, 'day').toDate());
    setCurrentDay(subDays(currentDay, 1));
  };

  const DayCell = ({ dateNum, month, monthNumber, year }: IDayCellProps) => {
    return (
      <div
        onClick={() => {
          setSelectedMonth(moment(`${dateNum}/${month}/${year}`).toDate());
          onSelect({ dateNum, month, monthNumber, year });
        }}
        className={classNames(
          'flex flex-col pb-1 px-2 rounded-md cursor-pointer',
          {
            'text-white bg-primary ': selectedMonth.getDate() === dateNum,
          },
        )}
      >
        <div className="text-center font-normal">{dateNum}</div>
        <div className="text-center text-xs font-normal">{month}</div>
        <div className="text-center text-xs font-normal">{year}</div>
      </div>
    );
  };

  const DalyList = () =>
    [-4, -3, -2, -1, 0].map((i) => {
      if (i < 0) {
        const dateNum = subDays(currentDay, Math.abs(i));
        return (
          <DayCell
            dateNum={getDate(dateNum)}
            month={format(dateNum, 'MMMM')}
            monthNumber={format(dateNum, 'MM')}
            year={getYear(dateNum)}
          />
        );
      } else if (i > 0) {
        const dateNum = addDays(currentDay, i);
        return (
          <DayCell
            dateNum={getDate(dateNum)}
            month={format(dateNum, 'MMMM')}
            monthNumber={format(dateNum, 'MM')}
            year={getYear(dateNum)}
          />
        );
      }
      return (
        <DayCell
          dateNum={getDate(currentDay)}
          month={format(currentDay, 'MMMM')}
          monthNumber={format(currentDay, 'MM')}
          year={getYear(currentDay)}
        />
      );
    });

  return (
    <div className="flex bg-white rounded-md p-3 border items-center">
      <div className="pr-2">
        <IconButton
          iconProps={{ iconName: 'NavigateBack' }}
          styles={SArrowButton}
          onClick={handleBack}
        />
      </div>
      <div className="flex justify-between gap-2">{DalyList()}</div>
      <div className="pl-2">
        <IconButton
          iconProps={{ iconName: 'NavigateBackMirrored' }}
          styles={SArrowButton}
          onClick={handleNext}
          disabled={moment().subtract(1, 'day').isBefore(currentDay)}
        />
      </div>
    </div>
  );
};
