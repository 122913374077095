import { useAuth } from '@worx.squad/hbp-react';
import { Navigate, useLocation } from 'react-router-dom';
import { useOrganizationContext } from '../context/OrganizationContext/OrganizationContext';

const ProtectedRoute: React.FC<{
  ownerOnly?: boolean;
}> = ({ children, ownerOnly }) => {
  const { signedIn, user } = useAuth();
  const { organization } = useOrganizationContext();
  const location = useLocation();

  const isOwner = user?.id === organization?.owned_by;

  if (!signedIn) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{ from: location }}
      />
    );
  } else {
    if (!isOwner && ownerOnly) {
      return (
        <Navigate
          to={{
            pathname: '/dashboard',
          }}
          state={{ from: location }}
        />
      );
    } else return <>{children}</>;
  }
};

export default ProtectedRoute;
