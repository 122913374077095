import { IconButton, Separator, Stack, Text } from '@fluentui/react';
import { useState } from 'react';
import { pricingFeatures } from './Utils';

export const FAQ = () => {
  const items = pricingFeatures.map((i, key) => {
    return {
      key: key,
      title: i.title,
      subTitleOne: i?.subTitleOne,
      contentOne: i?.contentOne,
      subTitleTwo: i?.subTitleTwo,
      contentTwo: i?.contentTwo,
      subTitleThree: i?.subTitleThree,
      contentThree: i?.contentThree,
      subTitleFour: i?.subTitleFour,
      contentFour: i?.contentFour,
      subTitleFive: i?.subTitleFive,
      contentFive: i?.contentFive,
      subTitleSix: i?.subTitleSix,
      contentSix: i?.contentSix,
      subTitleSeven: i?.subTitleSeven,
      contentSeven: i?.contentSeven,
    };
  });

  return (
    <Stack className="bg-white bg-opacity-20  py-10 text-center">
      <div className="mx-[20px] sm:mx-[40px]">
        {items.map((item, index) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [disabled, setDisabled] = useState(false);
          if (index === item.key) {
            return (
              <div className="pl-2 lg:pl-0" key={index}>
                <div
                  className="text-left mr-1 flex justify-between gap-10 sm:gap-96 cursor-pointer"
                  onClick={() => {
                    setDisabled(!disabled);
                  }}
                >
                  <Text className="text-lg font-semibold">{item.title}</Text>
                  <div className="border-[2px] border-[#FF9900] rounded-full pt-[1.75px] px-[3px] text-center">
                    <IconButton
                      className="text-[#FF9900] hover:text-[#FF9900] hover:bg-transparent p-0"
                      iconProps={
                        disabled
                          ? { iconName: 'CalculatorSubtract' }
                          : { iconName: 'Add' }
                      }
                      styles={{
                        root: {
                          width: '18px',
                          height: '18px',
                        },
                        icon: {
                          fontSize: '12px',
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="mr-5 gap-2" style={{ textAlign: 'left' }}>
                  {item.subTitleOne && (
                    <div>
                      <div className="flex flex-col">
                        <Text
                          className="text-[1rem] text-[#090700] font-semibold mt-2"
                          style={!disabled ? { display: 'none' } : {}}
                        >
                          {' '}
                          {item.subTitleOne}
                        </Text>
                        <Text
                          className="font-normal text-[1rem] text-[#090700]"
                          style={!disabled ? { display: 'none' } : {}}
                        >
                          {item.contentOne}
                        </Text>
                        {disabled && (
                          <div className="my-2   h-[0.5px] bg-black opacity-20"></div>
                        )}
                      </div>
                    </div>
                  )}
                  {item.subTitleTwo && (
                    <div className="flex flex-col">
                      <Text
                        className="text-[1rem] text-[#090700] font-semibold mt-2"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {' '}
                        {item.subTitleTwo}
                      </Text>
                      <Text
                        className="font-normal text-[1rem] text-[#090700]"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {item.contentTwo}
                      </Text>
                      {disabled && (
                        <div className="my-2  h-[0.5px] bg-black opacity-20"></div>
                      )}
                    </div>
                  )}
                  {item.subTitleThree && (
                    <div className="flex flex-col">
                      <Text
                        className="text-[1rem] text-[#090700] font-semibold mt-2"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {' '}
                        {item.subTitleThree}
                      </Text>
                      <Text
                        className="font-normal text-[1rem] text-[#090700]"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {item.contentThree}
                      </Text>
                      {disabled && item.title !== 'Wellness' && (
                        <div className="my-2  h-[0.5px] bg-black opacity-20"></div>
                      )}
                    </div>
                  )}
                  {item.subTitleFour && (
                    <div className="flex flex-col">
                      <Text
                        className="text-[1rem] text-[#090700] font-semibold mt-2"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {' '}
                        {item.subTitleFour}
                      </Text>
                      <Text
                        className="font-normal text-[1rem] text-[#090700]"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {item.contentFour}
                      </Text>
                      {disabled && item.title !== 'Engagement' && (
                        <div className="my-2   h-[0.5px] bg-black opacity-20"></div>
                      )}
                    </div>
                  )}
                  {item.subTitleFive && (
                    <div className="flex flex-col">
                      <Text
                        className="text-[1rem] text-[#090700] font-semibold mt-2"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {' '}
                        {item.subTitleFive}
                      </Text>
                      <Text
                        className="font-normal text-[1rem] text-[#090700]"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {item.contentFive}
                      </Text>
                      {disabled && (
                        <div className="my-2   h-[0.5px] bg-black opacity-20"></div>
                      )}
                    </div>
                  )}
                  {item.subTitleSix && (
                    <div className="flex flex-col">
                      <Text
                        className="text-[1rem] text-[#090700] font-semibold mt-2"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {' '}
                        {item.subTitleSix}
                      </Text>
                      <Text
                        className="font-normal text-[1rem] text-[#090700]"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {item.contentSix}
                      </Text>
                      {disabled && item.title !== 'Collaboration' && (
                        <div className="my-2   h-[0.5px] bg-black opacity-20"></div>
                      )}
                    </div>
                  )}
                  {item.subTitleSeven && (
                    <div className="flex flex-col">
                      <Text
                        className="text-[1rem] text-[#090700] font-semibold mt-2"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {' '}
                        {item.subTitleSeven}
                      </Text>
                      <Text
                        className="font-normal text-[1rem] text-[#090700]"
                        style={!disabled ? { display: 'none' } : {}}
                      >
                        {item.contentSeven}
                      </Text>
                    </div>
                  )}
                </div>
                <div className="my-4 h-[0.5px] bg-[#FF9900]"></div>
                <Separator className="opacity-0" />
              </div>
            );
          } else {
            return '';
          }
        })}
      </div>
    </Stack>
  );
};
