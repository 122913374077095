import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  client: yup.object().required().label('Client'),
  projectTitle: yup.string().required().max(50).label('Project Title'),
  description: yup.string().required().label('Description'),
  nonBillable: yup.boolean().label('Non Billable'),
});

export const newProjectSchema = yupResolver(schema);
