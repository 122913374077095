import { Pivot, PivotItem } from '@fluentui/react';
import { useHeader, useLayoutState } from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect } from 'react';
import { OrganizationEditForm } from '../../components/Settings/Organization/organizationEditForm';

const OrganizationSettings = () => {
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();

  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Organization Settings');
  }, [setTitle]);

  return (
    <div>
      <Pivot aria-label="Basic Pivot Example">
        <PivotItem
          headerText="Details"
          headerButtonProps={{
            'data-order': 1,
            'data-title': 'My Files Title',
          }}
        >
          <div className="w-2/3">
            <OrganizationEditForm />
          </div>
        </PivotItem>
        <PivotItem headerText="Permissions"></PivotItem>
      </Pivot>
    </div>
  );
};
export default OrganizationSettings;
