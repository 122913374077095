import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';

const schema = yup.object().shape({
  organization_name: yup
    .string()
    .trim()
    .max(100, 'Maximum 100 characters are allowed')
    .required('Organization Name is required'),
  organization_seat_no: yup
    .string()
    .trim()
    .required('Number of seats are required'),
  organization_employee_no: yup
    .string()
    .trim()
    .required('Organization Employee count is required'),
  org_start_time: yup.string().required('Office Hour Start time  is required'),
  timezone_name: yup.string().required('Timezone is required'),
  org_end_time: yup
    .string()
    .required('Office Hour End is required')
    .test(
      'org_start_time',
      'End time must be coming after start time',
      function (value) {
        const { org_start_time } = this.parent;
        console.log(value, org_start_time);
        return isSameOrBefore(org_start_time, value);
      },
    ),
  working_hours: yup.string().trim().required('Working Hour(s) is required'),
  working_days: yup.string().trim().required('Working Days are required'),
  logo_file: yup
    .mixed()
    .test(
      'type',
      'Only the following formats are accepted:  .jpg, .jpeg, .png & .svg',
      (value: any) => {
        return !isEmpty(value)
          ? value[0].type === 'image/jpeg' ||
              value[0].type === 'image/svg+xml' ||
              value[0].type === 'image/png'
          : true;
      },
    )
    .nullable(),
  holiday_list_file: yup
    .mixed()
    .test('type', 'Only .ics following formats are accepted', (value: any) => {
      return !isEmpty(value) ? value[0].type === 'text/calendar' : true;
    })
    .nullable(),
});
const isSameOrBefore = (org_start_time: any, org_end_time: any) => {
  return moment(org_start_time, 'HH:mm').isSameOrBefore(
    moment(org_end_time, 'HH:mm'),
  );
};
const onBoardingOrganizationSchema = yupResolver(schema);

export default onBoardingOrganizationSchema;
