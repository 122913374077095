import { useAuth } from '@worx.squad/hbp-react';
import { useLogoutMutation } from '..';

export const useLogout = () => {
  const { auth } = useAuth();
  const [, logout] = useLogoutMutation();

  return async () => {
    localStorage.clear();
    const refreshToken = await auth.getRefreshToken();
    try {
      await logout({
        refreshToken,
      });
      await auth.logout();
    } catch (error: any) {
      console.error(error);
    } finally {
      await auth.refreshSession();
    }
  };
};
