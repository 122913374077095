import DeepDiv from './assets/DeepDiv';
import DeploymentFriendly from './assets/DeploymentFriendly';
import FullEcosystem from './assets/FullEcosystem';
import HyperPersonalization from './assets/HyperPersonalization';
import ImmersiveExperience from './assets/ImmersiveExperience';
import Interoperability from './assets/Interoperability';

export const ProductivityNExperiencePlatform = () => {
  return (
    <div className="p-20 bg-[#1A1A1A]">
      <div className="text-[24px] sm:text-4xl leading-[2.5rem] lg:leading-[3.5rem] text-white text-center">
        How Squad stands out
      </div>
      <div className="text-white text-xl text-center mt-4">
        Discover the features that elevate user satisfaction and engagement
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 p-3 sm:p-6 gap-6 mt-10">
        <div className="rounded-md border shadow-lg hover:bg-gradient-to-b hover:from-[rgba(43,43,43,0.9)] hover:to-[rgba(24,93,116,0.9)] hover:text-white hover:transition-all hover:duration-500 hover:scale-110">
          <div className="p-4 sm:p-6">
            <FullEcosystem />
          </div>
          <div className="p-4 sm:px-6 sm:pb-6 sm:pt-0 text-white">
            <div className="text-[24px] text-bold font-semibold">
              {' '}
              Full Ecosystem
            </div>
            <div className="text-[#B9B9B9]">
              All business applications built within the Squad app for
              productivity.
            </div>
          </div>
        </div>
        <div className="rounded-md border shadow-lg hover:bg-gradient-to-b hover:from-[rgba(43,43,43,0.9)] hover:to-[rgba(24,93,116,0.9)] hover:text-white hover:transition-all hover:duration-500 hover:scale-110">
          <div className="p-4 sm:p-6">
            <ImmersiveExperience />
          </div>
          <div className="p-4 sm:px-6 sm:pb-6 sm:pt-0 text-white">
            <div className="text-[24px] text-bold font-semibold">
              Immersive Experience
            </div>
            <div className="text-[#B9B9B9]">
              3D meeting rooms, avatars, interactions and an intuitive user
              interface.
            </div>
          </div>
        </div>
        <div className="rounded-md border shadow-lg hover:bg-gradient-to-b hover:from-[rgba(43,43,43,0.9)] hover:to-[rgba(24,93,116,0.9)] hover:text-white hover:transition-all hover:duration-500 hover:scale-110">
          <div className="p-4 sm:p-6">
            <Interoperability />
          </div>
          <div className="p-4 sm:px-6 sm:pb-6 sm:pt-0 text-white">
            <div className="text-[24px] text-bold font-semibold">
              Interoperability
            </div>
            <div className="text-[#B9B9B9]">
              Features communicate and work together seamlessly.
            </div>
          </div>
        </div>
        <div className="rounded-md border shadow-lg hover:bg-gradient-to-b hover:from-[rgba(43,43,43,0.9)] hover:to-[rgba(24,93,116,0.9)] hover:text-white hover:transition-all hover:duration-500 hover:scale-110">
          <div className="p-4 sm:p-6">
            <HyperPersonalization />
          </div>
          <div className="p-4 sm:px-6 sm:pb-6 sm:pt-0 text-white">
            <div className="text-[24px] text-bold font-semibold">
              Hyper Personalization
            </div>
            <div className="text-[#B9B9B9]">
              Tailored to different user roles within the organisation, with
              custom actions and workflows.
            </div>
          </div>
        </div>
        <div className="rounded-md border shadow-lg hover:bg-gradient-to-b hover:from-[rgba(43,43,43,0.9)] hover:to-[rgba(24,93,116,0.9)] hover:text-white hover:transition-all hover:duration-500 hover:scale-110">
          <div className="p-4 sm:p-6">
            <DeepDiv />
          </div>
          <div className="p-4 sm:px-6 sm:pb-6 sm:pt-0 text-white">
            <div className="text-[24px] font-semibold">
              Organization Insights
            </div>
            <div className="text-[#B9B9B9]">
              Insights into productivity, skill gaps, billable hours, employee
              capacity and much more.
            </div>
          </div>
        </div>
        <div className="rounded-md border shadow-lg hover:bg-gradient-to-b hover:from-[rgba(43,43,43,0.9)] hover:to-[rgba(24,93,116,0.9)] hover:text-white hover:transition-all hover:duration-500 hover:scale-110">
          <div className="p-4 sm:p-6">
            <DeploymentFriendly />
          </div>
          <div className="p-4 sm:px-6 sm:pb-6 sm:pt-0 text-white">
            <div className="text-[24px] text-bold font-semibold">
              Highly Intuitive
            </div>
            <div className="text-[#B9B9B9]">
              Designed with user-friendly principles to minimize the learning
              curve and intuitive to use.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
