import {
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  MessageBarType,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  PrimaryButtonWithLoader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { FC } from 'react';
import { Holiday } from './holiday.types';
import { useDeleteHolidayMutation } from './holidays.generated';

const DeleteHoliday: FC<{ item: Holiday }> = ({ item }) => {
  const [hidden, { toggle }] = useBoolean(true);
  const [{ fetching }, deleteHoliday] = useDeleteHolidayMutation();
  const { notification } = useNotification();

  async function onDelete() {
    try {
      const res = await deleteHoliday({ id: item.id });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: `${item.name} holiday deleted successfully`,
          type: MessageBarType.success,
        });
        window.dispatchEvent(new CustomEvent('refresh-holidays'));
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <IconButton onClick={toggle} iconProps={{ iconName: 'Delete' }} />
      <Dialog
        hidden={hidden}
        onDismiss={toggle}
        dialogContentProps={{
          title: 'Delete Holiday?',
          subText: `Are you sure you want to delete ${item.name} from holidays?`,
          type: DialogType.normal,
        }}
      >
        <DialogFooter>
          <PrimaryButtonWithLoader
            onClick={onDelete}
            loading={fetching}
            text="Delete"
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DeleteHoliday;
