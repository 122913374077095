import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBarType,
} from '@fluentui/react';
import {
  PrimaryButtonWithLoader,
  dispatchListeners,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useDeleteAwardsByIdMutation } from '../awards.generated';

const AwardsDeleteConfirmation = ({
  awardId,
  dialogHidden,
  toggleDialog,
}: {
  awardId: string | null | undefined;
  dialogHidden: boolean;
  toggleDialog: () => void;
}) => {
  const listenerItems = ['refetch-awards'];
  const { notification } = useNotification();
  const [{ fetching }, deleteAward] = useDeleteAwardsByIdMutation();
  const onDelete = async () => {
    const res = await deleteAward({ awardId });

    if (res.error) {
      notification({
        message: 'Oops something went wrong!',
        type: MessageBarType.error,
      });
      return;
    }
    if (res.data) {
      notification({
        message: 'Award deleted successfully',
        type: MessageBarType.success,
      });
      dispatchListeners(listenerItems);
      toggleDialog();
    }
  };

  return (
    <Dialog
      hidden={dialogHidden}
      onDismiss={toggleDialog}
      dialogContentProps={{
        type: DialogType.close,
        title: 'Are you sure?',
        subText:
          'This will delete the award type, along with all other awards given to users under this award type!',
      }}
    >
      <DialogFooter>
        <PrimaryButtonWithLoader
          loading={fetching}
          text="Yes"
          onClick={onDelete}
        />
        <DefaultButton onClick={toggleDialog} text="No" />
      </DialogFooter>
    </Dialog>
  );
};

export default AwardsDeleteConfirmation;
