import { IDropdownOption } from '@fluentui/react';
import { Enum_Task_Status_Enum } from '@worx.squad/app-sdk/base-types';
import { StatusItem } from '../lib/Components';

export const statusArray: IDropdownOption[] = [
  {
    key: Enum_Task_Status_Enum.Open,
    text: 'Pending',
    data: {
      color: '#FF9900',
    },
  },
  {
    key: Enum_Task_Status_Enum.InProgress,
    text: 'In-Progress',
    data: {
      color: '#0078D4',
    },
  },
  {
    key: Enum_Task_Status_Enum.Completed,
    text: 'Completed',
    data: {
      color: '#579C43',
    },
  },
  {
    key: Enum_Task_Status_Enum.Reopened,
    text: 'Reopened',
    data: {
      color: '#da4d4d',
    },
  },
];

export const DelayedTask: IDropdownOption = {
  key: 'DELAYED',
  text: 'Delayed',
  data: {
    color: '#ac0000',
  },
};

export const DelayedTaskStatusItem = () => (
  <div className={'p-2'}>
    <StatusItem option={DelayedTask} />
  </div>
);
