import { DefaultButton, MessageBarType } from '@fluentui/react';
import {
  Form,
  PrimaryButtonWithLoader,
  SaveOrganizationAdminInput,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import DepartmentSelect from './DepartmentSelect';
import { useSaveOrganizationAdminMutation } from './administrators.generated';

interface IAddNewAdminForm {
  onCancel: () => void;
}

const AddNewAdminForm = ({ onCancel }: IAddNewAdminForm) => {
  const addNewAdminForm = useForm<SaveOrganizationAdminInput>();
  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const [{ fetching: loading }, saveOrganizationAdmin] =
    useSaveOrganizationAdminMutation();

  const onSubmit = async (data: SaveOrganizationAdminInput) => {
    try {
      const saveOrganizationAdminOperation = await saveOrganizationAdmin({
        object: {
          departmentId: get(data, 'department.value'),
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          organizationId: data.organizationId,
          password: data.password,
        },
      });

      if (saveOrganizationAdminOperation.error)
        throw saveOrganizationAdminOperation.error;

      notification({
        message: 'Successfully created new admin',
        type: MessageBarType.success,
      });

      onCancel();
    } catch (error: any) {
      notification({
        message: error?.message,
        type: MessageBarType.error,
      });
    }
  };
  console.log('loading', loading);
  useEffect(() => {
    if (organization?.id)
      addNewAdminForm.setValue('organizationId', organization?.id);
  }, [organization?.id]);

  return (
    <Form formHook={addNewAdminForm} onSubmit={onSubmit}>
      <div className="h-auto flex flex-col gap-5">
        <div className="flex gap-5">
          <div className="w-1/2">
            <TextField
              placeholder="Enter first name"
              formHook={addNewAdminForm}
              name="firstName"
              label="First name"
              required
            />
          </div>
          <div className="w-1/2">
            <TextField
              placeholder="Enter last name"
              formHook={addNewAdminForm}
              name="lastName"
              label="Last name"
              required
            />
          </div>
        </div>
        <div className="flex gap-5">
          <div className="w-1/2">
            <TextField
              placeholder="Enter email"
              formHook={addNewAdminForm}
              name="email"
              label="Email"
              required
            />
          </div>
          <div className="w-1/2">
            <DepartmentSelect formHook={addNewAdminForm} />
          </div>
        </div>
        <div>
          <TextField
            placeholder="Enter password"
            formHook={addNewAdminForm}
            name="password"
            label="Password"
          />
        </div>
        <div>
          <div className="flex gap-4">
            <PrimaryButtonWithLoader loading={loading} type="submit">
              Add
            </PrimaryButtonWithLoader>
            <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AddNewAdminForm;
