import { filesize } from 'filesize';
import { get } from 'lodash';
import { storage } from './hbp-helper';

export interface IUploadFile {
  path: string;
  file: File;
  insertFile: any;
  data: { created_by?: string; type: string };
}

// export const getFileType = async (file: RcFile) => {
//   const type = await FileType.fromBlob(file);
//   if (type !== undefined) {
//     return type?.ext;
//   } else {
//     if (file?.type === 'text/csv') return 'csv';
//     if (file?.type === 'application/vnd.ms-powerpoint') return 'ppt';
//     if (file?.type === 'application/msword') return 'doc';
//     if (file?.type === 'application/vnd.ms-excel') return 'xls';
//   }
//   return null;
// };

export const uploadFile = async ({
  path,
  file,
  insertFile,
  data: objects,
}: IUploadFile) => {
  const res = await storage.put(path, file);
  const { data } = await insertFile({
    object: {
      ...objects,
      file_url: res.key,
      metadata: {
        file_size: filesize(file?.size),
        file_type: file?.type,
      },
    },
  });
  return { id: get(data, 'insert_storage_files_one.id') };
};
