import { IconButton, Text } from '@fluentui/react';
import { useState } from 'react';
import { productivityItems } from './Utils';
import FeatureProd from './assets/FeatureProd.svg';
const FeatureProductivity = () => {
  const [disabledOne, setDisabledOne] = useState(true);
  const items = productivityItems.map((i, key) => {
    return {
      key: key,
      content: i.content,
      title: i.title,
    };
  });

  return (
    <div className="flex justify-between">
      <div className="text-center w-[40%] mt-8 sm:mt-2">
        <div className="py-2">
          <div
            className="text-left mt-2 mr-1 flex justify-between items-center gap-10 cursor-pointer"
            onClick={() => {
              setDisabledOne(!disabledOne);
            }}
          >
            <Text className="text-lg font-semibold text-[#185D74]">
              Project management
            </Text>
            <IconButton
              iconProps={
                disabledOne
                  ? { iconName: 'CalculatorSubtract' }
                  : { iconName: 'Add' }
              }
            />
          </div>
          <div
            className="mr-5 sm:mr-0"
            style={{ maxWidth: 800, textAlign: 'left' }}
          >
            <Text
              className="font-medium text-lg text-[#000000]"
              style={!disabledOne ? { display: 'none' } : {}}
            >
              Track tasks status (time to complete tasks) & deviations in
              project goals timelines.
            </Text>
          </div>
        </div>
        <div className="mx-auto">
          {items.map((item, index) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [disabled, setDisabled] = useState(false);
            if (index === item.key && index !== 0) {
              return (
                <div className="py-2 my-1" key={index}>
                  <div
                    className="text-left mr-1 flex justify-between items-center gap-10 cursor-pointer"
                    onClick={() => {
                      setDisabled(!disabled);
                    }}
                  >
                    <Text className="text-lg font-semibold text-[#185D74]">
                      {item.title}
                    </Text>
                    <IconButton
                      iconProps={
                        disabled
                          ? { iconName: 'CalculatorSubtract' }
                          : { iconName: 'Add' }
                      }
                    />
                  </div>
                  <div
                    className="mr-5 sm:mr-0"
                    style={{ maxWidth: 800, textAlign: 'left' }}
                  >
                    <Text
                      className="font-medium text-lg text-[#000000]"
                      style={!disabled ? { display: 'none' } : {}}
                    >
                      {item.content}
                    </Text>
                  </div>
                </div>
              );
            } else {
              return '';
            }
          })}
        </div>
      </div>{' '}
      <div>
        <img src={FeatureProd} alt="Starter Icon" />
      </div>
    </div>
  );
};

export default FeatureProductivity;
