import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryString = () => {
  const { search, hash } = useLocation();
  return {
    search: useMemo(() => new URLSearchParams(search), [search]),
    hash: useMemo(() => new URLSearchParams(hash), [hash]),
  };
};
