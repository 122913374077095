import { Text } from '@fluentui/react';
import { useUser } from '../../../hooks/user.hook';
import { useGetAllMyUnreadMessagesCountSubscription } from '../../../shared-graphql';

const UnreadCountBadge = () => {
  const user = useUser();
  const [{ data }] = useGetAllMyUnreadMessagesCountSubscription({
    variables: {
      userId: user.id,
    },
  });

  const count = data?.chat_message_action_aggregate?.aggregate?.count;
  const isThreeDigits = count && count >= 100 && count < 1000;

  return (
    <div
      className={`h-5 w-5 bg-red-700 rounded-[100%] p-[2px]
        flex items-center justify-center ${count ? 'visible' : 'hidden'}`}
    >
      <Text
        variant={isThreeDigits ? 'small' : 'medium'}
        className="text-white font-bold"
      >
        {count}
      </Text>
    </div>
  );
};

export default UnreadCountBadge;
