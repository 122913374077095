import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  IDetailsListProps,
  SelectionMode,
  TextField,
} from '@fluentui/react';
import { Enum_Award_Cycle_Type_Enum } from '@worx.squad/shared-frontend';
import { FC, useState } from 'react';
import AwardsActionBar from './AwardsActionBar';
import CreateAward from './actions/CreateAward';
import {
  awardCycleOpts,
  formatCycleType,
  getAwardsListColumns,
  newAwardRaw,
} from './shared';
import { IAwardsData } from './types';
const columns = getAwardsListColumns();

const AwardsListTable = ({ awards }: { awards: IAwardsData[] }) => {
  const [newAward, setNewAward] = useState<IAwardsData>(newAwardRaw);

  const RowItem: FC<{ item: IAwardsData }> = ({ item, children }) => (
    <div className="w-full cursor-pointer flex gap-6 items-center justify-between">
      {children}
    </div>
  );
  const onRenderItemColumn: IDetailsListProps['onRenderItemColumn'] = (
    item: IAwardsData,
    index,
    column,
  ) => {
    const fieldContent = item[column?.key as keyof IAwardsData] as string;
    if (item.newAwardList) {
      switch (column?.key) {
        case 'name': {
          return (
            <TextField
              borderless
              placeholder="Enter award name"
              value={newAward.name || ''}
              onChange={(e, newValue) => {
                setNewAward((value) => ({
                  ...value,
                  name: newValue,
                }));
              }}
            />
          );
        }
        case 'cycle': {
          return (
            <Dropdown
              className="w-full"
              placeholder="Select a cycle"
              options={awardCycleOpts}
              selectedKey={newAward.cycle}
              onChange={(e, option) => {
                setNewAward((value) => ({
                  ...value,
                  cycle: option?.key as Enum_Award_Cycle_Type_Enum,
                }));
              }}
              styles={{
                title: {
                  border: 'none',
                  paddingRight: 'none',
                },
              }}
            />
          );
        }
        case 'limit': {
          return (
            <TextField
              borderless
              type="number"
              min={0}
              placeholder="Enter Limit"
              value={newAward.limit}
              onChange={(e, newValue) => {
                setNewAward((value) => ({
                  ...value,
                  limit: newValue,
                }));
              }}
            />
          );
        }
        case 'description': {
          return (
            <TextField
              borderless
              placeholder="Enter Description..."
              value={newAward.description}
              onChange={(e, newValue) => {
                setNewAward((value) => ({
                  ...value,
                  description: newValue,
                }));
              }}
            />
          );
        }
        case 'action': {
          return <CreateAward award={newAward} />;
        }
        default:
          return <RowItem item={item}>{fieldContent}</RowItem>;
      }
    }
    switch (column?.key) {
      case 'name':
        return item.name;
      case 'cycle':
        return formatCycleType(item.cycle);
      case 'limit':
        return item.limit;
      case 'description':
        return item.description;
      case 'action':
        return <AwardsActionBar id={item.key} key={item.key} />;

      default:
        return <RowItem item={item}>{fieldContent}</RowItem>;
    }
  };

  return (
    <DetailsList
      items={newAwardRaw ? [newAward, ...awards] : awards}
      columns={columns}
      onRenderItemColumn={onRenderItemColumn}
      selectionMode={SelectionMode.none}
      disableSelectionZone
      constrainMode={ConstrainMode.unconstrained}
      layoutMode={DetailsListLayoutMode.justified}
    />
  );
};

export default AwardsListTable;
