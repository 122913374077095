import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

const schema = yup.object().shape({
  role: yup.object().required().label('Role'),
  email: yup
    .string()
    .email()
    .test('email', 'Email cannot contain white spaces.', (value) => {
      return !/\s/.test(value as string);
    })
    .required()
    .label('Email'),
  password: yup
    .string()
    .password()
    .test('password', 'Password cannot contain white spaces.', (value) => {
      return !/\s/.test(value as string);
    })
    .label('Password'),
  department: yup.object().required().label('Department'),
  manager: yup.object().required().label('Manager'),
});
export const employeeAddSchema = yupResolver(schema);
