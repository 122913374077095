import { Icon, PrimaryButton, SearchBox } from '@fluentui/react';
import { Pagination } from '@fluentui/react-experiments';
import { useBoolean } from 'ahooks';
import { Fragment, useEffect, useState } from 'react';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import { useGetListOfSkillsQuery } from '../Skill/Skill.generated';
import AddNewCompetencyPopup from './AddNewCompetencyPopup';
import { useListOfCompetencyQuery } from './Competency.generated';
import DeleteCompetencyPopup from './DeleteCompetencyPopup';
import EditCompetencyPopup from './EditCompetencyPopup';
import RenameCompetencyPopup from './RenameCompetencyPopup';
import SpecificSkillInfoPopup from './SpecificSkillInfoPopup';

const CompetencyList = () => {
  const { organization } = useOrganizationContext();

  const PER_PAGE_COUNT = 10;

  const [selectedInfo, setSelectedInfo] = useState<any>({
    index: -1,
    visible: false,
    id: '',
  });
  const [searchText, setSearchText] = useState('');
  const [skillList, setSkillList] = useState<any>([]);
  const [specificSkillInfo, setSpecificSkillInfo] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [totalPageCount, setTotalPageCount] = useState<any>(1);
  const [offset, setOffset] = useState<number>(0);
  const [renameVisibleClose, { toggle: togglerenameVisibleCloseModal }] =
    useBoolean(false);
  const [deleteVisibleClose, { toggle: toggledeleteVisibleCloseModal }] =
    useBoolean(false);
  const [editVisibleClose, { toggle: toggleeditVisibleCloseModal }] =
    useBoolean(false);
  const [infoVisibleClose, { toggle: toggleinfoVisibleCloseModal }] =
    useBoolean(false);
  const [createVisibleClose, { toggle: togglecreateVisibleCloseModal }] =
    useBoolean(false);

  const [createCompetency, setCreateCompetency] = useState({
    name: '',
    skills: [],
  });

  const handlePaginate = (index: number) => {
    setOffset(index * PER_PAGE_COUNT);
    setPage(index + 1);
  };

  const [{ data: listOfCompetencies }, listOfCompetenciesData] =
    useListOfCompetencyQuery({
      variables: {
        _eq: organization?.id,
        _ilike: searchText !== '' ? `%${searchText}%` : null,
        limit: PER_PAGE_COUNT,
        offset: offset,
      },
      pause: !organization?.id,
      requestPolicy: 'cache-and-network',
    });

  const [{ data: listOfSkills }] = useGetListOfSkillsQuery({
    variables: {
      _eq: organization?.id,
      _ilike: '%%',
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const updatedListOfSkills = listOfSkills?.list_Of_Skills.map(
      (item: any) => ({
        id: item.id,
        label: item.name,
        value: item.name,
      }),
    );
    setSkillList(updatedListOfSkills);
  }, [listOfSkills]);

  useEffect(() => {
    setTotalPageCount(
      Math.ceil(
        (listOfCompetencies?.main_skills_aggregate.aggregate?.count || 1) /
          PER_PAGE_COUNT,
      ),
    );
  }, [listOfCompetencies]);

  return (
    <div className="space-y-4">
      {listOfCompetencies?.main_skills.length === 0 && searchText === '' && (
        <div className="flex flex-col h-auto items-center mt-12">
          <img src="../../../../assets/NoSkillsAdded.png" alt="q" />
          <p className="text-sm font-semibold text-black mt-4">
            No Competencies Added Yet
          </p>
          <p className="text-sm font-normal text-black mt-2 mb-4 w-56 text-center">
            You will see a list of competencies once you add a few
          </p>
          <PrimaryButton
            onClick={() => {
              setSelectedInfo({ index: -1, visible: false, id: '' });
              togglecreateVisibleCloseModal();
            }}
            text="Add New Competency"
          />
        </div>
      )}
      {(listOfCompetencies?.main_skills.length !== 0 || searchText !== '') && (
        <Fragment>
          <div className="flex justify-between items-center mt-3 w-[69.5%]">
            <SearchBox
              placeholder="Search"
              value={searchText}
              onChange={(e, value: any) => setSearchText(value)}
            />
            <PrimaryButton
              onClick={() => {
                setCreateCompetency({
                  name: '',
                  skills: [],
                });
                setSelectedInfo({ index: -1, visible: false, id: '' });
                togglecreateVisibleCloseModal();
              }}
              text="Add New Competency"
            />
          </div>
          <div className="flex gap-x-2">
            <ul className="space-y-4 w-[70%]" id="ulList">
              {listOfCompetencies?.main_skills.map(
                (info: any, index: number) => {
                  return (
                    <li
                      className="flex flex-col border rounded border-[#dddddd]"
                      id={index + ''}
                    >
                      <div className="flex flex-row px-8 py-3 items-center justify-between hover:bg-[#F6F6F6]">
                        <div className="flex item-center flex-col w-[95%]">
                          <label className="font-semibold text-base text-black">
                            {info.name}{' '}
                          </label>
                          <div>
                            <span className="font-normal text-sm text-black">
                              Skills :{' '}
                              {info?.sub_skills.length === 0 &&
                                'No skills added'}
                            </span>
                            {info?.sub_skills?.map(
                              (skill: any, skillIdx: number) => (
                                <label
                                  className="text-sm font-normal cursor-pointer hover:text-[#175E74] hover:font-semibold"
                                  key={skillIdx}
                                  onClick={() => {
                                    setSpecificSkillInfo(skill.list_Of_Skill);
                                    toggleinfoVisibleCloseModal();
                                  }}
                                >
                                  {skill.list_Of_Skill.name}
                                  {skillIdx !== info.sub_skills.length - 1
                                    ? ' , '
                                    : ''}
                                </label>
                              ),
                            )}
                          </div>
                        </div>
                        <div
                          className="relative"
                          onClick={() =>
                            setSelectedInfo({
                              index: index,
                              visible:
                                selectedInfo.index === index &&
                                selectedInfo.visible
                                  ? false
                                  : true,
                              id: info.id,
                            })
                          }
                        >
                          <Icon
                            iconName="MoreVertical"
                            className="cursor-pointer"
                          />
                          <div
                            className={`${selectedInfo.index === index && selectedInfo.visible ? 'flex' : 'hidden'} w-52 h-28 bg-white border border-gray-300 rounded-sm shadow-md absolute right-2 top-5 flex-col`}
                          >
                            <label
                              className="text-sm font-normal cursor-pointer hover:bg-[#F6F6F6] px-4 py-2"
                              onClick={togglerenameVisibleCloseModal}
                            >
                              Rename
                            </label>
                            <label
                              className="text-sm font-normal cursor-pointer hover:bg-[#F6F6F6] px-4 py-2"
                              onClick={toggleeditVisibleCloseModal}
                            >
                              Edit
                            </label>
                            <label
                              className="text-sm font-normal cursor-pointer hover:bg-[#F6F6F6] px-4 py-2"
                              onClick={toggledeleteVisibleCloseModal}
                            >
                              Delete
                            </label>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                },
              )}
            </ul>
            <div className="border border-[#E0E3E1] rounded-lg p-4 flex flex-col gap-y-1 w-[30%] self-start">
              <label className="text-sm font-semibold text-black">
                Competency
              </label>
              <label className="text-sm font-normal text-black">
                A competency is a combination of skills,knowledge,behaviors and
                attitudes needed to perform a job effectively.
              </label>
              <label className="text-sm font-normal text-black mt-3">
                Each competency can have multiple skills.
              </label>
            </div>
          </div>
        </Fragment>
      )}

      {listOfCompetencies?.main_skills.length !== 0 && (
        <div className="flex justify-center w-[70%]">
          <Pagination
            selectedPageIndex={page - 1}
            pageCount={totalPageCount}
            itemsPerPage={PER_PAGE_COUNT}
            onPageChange={handlePaginate}
            format="buttons"
            numberOfPageButton={4}
          />
        </div>
      )}

      {searchText !== '' && listOfCompetencies?.main_skills.length === 0 && (
        <div
          className="flex flex-col items-center"
          style={{ marginTop: '10%' }}
        >
          <label className="text-sm font-semibold text-black mt-4">
            No Competencies found
          </label>
        </div>
      )}

      {renameVisibleClose && (
        <RenameCompetencyPopup
          isOpen={renameVisibleClose}
          onDismiss={togglerenameVisibleCloseModal}
          selectedID={selectedInfo.id}
          listOfCompetenciesData={listOfCompetenciesData}
        />
      )}
      {deleteVisibleClose && (
        <DeleteCompetencyPopup
          isOpen={deleteVisibleClose}
          onDismiss={toggledeleteVisibleCloseModal}
          selectedID={selectedInfo.id}
          listOfCompetenciesData={listOfCompetenciesData}
        />
      )}
      {editVisibleClose && (
        <EditCompetencyPopup
          isOpen={editVisibleClose}
          onDismiss={toggleeditVisibleCloseModal}
          createCompetency={createCompetency}
          setCreateCompetency={setCreateCompetency}
          listOfCompetenciesData={listOfCompetenciesData}
          selectedID={selectedInfo.id}
          setSelectedInfo={setSelectedInfo}
          skillList={skillList}
        />
      )}
      {infoVisibleClose && (
        <SpecificSkillInfoPopup
          isOpen={infoVisibleClose}
          onDismiss={toggleinfoVisibleCloseModal}
          specificSkillInfo={specificSkillInfo}
        />
      )}
      {createVisibleClose && (
        <AddNewCompetencyPopup
          isOpen={createVisibleClose}
          onDismiss={() => {
            togglecreateVisibleCloseModal();
            setCreateCompetency({ name: '', skills: [] });
          }}
          createCompetency={createCompetency}
          setCreateCompetency={setCreateCompetency}
          listOfCompetenciesData={listOfCompetenciesData}
          skillList={skillList}
        />
      )}
    </div>
  );
};

export default CompetencyList;
