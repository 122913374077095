import { Link, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { OperationContext } from '@urql/core';
import EmployeeAddModal from './EmployeeAddModal';
import EmployeeDeleteModal from './EmployeeDeleteModal';
import EmployeeViewModal from './EmployeeViewModal';

const EmployeeActions = ({
  id,
  refetch,
}: {
  id: string;
  refetch: (opts?: Partial<OperationContext>) => void;
}) => {
  const [viewModal, { setFalse: viewModalClose, setTrue: viewModalOpen }] =
    useBoolean(false);
  const [editModal, { setFalse: editModalClose, setTrue: editModalOpen }] =
    useBoolean(false);
  const [
    deleteModal,
    { setFalse: deleteModalClose, setTrue: deleteModalOpen },
  ] = useBoolean(false);
  return (
    <Stack horizontal gap="10">
      <Stack.Item>
        <Link onClick={viewModalOpen}>View</Link>
        <EmployeeViewModal
          id={id}
          isOpen={viewModal}
          onDismiss={viewModalClose}
        />
      </Stack.Item>
      <Stack.Item>
        <Link onClick={editModalOpen}>Edit</Link>
        <EmployeeAddModal
          id={id}
          refetch={refetch}
          hideModal={editModalClose}
          isModalOpen={editModal}
        />
      </Stack.Item>
      <Stack.Item>
        <Link onClick={deleteModalOpen}>Delete</Link>
        <EmployeeDeleteModal
          id={id}
          isOpen={deleteModal}
          onDismiss={() => {
            refetch();
            deleteModalClose();
          }}
        />
      </Stack.Item>
    </Stack>
  );
};

export default EmployeeActions;
