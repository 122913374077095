import ProjectManagement from '../Home/assets/ProjectManagement';
import ProjectImg from './assets/ProjectImg.png';
import ProjectMangImg from './assets/ProjectMangImg.png';
import TaskImg from './assets/TaskImg.png';
import WorkflowImg from './assets/WorkflowImg.png';

const ProjectManagements = () => {
  return (
    <div className="px-20">
      <div className="flex gap-2">
        <div className="w-1/2">
          <div className="flex items-center gap-2">
            <span>
              <ProjectManagement />
            </span>
            <span className="font-medium ">Project Management</span>
          </div>
          <div className="text-4xl font-medium mt-2 ">
            Effortless project management for <br /> seamless collaboration
          </div>
          <div className="mt-8 text-base ">
            Manage projects, tasks, sprints, gantt chart <br /> schedules and
            measure task productivity of people and project deviation
          </div>
        </div>
        <div className="w-[60%]">
          <img src={ProjectMangImg} alt="ProjectMng" />
        </div>
      </div>
      <div className="flex justify-between gap-4 mt-3 ">
        <div className="w-[30%]">
          <img src={TaskImg} alt="TaskImg" />
        </div>
        <div className="w-[30%]">
          <img src={WorkflowImg} alt="WorkflowImg" />
        </div>
        <div className="w-[30%]">
          <img src={ProjectImg} alt="ProjectImg" />
        </div>
      </div>
    </div>
  );
};

export default ProjectManagements;
