import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBarType,
  PrimaryButton,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Form,
  PrimaryButtonWithLoader,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useForm } from 'react-hook-form';
import { useResendActivateEmailMutation } from './activate.generated';
import { resendSchema } from './resend.schema';

const Resend = () => {
  const { notification } = useNotification();
  const [resendDialogHidden, { toggle: toggleResendDialog }] = useBoolean(true);
  const [{ fetching: loadingResend }, resendActivateEmail] =
    useResendActivateEmailMutation();

  const resendForm = useForm<{ email: string }>({
    resolver: resendSchema,
    mode: 'all',
  });

  const onResend = async ({ email }: { email: string }) => {
    try {
      const res = await resendActivateEmail({
        email,
      });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
        return;
      }
      notification({
        message:
          'If the email is registered and not activated. The mail is resend.',
        type: MessageBarType.success,
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <PrimaryButton onClick={toggleResendDialog} type="primary" key="console">
        Resend Activation Email
      </PrimaryButton>
      <Dialog
        hidden={resendDialogHidden}
        onDismiss={toggleResendDialog}
        dialogContentProps={{
          title: 'Resend activation email',
          type: DialogType.close,
        }}
      >
        <Form formHook={resendForm} onSubmit={onResend}>
          <Text>Enter your email below to resend the activation email.</Text>
          <TextField name="email" formHook={resendForm} type="email" />
          <DialogFooter>
            <PrimaryButtonWithLoader loading={loadingResend} type="submit">
              Resend
            </PrimaryButtonWithLoader>
            <DefaultButton onClick={toggleResendDialog}>Cancel</DefaultButton>
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};

export default Resend;
