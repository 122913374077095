import {
  DatePicker,
  defaultDatePickerStrings,
  IDatePickerProps,
  useTheme,
} from '@fluentui/react';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

export interface DatePickerFieldProps extends IDatePickerProps {
  formHook: UseFormReturn<any>;
  name: string;
  hideBorder?: boolean;
  customOnChange?: (date: Date | null | undefined) => void;
  shiftIcon?: boolean;
}

export const DatePickerField: FC<DatePickerFieldProps> = ({
  formHook,
  name,
  customOnChange,
  hideBorder,
  shiftIcon,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = formHook;

  const errorMessage = errors[name]?.message as string;

  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <>
          <DatePicker
            onSelectDate={(date) => {
              onChange(date);
              customOnChange?.(date);
            }}
            onBlur={onBlur}
            value={value}
            styles={{
              root: {
                selectors: {
                  '.ms-TextField-fieldGroup': {
                    border: hideBorder ? 'none !important' : '',
                  },
                  '.ms-DatePicker-event--without-label': shiftIcon
                    ? {
                        visibility: value ? 'hidden' : '',
                        left: '0px', // Adjust the left position of the icon
                        right: 'auto', // Override default right positioning
                      }
                    : {},
                },
              },
              readOnlyTextField: {
                border: errorMessage ? '1px solid rgb(164, 38, 44)' : undefined,
              },
            }}
            strings={{
              ...defaultDatePickerStrings,
              isRequiredErrorMessage: '',
              invalidInputErrorMessage: '',
              isOutOfBoundsErrorMessage: '',
            }}
            {...props}
          />
          {errorMessage && errors[name]?.type !== 'typeError' && (
            <span
              style={{
                fontSize: 12,
                color: theme.semanticColors.errorText,
              }}
            >
              {errorMessage}
            </span>
          )}
        </>
      )}
    />
  );
};
