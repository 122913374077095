export const OrganizationFormVector = () => (
  <svg
    width="473"
    height="344"
    viewBox="0 0 473 344"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M259.497 248.026h-60.25v91.802h60.25v-91.802z" fill="#175E74" />
    <path
      opacity=".6"
      d="M249.328 248.017h-50.081v91.811h50.081v-91.811z"
      fill="#fff"
    />
    <path
      opacity=".3"
      d="M259.507 248.017h-10.179v91.811h10.179v-91.811z"
      fill="#fff"
    />
    <path
      d="M214.477 252.688h-10.926v10.834h10.926v-10.834zM229.746 252.688h-10.927v10.834h10.927v-10.834zM245.023 252.688h-10.926v10.834h10.926v-10.834zM214.477 266.805h-10.926v10.834h10.926v-10.834zM229.746 266.805h-10.927v10.834h10.927v-10.834zM245.023 266.805h-10.926v10.834h10.926v-10.834zM214.477 280.922h-10.926v10.834h10.926v-10.834zM229.746 280.922h-10.927v10.834h10.927v-10.834zM245.023 280.922h-10.926v10.834h10.926v-10.834zM214.477 295.039h-10.926v10.834h10.926v-10.834zM229.746 295.039h-10.927v10.834h10.927v-10.834zM245.023 295.039h-10.926v10.834h10.926v-10.834zM214.477 309.156h-10.926v10.833h10.926v-10.833zM229.746 309.156h-10.927v10.833h10.927v-10.833zM245.023 309.156h-10.926v10.833h10.926v-10.833zM214.477 327.024h-10.926v12.804h10.926v-12.804zM229.746 323.272h-10.927v16.556h10.927v-16.556zM245.023 327.024h-10.926v12.804h10.926v-12.804z"
      fill="#175E74"
    />
    <path
      opacity=".4"
      d="M214.477 252.688h-10.028v10.834h10.028v-10.834zM229.755 252.688h-10.027v10.834h10.027v-10.834zM245.033 252.688h-10.028v10.834h10.028v-10.834zM214.477 266.805h-10.028v10.834h10.028v-10.834zM229.755 266.805h-10.027v10.834h10.027v-10.834zM245.033 266.805h-10.028v10.834h10.028v-10.834zM214.477 280.922h-10.028v10.834h10.028v-10.834zM229.755 280.922h-10.027v10.834h10.027v-10.834zM245.033 280.922h-10.028v10.834h10.028v-10.834zM214.477 295.039h-10.028v10.834h10.028v-10.834zM229.755 295.039h-10.027v10.834h10.027v-10.834zM245.033 295.039h-10.028v10.834h10.028v-10.834zM214.477 309.156h-10.028v10.833h10.028v-10.833zM229.755 309.156h-10.027v10.833h10.027v-10.833zM245.033 309.156h-10.028v10.833h10.028v-10.833zM214.477 327.024h-10.028v12.804h10.028v-12.804zM229.755 323.272h-10.027v16.556h10.027v-16.556zM245.033 327.024h-10.028v12.804h10.028v-12.804z"
      fill="#fff"
    />
    <path
      d="M195.585 244.049h74.243l-2.205 4.662h-69.824l-2.214-4.662z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M250.425 244.049h19.403l-2.205 4.662h-18.257l1.059-4.662z"
      fill="#000"
    />
    <path d="M417.016 193.106h-60.809v146.722h60.809V193.106z" fill="#175E74" />
    <path
      opacity=".4"
      d="M402.466 193.106h-46.259v146.722h46.259V193.106z"
      fill="#fff"
    />
    <path d="M419.863 188.763H353.35v4.681h66.513v-4.681z" fill="#175E74" />
    <path
      opacity=".2"
      d="M419.863 188.763h-17.387v4.681h17.387v-4.681z"
      fill="#000"
    />
    <path
      opacity=".3"
      d="M417.025 193.444h-14.549v146.384h14.549V193.444z"
      fill="#fff"
    />
    <path d="M370.274 198.959h-7.861v11.81h7.861v-11.81z" fill="#175E74" />
    <path
      opacity=".2"
      d="M370.274 199.738h-7.861v11.021h7.861v-11.021z"
      fill="#fff"
    />
    <path
      d="M363.538 198.959h-1.125v11.81h1.125v-11.81zM382.657 198.959h-7.861v11.81h7.861v-11.81z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M382.657 199.738h-7.861v11.021h7.861v-11.021z"
      fill="#fff"
    />
    <path
      d="M375.922 198.959h-1.126v11.81h1.126v-11.81zM395.04 198.959h-7.861v11.81h7.861v-11.81z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M395.04 199.738h-7.861v11.021h7.861v-11.021z"
      fill="#fff"
    />
    <path
      d="M388.304 198.959h-1.125v11.81h1.125v-11.81zM370.274 216.744h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M370.274 217.522h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M363.538 216.744h-1.125v11.809h1.125v-11.809zM382.657 216.744h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M382.657 217.522h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M375.922 216.744h-1.126v11.809h1.126v-11.809zM395.04 216.744h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M395.04 217.522h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M388.304 216.744h-1.125v11.809h1.125v-11.809zM370.274 234.519h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M370.274 235.307h-7.861v11.021h7.861v-11.021z"
      fill="#fff"
    />
    <path
      d="M363.538 234.519h-1.125v11.809h1.125v-11.809zM382.657 234.519h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M382.657 235.307h-7.861v11.021h7.861v-11.021z"
      fill="#fff"
    />
    <path
      d="M375.922 234.519h-1.126v11.809h1.126v-11.809zM395.04 234.519h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M395.04 235.307h-7.861v11.021h7.861v-11.021z"
      fill="#fff"
    />
    <path
      d="M388.304 234.519h-1.125v11.809h1.125v-11.809zM370.274 252.303h-7.861v11.81h7.861v-11.81z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M370.274 253.091h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M363.538 252.303h-1.125v11.81h1.125v-11.81zM382.657 252.303h-7.861v11.81h7.861v-11.81z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M382.657 253.091h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M375.922 252.303h-1.126v11.81h1.126v-11.81zM395.04 252.303h-7.861v11.81h7.861v-11.81z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M395.04 253.091h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M388.304 252.303h-1.125v11.81h1.125v-11.81zM370.274 270.088h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M370.274 270.866h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M363.538 270.088h-1.125v11.809h1.125v-11.809zM382.657 270.088h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M382.657 270.866h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M375.922 270.088h-1.126v11.809h1.126v-11.809zM395.04 270.088h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M395.04 270.866h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M388.304 270.088h-1.125v11.809h1.125v-11.809zM370.274 287.863h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M370.274 288.651h-7.861v11.021h7.861v-11.021z"
      fill="#fff"
    />
    <path
      d="M363.538 287.863h-1.125v11.809h1.125v-11.809zM382.657 287.863h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M382.657 288.651h-7.861v11.021h7.861v-11.021z"
      fill="#fff"
    />
    <path
      d="M375.922 287.863h-1.126v11.809h1.126v-11.809zM395.04 287.863h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M395.04 288.651h-7.861v11.021h7.861v-11.021z"
      fill="#fff"
    />
    <path
      d="M388.304 287.863h-1.125v11.809h1.125v-11.809zM370.274 305.647h-7.861v11.81h7.861v-11.81z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M370.274 306.435h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M363.538 305.647h-1.125v11.81h1.125v-11.81zM382.657 305.647h-7.861v11.81h7.861v-11.81z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M382.657 306.435h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M375.922 305.647h-1.126v11.81h1.126v-11.81zM395.04 305.647h-7.861v11.81h7.861v-11.81z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M395.04 306.435h-7.861v11.022h7.861v-11.022z"
      fill="#fff"
    />
    <path
      d="M388.304 305.647h-1.125v11.81h1.125v-11.81zM370.274 323.432h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M370.274 324.21h-7.861v11.022h7.861V324.21z"
      fill="#fff"
    />
    <path
      d="M363.538 323.432h-1.125v11.809h1.125v-11.809zM382.657 323.432h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M382.657 324.21h-7.861v11.022h7.861V324.21z"
      fill="#fff"
    />
    <path
      d="M375.922 323.432h-1.126v11.809h1.126v-11.809zM395.04 323.432h-7.861v11.809h7.861v-11.809z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M395.04 324.21h-7.861v11.022h7.861V324.21z"
      fill="#fff"
    />
    <path d="M388.304 323.432h-1.125v11.809h1.125v-11.809z" fill="#175E74" />
    <path
      d="M288.833 109.906h-1.353v28.89h1.353v-28.89zM318.234 109.906h-1.353v28.89h1.353v-28.89z"
      fill="#263238"
    />
    <path d="M343.672 123.066h-45.701v23.947h45.701v-23.947z" fill="#175E74" />
    <path
      opacity=".7"
      d="M343.672 123.066h-45.701v23.947h45.701v-23.947z"
      fill="#fff"
    />
    <path d="M317.241 123.066H271.54v23.947h45.701v-23.947z" fill="#175E74" />
    <path
      opacity=".4"
      d="M317.241 123.066H271.54v23.947h45.701v-23.947z"
      fill="#fff"
    />
    <path
      opacity=".5"
      d="M267.198 159.854h-2.29v9.38h2.29v-9.38zM267.198 184.073h-2.29v9.38h2.29v-9.38zM267.198 200.469h-2.29v9.381h2.29v-9.381zM267.198 216.875h-2.29v9.38h2.29v-9.38zM267.198 233.271h-2.29v9.38h2.29v-9.38zM267.198 249.667h-2.29v9.38h2.29v-9.38zM267.198 267.743h-2.29v9.38h2.29v-9.38zM267.198 285.818h-2.29v9.38h2.29v-9.38zM267.198 303.884h-2.29v9.38h2.29v-9.38zM267.198 321.959h-2.29v9.38h2.29v-9.38z"
      fill="#fff"
    />
    <path d="M356.538 147.36h-71.413v192.468h71.413V147.36z" fill="#175E74" />
    <path d="M330.088 147.36h-71.414v192.468h71.414V147.36z" fill="#175E74" />
    <path
      opacity=".3"
      d="M356.528 150.005h-26.431v189.833h26.431V150.005z"
      fill="#fff"
    />
    <path
      opacity=".5"
      d="M330.088 147.36h-71.414v192.468h71.414V147.36z"
      fill="#fff"
    />
    <path
      d="M323.324 155.774h-8.76v179.458h8.76V155.774zM306.447 155.774h-8.76v179.458h8.76V155.774zM289.571 155.774h-8.76v179.458h8.76V155.774zM272.684 155.774h-8.76v179.458h8.76V155.774z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M323.324 158.044h-8.76v177.188h8.76V158.044zM306.447 158.044h-8.76v177.188h8.76V158.044zM289.571 158.044h-8.76v177.188h8.76V158.044zM272.684 158.044h-8.76v177.188h8.76V158.044z"
      fill="#fff"
    />
    <path
      d="M353.482 155.774h-3.67v179.458h3.67V155.774zM346.406 155.774h-3.67v179.458h3.67V155.774zM339.321 155.774h-3.671v179.458h3.671V155.774z"
      fill="#175E74"
    />
    <g opacity=".3" fill="#fff">
      <path
        opacity=".4"
        d="M353.483 158.044h-3.671v177.188h3.671V158.044zM346.406 158.044h-3.67v177.188h3.67V158.044zM339.321 158.044h-3.671v177.188h3.671V158.044z"
      />
    </g>
    <path d="M355.005 141.891h-24.908v3.612h24.908v-3.612z" fill="#175E74" />
    <path d="M355.005 141.01h-96.331v4.493h96.331v-4.493z" fill="#F0F0F0" />
    <path
      d="M358.383 145.512H256.659v4.493h101.724v-4.493zM358.383 137.398H256.659v4.493h101.724v-4.493z"
      fill="#175E74"
    />
    <path
      opacity=".1"
      d="M358.383 145.512H256.659v4.493h101.724v-4.493zM358.383 137.398H256.659v4.493h101.724v-4.493z"
      fill="#000"
    />
    <path
      opacity=".2"
      d="M358.383 145.512h-28.286v4.493h28.286v-4.493zM358.383 137.398h-28.286v4.493h28.286v-4.493z"
      fill="#000"
    />
    <path
      opacity=".3"
      d="M299.872 123.066h-10.916v10.824h10.916v-10.824z"
      fill="#fff"
    />
    <path
      d="M178.794 209.849c-9.271-10.843-20.887-14.548-29.411-10.505a6.26 6.26 0 0 1-7.369-1.51c-10.312-11.078-27.027-12.391-33.962-1.942a21.542 21.542 0 0 0-1.986 3.752 12.526 12.526 0 0 1-4.096 5.328 12.863 12.863 0 0 0-3.15 3.33 20.841 20.841 0 0 0-2.99 12.306 3.362 3.362 0 0 1-.387 1.858 3.401 3.401 0 0 1-1.335 1.36 12.395 12.395 0 0 0-4.73 4.314c-5.619 8.442-3.14 23.544 5.534 33.684 7.729 9.033 17.766 11.106 23.83 5.459a1.332 1.332 0 0 1 1.835 0c4.371 3.893 9.46 3.921 12.081-.3a1.348 1.348 0 0 1 1.621-.624c.199.068.38.183.526.333 4.503 4.474 9.943 4.69 12.715.291a10.3 10.3 0 0 0 1.135-2.626 1.385 1.385 0 0 1 1.252-1.117c.2-.017.403.008.592.075 2.942 1.173 5.828.666 7.795-1.641a1.294 1.294 0 0 1 1.665-.291c10.094 5.985 20.737 4.831 26.659-4.09 7.918-11.94 4.474-33.167-7.824-47.444z"
      fill="#175E74"
    />
    <path
      opacity=".6"
      d="M178.794 209.849c-9.271-10.843-20.887-14.548-29.411-10.505a6.26 6.26 0 0 1-7.369-1.51c-10.312-11.078-27.027-12.391-33.962-1.942a21.542 21.542 0 0 0-1.986 3.752 12.526 12.526 0 0 1-4.096 5.328 12.863 12.863 0 0 0-3.15 3.33 20.841 20.841 0 0 0-2.99 12.306 3.362 3.362 0 0 1-.387 1.858 3.401 3.401 0 0 1-1.335 1.36 12.395 12.395 0 0 0-4.73 4.314c-5.619 8.442-3.14 23.544 5.534 33.684 7.729 9.033 17.766 11.106 23.83 5.459a1.332 1.332 0 0 1 1.835 0c4.371 3.893 9.46 3.921 12.081-.3a1.348 1.348 0 0 1 1.621-.624c.199.068.38.183.526.333 4.503 4.474 9.943 4.69 12.715.291a10.3 10.3 0 0 0 1.135-2.626 1.385 1.385 0 0 1 1.252-1.117c.2-.017.403.008.592.075 2.942 1.173 5.828.666 7.795-1.641a1.294 1.294 0 0 1 1.665-.291c10.094 5.985 20.737 4.831 26.659-4.09 7.918-11.94 4.474-33.167-7.824-47.444z"
      fill="#fff"
    />
    <path
      d="m117.087 272.227 8.788 8.526c-.7 12.035-1.277 39.752-1.277 39.752l-3.122 19.38 27.538.262s-1.249-16.368-1.249-17.25c0-.881-5.326-32.239-5.326-32.239l-1.892-17.559 14.19-4.531 2.593-7.823 10.406-6.172 5.098-8.188-1.967-.497-5.676 7.56-6.575 1.491 2.838-6.96h-3.131l-7.824 16.134-10.084 2.157 1.315-22.652c-8.06 3.939-10.643-2.627-10.643-2.627s-3.329 30.495-4.502 33.965l-5.194-2.729-5.676-9.305h-2.384l3.756 9.305zM76.787 310.065h-1.39v17.729h1.39v-17.729z"
      fill="#175E74"
    />
    <path
      opacity=".2"
      d="M76.787 310.065h-1.39v17.729h1.39v-17.729z"
      fill="#000"
    />
    <path d="M105.612 310.065h-1.391v17.729h1.391v-17.729z" fill="#175E74" />
    <path
      opacity=".2"
      d="M105.612 310.065h-1.391v17.729h1.391v-17.729z"
      fill="#000"
    />
    <path d="M107.192 326.068H73.874v2.157h33.318v-2.157z" fill="#175E74" />
    <path
      opacity=".2"
      d="M107.192 326.068H73.874v2.157h33.318v-2.157z"
      fill="#fff"
    />
    <path d="M76.087 328.159h-1.39v11.997h1.39v-11.997z" fill="#175E74" />
    <path
      opacity=".2"
      d="M76.087 328.159h-1.39v11.997h1.39v-11.997z"
      fill="#000"
    />
    <path d="M106.501 328.159h-1.391v11.997h1.391v-11.997z" fill="#175E74" />
    <path
      opacity=".2"
      d="M106.501 328.159h-1.391v11.997h1.391v-11.997z"
      fill="#000"
    />
    <path d="M107.192 310.863H73.874v10.43h33.318v-10.43z" fill="#175E74" />
    <path
      opacity=".2"
      d="M107.192 310.863H73.874v10.43h33.318v-10.43z"
      fill="#fff"
    />
    <path d="M107.125 327.793H73.92v3.359h33.205v-3.359z" fill="#175E74" />
    <path
      opacity=".1"
      d="M107.125 327.793H73.92v3.359h33.205v-3.359z"
      fill="#000"
    />
    <path d="M73.92 323.019h-1.39v17.119h1.39v-17.119z" fill="#175E74" />
    <path d="M74.46 321.303h-2.422v2.401h2.422v-2.401z" fill="#175E74" />
    <path
      opacity=".2"
      d="M74.46 321.303h-2.422v2.401h2.422v-2.401z"
      fill="#fff"
    />
    <path
      d="M76.343 321.303H74.45v2.401h1.892v-2.401zM107.135 340.138h1.39v-17.119h-1.39v17.119z"
      fill="#175E74"
    />
    <path d="M106.605 323.695h2.412v-2.402h-2.412v2.402z" fill="#175E74" />
    <path
      opacity=".2"
      d="M106.605 323.695h2.412v-2.402h-2.412v2.402z"
      fill="#fff"
    />
    <path d="M106.596 321.303h-1.892v2.401h1.892v-2.401z" fill="#175E74" />
    <path
      opacity=".1"
      d="M76.343 321.303H74.45v2.401h1.892v-2.401zM106.596 321.303h-1.892v2.401h1.892v-2.401z"
      fill="#000"
    />
    <path d="M73.863 190.367h-1.22v5.29h1.22v-5.29z" fill="#263238" />
    <path
      d="M82.132 188.163c-7.568.938-19.015 3.451-27.992 8.132v1.773a74.759 74.759 0 0 1 28.049-8.442l-.057-1.463z"
      fill="#175E74"
    />
    <path
      d="M95.272 190.799a6.477 6.477 0 0 0-1.618-1.014c-1.296-.553-9.138.61-11.825 1.032v-1.135l-.066-1.472a119.32 119.32 0 0 1 4.843-.404c2.261-.122 3.936-.197 5.175-.234a3.369 3.369 0 0 1 2.43.886 3.304 3.304 0 0 1 1.06 2.341z"
      fill="#175E74"
    />
    <path
      d="M54.14 208.771a.95.95 0 0 1-.67-.275.935.935 0 0 1-.277-.663c0-.479 0-1.473 5.033-5.985a20.882 20.882 0 0 1 2.252-1.791 39.748 39.748 0 0 1 15.25-5.703.957.957 0 0 1 .704.188.945.945 0 0 1 .174 1.327.961.961 0 0 1-.633.361c-.076 0-8.126 1.125-14.426 5.393a18.464 18.464 0 0 0-2.053 1.642c-3.273 2.936-4.314 4.314-4.418 4.69a.935.935 0 0 1-.314.582.955.955 0 0 1-.623.234z"
      fill="#263238"
    />
    <path
      d="M52.881 196.042h1.892a.23.23 0 0 1 .16.066.223.223 0 0 1 .067.159v143.955h-2.365V196.248a.228.228 0 0 1 .08-.154.229.229 0 0 1 .166-.052z"
      fill="#263238"
    />
    <path
      d="M95.28 190.902v.075l-10.982 1.022-2.441-1.022v-.16c2.687-.422 10.529-1.585 11.825-1.031a6.417 6.417 0 0 1 1.59 1.013.337.337 0 0 1 .009.103z"
      fill="#175E74"
    />
    <path
      opacity=".6"
      d="M95.28 190.902v.075l-10.982 1.022-2.441-1.022v-.16c2.687-.422 10.529-1.585 11.825-1.031a6.417 6.417 0 0 1 1.59 1.013.337.337 0 0 1 .009.103z"
      fill="#fff"
    />
    <path
      d="M375.505 257.744h2.45a.293.293 0 0 1 .294.29v89.476h-3.037v-89.476a.291.291 0 0 1 .293-.29z"
      fill="#263238"
    />
    <path
      d="M391.171 260.173h-28.995c-1.85 0-3.349 1.487-3.349 3.321v5.356c0 1.833 1.499 3.32 3.349 3.32h28.995c1.849 0 3.349-1.487 3.349-3.32v-5.356c0-1.834-1.5-3.321-3.349-3.321z"
      fill="#175E74"
    />
    <path
      opacity=".9"
      d="M391.171 260.173h-28.995c-1.85 0-3.349 1.487-3.349 3.321v5.356c0 1.833 1.499 3.32 3.349 3.32h28.995c1.849 0 3.349-1.487 3.349-3.32v-5.356c0-1.834-1.5-3.321-3.349-3.321z"
      fill="#fff"
    />
    <path
      d="M390.262 261.064h-27.008c-1.849 0-3.349 1.487-3.349 3.321v3.414c0 1.834 1.5 3.321 3.349 3.321h27.008c1.85 0 3.349-1.487 3.349-3.321v-3.414c0-1.834-1.499-3.321-3.349-3.321z"
      fill="#175E74"
    />
    <path
      d="m386.252 267.18.17-.366c.247.18.545.279.852.281.397 0 .567-.15.567-.337 0-.544-1.532-.188-1.532-1.135 0-.413.331-.76 1.04-.76.303-.007.601.071.861.225l-.151.366a1.508 1.508 0 0 0-.71-.197c-.397 0-.558.15-.558.347 0 .535 1.523.188 1.523 1.126 0 .403-.331.759-1.04.759a1.65 1.65 0 0 1-1.022-.309zM389.279 265.294h-.851v-.403h2.185v.403h-.861v2.158h-.482l.009-2.158z"
      fill="#fff"
    />
    <path d="M433.769 339.284H36.298v9.905h397.471v-9.905z" fill="#175E74" />
    <path
      opacity=".7"
      d="M433.769 339.284H36.298v9.905h397.471v-9.905z"
      fill="#fff"
    />
    <path
      d="M57.734 339.284h-.946v10.609h.946v-10.609zM146.639 339.284h-.946v10.609h.946v-10.609zM235.544 339.284h-.946v10.609h.946v-10.609zM324.449 339.284h-.946v10.609h.946v-10.609zM413.355 339.284h-.946v10.609h.946v-10.609z"
      fill="#fff"
    />
    <path d="M433.769 353.073H36.298v4.596h397.471v-4.596z" fill="#175E74" />
    <path
      opacity=".9"
      d="M433.769 353.073H36.298v4.596h397.471v-4.596z"
      fill="#fff"
    />
  </svg>
);
