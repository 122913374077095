import { Link } from '@fluentui/react';
import RiseOEFour from '../../../../assets/RiseOfEmployee/RiseOEFour.jpg';
import RiseOEOne from '../../../../assets/RiseOfEmployee/RiseOEOne.jpg';
import RiseOEThree from '../../../../assets/RiseOfEmployee/RiseOEThree.jpg';
import RiseOETwo from '../../../../assets/RiseOfEmployee/RiseOETwo.jpg';
import { BookADemo } from '../../Home/Utils';
const MRecentBContainThree = () => {
  return (
    <>
      <div
        className="pt-1 place-content-center py-14 bg-cover bg-no-repeat bg-center sm:h-screen "
        style={{
          backgroundImage:
            'url(../../../../assets/MostRecentBlogBanner/EmployeeExperience.jpg)',
        }}
      ></div>
      <div className="text-[28px] sm:text-[3rem] font-normal sm:font-light mx-[20px] sm:mx-[40px] my-[24px]">
        <div className="sm:px-[6px] md-[10px] lg:px-[120px]">
          <div className="text-[44px] font-bold leading-[50px]">
            The Rise of Employee Experience: Digital Facilitation
          </div>
          <div className="text-[24px] font-medium my-[12px]">
            Ten years ago, employers didn't focus much on employee experience.
            However, the COVID-19 pandemic highlighted the importance of
            treating all employees fairly and having clear policies for a
            company to thrive.
            <br />
            In a 2022 Gallup poll, only 21% of employees reported being engaged
            at work, and just 33% of employees said they were thriving in their
            overall well-being.
          </div>
          <div className="my-[24px]">
            <img src={RiseOETwo} alt="two" />
          </div>
          <div className="text-[24px] font-medium my-[24px]">
            Earlier organizations focused on culture and engagement, now they
            are targeting to take employee experience needs to another level
            which can now be achieved by a range of technologies in the market.
            Even though, Organizations are making significant investments in
            efforts to improve employee experience (such as onboarding,
            investments, and workplace redesign, etc.). However, a 2022 Gartner
            survey revealed that only 13% of employees were fully satisfied with
            their experience.
            <br />
            <br />
            In this digital era with the growing influence of millennials
            employees seek productive, engaging, enjoyable work experiences.
            Organizations are now working on the integration of collaborative
            platforms like pulse feedback tools, collaboration tools, wellness
            and fitness apps, and integrated employee self-service to enhance
            the entire employee journey. Through innovative approaches such as
            design thinking and employee journey maps, organizations are now
            focusing on understanding and improving this complete experience and
            using tools such as employee net promoter scores to measure employee
            satisfaction.
            <br />
            <br />
            As we know Employee experience refers to the sum of all interactions
            an employee has with their employer, from the first recruitment
            process to their day-to-day work life and beyond.
          </div>
          <div className="my-[24px]">
            <img src={RiseOEFour} alt="three" />
          </div>
          <div className="text-[24px] font-medium my-[24px]">
            It encompasses everything from the work environment and culture to
            the tools and technologies provided by the employer. It can be
            improved by incorporating a few factors:
          </div>
          <div>
            <div className="text-[24px] font-medium my-[24px]">
              <ul style={{ listStyleType: 'disc', marginLeft: '1.5rem' }}>
                <li>
                  <span className="font-semibold">
                    Ensure employee values align with company vision :
                  </span>
                  <span>
                    {' '}
                    The desire for employees to align with company values is
                    driven by their aspirations. This prompts the C-suite to
                    articulate these values clearly and ensure that corporate
                    actions remain in harmony with them. Setting clear goals,
                    along with defining milestones and success metrics,
                    establishes a meaningful connection between employees and
                    their company's mission. This aids in their understanding of
                    how their role contributes to the overall progress of the
                    organization.
                  </span>{' '}
                </li>
                <br />
                <li>
                  <span className="font-semibold">Success Recognition :</span>
                  <span>
                    {' '}
                    Recognition stands out as a cost-effective strategy to
                    enhance employee engagement, increasing loyalty, retention,
                    and productivity. As per a survey Employees who believe
                    their successes will be acknowledged are 2.7 times more
                    likely to be highly engaged compared to those without such
                    expectations. Recognition goes beyond just praise; it
                    involves identifying and nurturing potential and equipping
                    employees with the skills essential for their continuous
                    growth.
                  </span>{' '}
                </li>
                <br />
                <li>
                  <span className="font-semibold">
                    Technology Integration :
                  </span>
                  <span>
                    {' '}
                    As per a Harvard Business Review, Eighty-five percent of the
                    reasons for failure are deficiencies in the systems and
                    process rather than the employee. The role of management is
                    to change the process rather than pushing individuals to do
                    better. Technology is not an end but a tool for increasing
                    productivity and reducing effort. Research shows that
                    technology is one of the most poorly rated dimensions of
                    employee experience like the absence of leave management
                    systems, and asset management systems etc., To improve on
                    that organizations need to implement precise tools and
                    methods for a seamless employee experience.
                    <br />
                    <br />
                    Collaboration tools work as a catalyst to improve the
                    employee experience. Below mentioned points state some of
                    the unique capabilities these platforms provide to enhance
                    the overall employee journey.
                  </span>{' '}
                </li>
                <br />
                {/* <div className="my-[24px]">
                  <img src={futureOfWork3} alt="futureOfWork3" />
                </div> */}
                <li>
                  <span className="font-semibold">Enhanced Connectivity :</span>
                  <span>
                    {' '}
                    Picture yourself in an office, effortlessly accessing all
                    the verticals. In remote settings, connectivity poses a
                    major challenge. By integrating tools like Teams and Slack,
                    where teams stay connected, we not only enhance the employee
                    experience but also significantly boost overall enterprise
                    productivity.
                  </span>{' '}
                </li>
                <br />
                {/* <div className="my-[24px]">
                  <img src={futureOfWork4} alt="futureOfWork4" />
                </div> */}
                <li>
                  <span className="font-semibold">
                    Employee Learning and Development :
                  </span>
                  <span>
                    {' '}
                    Continuous learning is a journey to enhance individual
                    capabilities. Digital platforms simplify this process by
                    offering employees access to pre-recorded training and
                    manuals. This flexibility allows employees to engage with
                    these resources on their schedules, empowering them to take
                    charge of their learning and development
                  </span>{' '}
                  {/* <div className="my-[24px]">
                    <img src={futureOfWork5} alt="futureOfWork5" />
                  </div> */}
                </li>
                <br />
                <li>
                  <span className="font-semibold">
                    Productivity Management :
                  </span>
                  <span>
                    {' '}
                    Talking about managing productivity can sometimes make
                    employees feel like they're being watched too closely. But
                    it's about giving them a tool to track what they've
                    accomplished and share their successes with the team. This
                    not only helps them feel more confident but also makes the
                    whole team more productive and successful.
                  </span>{' '}
                  {/* <div className="my-[24px]">
                    <img src={futureOfWork5} alt="futureOfWork5" />
                  </div> */}
                </li>
                <br />
                <li>
                  <span className="font-semibold">Communication :</span>
                  <span>
                    {' '}
                    Digital tools play a crucial role in keeping remote or
                    hybrid teams connected, even when team members are in
                    different places. Cross-functional teams can easily share
                    updates and messages, as well as host meetings, ensuring
                    everyone stays on the same page and collaboration remains
                    smooth.
                  </span>{' '}
                  {/* <div className="my-[24px]">
                    <img src={futureOfWork5} alt="futureOfWork5" />
                  </div> */}
                </li>
                <br />
                <li>
                  <span className="font-semibold">HR processes :</span>
                  <span>
                    {' '}
                    Digital platforms like Workday simplify employees' access to
                    various work resources in one place. They can easily
                    retrieve crucial personal and company-wide information such
                    as past paychecks, insurance details, and upcoming company
                    holidays. Centralizing this information makes it convenient
                    for employees to find what they need without searching
                    through multiple sources.
                  </span>{' '}
                  <div className="my-[24px]">
                    <img src={RiseOEOne} alt="three" />
                  </div>
                </li>
                <br />
              </ul>
            </div>
            <div className="text-[24px] font-medium my-[24px]">
              According to a survey by the International Data Corporation last
              year, Of more than 500 company executives in the US, 45% said they
              anticipate demand for enhanced employee engagement software in the
              wake of the pandemic.
            </div>
            <div className="my-[24px]">
              <img src={RiseOEThree} alt="RiseOEThree" />
            </div>
            <div className="text-[24px] font-medium my-[24px]">
              The increasing emphasis on prioritizing employee well-being
              signifies a positive shift in our approach to work. Digital
              platforms are serving as enablers, offering tools and solutions
              that cater to the diverse needs of the modern workforce. As
              companies continue to prioritize employee experience, the
              integration of digital platforms will undoubtedly play a crucial
              role in shaping the future of work. By investing in these
              technologies, organizations not only foster a happier and more
              engaged workforce but also position themselves for sustained
              success in the dynamic landscape of today's business world.
              <br />
              <br />
              To enhance employee experience Worx Squad serves as an all-in-one
              tool that provides everything under one roof, one license. Squad
              is a full Operating system for small to mid-size businesses for
              productivity, collaboration, engagement, and wellness. It aims to
              provide immersive experiences like 3D rooms, and live offices for
              enhanced connectivity experience, this unique Enterprise
              Productivity and Experience Platform encompasses multiple unique
              features like Project management, Leave and Timesheet management,
              Performance review system, Time spent analysis, Digital training,
              etc. for the entire organization under a single license which in
              turn makes work life effortless and enables time optimization
              making it the perfect application for a better employee
              experience.
            </div>

            <div className="text-[24px] font-medium my-[24px]">
              <div>
                <Link
                  className={`font-semibold underline`}
                  rel="noopener noreferrer"
                  aria-label="Worxwide"
                >
                  Worx Squad
                </Link>{' '}
                is a part of{' '}
                <Link
                  className={`font-semibold underline`}
                  href="https://www.worxwide.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Worxwide"
                >
                  About Worxwide
                </Link>
                <span>
                  , based out of US, UK, and India. Worxwide Consulting is a
                  digital growth consulting firm offering bid consulting, sales
                  transformation, user experience, and customer experience
                  design services.
                </span>
              </div>
              <div className="my-[24px]">
                To explore the product features,{' '}
                <span
                  className="underline text-[#185D74] cursor-pointer"
                  onClick={BookADemo}
                >
                  book
                </span>{' '}
                a demo call with us!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MRecentBContainThree;
