import { Link, Stack } from '@fluentui/react';
import { Logo } from '@worx.squad/shared-frontend';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackIcon } from '../../assets/BackIcon';

interface IAuthLayout {
  vector?: JSX.Element;
  route?: string;
  logo?: JSX.Element;
}

export const AuthLayout: React.FC<IAuthLayout> = ({
  vector,
  children,
  route,
  logo,
}) => {
  const navigate = useNavigate();
  const url = useLocation();
  return (
    <Stack
      verticalFill
      styles={{ root: { height: '100vh' } }}
      className="relative"
    >
      <Stack.Item className="w-44 absolute bottom-4 right-16 hidden lg:block">
        <Link
          onClick={() => {
            if (route) {
              navigate(route);
            }
          }}
        >
          {!logo ? <Logo /> : logo}
        </Link>
      </Stack.Item>

      <Stack.Item>
        <div className="flex justify-between items-center p-4 lg:hidden">
          <Stack
            onClick={() => {
              if (url.pathname === '/login') {
                navigate('/');
              } else if (url.pathname === '/forgot-password') {
                navigate('/login');
              }
            }}
          >
            <BackIcon />
          </Stack>
          <Stack className="w-44">
            <Link
              onClick={() => {
                if (route) {
                  navigate(route);
                }
              }}
            >
              <Logo />
            </Link>
          </Stack>
        </div>
        <Stack
          horizontal
          verticalAlign="center"
          className="sm:h-[100vh] mt-[20vh] sm:mt-[0vh]"
        >
          <Stack.Item
            align="end"
            className="pb-10 object-contain hidden lg:block"
            styles={{
              root: {
                width: '65%',
              },
            }}
          >
            {vector}
          </Stack.Item>

          <Stack.Item
            align="center"
            className="flex justify-center items-center w-full"
          >
            {children}
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
