import { Stack, Text } from '@fluentui/react';
import {
  Form,
  PrimaryButtonWithLoader,
  STextFieldStyle,
  TextField,
} from '@worx.squad/shared-frontend';
import { useForm } from 'react-hook-form';
import { changePasswordSchema } from './schema';

const ChangePasswordForm = ({
  onSubmit,
  loading,
}: {
  onSubmit: (values: any) => void;
  loading: boolean;
}) => {
  const changePasswordForm = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: changePasswordSchema,
    reValidateMode: 'onChange',
  });
  return (
    <Form formHook={changePasswordForm} onSubmit={onSubmit}>
      <Stack verticalAlign="center" verticalFill tokens={{ childrenGap: 30 }}>
        <Stack.Item className="pb-10">
          <Stack>
            <Text variant="xxLarge">Reset Password</Text>
            <Text variant="large">Enter your new password below.</Text>
          </Stack>
        </Stack.Item>
        <Stack.Item grow>
          <TextField
            type="password"
            name="password"
            formHook={changePasswordForm}
            styles={STextFieldStyle}
            label="Password"
            revealPasswordAriaLabel="Reveal password"
            canRevealPassword
          />
        </Stack.Item>
        <Stack.Item grow>
          <TextField
            type="password"
            name="confirmPassword"
            formHook={changePasswordForm}
            styles={STextFieldStyle}
            label="Confirm Password"
            revealPasswordAriaLabel="Reveal password"
            canRevealPassword
          />
        </Stack.Item>
        <Stack.Item grow>
          <Stack horizontalAlign="center" verticalAlign="center">
            <PrimaryButtonWithLoader
              loading={loading}
              text="Change Password"
              onClick={changePasswordForm.handleSubmit(onSubmit)}
            />
          </Stack>
        </Stack.Item>
      </Stack>
    </Form>
  );
};

export default ChangePasswordForm;
