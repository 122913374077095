import { SVGProps } from 'react';
export const CalendarLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.5 0A7.5 7.5 0 0 1 36 7.5V9H0V7.5A7.5 7.5 0 0 1 7.5 0h21ZM36 28.5V12H0v16.5A7.5 7.5 0 0 0 7.5 36h21a7.5 7.5 0 0 0 7.5-7.5ZM26.25 21a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5ZM24 26.25a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Zm-6 2.25a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Zm-2.25-9.75a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Zm-6 2.25a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5Z"
      fill="#EF8538"
    />
  </svg>
);
