import { ChoiceGroup, TextField } from '@fluentui/react';

interface IProps {
  state: any;
  handleState: (field: string, value: any) => void;
  handlePeriod: (field: string, index: number, value: any) => void;
}

const TimePeriod = (props: IProps) => {
  const { state, handleState, handlePeriod } = props;
  const frequencyOptions = [
    { key: 'Quarterly', text: 'Quarterly' },
    { key: 'Half_Yearly', text: 'Half_Yearly' },
    { key: 'Annual', text: 'Annual' },
  ];

  return (
    <div className="pt-12 flex flex-col gap-y-1">
      <label className="text-sm text-[#000000] font-semibold">Frequency</label>
      <label className="text-sm text-[#5C5F60] font-normal">
        Please select the cycle start month. End month will be automatically
        calculated as 12 months from the start month.
      </label>
      <ChoiceGroup
        selectedKey={state.frequency}
        options={frequencyOptions}
        onChange={(e, option) => handleState('frequency', option?.key)}
        styles={{
          flexContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            gap: '24px',
          },
        }}
      />
      {state.period?.map((info: any, index: number) => (
        <div className="flex items-center gap-x-4" key={index}>
          <TextField
            label="TimePeriod"
            className="w-[20%]"
            value={info.timePeriod}
            disabled
          />
          <TextField
            label="Title"
            className="w-[30%]"
            value={info.title}
            onChange={(e, val) => handlePeriod('title', index, val)}
          />
          <TextField
            label="Review Start Date"
            className="w-[15%]"
            type="date"
            min={info.startDateFrom}
            max={info.startDateTo}
            value={info.startDate}
            onChange={(e, val) => {
              handlePeriod('startDate', index, val);
              handlePeriod('endingDate', index, info.dueDateTo);
            }}
          />
          <TextField
            label="Due Date"
            className="w-[15%]"
            type="date"
            min={info.startDateFrom}
            max={info.dueDateTo}
            value={info.dueDate}
            onChange={(e, val) => handlePeriod('dueDate', index, val)}
          />
        </div>
      ))}
    </div>
  );
};

export default TimePeriod;
