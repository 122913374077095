import {
  IconButton,
  MessageBarType,
  Modal,
  PrimaryButton,
} from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { useBoolean } from 'ahooks';
import moment from 'moment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import HolidayForm from './HolidayForm';
import { holidaySchema } from './holiday.schema';
import { HolidayFormValues } from './holiday.types';
import { useCreateHolidayMutation } from './holidays.generated';

const AddNewHoliday = () => {
  const [isModalOpen, { toggle: toggleModalOpen }] = useBoolean(false);
  const { organization } = useOrganizationContext();
  const addNewHolidayForm = useForm<HolidayFormValues>({
    mode: 'all',
    resolver: holidaySchema,
  });

  const [loading, setLoading] = useState(false);

  const [, createHoliday] = useCreateHolidayMutation();
  const { notification } = useNotification();

  const onSubmit = async (data: HolidayFormValues) => {
    setLoading(true);
    try {
      const res = await createHoliday({
        data: {
          name: data.name,
          description: data.description,
          date: moment(data.date).format('YYYY-MM-DD'),
          organization_id: organization?.id,
        },
      });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: 'Holiday added successfully',
          type: MessageBarType.success,
        });
        window.dispatchEvent(new CustomEvent('refresh-holidays'));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      toggleModalOpen();
    }
  };
  return (
    <>
      <PrimaryButton onClick={toggleModalOpen}>Add New</PrimaryButton>
      <Modal onDismiss={toggleModalOpen} isOpen={isModalOpen}>
        <div className="p-4 w-96">
          <div className="flex justify-between">
            <h1>Add New Holiday</h1>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              onClick={toggleModalOpen}
            />
          </div>
          <HolidayForm
            buttonText="Add new Holiday"
            form={addNewHolidayForm}
            loading={loading}
            onSubmit={onSubmit}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddNewHoliday;
