import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import ContactUs from '../components/CommonComponent/ContactUs';
import { Footer } from '../components/CommonComponent/Footer';
import { Header } from '../components/CommonComponent/Header';
import { Starter } from '../components/SquadPricingPlans/Starter';
import WhatYouGet from '../components/SquadPricingPlans/WhatYouGet';

const Pricing = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  return (
    <div className="relative bg-[#1A1A1A]">
      <Header />
      <div className="flex flex-col justify-center items-center">
        <Starter />
        <WhatYouGet />
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;
