import { MessageBarType, Modal, PrimaryButton, Stack } from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { useEffect, useState } from 'react';
import { VscClose } from 'react-icons/vsc';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import {
  useDeleteCompetencyMutation,
  useViewCompetencyQuery,
} from './Competency.generated';

interface IModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  children?: React.ReactNode;
  selectedID: string;
  listOfCompetenciesData: () => void;
}

const DeleteCompetencyPopup = (props: IModalProps) => {
  const { notification } = useNotification();
  const { organization } = useOrganizationContext();

  const [competencyInfo, setCompetencyInfo] = useState<any>();
  const [isDisabled, setIsDisabled] = useState(false);

  const [, deleteCompetency] = useDeleteCompetencyMutation();

  const handleDeleteCompetency = async () => {
    setIsDisabled(true);
    const res = await deleteCompetency({
      _eq: props.selectedID,
    });
    if (res.error) {
      setIsDisabled(false);
      notification({
        message:
          'Unable to delete competency. Skills from this competency have already been added by the user.',
        type: MessageBarType.error,
      });
    } else {
      setIsDisabled(false);
      notification({
        message: `Competency deleted!`,
        type: MessageBarType.success,
      });
    }
    props.onDismiss();
    props.listOfCompetenciesData();
  };

  const [{ data: viewCompetency }] = useViewCompetencyQuery({
    variables: {
      _eq: props.selectedID,
      _eq1: organization?.id,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setCompetencyInfo(viewCompetency?.main_skills[0]);
  }, [viewCompetency]);

  return (
    <Modal {...props}>
      <Stack className="p-6 pt-0" style={{ width: 720, height: 199 }}>
        <div>
          <div className="flex justify-between items-center pb-7  pt-6 bg-white z-10">
            <span>
              <label className="text-[#201F1E] text-xl leading-7 font-semibold">
                Confirm Delete
              </label>
            </span>
            <span
              onClick={props.onDismiss}
              className="flex justify-end cursor-pointer"
            >
              <VscClose size={25} />
            </span>
          </div>
          <label className="text-base font-semibold text-black">
            Are you sure you want to delete “{competencyInfo?.name}, along with{' '}
            {competencyInfo?.sub_skills.length} skills added to it”.
          </label>
          <div className="mt-10 flex justify-end items-center gap-x-4">
            <button
              type="button"
              onClick={props.onDismiss}
              className="px-5 py-1 bg-white border rounded-sm border-[#8A8886]"
            >
              Cancel
            </button>
            <PrimaryButton
              text="Confirm"
              onClick={handleDeleteCompetency}
              disabled={isDisabled}
              styles={{
                root: {
                  selectors: {
                    ':disabled': {
                      backgroundColor: '#F3F2F1 !important',
                      color: '#A19F9D',
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </Stack>
    </Modal>
  );
};

export default DeleteCompetencyPopup;
