import { MessageBarType } from '@fluentui/react';
import {
  PrimaryButtonWithLoader,
  dispatchListeners,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import { useCreateNewAwardTypeMutation } from '../awards.generated';
import { IAwardsData } from '../types';
const listenerItems = ['refetch-awards', 'reRender-table'];
const CreateAward = ({ award }: { award: IAwardsData }) => {
  const org = useOrganizationContext();
  const [loading, setLoading] = useState(false);
  const { notification } = useNotification();
  const [{ fetching }, createAward] = useCreateNewAwardTypeMutation();

  function showNotification(message: string) {
    notification({
      message,
      type: MessageBarType.error,
    });
  }

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (!award.name) {
        return showNotification('Please enter award name');
      }
      if (!award.cycle) {
        return showNotification('Please select award cycle');
      }
      console.log(award);
      const res = await createAward({
        object: {
          name: award.name || '',
          cycle: award.cycle || '',
          organization_id: org.organization?.id,
          permissions: 'manager',
          description: award.description,
          limit: award.limit,
        },
      });
      if (res.data) {
        notification({
          message: 'Award created successfully',
          type: MessageBarType.success,
        });

        dispatchListeners(listenerItems);
      }
      if (res.error) {
        notification({
          message: 'Oops something went wrong',
          type: MessageBarType.error,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <PrimaryButtonWithLoader disabled={false} onClick={onSubmit}>
      Add
    </PrimaryButtonWithLoader>
  );
};

export default CreateAward;
