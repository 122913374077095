import {
  useHeader,
  useLayoutState,
  useListener,
} from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect, useState } from 'react';
import AwardsInitializeScreen from '../components/Awards/AwardsInitializeScreen';
import AwardsList from '../components/Awards/AwardsList';
import GeneralSettings from '../components/Awards/GeneralSettings';
import {
  useGetAllAwardsByOrgIdQuery,
  useGetOrgAwardsQuery,
} from '../components/Awards/awards.generated';
import { IAwardsData } from '../components/Awards/types';
import { useOrganizationContext } from '../context/OrganizationContext/OrganizationContext';

const Awards = () => {
  const { setTitle } = useHeader();
  const [awards, setAwards] = useState<IAwardsData[]>([]);
  const [, setLayoutState] = useLayoutState();
  const [reRender, setRerender] = useState(0);

  const org = useOrganizationContext();
  const orgId = org.organization?.id;

  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Awards');
  }, [setTitle]);

  //* Data fetches
  const [{ data }, refetchAwards] = useGetAllAwardsByOrgIdQuery({
    variables: { orgId: orgId },
    pause: !orgId,
    requestPolicy: 'cache-and-network',
  });
  const [{ data: orgAwardsData, fetching }, refetchOrgIdQuery] =
    useGetOrgAwardsQuery({
      variables: { orgId },
      pause: !orgId,
      requestPolicy: 'cache-and-network',
    });

  //* Hooks
  useEffect(() => {
    if (data?.awards) {
      setAwards(
        data.awards.map((e) => ({
          key: e.id as string,
          name: e.name,
          cycle: e.cycle,
          description: e.description ?? undefined,
          limit: e.limit?.toString(),
        })),
      );
    }
  }, [orgId, data]);

  //* Handlers
  const updateKey = () => {
    setRerender((reRender) => reRender + 1);
  };
  useListener('refetch-awards', refetchAwards);
  useListener('reRender-table', updateKey);
  useListener('refetch-initialize-awards', refetchOrgIdQuery);

  return (
    <>
      {orgAwardsData?.organization_awards.length === 0 ? (
        <div className="flex justify-center items-center h-full">
          <AwardsInitializeScreen
            orgId={orgId}
            key={reRender}
            loading={fetching}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-16">
          <GeneralSettings orgAwardsData={orgAwardsData} orgId={orgId} />
          <hr />
          <AwardsList awards={awards} key={reRender} />
        </div>
      )}
    </>
  );
};

export default Awards;

//TODO: Define foreign keys for OrgAwards table
