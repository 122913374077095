import { Checkbox, MessageBarType } from '@fluentui/react';
import { GetOrgAwardsByOrgIdQuery } from '@worx.squad/app-sdk';
import { useNotification } from '@worx.squad/shared-frontend';
import { useCallback, useEffect, useState } from 'react';
import { useUpdateOrganizationAwardsByOrgIdMutation } from './awards.generated';

interface IGeneralSetting {
  orgAwardsData: GetOrgAwardsByOrgIdQuery | undefined;
  orgId: string | null | undefined;
}

const GeneralSettings = ({ orgAwardsData, orgId }: IGeneralSetting) => {
  const [, updateActiveState] = useUpdateOrganizationAwardsByOrgIdMutation();
  const { notification } = useNotification();

  // State to manage checkboxes
  const [checkboxState, setCheckboxState] = useState({
    isActiveChecked: orgAwardsData?.organization_awards[0]?.is_active || false,
    isPermissionChecked:
      orgAwardsData?.organization_awards[0]?.manager_permission || false,
  });

  useEffect(() => {
    if (orgAwardsData?.organization_awards.length) {
      setCheckboxState({
        isActiveChecked: orgAwardsData.organization_awards[0].is_active,
        isPermissionChecked:
          orgAwardsData.organization_awards[0].manager_permission,
      });
    }
  }, [orgAwardsData]);

  const handleCheckboxChange = useCallback(
    async (
      key: 'isActiveChecked' | 'isPermissionChecked',
      checked: boolean,
    ) => {
      const dataToUpdate =
        key === 'isActiveChecked'
          ? { is_active: checked }
          : { manager_permission: checked };

      const res = await updateActiveState({ orgId: orgId, data: dataToUpdate });

      if (res.data) {
        const message = checked
          ? key === 'isActiveChecked'
            ? 'Awards Activated Successfully'
            : 'Manager Permission Added'
          : key === 'isActiveChecked'
            ? 'Awards Deactivated Successfully'
            : 'Manager Permission Removed';

        const messageType = checked
          ? MessageBarType.success
          : MessageBarType.warning;

        notification({
          message: message,
          type: messageType,
        });
      }

      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      }
    },
    [orgId, updateActiveState, notification],
  );

  const { isActiveChecked, isPermissionChecked } = checkboxState;

  return (
    <div className="grid grid-cols-5 gap-16">
      <div className="col-span-1">
        <div className="flex flex-col gap-4">
          <strong>General Setting</strong>
          <p className="text-gray-400">
            Here you can enable or disable awards functionality for your
            organisation.
          </p>
        </div>
      </div>
      <div className="col-span-4">
        <div className="flex flex-col gap-4">
          <Checkbox
            checked={isActiveChecked}
            onChange={(ev, checked) => {
              setCheckboxState({
                ...checkboxState,
                isActiveChecked: !!checked,
              });
              handleCheckboxChange('isActiveChecked', !!checked);
            }}
            label="Enable Awards"
          />
          <Checkbox
            checked={isPermissionChecked}
            onChange={(ev, checked) => {
              setCheckboxState({
                ...checkboxState,
                isPermissionChecked: !!checked,
              });
              handleCheckboxChange('isPermissionChecked', !!checked);
            }}
            label="Allow managers to give awards"
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
