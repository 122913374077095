import constate from 'constate';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import { useGetOrganizationLocationsTitleQuery } from './hot-desking.generated';

function useHotDeskHook() {
  const { organization } = useOrganizationContext();
  const [locationName, setLocationName] = useState('');
  const [locationNameLazy] = useDebounce(locationName, 1000);
  const [{ data: locationTitlesRaw }, refetchOrganizationLocations] =
    useGetOrganizationLocationsTitleQuery({
      variables: {
        organization_id: organization?.id,
        title: locationNameLazy ? `%${locationNameLazy}%` : undefined,
      },
      pause: !organization?.id,
      requestPolicy: 'cache-and-network',
    });
  return {
    organization,
    locationName,
    setLocationName,
    locationNameLazy,
    locationTitlesRaw,
    refetchOrganizationLocations,
  };
}

export const [HotDeskProvider, useHotDesk] = constate(useHotDeskHook);
