import { FC } from 'react';
import Select, { CreatableProps } from 'react-select/creatable';
import { useCreateReactSelectFluentTheme } from '../../hooks';
import { ISelect, SelectLayout } from './SelectLayout';

export interface ICreatableSearchableSelectProps
  extends ISelect,
    CreatableProps<any, any, any> {
  fixedHeight?: boolean;
  hideBorder?: boolean;
  isClearable?: boolean;
}

export const CreatableSearchableSelect: FC<ICreatableSearchableSelectProps> = ({
  label,
  required,
  error,
  fixedHeight = true,
  hideBorder = false,
  isClearable = true,
  ...props
}) => {
  const baseStyles = useCreateReactSelectFluentTheme(error, fixedHeight);
  const hideBorderStyles = {
    control: (provided: any) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
    }),
  };

  return (
    <SelectLayout required={required} error={error} label={label}>
      <Select
        isClearable={isClearable}
        menuPortalTarget={document.body}
        styles={hideBorder ? hideBorderStyles : baseStyles}
        {...props}
      />
    </SelectLayout>
  );
};
