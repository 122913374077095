import { useTheme } from '@fluentui/react';
import { FieldError } from 'react-hook-form';

export interface ISearchableSelectOption {
  value?: string;
  label: string;
}
export interface ISelect {
  label?: string;
  required?: boolean;
  error?: FieldError | undefined;
}

export const SelectLayout: React.FC<ISelect> = ({
  label,
  required,
  error,
  children,
}) => {
  const theme = useTheme();
  return (
    <div className="w-full">
      {label && (
        <div
          className="ms-Label font-semibold w-full"
          style={{ paddingTop: 5, paddingBottom: 5 }}
          id="aria-label"
        >
          {label}{' '}
          {required && <span style={{ color: theme.palette.redDark }}>*</span>}
        </div>
      )}
      {children}
      {error && (
        <div
          className="text-xs"
          style={{ color: theme.palette.redDark, paddingTop: 5 }}
        >
          {error.message}
        </div>
      )}
    </div>
  );
};
