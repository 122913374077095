import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Link,
  MessageBarType,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  PrimaryButtonWithLoader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { OperationContext } from 'urql';
import { ProjectsData } from './ProjectsList';
import { useDeleteProjectMutation } from './projects-and-clients.generated';

const DeleteProject = ({
  project,
  refetch,
}: {
  project: ProjectsData;
  refetch: (opts?: Partial<OperationContext> | undefined) => void;
}) => {
  const [dialogHidden, { toggle: toggleDialog }] = useBoolean(true);
  const { notification } = useNotification();
  const [{ fetching }, deleteProject] = useDeleteProjectMutation();
  const onSubmit = async () => {
    try {
      const res = await deleteProject({ projectId: project.id });
      refetch?.();
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: 'Project deleted successfully',
          type: MessageBarType.success,
        });
        toggleDialog();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Link onClick={toggleDialog}>Delete</Link>
      <Dialog
        hidden={dialogHidden}
        onDismiss={toggleDialog}
        dialogContentProps={{
          title: `Are you sure?`,
          subText: `Are you sure you want to delete the project ${project.title}. This action cannot be undone.`,
          type: DialogType.close,
        }}
      >
        <DialogFooter>
          <PrimaryButtonWithLoader onClick={onSubmit} loading={fetching}>
            Delete
          </PrimaryButtonWithLoader>
          <DefaultButton onClick={toggleDialog}>Cancel</DefaultButton>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DeleteProject;
