import { IColumn } from '@fluentui/react';
import { Enum_Award_Cycle_Type_Enum } from '@worx.squad/shared-frontend';
import { IAwardsData } from './types';

export function getAwardsListColumns() {
  return [
    {
      name: 'Award Name*',
      key: 'name',
      isRowHeader: true,
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
      isMultiline: true,
      className: 'text-sm flex items-center',
    },
    {
      name: 'Cycle*',
      key: 'cycle',
      isRowHeader: true,
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      isMultiline: true,
      className: 'text-sm flex items-center',
    },
    {
      name: 'Limit',
      key: 'limit',
      isRowHeader: true,
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      isMultiline: true,
      className: 'text-sm flex items-center',
    },
    {
      name: 'Description',
      key: 'description',
      isRowHeader: true,
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
      isMultiline: true,
      className: 'text-sm flex items-center',
    },
    {
      name: 'Action',
      key: 'action',
      isRowHeader: true,
      minWidth: 130,
      maxWidth: 130,
      isResizable: true,
      isMultiline: true,
      className: 'text-sm flex items-center',
    },
  ] as IColumn[];
}

export const awardCycleOpts: { key: string; text: string; title?: string }[] = [
  {
    key: Enum_Award_Cycle_Type_Enum.Annual,
    text: 'Annual',
  },
  {
    key: Enum_Award_Cycle_Type_Enum.HalfYearly,
    text: 'Half-Yearly',
  },
  {
    key: Enum_Award_Cycle_Type_Enum.Quarterly,
    text: 'Quarterly',
  },
];

export const newAwardRaw: IAwardsData = {
  key: undefined,
  name: undefined,
  cycle: undefined,
  description: undefined,
  limit: undefined,
  newAwardList: true,
};

export function formatCycleType(
  type: Enum_Award_Cycle_Type_Enum | null | undefined,
) {
  const formattedType = type
    ?.replace(/_/g, ' ')
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedType;
}
