import { IconButton } from '@fluentui/react';
import { useBoolean } from 'ahooks';
import AwardsDeleteConfirmation from './modals/AwardsDeleteConfirmation';
import AwardsEditModal from './modals/AwardsEditModal';
interface IAwardsActionBar {
  key: string | undefined;
  id: string | undefined;
}
const AwardsActionBar = ({ key, id }: IAwardsActionBar) => {
  const [editModalVisible, { toggle: toggleEditModal }] = useBoolean(false);
  const [dialogHidden, { toggle: toggleDialog }] = useBoolean(true);

  return (
    <>
      <div className="flex flex-row gap-6">
        <IconButton
          menuIconProps={{
            iconName: 'edit',
            style: { color: 'gray', fontSize: 20 },
          }}
          onClick={toggleEditModal}
        />
        <IconButton
          menuIconProps={{
            iconName: 'delete',
            style: { color: 'gray', fontSize: 20 },
          }}
          onClick={toggleDialog}
        />
      </div>
      <AwardsEditModal
        awardId={id}
        editModalVisible={editModalVisible}
        toggleEditModal={toggleEditModal}
      />
      <AwardsDeleteConfirmation
        awardId={id}
        dialogHidden={dialogHidden}
        toggleDialog={toggleDialog}
      />
    </>
  );
};

export default AwardsActionBar;
