import { useAuth } from '@worx.squad/hbp-react';
import {
  AppLayout,
  HeaderContext,
  NormalLayout,
  useLayoutState,
} from '@worx.squad/shared-frontend';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import AppHeader from './AppHeader';
import navigationRoutes from './navigation';

const LayoutProvider: React.FC = ({ children }) => {
  const { organization } = useOrganizationContext();
  const { user } = useAuth();
  const [layout] = useLayoutState();
  const isOwner = user?.id === organization?.owned_by;

  const navigation = navigationRoutes(isOwner);

  if (layout === 'app') {
    return (
      <HeaderContext>
        <AppLayout headerContent={<AppHeader />} navigation={navigation}>
          {children}
        </AppLayout>
      </HeaderContext>
    );
  }
  return <NormalLayout>{children}</NormalLayout>;
};

export default LayoutProvider;
