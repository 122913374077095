import { IconButton, Modal, Stack, Text } from '@fluentui/react';
import { OperationContext } from 'urql';
import DepartmentAddForm from './DepartmentAddForm';

interface IDepartmentAddModal {
  isModalOpen: boolean;
  hideModal: () => void;
  refetch?: (opts?: Partial<OperationContext>) => void;
  edit?: { name: any; id: any; manager: any };
}

const DepartmentAddModal = ({
  isModalOpen,
  hideModal,
  refetch,
  edit,
}: IDepartmentAddModal) => {
  const onCancel = () => {
    hideModal();
    refetch && refetch();
  };
  return (
    <Modal isOpen={isModalOpen} onDismiss={onCancel}>
      <div className="p-5 w-96">
        <div className="flex  justify-between mb-6">
          <div>
            <Text className="text-2xl">
              {edit ? 'Edit' : 'Add new'} department
            </Text>
          </div>
          <div>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={hideModal}
            />
          </div>
        </div>
        <div>
          <Stack tokens={{ childrenGap: 20 }}>
            <DepartmentAddForm
              refetch={refetch}
              onCancel={onCancel}
              edit={edit}
            />
          </Stack>
        </div>
      </div>
    </Modal>
  );
};

export default DepartmentAddModal;
