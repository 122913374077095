import {
  MessageBarType,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { isEmpty } from 'lodash';
import Multiselect from 'multiselect-react-dropdown';
import { useState } from 'react';
import { VscClose } from 'react-icons/vsc';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import { useAddCompetencyMutation } from './Competency.generated';

interface IModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  children?: React.ReactNode;
  createCompetency: any;
  setCreateCompetency: any;
  listOfCompetenciesData: () => void;
  skillList: any;
}

const AddNewCompetencyPopup = (props: IModalProps) => {
  const {
    isOpen,
    onDismiss,
    createCompetency,
    setCreateCompetency,
    listOfCompetenciesData,
    skillList,
  } = props;

  const { organization } = useOrganizationContext();
  const { notification } = useNotification();
  const [isDisabled, setIsDisabled] = useState(false);

  const [, addCompetency] = useAddCompetencyMutation();

  const handleSkillOnCompetency = (field: string, value: any) => {
    if (field === 'name') {
      setCreateCompetency({
        ...createCompetency,
        name: value,
      });
    }
    if (field === 'skills') {
      setCreateCompetency({
        ...createCompetency,
        skills: value,
      });
    }
  };

  const addNewCompetencyHandler = async () => {
    const allBlank = createCompetency.skills.every(
      (skill: any) => skill.label === '',
    );
    const fromattedSkillList = createCompetency.skills.map((item: any) => ({
      id: item.id,
    }));
    if (!isEmpty(createCompetency.name)) {
      setIsDisabled(true);
      const { data: responseData, error: responseError } = await addCompetency({
        object: {
          orgId: organization?.id,
          name: createCompetency.name,
          competencyskills: allBlank ? null : fromattedSkillList,
        },
      });
      if (responseData?.addCompetency?.id) {
        setIsDisabled(false);
        notification({
          message: `Competency added successfully!`,
          type: MessageBarType.success,
        });
        onDismiss();
        listOfCompetenciesData();
      }
      if (responseError) {
        setIsDisabled(false);
        notification({
          message: 'Something went wrong',
          type: MessageBarType.error,
        });
      }
    } else {
      notification({
        type: MessageBarType.info,
        message: 'Please enter name',
      });
    }
  };

  return (
    <Modal {...props}>
      <Stack
        className="p-6 pt-0"
        style={{ width: 518, height: 359, overflowY: 'auto' }}
      >
        <div>
          <div className="flex justify-between items-center pb-3  pt-6 sticky top-0 bg-white z-10">
            <span>
              <label className="text-[#201F1E] text-xl leading-7 font-semibold">
                Add New Competency
              </label>
            </span>
            <span
              onClick={onDismiss}
              className="flex justify-end cursor-pointer"
            >
              <VscClose size={25} />
            </span>
          </div>
          <TextField
            autoFocus={false}
            label="Name*"
            placeholder={'Type competency here..'}
            onChange={(e, newValue) => {
              handleSkillOnCompetency('name', newValue);
            }}
            className="mb-8"
          />
          <label className={'text-sm font-semibold'}>Skills</label>
          <div className="skillMultiselect">
            <Multiselect
              options={skillList}
              showCheckbox={true}
              displayValue="label"
              style={{
                chips: {
                  background: '#F3F2F1',
                  color: '#201F1E',
                  borderRadius: 0,
                  marginBottom: 0,
                  marginRight: 4,
                },
                searchWrappers: {
                  padding: 30,
                  border: '1px solid #8A8886',
                },
                searchBox: {
                  border: '1px solid #8A8886',
                  borderRadius: '0px',
                },
                optionContainer: {
                  backgroundColor: 'white',
                  height: '120px',
                },
              }}
              customCloseIcon={<span className="cursor-pointer px-1">X</span>}
              selectedValues={createCompetency.skills}
              onSelect={(newValue) =>
                handleSkillOnCompetency('skills', newValue)
              }
              onRemove={(newValue) =>
                handleSkillOnCompetency('skills', newValue)
              }
            />
          </div>
          <PrimaryButton
            text="Add"
            style={{
              flexWrap: 'wrap',
              position: 'absolute',
              bottom: 20,
              right: 35,
            }}
            onClick={addNewCompetencyHandler}
            disabled={isDisabled}
            styles={{
              root: {
                selectors: {
                  ':disabled': {
                    backgroundColor: '#F3F2F1 !important',
                    color: '#A19F9D',
                  },
                },
              },
            }}
          />
        </div>
      </Stack>
    </Modal>
  );
};

export default AddNewCompetencyPopup;
