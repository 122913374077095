import { useLayoutState, WelcomeLayout } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import { Close } from '../components/Close/Close';

const Closing = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);
  return (
    <WelcomeLayout>
      <Close />
    </WelcomeLayout>
  );
};

export default Closing;
