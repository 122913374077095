import { CustomersFeedbackCardDetails } from './Utils';

const CustomersFeedback = () => {
  return (
    <div className="p-20">
      <div className="text-[24px] sm:text-4xl leading-[2.5rem] lg:leading-[3.5rem] text-[#000000] text-center">
        Trusted by Businesses to Elevate Productivity
      </div>
      <div className="text-[#7A7A7A] text-xl text-center mt-4">
        See how Worx Squad has empowered teams to streamline their operations
        and achieve remarkable results.
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 px-[60px] mt-8">
        {CustomersFeedbackCardDetails?.map((item: any, index: number) => (
          <div
            key={index}
            className="border p-[20px] bg-white rounded-xl shadow-lg hover:bg-gradient-to-b hover:from-[rgba(43,43,43,0.9)] hover:to-[rgba(24,93,116,0.9)] hover:text-white hover:transition-all hover:duration-500 hover:scale-110"
          >
            <div className="py-2 text-center text-[16px] sm:text-[20px] font-semibold">
              {item.feedback}
            </div>
            <div>{item.customerImage}</div>
            <div className="my-2 font-medium">{item.name}</div>
            <div>{item.role}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomersFeedback;
