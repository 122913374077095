import { Spinner } from '@fluentui/react';

export default function SpinnerCustom() {
  return (
    <div className="relative w-full h-screen">
      <div className="absolute h-full w-full  flex justify-center items-center  bg-gray-50">
        <Spinner className="z-20" />
        <div className=" absolute z-10 h-full w-full opacity-95 bg-gray-50"></div>
      </div>
    </div>
  );
}
