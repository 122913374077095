import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .test('email', 'Email cannot contain white spaces.', (value) => {
      return !/\s/.test(value as string);
    })
    .label('Email')
    .required(),
  first_name: yup
    .string()
    .trim()
    .max(100, 'Maximum 100 characters are allowed'),
  last_name: yup.string().trim().max(100, 'Maximum 100 characters are allowed'),
});

export const tryUsSchema = yupResolver(schema);
