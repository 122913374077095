import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Link,
  MessageBarType,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  PrimaryButtonWithLoader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import { auth } from '../../../helpers/hbp-helper';
import { useTransferOrganizationOwnershipMutation } from './administrators.generated';

const TransferOwnership = ({ userId }: { userId: string }) => {
  const { organization } = useOrganizationContext();
  const [{ fetching }, transferOwnership] =
    useTransferOrganizationOwnershipMutation();
  const { notification } = useNotification();
  const [dialogHidden, { toggle: toggleDialog }] = useBoolean(true);

  const dialogContentProps = {
    type: DialogType.close,
    title: 'Transfer ownership?',
    subText:
      'Are you sure you want to transfer the ownership of this organization? Once transferred you wont be able to change the ownership back.',
  };

  const onSubmit = async () => {
    try {
      const res = await transferOwnership({
        object: { org_id: organization?.id, user_id: userId },
      });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
        throw new Error(res.error.message);
      }
      notification({
        message: 'Successfully transferred ownership. Please login again.',
        type: MessageBarType.success,
      });
      toggleDialog();
      auth.logout();
    } catch (error) {
      console.error(error);
      toggleDialog();
    }
  };

  return (
    <>
      <Link onClick={toggleDialog}>Transfer ownership</Link>
      <Dialog
        hidden={dialogHidden}
        onDismiss={toggleDialog}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButtonWithLoader
            loading={fetching}
            onClick={onSubmit}
            text="Transfer"
          />
          <DefaultButton onClick={toggleDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default TransferOwnership;
