import Auth from './lib/Auth';
import NhostClient from './lib/NhostClient';
import Storage from './lib/Storage';
import { UserConfig } from './lib/types';
import UserSession from './lib/UserSession';
export * from './lib/types';
export { Auth, createClient, NhostClient, Storage, UserSession };

const createClient = (config: UserConfig) => {
  return new NhostClient(config);
};
