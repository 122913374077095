import { INavLinkGroup } from '@fluentui/react';

const navLinkGroups = (isOwner: boolean) => {
  return [
    {
      links: [
        {
          name: 'Dashboard',
          url: '/dashboard',
          key: '/dashboard',
          iconProps: {
            iconName: 'autoRacing',
          },
        },
        {
          name: 'Work',
          url: '',
          key: 'work',
          iconProps: {
            iconName: 'WorkforceManagement',
          },
          links: [
            {
              name: 'Employees',
              url: '/employees',
              key: '/employees',
              iconProps: {
                iconName: 'People',
              },
            },
            {
              name: 'Department',
              url: '/department',
              key: '/department',
              iconProps: {
                iconName: 'ManagerSelfService',
              },
            },
            {
              name: 'Roles',
              url: '/roles',
              key: '/roles',
              iconProps: {
                iconName: 'ContactHeart',
              },
            },
            {
              name: 'Training Type',
              url: '/trainingtype',
              key: '/trainingtype',
              iconProps: {
                iconName: 'ContactHeart',
              },
            },
            {
              name: 'Assessment Type',
              url: '/Assessmenttype',
              key: '/Assessmenttype',
              iconProps: { iconName: 'ContactHeart' },
            },
            {
              name: 'Performance Review',
              url: '/PerformanceReview',
              key: '/PerformanceReview',
              iconProps: { iconName: 'ContactHeart' },
            },
            {
              name: 'Hot Desking',
              url: '/hot-desking',
              key: '/hot-desking',
              iconProps: {
                iconName: 'BacklogBoard',
              },
            },
            {
              name: 'Leaves',
              url: '/leaves',
              key: '/leaves',
              iconProps: {
                iconName: 'Calendar',
              },
            },
            {
              name: 'Awards',
              url: '/awards',
              key: '/awards',
              iconProps: {
                iconName: 'Ribbon',
              },
            },
            {
              name: 'Competencies',
              url: '/competencies',
              key: '/competencies',
              iconProps: {
                iconName: 'D365TalentLearn',
              },
            },
            {
              name: 'Announcements',
              url: '/announcements',
              key: '/announcements',
              iconProps: {
                iconName: 'Megaphone',
              },
            },
            {
              name: 'Holidays',
              url: '/holidays',
              key: '/holidays',
              iconProps: {
                iconName: 'Calendar',
              },
            },
          ],
        },
        {
          name: 'Task Management',
          url: '',
          key: 'task-management',
          iconProps: {
            iconName: 'TaskManager',
          },
          links: [
            {
              name: 'Projects and Clients',
              url: '/task-management/projects-and-clients',
              key: '/task-management/projects-and-clients',
              iconProps: {
                iconName: 'NewTeamProject',
              },
            },
          ],
        },
        {
          name: 'Settings',
          url: '',
          key: 'settings',
          iconProps: {
            iconName: 'Settings',
          },
          links: isOwner
            ? [
                {
                  name: 'Manage Administrators',
                  url: '/settings/manage-administrators',
                  key: '/settings/manage-administrators',
                  iconProps: {
                    iconName: 'Admin',
                  },
                },
                {
                  name: 'Organization Settings',
                  url: '/settings/organization-settings',
                  key: '/settings/organization-settings',
                  iconProps: {
                    iconName: 'Org',
                  },
                },
              ]
            : [],
        },
      ],
    },
  ] as INavLinkGroup[];
};
export default navLinkGroups;
