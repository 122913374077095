import { productivityItems } from './Utils';

const Productivity = () => {
  return (
    <div className="mt-[25%] p-20">
      <div className="mx-[20px] sm:mx-[40px] mb-[30px] gap-2 sm:gap-4 flex flex-col items-center justify-center">
        <div className="text-[24px] sm:text-4xl leading-[2.5rem] lg:leading-[3.5rem] text-[#101010]">
          What improves productivity?
        </div>
        <div className="text-[#7A7A7A] text-xl">
          Discover the features that elevate user satisfaction and engagement
        </div>
      </div>
      <div className="mx-[20px] sm:mx-[60px]">
        <div className="grid grid-cols-1 md:grid-cols-3">
          {productivityItems.map((item, index) => (
            <div
              key={index}
              className="px-4 pt-4 bg-[#FAFAFA] rounded-md mx-4 my-6 drop-shadow-lg"
            >
              <p className="text-lg font-semibold">{item.title}</p>
              <p className="text-base font-normal h-[72px]">{item.content}</p>
              <p className="mt-10">{item.icon}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Productivity;
