import { Checkbox, ICheckboxProps } from '@fluentui/react';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

export interface CheckboxProps extends ICheckboxProps {
  formHook: UseFormReturn<any>;
  name: string;
}

export const CheckboxField: FC<CheckboxProps> = ({
  formHook,
  name,
  ...props
}) => {
  const { control } = formHook;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Checkbox onChange={onChange} checked={value} {...props} />
      )}
    />
  );
};
