import { get } from 'lodash';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  CreatableSearchableSelect,
  ICreatableSearchableSelectProps,
} from '../../Components';

export interface ICreatableSearchableSelectField
  extends ICreatableSearchableSelectProps {
  formHook: UseFormReturn<any>;
  name: string;
}

export const CreatableSearchableSelectField: FC<
  ICreatableSearchableSelectField
> = ({ formHook, name, ...props }) => {
  const {
    control,
    formState: { errors },
  } = formHook;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        // @ts-ignore
        const typedError = get(error, `${error?.length - 1}.value`, undefined);
        return (
          <CreatableSearchableSelect
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            error={typedError || error}
            {...props}
          />
        );
      }}
    />
  );
};
