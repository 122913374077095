import {
  ComboBox,
  IDropdownOption,
  IDropdownStyleProps,
  IDropdownStyles,
  IStyleFunctionOrObject,
} from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { getStates } from '..';
export interface IStateFieldProps {
  formHook: UseFormReturn<any>;
  name: string;
  label: string;
  styles?: Partial<
    IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>
  >;
  countryCode: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
}

export const StatePicker: FC<IStateFieldProps> = ({
  formHook,
  name,
  label,
  styles,
  countryCode,
  disabled = false,
  placeholder,
  required = false,
  ...props
}) => {
  const { control } = formHook;
  const [states, setStates] = useState<IDropdownOption[]>([]);
  function compare(a: any, b: any) {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }
  useEffect(() => {
    const statesData = getStates(countryCode)
      ?.map((state: string) => {
        return {
          key: state?.toLowerCase(),
          text: state,
        };
      })
      ?.sort(compare);
    setStates(statesData);
  }, [countryCode]);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <ComboBox
          {...props}
          required={required}
          placeholder={placeholder || ''}
          disabled={disabled}
          label={label}
          onChange={(e, option) => {
            onChange(option?.key);
          }}
          onBlur={onBlur}
          selectedKey={value}
          errorMessage={error && error.message}
          options={states}
          calloutProps={{
            calloutMaxHeight: 300,
          }}
          styles={styles}
          allowFreeform
          autoComplete="on"
        />
      )}
    />
  );
};
