import {
  ISearchableSelectOption,
  SearchableSelectField,
} from '@worx.squad/shared-frontend';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import { useGetDepartmentByOrgIdForAdminQuery } from '../../Employees/employee.generated';

interface DepartmentSelectProps {
  formHook: UseFormReturn<any, object>;
}

export default function DepartmentSelect({ formHook }: DepartmentSelectProps) {
  const { organization } = useOrganizationContext();

  const [departmentsText, setDepartmentsText] = useState<string | undefined>();
  const [departmentsTextLazy] = useDebounce(departmentsText, 500);
  const [{ data: departmentsRaw }] = useGetDepartmentByOrgIdForAdminQuery({
    variables: {
      orgId: organization?.id,
      departmentText: departmentsTextLazy
        ? `%${departmentsTextLazy}%`
        : undefined,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });
  const [departments, setDepartments] = useState<ISearchableSelectOption[]>([]);

  useEffect(() => {
    if (departmentsRaw?.department) {
      setDepartments(
        departmentsRaw.department.map((department) => ({
          value: department.id,
          label: department.name,
        })),
      );
    }
  }, [departmentsRaw]);

  return (
    <SearchableSelectField
      onInputChange={(value) => {
        setDepartmentsText(value);
      }}
      inputValue={departmentsText}
      placeholder="Search or select department"
      options={departments}
      formHook={formHook}
      name="department"
      label="Department"
      required
    />
  );
}
