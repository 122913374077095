import {
  DefaultButton,
  IconButton,
  PrimaryButton,
  Slider,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper, { Area } from 'react-easy-crop';
import { CustomModal } from '.';
import { getCroppedImg } from '../../../function';

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export function AvatarSelect({
  setImageURL,
}: {
  setImageURL: (arg0: string) => void;
}) {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const videoConstraints = {
    width: 720,
    height: 405,
    facingMode: 'user',
  };
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState(0);

  const [imageSrc, setImageSrc] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
      );

      if (croppedImage) setImageURL(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: 1,
  });
  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  useEffect(() => {
    const selectedFile = acceptedFiles.pop();

    if (selectedFile) setImageSrc(URL.createObjectURL(selectedFile));
  }, [acceptedFiles]);

  return (
    <div>
      <DefaultButton
        onClick={showModal}
        text="Select Photo"
        className="w-full"
      />
      <CustomModal onClose={hideModal} show={isModalOpen}>
        <IconButton
          className="absolute right-0 -top-1"
          iconProps={{ iconName: 'Cancel' }}
          onClick={hideModal}
        />
        <div className="p-4">
          {imageSrc ? (
            <div
              className={`relative w-[100%] sm:w-${videoConstraints.width}`}
              style={{
                height: videoConstraints.height,
              }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            </div>
          ) : (
            <div
              className="justify-center"
              {...getRootProps({
                style: {
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '20px',
                  borderWidth: 2,
                  borderRadius: 2,
                  borderColor: '#eeeeee',
                  borderStyle: 'dashed',
                  backgroundColor: '#fafafa',
                  color: '#bdbdbd',
                  outline: 'none',
                  transition: 'border .24s ease-in-out',
                  minHeight: 405,
                  ...style,
                },
              })}
            >
              <input
                title="upload"
                {...getInputProps()}
                accept="image/jpeg, image/png"
              />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          )}
          <div className="pt-4 flex flex-col sm:flex-row sm:justify-between">
            <div className="flex flex-col sm:flex-row gap-4 py-2 sm:py-0">
              <DefaultButton
                disabled={!imageSrc}
                onClick={() => {
                  setImageSrc('');
                }}
                text="Clean"
              />

              <div className="w-60 items-center flex">
                <div>Zoom:</div>
                <Slider
                  className="flex-1"
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  showValue={false}
                  onChange={(zoom) => setZoom(zoom)}
                />
              </div>
              <div className="w-60 items-center flex">
                <div>Rotate:</div>
                <Slider
                  className="flex-1"
                  min={0}
                  max={360}
                  step={1}
                  value={rotation}
                  showValue
                  onChange={(rotation) => setRotation(rotation)}
                />
              </div>
            </div>
            <PrimaryButton
              disabled={!imageSrc}
              onClick={async () => {
                await showCroppedImage();
                hideModal();
              }}
              text="Select"
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
