import { IPersonaSharedProps, PersonaPresence } from '@fluentui/react';
import { EnumActivityStatus } from '@worx.squad/shared';

export const encodeFileUrl = (url: string) => {
  const urlSplit = url?.split('/');
  urlSplit[urlSplit.length - 1] = encodeURIComponent(
    urlSplit[urlSplit.length - 1],
  );
  return urlSplit.join('/');
};

export const getFileUrl = (url: string | undefined | null) => {
  return url
    ? `${process.env['NX_DATA_STORAGE_URL']}/${encodeFileUrl(url)}`
    : undefined;
};

export const decodeFileUrl = (url: string | undefined | null): string => {
  if (!url) {
    return '';
  }

  const prefix = `${process.env['NX_DATA_STORAGE_URL']}/`;

  // Check if the URL starts with the expected prefix
  if (!url.startsWith(prefix)) {
    throw new Error('Invalid file URL'); // Adjust the error handling as needed
  }

  // Remove the prefix and decode the remaining part of the URL
  const encodedUrl = url.slice(prefix.length);
  const decodedUrl = decodeURIComponent(encodedUrl);

  return decodedUrl ?? '';
};

export const isFileUrlProcessed = (url: string | undefined | null): boolean => {
  if (!url) {
    return false; // If the input URL is undefined or null, consider it not processed
  }

  const expectedPrefix = `${process.env['NX_DATA_STORAGE_URL']}/`;
  const encodedUrl = encodeFileUrl(url);

  return url.startsWith(expectedPrefix) && url.endsWith(encodedUrl);
};

export const getShortName = (currentName: string) => {
  return !currentName?.includes(' ')
    ? currentName?.charAt(0)
    : currentName
        ?.split(' ')
        ?.reduce((prev, curr) => prev.charAt(0) + curr.substring(0, 1))
        ?.toUpperCase();
};

export const createImage = (url: string): any =>
  new Promise<any>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc: string,
  pixelCrop?: any,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise<string>((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) resolve(URL.createObjectURL(file));
    }, 'image/jpeg');
  });
}

export const onlyNumberInput = (text: string | undefined) => {
  return text?.replace(/[^0-9.]/g, '');
};

export const getUserActivityStatus = (
  activityStatus: string | null | undefined,
): IPersonaSharedProps | undefined => {
  switch (activityStatus) {
    case EnumActivityStatus.Away:
      return {
        presence: PersonaPresence.offline,
        presenceTitle: 'Away',
      };
    case EnumActivityStatus.Busy:
      return {
        presence: PersonaPresence.busy,
        presenceTitle: 'Busy',
      };
    case EnumActivityStatus.Dnd:
      return {
        presence: PersonaPresence.dnd,
        presenceTitle: 'Do not disturb',
      };
    case EnumActivityStatus.Offline:
      return {
        presence: PersonaPresence.offline,
        isOutOfOffice: true,
        presenceTitle: 'Offline',
      };
    case EnumActivityStatus.Online:
      return {
        presence: PersonaPresence.online,
        presenceTitle: 'Live',
      };
    case EnumActivityStatus.Relax:
      return {
        presence: PersonaPresence.online,
        presenceTitle: 'Online',
        isOutOfOffice: true,
      };
    default:
      return;
  }
};

export function isJson(string: string) {
  try {
    return string && JSON.parse(string);
  } catch (e) {
    return false;
  }
}

export function dispatchListeners(listenerNames?: Array<string>) {
  if (listenerNames)
    for (const listener of listenerNames)
      window.dispatchEvent(new CustomEvent(listener));
}

/***
 * Trims the Given String to a required length
 */
export function trimStringLength(text: string, maxLength: number) {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)} ...`;
  }
  return text;
}
