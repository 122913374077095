import {
  DefaultButton,
  IDropdownOption,
  MessageBarType,
  Stack,
} from '@fluentui/react';
import { OperationContext } from '@urql/core';
import {
  DropdownField,
  Form,
  PrimaryButtonWithLoader,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useForm } from 'react-hook-form';
import { ROLES_MAP } from '../../constants';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import { roleAddSchema } from './role-add.schema';
import { useInsertOrganizationRoleMutation } from './roles.generated';

interface IFormValues {
  role: string;
  systemRole: string;
}

const RoleAddForm = ({
  onCancel,
  refetch,
}: {
  onCancel: () => void;
  refetch?: (opts?: Partial<OperationContext> | undefined) => void;
}) => {
  const roleAddForm = useForm<IFormValues>({ resolver: roleAddSchema });
  const { handleSubmit } = roleAddForm;
  const { notification } = useNotification();

  const { organization } = useOrganizationContext();

  const [{ fetching: loading }, insertOrganizationRole] =
    useInsertOrganizationRoleMutation();

  const systemRoles = ROLES_MAP.map(
    (role) =>
      ({
        key: role.role,
        text: role.name,
      }) as IDropdownOption,
  );

  const onSubmit = async (data: IFormValues) => {
    try {
      const res = await insertOrganizationRole({
        orgId: organization?.id,
        roleTitle: data.role,
        systemRole: data.systemRole,
      });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: `Role ${data.role} created!`,
          type: MessageBarType.success,
        });
        refetch && refetch();
        onCancel();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} formHook={roleAddForm}>
      <div className="h-auto">
        <div>
          <TextField
            placeholder="Enter role"
            formHook={roleAddForm}
            name="role"
            label="Role"
            required
          />
        </div>
        <div>
          <DropdownField
            formHook={roleAddForm}
            name="systemRole"
            placeholder="Select system role"
            options={systemRoles}
            label="System role"
            required
          />
        </div>
        <Stack tokens={{ childrenGap: 20 }} horizontal className="mt-4">
          <Stack.Item>
            <PrimaryButtonWithLoader loading={loading} type="submit">
              Add
            </PrimaryButtonWithLoader>
          </Stack.Item>
          <Stack.Item>
            <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
          </Stack.Item>
        </Stack>
      </div>
    </Form>
  );
};

export default RoleAddForm;
