import {
  DefaultButton,
  IconButton,
  PrimaryButton,
  Slider,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import Webcam from 'react-webcam';
import { CustomModal } from '.';
import { getCroppedImg } from './../../../function';

export function AvatarWebCam({
  setImageURL,
}: {
  setImageURL: (arg0: string) => void;
}) {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const videoConstraints = {
    width: 720,
    height: 405,
    facingMode: 'user',
  };
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState(0);

  const webcamRef = React.useRef<Webcam>(null);

  const [imageSrc, setImageSrc] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) setImageSrc(imageSrc);
  }, [webcamRef]);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
      );

      if (croppedImage) setImageURL(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imageSrc, rotation, setImageURL]);

  return (
    <div>
      <DefaultButton
        onClick={showModal}
        text="Capture Photo"
        className="w-full"
      />
      <CustomModal onClose={hideModal} show={isModalOpen}>
        <IconButton
          className="absolute right-0 -top-1"
          iconProps={{ iconName: 'Cancel' }}
          onClick={hideModal}
        />
        <div className="p-4">
          {imageSrc ? (
            <div
              className={`relative w-[100%] sm:w-${videoConstraints.width}`}
              style={{
                height: videoConstraints.height,
              }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            </div>
          ) : (
            <Webcam
              style={{
                width: '100%',
                height: videoConstraints.height,
              }}
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          )}
          <div className="pt-4 flex flex-col sm:flex-row sm:justify-between">
            <div className="flex flex-col sm:flex-row gap-4 py-2 sm:py-0">
              <PrimaryButton
                disabled={!isEmpty(imageSrc)}
                onClick={() => {
                  capture();
                }}
                text="Capture"
              />

              <DefaultButton
                disabled={!imageSrc}
                onClick={() => {
                  setImageSrc('');
                }}
                text="Clean"
              />

              <div className="w-60 items-center flex">
                <div>Zoom:</div>
                <Slider
                  disabled={!imageSrc}
                  className="flex-1"
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  showValue={false}
                  onChange={(zoom) => setZoom(zoom)}
                />
              </div>
              <div className="w-60 items-center flex">
                <div>Rotate:</div>
                <Slider
                  disabled={!imageSrc}
                  className="flex-1"
                  min={0}
                  max={360}
                  step={1}
                  value={rotation}
                  showValue
                  onChange={(rotation) => setRotation(rotation)}
                />
              </div>
            </div>
            <PrimaryButton
              disabled={!imageSrc}
              onClick={async () => {
                await showCroppedImage();
                hideModal();
              }}
              text="Select"
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
