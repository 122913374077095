import {
  OnBoardingFormLayout,
  OrganizationFormVector,
  useLayoutState,
} from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import { OrganizationRegisterForm } from '../components/OrganizationRegister/OrganizationRegisterForm';

const OrganizationRegister = () => {
  const title = 'Add Organization Details';
  const description =
    'Worx Squad is an enterprise productivity and employee engagement platform. Create, Collaborate, Discuss and Manage, Worx Squad handles all your daily work needs with ease. Experience the office vibes at Worx Squad.';

  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);
  return (
    <OnBoardingFormLayout
      title={title}
      description={description}
      vector={<OrganizationFormVector />}
    >
      <OrganizationRegisterForm />
    </OnBoardingFormLayout>
  );
};

export default OrganizationRegister;
