import {
  DetailsListLayoutMode,
  IColumn,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
} from '@fluentui/react';
import { Pagination } from '@fluentui/react-experiments';
import { DependencyList, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import DeleteClient from './DeleteClient';
import EditClient from './EditClient';
import {
  GetClientsByOrganizationIdQuery,
  useGetClientsByOrganizationIdQuery,
} from './projects-and-clients.generated';

export type ClientsData = GetClientsByOrganizationIdQuery['project_client'][0];
/**
 *
 * @param dependencies Provide the dependencies at which the projects must refetch
 */
const ClientsList = ({ dependencies }: { dependencies: DependencyList }) => {
  const { organization } = useOrganizationContext();

  const PER_PAGE_COUNT = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>();
  const [searchTextLazy] = useDebounce(searchText, 500);

  const [{ data, fetching }, refetchClients] =
    useGetClientsByOrganizationIdQuery({
      variables: {
        organizationId: organization?.id,
        clientName: searchTextLazy ? `%${searchTextLazy}%` : undefined,
        limit: PER_PAGE_COUNT,
        offset,
      },
      pause: !organization?.id,
      requestPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (!fetching) refetchClients();
  }, [...dependencies]);

  const columns: IColumn[] = [
    {
      key: 'srNo',
      name: 'Sr. No.',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      className: ' flex items-center text-black',
      onRender: (item, counts = 0) => {
        return (counts = counts + offset + 1);
      },
    },
    {
      key: 'clientName',
      name: 'Client Name',
      minWidth: 200,
      isResizable: true,
      isMultiline: true,
      className: ' flex items-center text-black',
      onRender: (item: ClientsData) => item.client_name,
    },
    {
      key: 'actions',
      name: 'Actions',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      className: 'flex items-center text-black',
      onRender: (item: ClientsData) => {
        return (
          <div className="flex gap-3">
            <EditClient client={item} />
            <DeleteClient client={item} />
          </div>
        );
      },
    },
  ];

  const paginate = (index: number) => {
    setOffset(index * PER_PAGE_COUNT);
    setCurrentPage(index + 1);
  };

  return (
    <>
      <SearchBox
        placeholder="Search by client name"
        value={searchText || ''}
        onChange={(e, value) => setSearchText(value)}
      />
      {data?.project_client.length && data?.project_client.length > 0 ? (
        <>
          <ShimmeredDetailsList
            enableShimmer={fetching}
            items={data?.project_client || []}
            columns={columns}
            compact={true}
            selectionMode={SelectionMode.none}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            enterModalSelectionOnTouch={true}
          />
          <hr className="my-2" />
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={
              (data?.project_client_aggregate?.aggregate?.count || 1) /
              PER_PAGE_COUNT
            }
            totalItemCount={
              data?.project_client_aggregate?.aggregate?.count || 0
            }
            itemsPerPage={PER_PAGE_COUNT}
            onPageChange={paginate}
            format="buttons"
            numberOfPageButton={4}
          />
        </>
      ) : (
        <div className="place-items-center">
          <Text className="text-xl">No clients added yet</Text>
        </div>
      )}
    </>
  );
};

export default ClientsList;
