import {
  DefaultButton,
  Icon,
  Link,
  MessageBarType,
  Stack,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Form,
  ISearchableSelectOption,
  PrimaryButtonWithLoader,
  SearchableSelectField,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import DepartmentAddModal from '../Department/DepartmentAddModal';
import RoleAddModal from '../Roles/RoleAddModal';
import { employeeAddSchema } from './employee-add.schema';
import {
  useFrontend_GetUserByIdQuery,
  useGetDepartmentByOrgIdForAdminQuery,
  useGetManagersByOrgIdQuery,
  useGetOrganizationRolesByOrgIdQuery,
  useSaveEmployeeMutation,
} from './employee.generated';

interface IFormData {
  firstName: string;
  lastName: string;
  role: ISearchableSelectOption;
  password?: string;
  email: string;
  department: ISearchableSelectOption;
  manager: ISearchableSelectOption;
}

interface IEmployeeAddForm {
  id?: string;
  onCancel: () => void;
}

const EmployeeAddForm: FC<IEmployeeAddForm> = ({ id, onCancel }) => {
  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const [roles, setRoles] = useState<ISearchableSelectOption[]>([]);
  const [departments, setDepartments] = useState<ISearchableSelectOption[]>([]);
  const [managers, setManagers] = useState<ISearchableSelectOption[]>([]);
  const [rolesText, setRolesText] = useState<string | undefined>();
  const [rolesTextLazy] = useDebounce(rolesText, 500);
  const [departmentsText, setDepartmentsText] = useState<string | undefined>();
  const [departmentsTextLazy] = useDebounce(departmentsText, 500);
  const [managersText, setManagersText] = useState<string | undefined>();
  const [managersTextLazy] = useDebounce(managersText, 500);

  const [roleAddModalVisible, { toggle: toggleRoleAddModalVisibility }] =
    useBoolean(false);
  const [
    departmentAddModalVisible,
    { toggle: toggleDepartmentAddModalVisibility },
  ] = useBoolean(false);

  const employeeAddForm = useForm<IFormData>({
    resolver: employeeAddSchema,
    mode: 'all',
  });

  const { handleSubmit } = employeeAddForm;
  const [{ data: employeeRaw }] = useFrontend_GetUserByIdQuery({
    variables: { id },
    pause: !id,
    requestPolicy: 'cache-and-network',
  });

  const [{ data: departmentsRaw }] = useGetDepartmentByOrgIdForAdminQuery({
    variables: {
      orgId: organization?.id,
      departmentText: departmentsTextLazy
        ? `%${departmentsTextLazy}%`
        : undefined,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });
  const [{ data: rolesRaw }, refetchRoles] =
    useGetOrganizationRolesByOrgIdQuery({
      variables: {
        orgId: organization?.id,
        roleText: rolesTextLazy ? `%${rolesTextLazy}%` : undefined,
      },
      pause: !organization?.id,
      requestPolicy: 'cache-and-network',
    });

  const [{ data: managersRaw }, refetchManager] = useGetManagersByOrgIdQuery({
    variables: {
      orgId: organization?.id,
      managerText: managersTextLazy ? `%${managersTextLazy}%` : undefined,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  const [{ fetching: loading }, saveEmployeeMutation] =
    useSaveEmployeeMutation();

  const onSubmit = async (data: IFormData) => {
    try {
      const { error } = await saveEmployeeMutation({
        object: {
          userId: id,
          firstName: data.firstName,
          lastName: data.lastName,
          departmentId: data.department.value,
          email: data.email,
          managerId: data.manager.value,
          organizationRoleId: data.role.value,
          password: data.password,
        },
      });
      if (error) {
        notification({
          message: error.message,
          type: MessageBarType.error,
        });
        console.error(error);
      } else {
        notification({
          message: 'Employee created successfully!',
          type: MessageBarType.success,
        });
        onCancel();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (employeeRaw) {
      employeeAddForm.reset({
        firstName: employeeRaw.user?.first_name || '',
        lastName: employeeRaw.user?.last_name || '',
        email: employeeRaw.user?.account?.email,
      });
    }
  }, [employeeAddForm, employeeRaw]);

  useEffect(() => {
    if (!employeeRaw) return;
    const existingDepartment = employeeAddForm.getValues('department');
    if (!existingDepartment) {
      const department = departments.find(
        ({ value }) =>
          value === employeeRaw.user?.department_employees[0].department.id,
      );

      if (department) employeeAddForm.setValue('department', department);
    }
  }, [departments, employeeAddForm, employeeRaw]);

  useEffect(() => {
    if (!employeeRaw) return;
    const existingRole = employeeAddForm.getValues('role');
    if (!existingRole) {
      const role = roles.find(
        ({ value }) =>
          value === employeeRaw.user?.profile[0]?.organization_role?.id,
      );

      if (role) employeeAddForm.setValue('role', role);
    }
  }, [employeeAddForm, employeeRaw, roles]);

  useEffect(() => {
    if (!employeeRaw) return;
    const existingManager = employeeAddForm.getValues('manager');
    console.log(existingManager);
    if (!existingManager) {
      const manager = managers.find(
        ({ value }) =>
          value === employeeRaw.user?.organization_employees[0].manager_id,
      );

      if (manager) employeeAddForm.setValue('manager', manager);
    }
  }, [employeeAddForm, employeeRaw, managers]);

  useEffect(() => {
    if (departmentsRaw?.department) {
      setDepartments(
        departmentsRaw.department.map((department) => ({
          value: department.id,
          label: department.name,
        })),
      );
    }
  }, [departmentsRaw]);

  useEffect(() => {
    if (rolesRaw?.organization_roles) {
      setRoles(
        rolesRaw.organization_roles.map((role) => ({
          value: role.id,
          label: role.role_title,
        })),
      );
    }
  }, [rolesRaw]);

  useEffect(() => {
    if (managersRaw?.users) {
      setManagers(
        managersRaw.users.map((user) => ({
          value: user.id,
          label: `${user.display_name} (${user?.my_organization?.role_title})`,
        })),
      );
    }
  }, [managersRaw]);

  useEffect(() => {
    const existingManager = employeeAddForm.getValues('manager');
    if (!existingManager)
      if (managers.length === 1 && managers[0].value) {
        employeeAddForm.setValue('manager', managers[0]);
      }
  }, [employeeAddForm, managers]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} formHook={employeeAddForm}>
        <div className="h-auto flex flex-col gap-5">
          <div className="flex gap-5">
            <div className="w-1/2">
              <TextField
                placeholder="Enter first name"
                formHook={employeeAddForm}
                name="firstName"
                label="First name"
                required
                disabled={!!id}
              />
            </div>
            <div className="w-1/2">
              <TextField
                placeholder="Enter last name"
                formHook={employeeAddForm}
                name="lastName"
                label="Last name"
                required
                disabled={!!id}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2 flex flex-col gap-2">
              <SearchableSelectField
                onInputChange={(value) => {
                  setRolesText(value);
                }}
                inputValue={rolesText}
                options={roles}
                formHook={employeeAddForm}
                placeholder="Search or select role"
                name="role"
                label="Role"
                required
              />
              <Link onClick={toggleRoleAddModalVisibility}>
                <Icon iconName="Add" className="text-xs" /> Add new role
              </Link>
            </div>
            <div className="w-1/2">
              <TextField
                placeholder="Enter email"
                formHook={employeeAddForm}
                name="email"
                label="Email"
                required
                disabled={!!id}
              />
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-1/2">
              <SearchableSelectField
                onInputChange={(value) => {
                  setManagersText(value);
                }}
                inputValue={managersText}
                placeholder="Search or select manager"
                options={managers}
                formHook={employeeAddForm}
                name="manager"
                label="Select manager"
                required
              />
            </div>
            <div className="w-1/2 flex flex-col gap-2">
              <SearchableSelectField
                onInputChange={(value) => {
                  setDepartmentsText(value);
                }}
                inputValue={departmentsText}
                placeholder="Search or select department"
                options={departments}
                formHook={employeeAddForm}
                name="department"
                label="Department"
                required
              />
              <Link onClick={toggleDepartmentAddModalVisibility}>
                <Icon iconName="Add" className="text-xs" /> Add new department
              </Link>
            </div>
          </div>
          <div>
            <TextField
              placeholder="Enter password"
              formHook={employeeAddForm}
              name="password"
              label="Password"
              disabled={!!id}
            />
          </div>
          <Stack tokens={{ childrenGap: 20 }} horizontal className="mt-4">
            <Stack.Item>
              <PrimaryButtonWithLoader loading={loading} type="submit">
                {id ? 'Save' : 'Invite'}
              </PrimaryButtonWithLoader>
            </Stack.Item>
            <Stack.Item>
              <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
            </Stack.Item>
          </Stack>
        </div>
      </Form>
      <RoleAddModal
        isOpen={roleAddModalVisible}
        onDismiss={toggleRoleAddModalVisibility}
        refetchRoles={refetchRoles}
      />
      <DepartmentAddModal
        isModalOpen={departmentAddModalVisible}
        hideModal={toggleDepartmentAddModalVisibility}
        refetch={refetchManager}
      />
    </>
  );
};

export default EmployeeAddForm;
