import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import ContactUs from '../components/CommonComponent/ContactUs';
import { Footer } from '../components/CommonComponent/Footer';
import { Header } from '../components/CommonComponent/Header';
import { TryUs } from '../components/CommonComponent/TryUs';
import ForEmployeeNAdmin from '../components/HowItWorks/ForEmployeeNAdmin';
import { Starter } from '../components/HowItWorks/Starter';

const HowItWorks = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);
  return (
    <div className="relative">
      <Header />
      <Starter />
      <ForEmployeeNAdmin />
      <TryUs />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default HowItWorks;
