export const ROLES_MAP = [
  {
    role: 'manager-1',
    name: 'Senior Manager',
  },
  {
    role: 'manager-2',
    name: 'Manager',
  },
  {
    role: 'employee',
    name: 'Employee',
  },
  {
    role: 'org-admin',
    name: 'Organization Admin',
  },
];
