import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

const schema = yup.object().shape({
  name: yup.string().required().label('Department name'),
  manager: yup.object().required().label('Manager'),
});
export const departmentSchema = yupResolver(schema);
