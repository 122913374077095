import { useLayoutState, useQueryString } from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect } from 'react';
import AuthScreen from '../components/AuthScreen/AuthScreen';

const GoogleDriveAuth = () => {
  const { search, hash } = useQueryString();

  const googledriveCode = search.get('code');
  const error = hash.get('authError');

  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  useEffect(() => {
    if (googledriveCode) {
      navigator.clipboard.writeText(googledriveCode);
      window.location?.replace(
        `worx-squad://open-app?googledriveCode=${googledriveCode}`,
      );
    }
  }, [googledriveCode]);

  return <AuthScreen error={!!error} />;
};

export default GoogleDriveAuth;
