import { SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import { useEffect } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import AddNewHoliday from './AddNewHoliday';
import { columns } from './holidays.column';
import { useGetHolidaysQuery } from './holidays.generated';

const Holidays = () => {
  const { organization } = useOrganizationContext();
  const [{ data, fetching }, refetch] = useGetHolidaysQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      organization_id: organization?.id,
    },
    pause: !organization?.id,
  });

  useEffect(() => {
    window.addEventListener('refresh-holidays', refetch);
    return () => {
      window.removeEventListener('refresh-holidays', refetch);
    };
  }, [refetch]);

  const holidays = data?.holidays || [];

  return (
    <div className="max-w-[900px]">
      <div className="flex w-full justify-between">
        <h1>Organization Holidays</h1>
        <AddNewHoliday />
      </div>
      <ShimmeredDetailsList
        enableShimmer={fetching}
        selectionMode={SelectionMode.none}
        columns={columns}
        items={holidays}
      />
    </div>
  );
};

export default Holidays;
