import { Stack, useTheme } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  DailyCalendar,
  MonthlyCalendar,
  PrimaryButtonWithLoader,
  SearchableSelect,
  WeeklyCalendar,
} from '@worx.squad/shared-frontend';
import { TimeSummaryCards } from './TimeSummaryCards';

export const DashboardHeader = () => {
  const theme = useTheme();
  const [
    isSelectedDaily,
    { setTrue: selectedDaily, setFalse: unSelectedDaily },
  ] = useBoolean(true);
  const [
    isSelectedWeekly,
    { setTrue: selectedWeekly, setFalse: unSelectedWeekly },
  ] = useBoolean(false);
  const [
    isSelectedMonthly,
    { setTrue: selectedMonthly, setFalse: unSelectedMonthly },
  ] = useBoolean(false);
  const buttonsLight = {
    backgroundColor: '#f3f6f4',
    color: theme.palette.black,
    border: '1px solid rgba(0,0,0,0.5)',
  };
  const buttonsSelected = {
    backgroundColor: theme.palette.themePrimary,
  };
  return (
    <Stack>
      <Stack horizontal horizontalAlign="space-between">
        <div className=" flex gap-2 mr-16">
          <PrimaryButtonWithLoader
            style={isSelectedDaily ? buttonsSelected : buttonsLight}
            onClick={() => {
              selectedDaily();
              unSelectedWeekly();
              unSelectedMonthly();
            }}
            text="Daily"
          />
          <PrimaryButtonWithLoader
            style={isSelectedWeekly ? buttonsSelected : buttonsLight}
            onClick={() => {
              unSelectedDaily();
              selectedWeekly();
              unSelectedMonthly();
            }}
            text="Weekly"
          />
          <PrimaryButtonWithLoader
            style={isSelectedMonthly ? buttonsSelected : buttonsLight}
            onClick={() => {
              unSelectedDaily();
              unSelectedWeekly();
              selectedMonthly();
            }}
            text="Monthly"
          />
        </div>
      </Stack>
      <Stack
        className="mt-5"
        verticalAlign="center"
        horizontal
        horizontalAlign="space-between"
      >
        <div className="w-1/4">
          <SearchableSelect
            styles={{
              input: (provided, state) => ({
                ...provided,
                padding: '8px',
              }),
            }}
            placeholder="Select an employee"
          />
        </div>
        <div className={isSelectedDaily ? '' : 'hidden'}>
          <DailyCalendar onSelect={console.log} />
        </div>
        <div className={isSelectedWeekly ? '' : 'hidden'}>
          <WeeklyCalendar onSelect={console.log} />
        </div>
        <div className={isSelectedMonthly ? '' : 'hidden'}>
          <MonthlyCalendar onSelect={console.log} />
        </div>
      </Stack>
      <Stack className="mt-5">
        <TimeSummaryCards />
      </Stack>
    </Stack>
  );
};
