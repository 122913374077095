import { IDropdownOption, Link, Text } from '@fluentui/react';
import {
  AvatarMaker,
  DatePickerField,
  DropdownField,
  Enum_User_Gender_Enum,
  Form,
  PrimaryButtonWithLoader,
  STextFieldStyle,
  TextField,
} from '@worx.squad/shared-frontend';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { registerSchema } from './register.schema';

const marriedStatusOptions: IDropdownOption[] = [
  { key: 'Married', text: 'Married' },
  { key: 'Unmarried', text: 'Unmarried' },
];

export interface IRegisterFormProps {
  fname: string;
  lname: string;
  email: string;
  password: string;
  confirmPassword: string;
  dateOfBirth?: string;
  dateOfJoining?: string;
  maritalStatus?: string;
  marriageAnniversary?: string;
  avatarImage?: File;
  gender: Enum_User_Gender_Enum;
}

const RegisterForm = ({
  onSubmit,
  loading,
}: {
  onSubmit: (data: IRegisterFormProps) => void;
  loading?: boolean;
}) => {
  const navigate = useNavigate();
  const registerForm = useForm<IRegisterFormProps>({
    mode: 'all',
    resolver: registerSchema,
    defaultValues: {
      maritalStatus: 'Unmarried',
    },
  });
  const { handleSubmit } = registerForm;
  return (
    <Form formHook={registerForm} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex gap-5 flex-col">
        <Text variant="xxLarge">Create your account</Text>
        <div className="text-[1rem] block sm:hidden w-full">
          Worx Squad is an enterprise productivity and employee engagement
          platform. Create, Collaborate, Discuss and Manage, Worx Squad handles
          all your daily work needs with ease. Experience the office vibes at
          Worx Squad.
        </div>
        <div className="flex flex-col sm:flex-row gap-5">
          <div className="w-full sm:w-1/2">
            <TextField
              name="fname"
              required
              formHook={registerForm}
              styles={STextFieldStyle}
              label="First Name"
              placeholder="Enter first name"
            />
          </div>
          <div className="w-full sm:w-1/2">
            <TextField
              name="lname"
              required
              formHook={registerForm}
              styles={STextFieldStyle}
              label="Last name"
              placeholder="Enter last name"
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-5">
          <div className="w-full sm:w-1/2">
            <TextField
              type="email"
              name="email"
              required
              formHook={registerForm}
              styles={STextFieldStyle}
              label="Email ID (Username)"
              placeholder="Enter email id"
            />
          </div>
          <div className="w-full sm:w-1/2">
            <DropdownField
              formHook={registerForm}
              name="gender"
              placeholder="Select your Gender"
              label="Gender"
              options={[
                { key: Enum_User_Gender_Enum.Female, text: 'Female' },
                { key: Enum_User_Gender_Enum.Male, text: 'Male' },
              ]}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-5">
          <div className="w-full sm:w-1/2">
            <TextField
              type="password"
              required
              name="password"
              formHook={registerForm}
              styles={STextFieldStyle}
              label="Password"
              revealPasswordAriaLabel="Reveal password"
              canRevealPassword
              placeholder="Enter password"
            />
          </div>
          <div className="w-full sm:w-1/2">
            <TextField
              type="password"
              required
              name="confirmPassword"
              formHook={registerForm}
              styles={STextFieldStyle}
              label="Confirm password"
              revealPasswordAriaLabel="Reveal password"
              canRevealPassword
              placeholder="Confirm your password"
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-5">
          <div className="w-full sm:w-1/2">
            <DatePickerField
              formHook={registerForm}
              name="dateOfBirth"
              label="Date of birth"
              ariaLabel="Select a date"
              placeholder="Select your date of birth"
            />
          </div>
          <div className="w-full sm:w-1/2">
            <DatePickerField
              formHook={registerForm}
              name="dateOfJoining"
              label="Date of joining"
              ariaLabel="Select a date"
              placeholder="Select your date of joining"
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-5">
          <div className="w-full sm:w-1/2">
            <DropdownField
              formHook={registerForm}
              name="maritalStatus"
              placeholder="Select your marital status"
              label="Marital status"
              options={marriedStatusOptions}
            />
          </div>
          <div className="w-full sm:w-1/2">
            <DatePickerField
              formHook={registerForm}
              disabled={!(registerForm.watch().maritalStatus === 'Married')}
              name="marriageAnniversary"
              label="Marriage anniversary"
              placeholder="Select your marriage anniversary date"
              ariaLabel="Select a date"
            />
          </div>
        </div>
        <div className="flex">
          <AvatarMaker name="avatarImage" formHook={registerForm} />
        </div>
        <div className="hidden sm:block">
          <Text className="text-gray-600">
            By clicking below, you agree to Worx Squad{' '}
            <Link>Term of Service</Link> and <Link>Privacy Policy</Link>.
          </Text>
        </div>
        <div className="flex justify-between items-center">
          <div>
            <Text variant="medium">
              Already have an account?{' '}
              <Link onClick={() => navigate('/login')}>Login</Link>
            </Text>
          </div>
          <div>
            <PrimaryButtonWithLoader
              loading={loading}
              text="Create Account"
              type="submit"
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default RegisterForm;
