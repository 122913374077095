import MagicPlanImg from './assets/MagicPlanImg.png';
const MagicPlanner = () => {
  return (
    <div className="px-20 pt-20 flex gap-2">
      <div className="w-1/2">
        <div className="text-4xl font-medium mt-2 text-white">
          Magic Planner acts as magic wand for your plans
        </div>
        <div className="mt-8 text-base text-white">
          Magic Planner helps you optimise and re-arrange <br />
          your project timelines with a click, ensuring smooth <br />
          workload distribution and efficiency.
        </div>
      </div>
      <div className="w-[60%]">
        <img src={MagicPlanImg} alt="magic planner" />
      </div>
    </div>
  );
};

export default MagicPlanner;
