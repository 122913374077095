import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .test('email', 'Email cannot contain white spaces.', (value) => {
      return !/\s/.test(value as string);
    })
    .label('Email')
    .required(),
});

export const resendSchema = yupResolver(schema);
