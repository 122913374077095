import { MessageBarType } from '@fluentui/react';
import { useAuth } from '@worx.squad/hbp-react';
import isOnline from 'is-online';
import { omit } from 'lodash';
import { createContext, useEffect, useState } from 'react';
import { OperationContext } from 'urql';
import { Notification } from '../../lib/Components';
import { GetUserDataQuery, useGetUserDataQuery } from '../../shared-graphql';

type TCleanUserData = Exclude<GetUserDataQuery['user'], null | undefined>;

type TUserData = Omit<
  TCleanUserData,
  'profile' | 'my_organization' | 'department_employees' | '__typename'
>;

type TAgoraId = TCleanUserData['profile'][0]['agora_id'];

type TAvatar = TCleanUserData['profile'][0]['avatar_file'];

type TManagerId = TCleanUserData['organization_employees'][0]['manager_id'];

type TOrganization =
  | {
      __typename?: 'organization' | undefined;
      id: any;
      organization_name: string;
      logo_file_id?: any;
      additional_fields: any;
      storageFileByLogoFileId?:
        | {
            __typename?: 'storage_files' | undefined;
            file_url: string;
          }
        | null
        | undefined;
    }
  | null
  | undefined;

export type TDepartment =
  TCleanUserData['department_employees'][0]['department'];

type TReturnType = {
  agoraId: TAgoraId;
  department: TDepartment;
  organization: TOrganization;
  managerId: TManagerId;
  refetchUser: (opts?: Partial<OperationContext> | undefined) => void;
  avatar: TAvatar;
  role: string | null | undefined;
  roleTitle: string | null | undefined;
  calendarId: string | null | undefined;
  email: string | null | undefined;
  activityStatus: any;
  timezone: string | undefined | null;
  orgAdminId: string | undefined | null;
  organization_role_id: string | undefined | null;
} & TUserData;

export const UserContext = createContext<TReturnType>({} as TReturnType);

export const UserProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [networkStatus, setNetworkStatus] = useState<boolean>(false);

  const [contextData, setContextData] = useState<TReturnType>(
    {} as TReturnType,
  );
  const [{ data }, refetchUser] = useGetUserDataQuery({
    variables: { id: user?.id },
    pause: !user?.id,
    requestPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (data?.user) {
      const mainData = data.user;

      const agoraId = mainData.profile[0]?.agora_id;
      const department = mainData.department_employees[0]?.department;
      const role =
        mainData.my_organization?.system_role || mainData.account?.default_role;
      const roleTitle = mainData.my_organization?.role_title;
      const organization = mainData.my_organization?.organization;
      const managerId = mainData.organization_employees[0]?.manager_id;
      const avatar = mainData.profile[0]?.avatar_file;
      const activityStatus = mainData.profile[0]?.activity_status;
      const calendarId = mainData.calendars[0]?.id;
      const email = mainData.account?.email;
      const timezone = mainData.profile[0]?.timezone;
      const orgAdminId = mainData.my_organization?.organization?.owned_by;
      const organization_role_id = mainData.profile[0]?.organization_role_id;
      const cleanedMainData = omit(mainData, [
        'profile',
        'my_organization',
        'department_employees',
        '__typename',
      ]);
      const cleanedData = {
        currentUserId: mainData.id,
        ...cleanedMainData,
        agoraId,
        calendarId,
        email,
        organization_role_id,
        department,
        organization,
        avatar,
        role,
        activityStatus,
        orgAdminId,
        roleTitle,
        managerId,
        timezone,
        refetchUser,
      };
      setContextData(cleanedData);
    }
  }, [data, refetchUser]);

  useEffect(() => {
    let onlineChecker: any = null;
    // To prevent request waterfall in network log
    if (process.env['NODE_ENV'] !== 'development') {
      onlineChecker = setInterval(async () => {
        setNetworkStatus(!(await isOnline()));
      }, 5000);
    }
    return () => clearInterval(onlineChecker);
  }, []);

  return (
    <UserContext.Provider value={contextData}>
      {networkStatus && (
        <Notification
          onDismiss={() => setNetworkStatus(false)}
          messageBarType={MessageBarType.warning}
        >
          {' '}
          Internet connection lost, Trying to establish connection...{' '}
        </Notification>
      )}
      {children}
    </UserContext.Provider>
  );
};
