import { registerIcons } from '@fluentui/react';
import * as bsIcons from 'react-icons/bs';

const iconSet: { [key: string]: JSX.Element } = {};

Object.keys(bsIcons).forEach((iconName) => {
  const IconComponent = (bsIcons as any)[iconName];
  iconSet[iconName] = <IconComponent />;
});

registerIcons({
  icons: iconSet,
});

const RegisterBootstrapIcons = () => {
  registerIcons({
    icons: iconSet,
  });
};

export default RegisterBootstrapIcons;
