import { Stack } from '@fluentui/react';
import { VerticalBarChart } from '@fluentui/react-charting';
import { DefaultFontStyles, DefaultPalette } from '@fluentui/theme';
import { useState } from 'react';
interface Props {
  timeSpent?: boolean;
}
export const BarChart = ({ timeSpent }: Props) => {
  const [data, setData] = useState({
    dynamicData: [
      { x: 'Meetings Time', y: 30 },
      { x: 'Social Media', y: 16 },
      { x: 'Task Assigned', y: 50 },
      { x: 'Break Time', y: 26 },
      { x: 'Leaves', y: 13 },
    ],
  });
  const [data2, setData2] = useState({
    dynamicData: [
      { x: 'XD', y: 30 },
      { x: 'Miro', y: 16 },
      { x: 'Adobe CC', y: 50 },
      { x: 'Macbook', y: 26 },
      { x: 'Zoom', y: 13 },
      { x: 'MS Office', y: 73 },
      { x: 'Monitor', y: 54 },
      { x: 'Figma', y: 25 },
    ],
  });
  const axisStyle = {
    stroke: DefaultPalette.orange,
  };
  const textStyle = {
    fill: DefaultPalette.orangeLight,
    fontSize: '12px',
  };

  const customStyles = () => {
    return {
      chart: {
        paddingBottom: '45px',
      },
      chartLabel: {
        color: DefaultPalette.orange,
        ...DefaultFontStyles.superLarge,
      },
      xAxisDomain: axisStyle,
      xAxisTicks: axisStyle,
      yAxisDomain: axisStyle,
      yAxisTicks: axisStyle,
      yAxisText: textStyle,
    };
  };

  return (
    <Stack className="bg-white p-5 mt-5">
      <VerticalBarChart
        data={timeSpent ? data.dynamicData : data2.dynamicData}
        width={800}
        height={700}
        barWidth={Math.ceil(500 / data.dynamicData.length)}
        yAxisTickCount={4}
        xAxisTickCount={5}
        styles={customStyles}
        colors={
          timeSpent
            ? ['#021b79', '#EF629F', '#396afc', '#20e3b2', '#20e3b2']
            : ['#2948ff', '#E100FF']
        }
        hideLegend={true}
        hideTooltip={false}
      />
    </Stack>
  );
};
