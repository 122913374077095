import {
  Dropdown,
  MessageBarType,
  Modal,
  Text,
  TextField,
} from '@fluentui/react';
import {
  DefaultButtonWithLoader,
  Enum_Award_Cycle_Type_Enum,
  PrimaryButtonWithLoader,
  dispatchListeners,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useCallback, useEffect, useState } from 'react';
import {
  useGetAwardDetailsByIdQuery,
  useUpdateAwardsByIdMutation,
} from '../awards.generated';
import { awardCycleOpts } from '../shared';
import { IAwardsData } from '../types';

interface IAawardsEditModal {
  editModalVisible: boolean;
  toggleEditModal: () => void;
  awardId: string | undefined;
}

const AwardsEditModal = ({
  editModalVisible,
  toggleEditModal,
  awardId,
}: IAawardsEditModal) => {
  const listenerItems = ['refetch-awards'];
  const [awards, setAwards] = useState<IAwardsData[]>([]);
  const [{ data, fetching }] = useGetAwardDetailsByIdQuery({
    variables: { awardId },
  });
  const [{ fetching: updateFetching }, updateAwards] =
    useUpdateAwardsByIdMutation();
  const awardData = data?.awards[0];
  const { notification } = useNotification();
  useEffect(() => {
    if (data?.awards) {
      setAwards(
        data.awards.map((e) => ({
          key: e.id as string,
          name: e.name,
          cycle: e.cycle,
          description: e.description ?? undefined,
          limit: e.limit?.toString(),
        })),
      );
    }
  }, [awardId, data]);

  const handleChange = useCallback(
    (index: number, field: string, value: any) => {
      setAwards((prevAwards) =>
        prevAwards.map((award, i) =>
          i === index ? { ...award, [field]: value } : award,
        ),
      );
    },
    [],
  );

  const onSubmit = async () => {
    const res = await updateAwards({
      awardId,
      data: {
        name: awards[0].name,
        cycle: awards[0].cycle,
        limit: parseInt(awards[0].limit ?? '0', 10),
        description: awards[0].description,
      },
    });
    if (res.error) {
      notification({
        message: 'Oops something went wrong!',
        type: MessageBarType.error,
      });
    }
    if (res.data) {
      notification({
        message: 'Award successfully updated',
        type: MessageBarType.success,
      });
      dispatchListeners(listenerItems);
      toggleEditModal();
    }
  };

  return (
    <Modal
      isBlocking={updateFetching}
      isOpen={editModalVisible}
      onDismiss={toggleEditModal}
    >
      <div className="p-5">
        <Text className="text-2xl">Edit Award</Text>
        <div className="grid grid-cols-2 gap-4 mt-5">
          <TextField
            label="Award Name"
            value={awards[0]?.name || ''}
            onChange={(e, newValue) => {
              handleChange(0, 'name', newValue);
            }}
          />

          <Dropdown
            label="Cycle"
            className="w-full"
            placeholder="Select a cycle"
            options={awardCycleOpts}
            selectedKey={awards[0]?.cycle || null}
            onChange={(e, option) => {
              handleChange(
                0,
                'cycle',
                option?.key as Enum_Award_Cycle_Type_Enum,
              );
            }}
          />

          <TextField
            label="Limit"
            type="number"
            min={0}
            placeholder="Enter Limit"
            value={awards[0]?.limit || ''}
            onChange={(e, newValue) => {
              handleChange(0, 'limit', newValue);
            }}
          />
          <TextField
            label="Description"
            multiline
            rows={2}
            placeholder="Enter Description"
            value={awards[0]?.description || ''}
            onChange={(e, newValue) => {
              handleChange(0, 'description', newValue);
            }}
          />
          <div />
          <div className="flex flex-row gap-4">
            <PrimaryButtonWithLoader
              loading={updateFetching}
              text="Update"
              onClick={onSubmit}
            />
            <DefaultButtonWithLoader text="Cancel" onClick={toggleEditModal} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AwardsEditModal;
