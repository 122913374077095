import { PrimaryButton, Stack, StackItem, Text } from '@fluentui/react';
import { useAuth } from '@worx.squad/hbp-react';
import { ClosingPageVector } from '@worx.squad/shared-frontend';
import { auth } from '../../helpers/hbp-helper';

export const Close = () => {
  const { user } = useAuth();
  // const [, updateStatus] = useUpdateUserStatusMutation();
  const onLogout = async () => {
    // TODO: Resolve Status Change
    // await updateStatus({
    //   user_id: user?.id,
    //   activity_status: EnumActivityStatus.Offline,
    // });
    auth.logout();
  };
  return (
    <Stack horizontal horizontalAlign="center">
      <StackItem
        align="center"
        styles={{ root: { maxWidth: 594 } }}
        className="flex justify-center m-10"
      >
        <Stack className="mx-auto" tokens={{ childrenGap: 20 }}>
          <StackItem>
            <Text variant="xxLargePlus" className="font-bold">
              Let's call your day, {user?.display_name}
            </Text>
          </StackItem>
          <StackItem>
            <Text variant="xLarge" className="font-semibold">
              Select What do you want to do,
            </Text>
          </StackItem>
          <StackItem>
            <PrimaryButton
              text="Extend my day"
              iconProps={{ iconName: 'Timer' }}
            />
          </StackItem>
          <StackItem>
            <PrimaryButton
              text="Reward my extension"
              iconProps={{ iconName: 'Medal' }}
            />
          </StackItem>
          <StackItem>
            <PrimaryButton
              text="Checkout for day"
              iconProps={{ iconName: 'SignOut' }}
              onClick={() => onLogout()}
            />
          </StackItem>
        </Stack>
      </StackItem>
      <StackItem
        grow
        align="center"
        styles={{ root: { maxWidth: 594 } }}
        className="flex justify-center m-10"
      >
        <ClosingPageVector />
      </StackItem>
    </Stack>
  );
};
