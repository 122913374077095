import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTriningTypeOrgAdminMutationVariables = Types.Exact<{
  objects?: Types.InputMaybe<
    | Array<Types.Training_Type_For_Training_Insert_Input>
    | Types.Training_Type_For_Training_Insert_Input
  >;
}>;

export type CreateTriningTypeOrgAdminMutation = {
  __typename?: 'mutation_root';
  insert_training_type_for_training?: {
    __typename?: 'training_type_for_training_mutation_response';
    returning: Array<{ __typename?: 'training_type_for_training'; id: any }>;
  } | null;
};

export type GetUserQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;

export type GetUserQuery = {
  __typename?: 'query_root';
  organization_by_pk?: {
    __typename?: 'organization';
    user: { __typename?: 'users'; id: any };
  } | null;
};

export type GetTrainingTypeOrgAdminQueryVariables = Types.Exact<{
  trainingType?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  _eq: Types.Scalars['uuid'];
}>;

export type GetTrainingTypeOrgAdminQuery = {
  __typename?: 'query_root';
  training_type_for_training: Array<{
    __typename?: 'training_type_for_training';
    name: string;
    status: boolean;
    id: any;
    user_id: any;
    meta_tags?: string | null;
    user: { __typename?: 'users'; first_name?: string | null };
  }>;
  training_type_for_training_aggregate: {
    __typename?: 'training_type_for_training_aggregate';
    aggregate?: {
      __typename?: 'training_type_for_training_aggregate_fields';
      count: number;
    } | null;
  };
};

export type DeleteTrainingTypeOrgAdminMutationVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
}>;

export type DeleteTrainingTypeOrgAdminMutation = {
  __typename?: 'mutation_root';
  delete_training_type_for_training?: {
    __typename?: 'training_type_for_training_mutation_response';
    returning: Array<{ __typename?: 'training_type_for_training'; id: any }>;
  } | null;
};

export type EditTrainingTypeOrgAdminMutationVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
  status: Types.Scalars['Boolean'];
}>;

export type EditTrainingTypeOrgAdminMutation = {
  __typename?: 'mutation_root';
  update_training_type_for_training?: {
    __typename?: 'training_type_for_training_mutation_response';
    returning: Array<{ __typename?: 'training_type_for_training'; id: any }>;
  } | null;
};

export function useCreateTriningTypeOrgAdminMutation() {
  return Urql.useMutation<
    Operations.CreateTriningTypeOrgAdminMutation,
    Operations.CreateTriningTypeOrgAdminMutationVariables
  >(Operations.CreateTriningTypeOrgAdminDocument);
}

export function useGetUserQuery(
  options: Omit<Urql.UseQueryArgs<Operations.GetUserQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    Operations.GetUserQuery,
    Operations.GetUserQueryVariables
  >({ query: Operations.GetUserDocument, ...options });
}

export function useGetTrainingTypeOrgAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetTrainingTypeOrgAdminQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetTrainingTypeOrgAdminQuery,
    Operations.GetTrainingTypeOrgAdminQueryVariables
  >({ query: Operations.GetTrainingTypeOrgAdminDocument, ...options });
}

export function useDeleteTrainingTypeOrgAdminMutation() {
  return Urql.useMutation<
    Operations.DeleteTrainingTypeOrgAdminMutation,
    Operations.DeleteTrainingTypeOrgAdminMutationVariables
  >(Operations.DeleteTrainingTypeOrgAdminDocument);
}

export function useEditTrainingTypeOrgAdminMutation() {
  return Urql.useMutation<
    Operations.EditTrainingTypeOrgAdminMutation,
    Operations.EditTrainingTypeOrgAdminMutationVariables
  >(Operations.EditTrainingTypeOrgAdminDocument);
}
