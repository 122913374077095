import {
  DefaultButton,
  MessageBarType,
  Stack,
  TextField,
} from '@fluentui/react';
import {
  PrimaryButtonWithLoader,
  useHeader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import AnnouncementsList from '../components/Announcements/AnnouncementsList';
import { useFrontendCreateAnnouncementMutation } from '../components/Announcements/announcement.generated';

const Announcements = () => {
  const { setTitle } = useHeader();
  setTitle('Announcements');
  const { notification } = useNotification();
  const [announcementMessage, setAnnouncementMessage] = useState<
    string | undefined
  >('');
  const [, createAnAnnouncement] = useFrontendCreateAnnouncementMutation();
  const onPostHandle = async () => {
    try {
      if (!announcementMessage) throw new Error('Please enter a message');
      const data = await createAnAnnouncement({
        message: announcementMessage,
      });
      if (data.data) {
        notification({
          message: 'Posted',
          type: MessageBarType.success,
        });
        setAnnouncementMessage('');
      } else throw new Error(data.error?.message || 'Something went wrong');
    } catch (e) {
      console.error(e);
      notification({
        message: e + '' || 'Something went wrong',
        type: MessageBarType.error,
      });
    } finally {
      window.dispatchEvent(new CustomEvent('refetch-announcements'));
    }
  };
  return (
    <Stack>
      <Helmet>
        <title>Announcements | Worx Squad</title>
      </Helmet>
      <div
        className="flex ml-2 items-center justify-center w-max bg-blue-500 text-white font-bold px-2 py-1"
        role="alert"
      >
        <svg
          className="fill-current w-4 h-4 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
        </svg>
        <p className="text-base text-center">
          This post will be visible on the application's{' '}
          <strong className="italic bold">Wall of moments</strong>
        </p>
      </div>
      <TextField
        className="w-96 m-2"
        multiline
        autoAdjustHeight
        maxLength={70}
        placeholder="make an announcement.."
        onChange={(e, newValue) => setAnnouncementMessage(newValue)}
        value={announcementMessage}
      />
      <div className="flex flex-row gap-5 ml-2">
        <PrimaryButtonWithLoader text="Post" onClick={onPostHandle} />
        <DefaultButton
          text="Cancel"
          onClick={() => setAnnouncementMessage('')}
        />
      </div>
      <div>
        <AnnouncementsList />
      </div>
    </Stack>
  );
};

export default Announcements;
