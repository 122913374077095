import { IDropdownOption } from '@fluentui/react';
import moment from 'moment';

export const workingHours: IDropdownOption[] = [
  {
    key: '1',
    text: '1 Hour',
  },
  {
    key: '2',
    text: '2 Hours',
  },
  {
    key: '4',
    text: '4 Hours',
  },
  {
    key: '6',
    text: '6 Hours',
  },
  {
    key: '8',
    text: '8 Hours',
  },
  {
    key: '10',
    text: '10 Hours',
  },
  {
    key: '12',
    text: '12 Hours',
  },
];

export const workingDays: IDropdownOption[] = [
  {
    key: '1',
    text: '1 Standard Day',
  },
  {
    key: '2',
    text: '2 Standard Days',
  },
  {
    key: '3',
    text: '3 Standard Days',
  },
  {
    key: '4',
    text: '4 Standard Days',
  },
  {
    key: '5',
    text: '5 Standard Days',
  },
  {
    key: '6',
    text: '6 Standard Days',
  },
  {
    key: '7',
    text: '7 Standard Days',
  },
];

export const hourPickerOptions: IDropdownOption[] = [
  {
    key: moment('00:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '00:00',
  },
  {
    key: moment('00:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '00:30',
  },
  {
    key: moment('01:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '01:00',
  },
  {
    key: moment('01:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '01:30',
  },
  {
    key: moment('02:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '02:00',
  },
  {
    key: moment('02:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '02:30',
  },
  {
    key: moment('03:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '03:00',
  },
  {
    key: moment('03:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '03:30',
  },
  {
    key: moment('04:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '04:00',
  },
  {
    key: moment('04:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '04:30',
  },
  {
    key: moment('05:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '05:00',
  },
  {
    key: moment('05:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '05:30',
  },
  {
    key: moment('06:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '06:00',
  },
  {
    key: moment('06:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '06:30',
  },
  {
    key: moment('07:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '07:00',
  },
  {
    key: moment('07:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '07:30',
  },
  {
    key: moment('08:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '08:00',
  },
  {
    key: moment('08:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '08:30',
  },
  {
    key: moment('09:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '09:00',
  },
  {
    key: moment('09:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '09:30',
  },
  {
    key: moment('10:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '10:00',
  },
  {
    key: moment('10:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '10:30',
  },
  {
    key: moment('11:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '11:00',
  },
  {
    key: moment('11:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '11:30',
  },
  {
    key: moment('12:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '12:00',
  },
  {
    key: moment('12:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '12:30',
  },
  {
    key: moment('13:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '13:00',
  },
  {
    key: moment('13:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '13:30',
  },
  {
    key: moment('14:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '14:00',
  },
  {
    key: moment('14:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '14:30',
  },
  {
    key: moment('15:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '15:00',
  },
  {
    key: moment('15:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '15:30',
  },
  {
    key: moment('16:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '16:00',
  },
  {
    key: moment('16:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '16:30',
  },
  {
    key: moment('17:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '17:00',
  },
  {
    key: moment('17:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '17:30',
  },
  {
    key: moment('18:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '18:00',
  },
  {
    key: moment('18:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '18:30',
  },
  {
    key: moment('19:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '19:00',
  },
  {
    key: moment('19:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '19:30',
  },
  {
    key: moment('20:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '20:00',
  },
  {
    key: moment('20:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '20:30',
  },
  {
    key: moment('21:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '21:00',
  },
  {
    key: moment('21:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '21:30',
  },
  {
    key: moment('22:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '22:00',
  },
  {
    key: moment('22:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '22:30',
  },
  {
    key: moment('23:00:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '23:00',
  },
  {
    key: moment('23:30:00', 'HH:mm:ss').format('HH:mm:ssZ'),
    text: '23:30',
  },
];
