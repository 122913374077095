import { initializeIcons, ThemeProvider } from '@fluentui/react';
import { AuthProvider, GraphQLProvider } from '@worx.squad/hbp-react';
import {
  LayoutStateProvider,
  NotificationProvider,
  useGenerateTheme,
} from '@worx.squad/shared-frontend';
import { HASURA_BASE_URL } from '../configs';
import LayoutProvider from './components/Layouts/LayoutProvider';
import { OrganizationProvider } from './context/OrganizationContext/OrganizationContext';
import { SDKProvider } from './context/SDKContext/SDKContext';
import { auth } from './helpers/hbp-helper';
import Router from './Router';

initializeIcons(undefined, { disableWarnings: true });

const App = () => {
  const theme = useGenerateTheme('#175E74', '#302f2f', '#ffffff');

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider auth={auth}>
        <GraphQLProvider auth={auth} url={HASURA_BASE_URL}>
          <NotificationProvider>
            <OrganizationProvider>
              <LayoutStateProvider>
                <LayoutProvider>
                  <SDKProvider auth={auth} url={HASURA_BASE_URL}>
                    <Router />
                  </SDKProvider>
                </LayoutProvider>
              </LayoutStateProvider>
            </OrganizationProvider>
          </NotificationProvider>
        </GraphQLProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
