import { MessageBarType } from '@fluentui/react';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Notification as BrowserNotification } from './Notification';

export interface INotification {
  message: string;
  type: MessageBarType;
  delay?: number;
}
interface INotificationState {
  createSystemNotification: (
    title: string,
    body: string,
    redirectTo: string,
  ) => Notification;
  notification: INotification | undefined;
  setNotification: Dispatch<SetStateAction<INotification | undefined>>;
}

const NotificationContext = createContext({} as INotificationState);

export const NotificationProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState<INotification>();
  const navigate = useNavigate();

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification(undefined);
      }, notification.delay || 4000);
    }
  }, [notification]);

  const notificationTuneRef = useRef(
    new Audio('assets/notification-sound.mp3'),
  );

  const createSystemNotification = (
    title: string,
    body: string,
    redirectTo: string,
  ) => {
    notificationTuneRef.current.play();
    notificationTuneRef.current.currentTime = 0;
    const notification = new Notification(title, {
      body,
    });

    notification.onclick = () => {
      if (redirectTo) navigate(redirectTo);
      if ((window as any).electron) {
        (window as any).electron.notificationClicked();
      }
    };

    return notification;
  };

  return (
    <NotificationContext.Provider
      value={{ notification, setNotification, createSystemNotification }}
    >
      {notification && (
        <BrowserNotification
          messageBarType={notification?.type}
          onDismiss={() => setNotification(undefined)}
        >
          {capitalizeFirstLetter(
            notification?.message
              ?.replaceAll('_', ' ')
              ?.replaceAll(/\[(.*?)\]/g, '')
              .trim(),
          ) || ''}
        </BrowserNotification>
      )}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const { setNotification, createSystemNotification } =
    useContext(NotificationContext);
  return { notification: setNotification, createSystemNotification };
};
