import ProjectManagement from '../Home/assets/ProjectManagement';
import AssessmentImg from './assets/AssessmentImg.png';
import LearningImg from './assets/LearningImg.png';
import SkillImg from './assets/SkillImg.png';
import SkillMatrix from './assets/SkillMatrix.png';

const UpSkillAndAssessment = () => {
  return (
    <div className="px-20">
      <div className="flex gap-10">
        <div className="w-1/2">
          <div className="flex items-center gap-2">
            <span>
              <ProjectManagement />
            </span>
            <span className="font-medium text-white">
              Upskill and Assessment
            </span>
          </div>
          <div className="text-4xl font-medium mt-2 text-white">
            Continual upskilling to boost productivity
          </div>
          <div className="mt-8 text-base text-white">
            Custom assessments, custom trainings, skill forecasting and skill
            reporting
          </div>
        </div>
        <div className="w-[60%]">
          <img src={SkillMatrix} alt="SkillMatrix" />
        </div>
      </div>
      <div className="flex justify-between mt-8 gap-4 ">
        <div className="w-[30%]">
          <img src={AssessmentImg} alt="AssessmentImg" />
        </div>
        <div className="w-[30%]">
          <img src={SkillImg} alt="SkillImg" />
        </div>
        <div className="w-[30%]">
          <img src={LearningImg} alt="LearningImg" />
        </div>
      </div>
    </div>
  );
};

export default UpSkillAndAssessment;
