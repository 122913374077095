const WallOfFame = () => {
  return (
    <svg
      width="58"
      height="57"
      viewBox="0 0 58 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.867 21.5478C15.0443 21.415 15.1753 21.2299 15.2413 21.0186C15.3074 20.8074 15.3053 20.5807 15.2352 20.3708C15.1651 20.1608 15.0307 19.9782 14.8509 19.8488C14.6711 19.7194 14.4552 19.6497 14.2336 19.6497H10.1792L8.91224 15.5583C8.84669 15.3291 8.70819 15.1275 8.51768 14.984C8.32718 14.8405 8.09505 14.7628 7.85642 14.7628C7.61779 14.7628 7.38566 14.8405 7.19516 14.984C7.00465 15.1275 6.86615 15.3291 6.8006 15.5583L5.53362 19.6497H1.56375C1.34215 19.6497 1.12617 19.7194 0.946405 19.8488C0.766635 19.9782 0.632191 20.1608 0.562116 20.3708C0.49204 20.5807 0.489887 20.8074 0.55596 21.0186C0.622033 21.2299 0.752983 21.415 0.930261 21.5478L4.24553 24.0786L2.97855 28.2966C2.9075 28.5102 2.90824 28.7412 2.98066 28.9543C3.05308 29.1675 3.19321 29.3513 3.37976 29.4776C3.56182 29.6065 3.7795 29.6758 4.00269 29.6758C4.22588 29.6758 4.44356 29.6065 4.62562 29.4776L7.89865 26.8625L11.1717 29.3722C11.3537 29.5104 11.5765 29.5846 11.8052 29.5831C12.0237 29.582 12.2369 29.516 12.4176 29.3932C12.6041 29.2669 12.7442 29.0832 12.8166 28.87C12.8891 28.6568 12.8898 28.4258 12.8188 28.2122L11.5518 23.9942L14.867 21.5478ZM9.82024 25.7658L8.53214 24.7956C8.35591 24.6442 8.13113 24.5609 7.89865 24.5609C7.66617 24.5609 7.4414 24.6442 7.26516 24.7956L5.97707 25.7658L6.48386 24.0997C6.54504 23.8925 6.54361 23.6719 6.47975 23.4656C6.41588 23.2592 6.29243 23.0763 6.12488 22.9397L4.68897 21.8431H6.39939C6.62516 21.8444 6.84542 21.7735 7.02784 21.6406C7.21027 21.5078 7.34525 21.32 7.41298 21.1049L7.89865 19.4388L8.38433 21.0206C8.45206 21.2357 8.58704 21.4234 8.76946 21.5563C8.95189 21.6891 9.17215 21.7601 9.39791 21.7587H11.1083L9.67243 22.8554C9.50487 22.9919 9.38142 23.1749 9.31756 23.3812C9.2537 23.5876 9.25227 23.8082 9.31345 24.0153L9.82024 25.7658Z"
        fill="#FF9900"
        id="wallOfFameBlink"
      />
      <path
        d="M57.4558 20.3665C57.3829 20.1605 57.2488 19.9815 57.0715 19.8534C56.8942 19.7253 56.6821 19.6542 56.4633 19.6495H52.409L51.142 15.5581C51.0764 15.3289 50.9379 15.1273 50.7474 14.9838C50.5569 14.8402 50.3248 14.7626 50.0862 14.7626C49.8475 14.7626 49.6154 14.8402 49.4249 14.9838C49.2344 15.1273 49.0959 15.3289 49.0303 15.5581L47.7634 19.6495H43.7935C43.5719 19.6495 43.3559 19.7191 43.1761 19.8485C42.9964 19.9779 42.8619 20.1606 42.7919 20.3705C42.7218 20.5805 42.7196 20.8071 42.7857 21.0184C42.8518 21.2297 42.9827 21.4148 43.16 21.5476L46.4753 24.0784L45.2083 28.2963C45.1372 28.51 45.138 28.7409 45.2104 28.9541C45.2828 29.1673 45.4229 29.351 45.6095 29.4774C45.7916 29.6063 46.0092 29.6756 46.2324 29.6756C46.4556 29.6756 46.6733 29.6063 46.8554 29.4774L50.1284 26.8622L53.4014 29.3719C53.5834 29.5102 53.8062 29.5844 54.0349 29.5828C54.2534 29.5818 54.4666 29.5157 54.6473 29.393C54.8338 29.2667 54.974 29.0829 55.0464 28.8698C55.1188 28.6566 55.1195 28.4256 55.0485 28.212L53.7815 23.994L57.0968 21.4632C57.2568 21.335 57.376 21.163 57.4398 20.9683C57.5035 20.7735 57.5091 20.5644 57.4558 20.3665ZM52.05 25.6812L50.7619 24.7111C50.5856 24.5596 50.3609 24.4764 50.1284 24.4764C49.8959 24.4764 49.6711 24.5596 49.4949 24.7111L48.2068 25.6812L48.7136 24.0151C48.7748 23.8079 48.7733 23.5873 48.7095 23.381C48.6456 23.1746 48.5222 22.9917 48.3546 22.8552L46.9187 21.7585H48.6291C48.8549 21.7598 49.0752 21.6889 49.2576 21.556C49.44 21.4232 49.575 21.2354 49.6427 21.0203L50.1284 19.4386L50.6141 21.0203C50.6818 21.2354 50.8168 21.4232 50.9992 21.556C51.1816 21.6889 51.4019 21.7598 51.6276 21.7585H53.3381L51.9022 22.8552C51.7346 22.9917 51.6112 23.1746 51.5473 23.381C51.4834 23.5873 51.482 23.8079 51.5432 24.0151L52.05 25.6812Z"
        fill="#FF9900"
        id="wallOfFameBlink"
      />
      <path
        d="M24.5592 11.9308L22.7854 17.6673C22.7179 17.8812 22.7203 18.111 22.7925 18.3234C22.8646 18.5358 23.0027 18.7196 23.1866 18.8483C23.3687 18.9772 23.5864 19.0465 23.8096 19.0465C24.0328 19.0465 24.2504 18.9772 24.4325 18.8483L29.0147 15.3263L33.597 18.8272C33.779 18.9655 34.0018 19.0397 34.2305 19.0381C34.449 19.0371 34.6622 18.971 34.8429 18.8483C35.0268 18.7196 35.1649 18.5358 35.237 18.3234C35.3092 18.111 35.3116 17.8812 35.2441 17.6673L33.4703 11.9308L38.0948 8.38772C38.2636 8.25255 38.3868 8.06881 38.4477 7.86138C38.5086 7.65396 38.5041 7.43287 38.435 7.22805C38.3659 7.02322 38.2354 6.84457 38.0612 6.71626C37.8871 6.58796 37.6776 6.5162 37.4613 6.51072H31.781L30.0283 0.795358C29.9628 0.566197 29.8243 0.364597 29.6338 0.221072C29.4433 0.0775464 29.2111 -9.15527e-05 28.9725 -9.15527e-05C28.7339 -9.15527e-05 28.5017 0.0775464 28.3112 0.221072C28.1207 0.364597 27.9822 0.566197 27.9167 0.795358L26.164 6.51072H20.5682C20.3519 6.5162 20.1424 6.58796 19.9683 6.71626C19.7941 6.84457 19.6636 7.02322 19.5945 7.22805C19.5254 7.43287 19.5209 7.65396 19.5818 7.86138C19.6426 8.06881 19.7658 8.25255 19.9347 8.38772L24.5592 11.9308ZM27.0298 8.61971C27.2546 8.6152 27.4721 8.53913 27.6506 8.40257C27.8292 8.266 27.9594 8.07608 28.0223 7.86047L29.0147 4.6759L30.0072 7.86047C30.0701 8.07608 30.2003 8.266 30.3788 8.40257C30.5574 8.53913 30.7749 8.6152 30.9997 8.61971H34.3361L31.5909 10.7287C31.4229 10.8601 31.2986 11.0392 31.2345 11.2425C31.1704 11.4458 31.1695 11.6637 31.232 11.8675L32.2455 15.1576L29.6482 13.154C29.4655 13.0171 29.2432 12.9431 29.0147 12.9431C28.7863 12.9431 28.564 13.0171 28.3813 13.154L25.7839 15.1365L26.9031 11.8465C26.9655 11.6426 26.9646 11.4247 26.9005 11.2214C26.8365 11.0181 26.7122 10.839 26.5441 10.7076L23.799 8.59862L27.0298 8.61971Z"
        fill="#FF9900"
        id="wallOfFameBlink"
      />
      <path
        d="M29.0125 27.4745C28.7325 27.4745 28.4639 27.5856 28.2659 27.7834C28.0679 27.9811 27.9567 28.2493 27.9567 28.529V39.074C27.9567 39.3536 28.0679 39.6218 28.2659 39.8196C28.4639 40.0174 28.7325 40.1284 29.0125 40.1284C29.2925 40.1284 29.5611 40.0174 29.7591 39.8196C29.9571 39.6218 30.0683 39.3536 30.0683 39.074V28.529C30.0683 28.2493 29.9571 27.9811 29.7591 27.7834C29.5611 27.5856 29.2925 27.4745 29.0125 27.4745Z"
        fill="#FF9900"
        id="wallOfFameBlink"
      />
      <path
        d="M43.0614 36.2266C33.4112 45.8646 32.2287 55.4184 32.1865 55.8191C32.1565 56.0981 32.2368 56.3777 32.4104 56.5984C32.584 56.819 32.8371 56.9632 33.1156 57.0001H33.2423C33.5004 56.9975 33.749 56.9023 33.9428 56.732C34.1366 56.5617 34.2627 56.3275 34.2981 56.0721C35.7167 49.0345 39.2965 42.6136 44.5395 37.7028C44.7355 37.5071 44.8456 37.2416 44.8456 36.9647C44.8456 36.6878 44.7355 36.4223 44.5395 36.2266C44.3435 36.0308 44.0776 35.9208 43.8004 35.9208C43.5232 35.9208 43.2574 36.0308 43.0614 36.2266Z"
        fill="#FF9900"
        id="wallOfFameBlink"
      />
      <path
        d="M13.4955 36.2257C13.3945 36.3204 13.314 36.4347 13.259 36.5617C13.2039 36.6886 13.1755 36.8255 13.1755 36.9639C13.1755 37.1022 13.2039 37.2391 13.259 37.366C13.314 37.493 13.3945 37.6073 13.4955 37.702C18.7385 42.6127 22.3183 49.0337 23.7369 56.0713C23.7723 56.3267 23.8984 56.5609 24.0922 56.7312C24.286 56.9015 24.5346 56.9966 24.7927 56.9992H24.9194C25.1979 56.9623 25.451 56.8182 25.6246 56.5975C25.7982 56.3769 25.8785 56.0972 25.8485 55.8182C25.8485 55.4175 24.6238 45.8638 14.9736 36.2257C14.8788 36.1248 14.7644 36.0444 14.6372 35.9895C14.5101 35.9345 14.3731 35.9062 14.2346 35.9062C14.096 35.9062 13.959 35.9345 13.8319 35.9895C13.7048 36.0444 13.5903 36.1248 13.4955 36.2257Z"
        fill="#FF9900"
        id="wallOfFameBlink"
      />
    </svg>
  );
};

export default WallOfFame;
