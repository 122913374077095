import { Storage, UserSession } from '@worx.squad/hbp-sdk';

import { filesize } from 'filesize';
import { get } from 'lodash';
import { RcFile } from 'rc-upload/lib/interface';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  Enum_Storage_File_Type_Enum,
  useInsertFileMutation,
} from '../shared-graphql';
import { useUser } from './user.hook';

const HBP_BASE_URL = process.env['NX_HBP_BASE_URL'] as string;
const session = new UserSession();

interface IUploadStatus {
  name: string | undefined;
  progress: number | undefined;
  uploading: boolean;
}

export const useStorageHook = (): [
  IUploadStatus,
  (
    file: RcFile | File,
    type: Enum_Storage_File_Type_Enum,
    category: string,
    user?: boolean,
  ) => Promise<{
    id: any;
    file_name: any;
    file_url: any;
  }>,
  Storage,
] => {
  const { organization, id } = useUser();
  const [, insertFile] = useInsertFileMutation();
  const [progress, setProgress] = useState<number>();
  const [fileName, setFileName] = useState<string>();
  const [uploading, setUploading] = useState<boolean>(false);

  const storage = new Storage(
    {
      baseURL: HBP_BASE_URL,
      useCookies: false,
    },
    session,
  );

  const uploadFile = async (
    file: RcFile | File,
    type: Enum_Storage_File_Type_Enum,
    category: string,
    user?: boolean,
  ) => {
    setUploading(true);
    const fileType = file?.name?.split(/[#?]/)[0].split('.').pop()?.trim();

    const res = await storage.put(
      `/public/${
        user ? `user/${id}` : `organization/${organization?.id}`
      }/${category}/${uuid() + '.' + fileType}`,
      file,
      null,
      (event: ProgressEvent) => {
        const percent = event.loaded / event.total;
        setProgress(percent);
        setFileName(file?.name);
        if (percent === 1 || percent === 0) {
          setProgress(undefined);
          setFileName(undefined);
        }
      },
    );

    const { data } = await insertFile({
      object: {
        file_url: res.key,
        created_by: id,
        type: type,
        metadata: {
          file_size: filesize(file?.size),
          file_type: file?.type,
          file_name: file?.name,
        },
      },
    });
    setUploading(false);

    return {
      id: get(data, 'insert_storage_files_one.id'),
      file_name: file?.name,
      file_url: res?.key,
    };
  };
  return [{ name: fileName, progress, uploading }, uploadFile, storage];
};
