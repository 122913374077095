import { DefaultButton } from '@fluentui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinkSvg from '../Home/assets/LinkSvg';
import CoreFeature from './CoreFeature';
import PlanDetails from './PlanDetails';
import { calculatePrice } from './Utils';

export const Starter = () => {
  const [option, setOption] = useState<string>('Yearly');
  const navigate = useNavigate();

  return (
    <div className=" bg-[#1A1A1A] p-20 w-[80%]">
      <div className="w-full flex flex-col items-start justify-center py-[2rem] sm:pt-[16px] text-white text-3xl text-[64px] sm:text-[72px] font-medium font-poppins leading-[3.5rem] sm:leading-[5rem] sm:py-[20px]">
        <div>
          <span className="text-[1rem] border border-[#424242] bg-gradient-to-b from-[#1A1A1A] via-[rgba(128,128,128,0.6)] text-[#FFFFFF] py-2 px-4 rounded-md">
            Pricing
          </span>
          <div>
            <span>Caliber of big tools,</span>
            <br />
            <span className="text-[#FF9900] text-left">cost of one!</span>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex flex-col">
          <div className="flex bg-[#242424] rounded-lg">
            {' '}
            <div
              onClick={() => setOption('Monthly')}
              className={`rounded-lg py-2 px-4 cursor-pointer ${option === 'Monthly' ? 'bg-[#6E6E6E] text-white' : 'bg-[#242424] text-[#757575]'}`}
            >
              Monthly
            </div>
            <div
              onClick={() => setOption('Yearly')}
              className={`rounded-lg  py-2 px-4 cursor-pointer ${option === 'Yearly' ? 'bg-[#6E6E6E] text-white' : 'bg-[#242424] text-[#757575]'}`}
            >
              Yearly
            </div>
            <div
              onClick={() => setOption('Life Time')}
              className={`rounded-lg py-2 px-4 cursor-pointer ${option === 'Life Time' ? 'bg-[#6E6E6E] text-white' : 'bg-[#242424] text-[#757575]'}`}
            >
              Life Time
            </div>
          </div>
          {option !== 'Monthly' && (
            <div className="text-[#FF9900] flex justify-start py-1 px-2 mt-2 bg-[#FF99001A] rounded-lg w-fit">
              {option === 'Yearly' ? 'Save up to 10%' : 'Save up to 20%'}
            </div>
          )}
        </div>
      </div>
      <PlanDetails option={option} />

      <div className="w-full flex flex-col items-start justify-center py-[2rem] sm:pt-[16px] text-white text-3xl text-[64px] sm:text-[72px] font-medium font-poppins leading-[3.5rem] sm:leading-[5rem] sm:py-[20px]">
        <div>
          <span className="text-[1rem] border border-[#424242] bg-gradient-to-b from-[#1A1A1A] via-[rgba(128,128,128,0.6)] text-[#FFFFFF] py-2 px-4 rounded-md">
            All Features
          </span>
          <div>Core Platform Features</div>
        </div>
      </div>
      <div className="flex gap-8 sticky top-20 bg-[#1A1A1A] z-10">
        <div className="w-1/4"></div>
        <div className="w-1/4">
          <div className="text-base font-bold text-white py-1">
            Experience Empowered
          </div>
          <div className="text-2xl font-semibold text-white py-1">Free</div>
          <div className="w-full sm:w-auto py-2">
            <DefaultButton
              onClick={() => navigate('/contact-us')}
              className="border border-[#FF9900] rounded-lg w-full  text-white hover:text-white px-6 py-6 hover:bg-black"
            >
              <span className="flex gap-4 justify-center items-center font-semibold">
                <span className="text-[1rem]">Get started</span>
                <span>
                  <LinkSvg />
                </span>
              </span>
            </DefaultButton>
          </div>
        </div>
        <div className="w-1/4">
          <div className="text-base font-bold text-white py-1">
            Productivity Plus
          </div>
          <div className="py-1">
            <span className="text-2xl font-semibold text-white">
              {'$' + calculatePrice(8, option)} &nbsp;
            </span>
            <span className="text-[#A8A8A8] text-xl">per user &nbsp;/ </span>
            <span className="text-[#FFFFFFB2] text-xl">
              {option === 'Monthly'
                ? 'month'
                : option === 'Yearly'
                  ? 'year'
                  : 'life time'}
            </span>
          </div>
          <div className="w-full sm:w-auto py-2">
            <DefaultButton
              onClick={() => navigate('/contact-us')}
              className="border border-[#FF9900] rounded-lg w-full  text-white hover:text-white px-6 py-6 hover:bg-black"
            >
              <span className="flex gap-4 justify-center items-center font-semibold">
                <span className="text-[1rem]">Get started</span>
                <span>
                  <LinkSvg />
                </span>
              </span>
            </DefaultButton>
          </div>
        </div>
        <div className="w-1/4">
          <div className="text-base font-bold text-white py-1">
            Growth Genius
          </div>
          <div className="py-1">
            <span className="text-2xl font-semibold text-white">
              {'$' + calculatePrice(15, option)}&nbsp;
            </span>
            <span className="text-[#A8A8A8] text-xl">per user &nbsp;/ </span>
            <span className="text-[#FFFFFFB2] text-xl">
              {option === 'Monthly'
                ? 'month'
                : option === 'Yearly'
                  ? 'year'
                  : 'life time'}
            </span>
          </div>
          <div className="w-full sm:w-auto py-2">
            <DefaultButton
              onClick={() => navigate('/contact-us')}
              className="border border-[#FF9900] rounded-lg w-full  text-white hover:text-white px-6 py-6 hover:bg-black"
            >
              <span className="flex gap-4 justify-center items-center font-semibold">
                <span className="text-[1rem]">Get started</span>
                <span>
                  <LinkSvg />
                </span>
              </span>
            </DefaultButton>
          </div>
        </div>
      </div>
      <CoreFeature />
    </div>
  );
};
