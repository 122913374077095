import { cxoInsightList } from './Utils';

const CxoInsight = () => {
  return (
    <div className="p-20">
      <div className="text-4xl font-semibold">
        CXO Insights for Informed Decision Making{' '}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3">
        {cxoInsightList.map((item, index) => (
          <div className="p-4 shadow-lg rounded-lg" key={index}>
            <div className="text-lg font-bold mb-2">{item.title}</div>
            <div className="text-base text-gray-600">{item.content}</div>
            <div className="mt-4">{item.icon}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CxoInsight;
