import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import { Header } from '../components/CommonComponent/Header';
import Contact from '../components/Contact/Contact';

const ContactUs = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);
  return (
    <div className="relative">
      <div className="sticky bg-[#101010] top-0 z-[9999]">
        <Header />
      </div>
      <div className="bg-[#F1F8F9]]">
        <Contact />
      </div>
    </div>
  );
};

export default ContactUs;
