import { Link } from '@fluentui/react';

const Address = () => {
  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="px-[20px]">
          <div className="py-[10px]">
            <div className="text-[1.5rem] font-normal text-white">Address</div>

            <div className="text-white text-[1rem]">
              32, Global business park, Sector 44, Gurgaon,
            </div>

            <div className="text-white text-[1rem]">Haryana, 122001</div>
          </div>
          <div className="py-[20px]">
            <div className="text-[1.5rem] font-normal text-white">Say Hi</div>
            <div className="text-[1rem] font-light text-white">
              consult@worxwide.com
            </div>
          </div>
          <div className="py-[20px]">
            <div className="text-[1.5rem] font-normal text-white">
              For more business. Check out our website
            </div>
            <div className="text-[1rem] font-light text-white">
              <Link
                className="h-[25px] w-[25px] cursor-pointer text-white hover:text-white"
                href="https://www.worxwide.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Worxwide"
              >
                www.worxwide.com
              </Link>
            </div>
          </div>
        </div>
        <div className="flex py-[20px] px-[20px] gap-4">
          <Link
            className="h-[25px] w-[25px] cursor-pointer"
            href="https://www.facebook.com/Worxwide"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Worxwide"
          >
            <img src="../../../assets/Facebook.png" alt="facebook" />
          </Link>
          <Link
            className="h-[25px] w-[25px] cursor-pointer"
            href="https://www.instagram.com/worxwide/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Worxwide"
          >
            <img src="../../../assets/Instagram.png" alt="instagram" />
          </Link>
          <Link
            className="h-[25px] w-[25px] cursor-pointer"
            href="https://www.linkedin.com/company/worxwide/mycompany/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Worxwide"
          >
            <img src="../../../assets/LinkedIn.png" alt="linkedIn" />
          </Link>
          <Link
            className="h-[25px] w-[25px] cursor-pointer"
            href="https://twitter.com/worxwide"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Worxwide"
          >
            <img src="../../../assets/Twiter.png" alt="twiter" />
          </Link>
        </div>
      </div>
      <div>
        <img src="../../../assets/ContactBanner.png" alt="banner" />
      </div>
    </div>
  );
};

export default Address;
