import {
  FontWeights,
  getTheme,
  IIconProps,
  mergeStyleSets,
  Modal,
  Spinner,
} from '@fluentui/react';
import {
  DefaultButton,
  IButtonStyles,
  IconButton,
} from '@fluentui/react/lib/Button';
import {
  Form,
  PhoneInputField,
  STextFormFieldStyle,
  STextFormFieldStyleMobileView,
  TextField,
} from '@worx.squad/shared-frontend';
import moment from 'moment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import LinkSvg from '../Home/assets/LinkSvg';
import { tryUsSchema } from '../Home/tryUs.schema';

interface Props {
  isModalOpenOne: boolean;
  hideModalOne: () => void;
  showModalOne: () => void;
}
export default function JoinAsPartnerModal({
  isModalOpenOne,
  hideModalOne,
}: Props) {
  const cancelIcon: IIconProps = { iconName: 'Cancel' };
  const theme = getTheme();
  const [loader, setLoader] = useState(false);
  const isMobile = window.innerWidth < 768;
  const tryUsForm = useForm({
    mode: 'all',
    resolver: tryUsSchema,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_no: '91',
      description: '',
    },
  });
  const { handleSubmit } = tryUsForm;

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      padding: '0px 12px',
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 16px',
      },
    ],
    heading: {
      color: theme.palette.neutralPrimary,
      fontWeight: FontWeights.semibold,
      fontSize: 'inherit',
      margin: '0',
    },
    body: {
      flex: '4 4 auto',
      padding: '12px 16px',
      overflowY: 'hidden',
    },
  });
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

  const onSubmit = async (data: any) => {
    console.log('akki form', data);
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpenOne}
        onDismiss={hideModalOne}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <div className={contentStyles.header}>
          <div className="text-[#000000] text-4xl font-semibold leading-[2.5rem] sm:leading-[3.5rem] flex items-center flex-wrap sm:flex-wrap gap-4">
            Join as a Partner
          </div>

          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={hideModalOne}
          />
        </div>
        <div className={contentStyles.body}>
          <Form
            className="flex flex-col w-full sm:w-full gap-5 items-center"
            formHook={tryUsForm}
            onSubmit={() => handleSubmit(onSubmit)}
          >
            <div className="flex flex-col sm:flex-row gap-5">
              <label>
                First Name
                <TextField
                  formHook={tryUsForm}
                  name="first_name"
                  label=""
                  styles={
                    isMobile
                      ? STextFormFieldStyleMobileView
                      : STextFormFieldStyle
                  }
                  placeholder="Enter first name"
                  className="w-full"
                />
              </label>
              <label>
                Last Name
                <TextField
                  formHook={tryUsForm}
                  name="last_name"
                  styles={
                    isMobile
                      ? STextFormFieldStyleMobileView
                      : STextFormFieldStyle
                  }
                  placeholder="Enter last name"
                  className="w-full"
                />
              </label>
            </div>

            <div className="flex flex-col sm:flex-row gap-5">
              <label className="mt-[4px]">
                Email
                <TextField
                  formHook={tryUsForm}
                  name="email"
                  styles={
                    isMobile
                      ? STextFormFieldStyleMobileView
                      : STextFormFieldStyle
                  }
                  placeholder="Enter company email address"
                  className="w-full"
                />
              </label>
              <div>
                <label>Phone Number</label>
                <PhoneInputField
                  country={moment.tz.guess()}
                  placeholder="Enter phone number"
                  formHook={tryUsForm}
                  name="phone_no"
                  label=""
                  inputStyle={{
                    width: isMobile ? '100%' : '322px',
                    height: '52px',
                    borderRadius: 'unset',
                  }}
                />
              </div>
            </div>
            <div className="w-full">
              <label>Description</label>
              <TextField
                formHook={tryUsForm}
                name="Description"
                multiline
                rows={4}
              />
            </div>
            <div className="w-full my-4">
              <DefaultButton
                onClick={handleSubmit(onSubmit)}
                className="bg-[#FF9900] w-full sm:w-auto border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
              >
                {loader ? (
                  <Spinner />
                ) : (
                  <span className="flex justify-center items-center gap-4">
                    <span className="text-[1rem]">Join</span>
                    <span>
                      <LinkSvg />
                    </span>
                  </span>
                )}
              </DefaultButton>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
