import { Link, Stack } from '@fluentui/react';
import { Logo } from '@worx.squad/shared-frontend';
import { BackIcon } from 'libs/shared-frontend/src/assets/BackIcon';
import { useLocation, useNavigate } from 'react-router-dom';

const OnboardHeader = () => {
  const url = useLocation();
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center p-4 lg:hidden sticky top-0 z-10 bg-white">
      <Stack
        onClick={() => {
          if (url.pathname === '/register') {
            navigate('/login');
          }
        }}
      >
        <BackIcon />
      </Stack>
      <Stack className="w-44">
        <Link
          onClick={() => {
            if (url.pathname === '/register') {
              navigate('/');
            }
          }}
        >
          <Logo />
        </Link>
      </Stack>
    </div>
  );
};
export default OnboardHeader;
