const ThankYouMail = () => {
  return (
    <svg
      width="63"
      height="62"
      viewBox="0 0 63 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1718_5091)">
        <path
          d="M52.166 10.333H10.8327C7.99102 10.333 5.69185 12.658 5.69185 15.4997L5.66602 46.4997C5.66602 49.3413 7.99102 51.6663 10.8327 51.6663H52.166C55.0077 51.6663 57.3327 49.3413 57.3327 46.4997V15.4997C57.3327 12.658 55.0077 10.333 52.166 10.333ZM49.5827 46.4997H13.416C11.9952 46.4997 10.8327 45.3372 10.8327 43.9163V20.6663L28.761 31.878C30.4402 32.9372 32.5585 32.9372 34.2377 31.878L52.166 20.6663V43.9163C52.166 45.3372 51.0035 46.4997 49.5827 46.4997ZM31.4994 28.4163L10.8327 15.4997H52.166L31.4994 28.4163Z"
          fill="black"
        />
        <rect x="38" y="38" width="24" height="24" rx="12" fill="white" />
        <g clip-path="url(#clip1_1718_5091)">
          <path
            d="M50.7196 40.0296C44.6296 39.5996 39.5996 44.6296 40.0296 50.7196C40.3896 56.0096 45.0096 59.9996 50.3096 59.9996H53.9996C54.5496 59.9996 54.9996 59.5496 54.9996 58.9996C54.9996 58.4496 54.5496 57.9996 53.9996 57.9996H50.3296C46.5996 57.9996 43.1796 55.5796 42.2496 51.9696C40.7596 46.1696 46.1596 40.7596 51.9596 42.2596C55.5796 43.1796 57.9996 46.5996 57.9996 50.3296V51.4296C57.9996 52.2196 57.2896 52.9996 56.4996 52.9996C55.7096 52.9996 54.9996 52.2196 54.9996 51.4296V50.1796C54.9996 47.6696 53.2196 45.4096 50.7396 45.0596C47.3396 44.5696 44.4696 47.5096 45.0796 50.9296C45.4196 52.8396 46.9096 54.4196 48.7996 54.8696C50.6396 55.2996 52.3896 54.7096 53.5396 53.5396C54.4296 54.7596 56.2096 55.3996 57.8396 54.7496C59.1796 54.2196 59.9996 52.8496 59.9996 51.4096V50.3196C59.9996 45.0096 56.0096 40.3896 50.7196 40.0296ZM49.9996 52.9996C48.3396 52.9996 46.9996 51.6596 46.9996 49.9996C46.9996 48.3396 48.3396 46.9996 49.9996 46.9996C51.6596 46.9996 52.9996 48.3396 52.9996 49.9996C52.9996 51.6596 51.6596 52.9996 49.9996 52.9996Z"
            fill="#FF9900"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1718_5091">
          <rect
            width="62"
            height="62"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
        <clipPath id="clip1_1718_5091">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(38 38)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ThankYouMail;
