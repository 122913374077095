import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoginMutationVariables = Types.Exact<{
  object: Types.LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'mutation_root';
  login?: {
    __typename?: 'LoginOutput';
    user_id?: any | null;
    jwt_token: string;
    jwt_expires_in: number;
    refresh_token: string;
    setup_complete?: boolean | null;
  } | null;
};

export function useLoginMutation() {
  return Urql.useMutation<
    Operations.LoginMutation,
    Operations.LoginMutationVariables
  >(Operations.LoginDocument);
}
