import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrganizationLocationsDetailsQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetOrganizationLocationsDetailsQuery = {
  __typename?: 'query_root';
  organization_locations: Array<{
    __typename?: 'organization_locations';
    id: any;
    title: string;
    total_seats: number;
    timezone_name: string;
    address: {
      __typename?: 'address';
      id: any;
      address_line: string;
      additional_fields?: any | null;
      state: string;
      country: string;
      pin_code: string;
      landmark?: string | null;
    };
  }>;
  organization_locations_aggregate: {
    __typename?: 'organization_locations_aggregate';
    aggregate?: {
      __typename?: 'organization_locations_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetOrganizationLocationsTitleQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid'];
  title?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetOrganizationLocationsTitleQuery = {
  __typename?: 'query_root';
  organization_locations: Array<{
    __typename?: 'organization_locations';
    id: any;
    title: string;
  }>;
};

export type UpdateOrganizationLocationMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  data: Types.Organization_Locations_Set_Input;
}>;

export type UpdateOrganizationLocationMutation = {
  __typename?: 'mutation_root';
  update_organization_locations_by_pk?: {
    __typename?: 'organization_locations';
    id: any;
  } | null;
};

export type UpdateAddressMutationVariables = Types.Exact<{
  data: Types.Address_Set_Input;
  id: Types.Scalars['uuid'];
}>;

export type UpdateAddressMutation = {
  __typename?: 'mutation_root';
  update_address_by_pk?: { __typename?: 'address'; id: any } | null;
};

export type GetLocationBookingsQueryVariables = Types.Exact<{
  date: Types.Scalars['date'];
  organizationId: Types.Scalars['uuid'];
  locationId: Types.Scalars['uuid'];
  offset: Types.Scalars['Int'];
  limit: Types.Scalars['Int'];
}>;

export type GetLocationBookingsQuery = {
  __typename?: 'query_root';
  organization_locations: Array<{
    __typename?: 'organization_locations';
    total_seats: number;
    booked_count: {
      __typename?: 'hotdesk_bookings_aggregate';
      aggregate?: {
        __typename?: 'hotdesk_bookings_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
  hotdesk_bookings: Array<{
    __typename?: 'hotdesk_bookings';
    user: { __typename?: 'users'; id: any; display_name?: string | null };
  }>;
};

export type GetOrganizationLocationByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;

export type GetOrganizationLocationByIdQuery = {
  __typename?: 'query_root';
  location?: {
    __typename?: 'organization_locations';
    id: any;
    title: string;
    total_seats: number;
    timezone_name: string;
    address: {
      __typename?: 'address';
      id: any;
      address_line: string;
      additional_fields?: any | null;
      state: string;
      country: string;
      pin_code: string;
      landmark?: string | null;
    };
  } | null;
};

export type DeleteOrgLocationMutationVariables = Types.Exact<{
  orgId: Types.Scalars['String'];
  locationId: Types.Scalars['String'];
}>;

export type DeleteOrgLocationMutation = {
  __typename?: 'mutation_root';
  deleteOrgLocation?: {
    __typename?: 'DeleteOrgLocationOutput';
    status?: number | null;
    message?: string | null;
  } | null;
};

export function useGetOrganizationLocationsDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetOrganizationLocationsDetailsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetOrganizationLocationsDetailsQuery,
    Operations.GetOrganizationLocationsDetailsQueryVariables
  >({ query: Operations.GetOrganizationLocationsDetailsDocument, ...options });
}

export function useGetOrganizationLocationsTitleQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetOrganizationLocationsTitleQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetOrganizationLocationsTitleQuery,
    Operations.GetOrganizationLocationsTitleQueryVariables
  >({ query: Operations.GetOrganizationLocationsTitleDocument, ...options });
}

export function useUpdateOrganizationLocationMutation() {
  return Urql.useMutation<
    Operations.UpdateOrganizationLocationMutation,
    Operations.UpdateOrganizationLocationMutationVariables
  >(Operations.UpdateOrganizationLocationDocument);
}

export function useUpdateAddressMutation() {
  return Urql.useMutation<
    Operations.UpdateAddressMutation,
    Operations.UpdateAddressMutationVariables
  >(Operations.UpdateAddressDocument);
}

export function useGetLocationBookingsQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetLocationBookingsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetLocationBookingsQuery,
    Operations.GetLocationBookingsQueryVariables
  >({ query: Operations.GetLocationBookingsDocument, ...options });
}

export function useGetOrganizationLocationByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetOrganizationLocationByIdQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetOrganizationLocationByIdQuery,
    Operations.GetOrganizationLocationByIdQueryVariables
  >({ query: Operations.GetOrganizationLocationByIdDocument, ...options });
}

export function useDeleteOrgLocationMutation() {
  return Urql.useMutation<
    Operations.DeleteOrgLocationMutation,
    Operations.DeleteOrgLocationMutationVariables
  >(Operations.DeleteOrgLocationDocument);
}
