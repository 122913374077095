import { Pivot, PivotItem, Stack } from '@fluentui/react';
import { useHeader, useLayoutState } from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BarChart } from '../components/Dashboard/Charts/BarChart';
import { DashboardHeader } from '../components/Dashboard/DashboardHeader';

const Dashboard = () => {
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();

  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Admin Dashboard');
  }, [setTitle]);

  return (
    <Stack className="mx-14 mt-5">
      <Helmet>
        <title>Dashboard | Worx Squad</title>
      </Helmet>
      <DashboardHeader />
      <Stack
        className="w-full mt-5 border rounded-md p-2"
        tokens={{ childrenGap: 20 }}
      >
        <Pivot>
          <PivotItem headerText="Billable hours">
            <BarChart />
          </PivotItem>
          <PivotItem headerText="License utilization">
            <BarChart timeSpent={true} />
          </PivotItem>
        </Pivot>
      </Stack>
    </Stack>
  );
};

export default Dashboard;
