import { Stack } from '@fluentui/react';
import { useHeader, useLayoutState } from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { HotDeskProvider } from '../components/HotDesking/HotDeskingHook';
import Locations from '../components/HotDesking/Locations';
import SeatsAndBookings from '../components/HotDesking/SeatsAndBookings';

const HotDesking = () => {
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();

  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Hot Desking');
  }, [setTitle]);
  return (
    <>
      <Helmet>
        <title>Hot Desking | Worx Squad</title>
      </Helmet>
      <HotDeskProvider>
        <Stack tokens={{ childrenGap: 30 }} className="mainContainer 2xl:w-4/6">
          <Locations />
          <hr />
          <SeatsAndBookings />
        </Stack>
      </HotDeskProvider>
    </>
  );
};

export default HotDesking;
