import { useState } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import CreateOrUpdatePerformanceReview from './ManagePerformanceReview/CreateOrUpdatePerformanceReview';
import { useLoadPerformanceReviewMutation } from './PerformanceReview.generated';
import PerformanceReviewList from './PerformanceReviewList';

const PerformanceReviewHome = () => {
  const { organization } = useOrganizationContext();

  const [createPR, setCreatePR] = useState(false);
  const [performanceList, setPerformanceList] = useState<any>([]);
  const [selectedPerformance, setSelectedPerformance] = useState({});

  const [, loadPerformanceReview] = useLoadPerformanceReviewMutation();

  const getPerformanceReviewList = async () => {
    const { data: responseData, error: responseError } =
      await loadPerformanceReview({
        object: {
          org_id: organization?.id,
        },
      });
    if (responseData?.loadPerformanceReview?.response) {
      setPerformanceList(responseData.loadPerformanceReview.response);
    }
  };

  return (
    <div>
      {createPR ? (
        <CreateOrUpdatePerformanceReview
          selectedPerformance={selectedPerformance}
          setCreatePR={setCreatePR}
          getPerformanceReviewList={getPerformanceReviewList}
          setSelectedPerformance={setSelectedPerformance}
        />
      ) : (
        <PerformanceReviewList
          performanceList={performanceList}
          setCreatePR={setCreatePR}
          setSelectedPerformance={setSelectedPerformance}
          getPerformanceReviewList={getPerformanceReviewList}
        />
      )}
    </div>
  );
};

export default PerformanceReviewHome;
