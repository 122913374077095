import {
  IIconProps,
  IStackStyles,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  useTheme,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import DepartmentAddModal from './DepartmentAddModal';
import { DepartmentList } from './DepartmentList';
import { useGetDepartmentQuery } from './department.generated';

export const Department = () => {
  const { organization } = useOrganizationContext();
  const [department, setDepartment] = useState([]);

  const [{ data: departments }, refetchDepartment] = useGetDepartmentQuery({
    variables: {
      organization_id: organization?.id,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  const stackStyles: IStackStyles = {
    root: {
      minHeight: 'calc(100vh - 140px)',
    },
  };
  const theme = useTheme();

  const addIcon: IIconProps = { iconName: 'Add' };

  useEffect(() => {
    if (departments) {
      const items: any = get(departments, 'department');
      setDepartment(items);
    }
  }, [departments]);

  const [
    addDepartmentModalVisible,
    { setTrue: showAddDepartmentModal, setFalse: hideAddDepartmentModal },
  ] = useBoolean(false);

  return (
    <Stack tokens={{ childrenGap: 20 }} styles={stackStyles}>
      <StackItem>
        <Text className="text-2xl font-medium text-gray-500 ">
          Total departments: {department?.length || 0}
        </Text>
      </StackItem>
      <StackItem>
        <PrimaryButton
          styles={{
            root: {
              maxWidth: '200px',
              color: theme.palette.white,
            },
          }}
          text="Add department"
          iconProps={addIcon}
          allowDisabledFocus
          onClick={showAddDepartmentModal}
        />
      </StackItem>
      <StackItem>
        <DepartmentList department={department} refetch={refetchDepartment} />
      </StackItem>
      <DepartmentAddModal
        isModalOpen={addDepartmentModalVisible}
        hideModal={hideAddDepartmentModal}
        refetch={refetchDepartment}
      />
    </Stack>
  );
};
