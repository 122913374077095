import { useEffect } from 'react';

export const useListener = (
  type: string,
  listener: EventListenerOrEventListenerObject,
  options?: AddEventListenerOptions,
) => {
  useEffect(() => {
    window.addEventListener(type, listener);
    return () => {
      window.removeEventListener(type, listener);
    };
  }, [listener, type]);
};
