import {
  DefaultButton,
  Icon,
  Link,
  MessageBarType,
  Stack,
  StackItem,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useAuth } from '@worx.squad/hbp-react';
import {
  Form,
  ISearchableSelectOption,
  PrimaryButtonWithLoader,
  SearchableSelectField,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { OperationContext } from 'urql';
import { useDebounce } from 'use-debounce';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import EmployeeAddModal from '../Employees/EmployeeAddModal';
import {
  useEditDepartmentMutation,
  useGetManagerQuery,
  useInsertDepartmentMutation,
} from './department.generated';
import { departmentSchema } from './department.schema';

interface IFormData {
  name: string;
  manager: ISearchableSelectOption;
}

const DepartmentAddForm = ({
  refetch,
  onCancel,
  edit,
}: {
  refetch?: (opts?: Partial<OperationContext>) => void;
  onCancel?: () => void;
  edit?: { name: any; id: any; manager: any };
}) => {
  const { notification } = useNotification();
  const { user } = useAuth();
  const { organization } = useOrganizationContext();

  const [loading, setLoading] = useState(false);
  const [rolesText, setRolesText] = useState<string | undefined>('');
  const [options, setOptions] = useState<ISearchableSelectOption[]>([]);
  const [rolesTextLazy] = useDebounce(rolesText, 500);

  const [, insertDepartment] = useInsertDepartmentMutation();
  const [, editDepartment] = useEditDepartmentMutation();

  const [addEmployeeVisible, { toggle: toggleAddEmployee }] = useBoolean(false);

  const [{ data: manager }, refetchManager] = useGetManagerQuery({
    variables: {
      organization_id: organization?.id,
      search: rolesTextLazy ? `%${rolesTextLazy}%` : undefined,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  const departmentForm = useForm<IFormData>({
    resolver: departmentSchema,
    mode: 'all',
    defaultValues: {
      name: edit ? edit?.name : '',
      manager: edit
        ? { value: edit.manager.value, label: edit.manager.label }
        : {},
    },
  });

  const { handleSubmit } = departmentForm;
  useEffect(() => {
    if (manager?.users) {
      const option = manager?.users.map((user) => {
        return {
          value: user.id,
          label: user.display_name,
        } as ISearchableSelectOption;
      });
      setOptions(option);
    }
  }, [manager]);

  const onSubmit = async (value: IFormData) => {
    setLoading(true);
    try {
      const res = await insertDepartment({
        name: value.name,
        manager_id: value.manager.value,
        created_by: user?.id,
        organization_id: organization?.id,
      });
      if (res.error) {
        if (res.error.message.toLowerCase().includes('uniqueness violation'))
          notification({
            message: 'The department name already exists',
            type: MessageBarType.error,
          });
        else
          notification({
            message: res.error.message,
            type: MessageBarType.error,
          });
      } else {
        notification({
          message: 'Successfully added department',
          type: MessageBarType.success,
        });
      }
      onCancel && onCancel();

      setLoading(false);
    } catch (error) {
      notification({
        message: 'Failed to add department',
        type: MessageBarType.error,
      });
      console.error(error);
      setLoading(false);
    }
    refetch && refetch();
    refetchManager();
  };
  const onEdit = async (value: IFormData) => {
    setLoading(true);
    try {
      const res = await editDepartment({
        departmentId: edit?.id,
        manager_id: value.manager.value,
        name: value.name,
      });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: 'Successfully edited department',
          type: MessageBarType.success,
        });
      }
      onCancel && onCancel();

      setLoading(false);
    } catch (error) {
      notification({
        message: 'Failed to edit department',
        type: MessageBarType.error,
      });
      console.error(error);
      setLoading(false);
    }
    refetch && refetch();
    refetchManager();
  };
  return (
    <>
      <Form
        onSubmit={edit ? handleSubmit(onEdit) : handleSubmit(onSubmit)}
        formHook={departmentForm}
      >
        <TextField
          formHook={departmentForm}
          name="name"
          placeholder="Department name"
          label="Department name"
          className="mb-4"
        />
        <SearchableSelectField
          onInputChange={(value) => {
            setRolesText(value);
          }}
          inputValue={rolesText}
          options={options}
          formHook={departmentForm}
          placeholder="Select manager"
          name="manager"
          label="Assign manager"
        />
        <Link onClick={toggleAddEmployee} className="mt-2">
          <Icon iconName="Add" className="text-xs" /> Add new manager (employee)
        </Link>
        <Stack className="my-5" tokens={{ childrenGap: 10 }} horizontal>
          <StackItem>
            <PrimaryButtonWithLoader
              type="submit"
              text={edit ? 'Edit' : 'Create'}
              loading={loading}
            />
          </StackItem>
          <StackItem>
            <DefaultButton
              onClick={() => {
                onCancel && onCancel();
              }}
              text="Cancel"
            />
          </StackItem>
        </Stack>
      </Form>
      <EmployeeAddModal
        hideModal={toggleAddEmployee}
        isModalOpen={addEmployeeVisible}
        refetch={refetchManager}
      />
    </>
  );
};

export default DepartmentAddForm;
