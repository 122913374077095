import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import * as yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(yup);

const schema = yup.object().shape({
  fname: yup
    .string()
    .max(100, 'Maximum 100 characters are allowed')
    .trim()
    .required()
    .label('First name'),
  lname: yup
    .string()
    .max(100, 'Maximum 100 characters are allowed')
    .trim()
    .required()
    .label('Last name'),
  email: yup
    .string()
    .email()
    .trim()
    .test('email', 'Email cannot contain white spaces.', (value) => {
      return !/\s/.test(value as string);
    })
    .label('Email')
    .required(),
  password: yup
    .string()
    .password()
    .test('password', 'Password cannot contain white spaces.', (value) => {
      return !/\s/.test(value as string);
    })
    .required()
    .label('Password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match'),
  dateOfBirth: yup
    .date()
    .max(
      moment().subtract(18, 'years').toDate(),
      'You must be 18 years or older',
    )
    .label('Date of birth'),
  dateOfJoining: yup
    .date()
    .max(moment().toDate(), 'Date of joining cannot be in the future')
    .label('Date of joining'),
  marriedAnniversary: yup
    .date()
    .max(moment().toDate(), 'Marriage anniversary cannot be in the future')
    .label('Marriage anniversary'),
  avatarImage: yup.mixed().label('Avatar image'),
});

export const registerSchema = yupResolver(schema);
