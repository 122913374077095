import { DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import LinkSvg from '../Home/assets/LinkSvg';
import JoinAsPartnerModal from './JoinAsPartnerModal';

const JoinAsPartner = () => {
  const [isModalOpenOne, { setTrue: showModalOne, setFalse: hideModalOne }] =
    useBoolean(false);
  return (
    <div className="flex flex-col justify-center items-center bg-[#F1F8F9]">
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col items-center gap-12 py-16">
          <div className="text-4xl font-medium text-center">
            Join as a Partner Today
          </div>
          <div className="w-auto text-center flex justify-center items-center">
            <DefaultButton
              onClick={showModalOne}
              className="w-full border border-[#FF9900] bg-[#FF9900] hover:bg-[#e19a30] hover:text-white px-6 py-6 ease-in-out duration-300"
            >
              <span className="flex gap-4 justify-center items-center">
                <span className="text-[1rem] text-white font-semibold">
                  Join
                </span>
                <span>
                  <LinkSvg />
                </span>
              </span>
            </DefaultButton>
          </div>
          <JoinAsPartnerModal
            showModalOne={showModalOne}
            isModalOpenOne={isModalOpenOne}
            hideModalOne={hideModalOne}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinAsPartner;
