import {
  DefaultButton,
  IconButton,
  MessageBarType,
  Modal,
  Text,
  TextField,
  Toggle,
} from '@fluentui/react';
import {
  PrimaryButtonWithLoader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useEffect, useState } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import {
  useAddNewLeaveTypeMutation,
  useGetLeaveTypeByIdSubscription,
  useUpdateLeaveTypeByIdMutation,
} from './leaves.generated';

export const LeaveTypeModal = ({
  isModalOpen,
  hidesModal,
  editId,
  refetch,
  setEditId,
}: any) => {
  const { notification } = useNotification();
  const { organization } = useOrganizationContext();
  const [noOfDays, setNoOfDays] = useState<string | undefined>('0');
  const [monthlyToggle, setMonthlyToggle] = useState<boolean>(false);
  const [carryToggle, setCarryToggle] = useState<boolean>(false);
  const [monthlyLimit, setMonthlyLimit] = useState<string | undefined>('0');
  const [perRequestLimit, setPerRequestLimit] = useState<string | undefined>(
    '0',
  );
  const [selectedLeaveType, setSelectedLeaveType] = useState<
    string | undefined
  >();
  const [carry, setCarry] = useState<string | undefined>('0');
  const [, editLeave] = useUpdateLeaveTypeByIdMutation();
  const [, addLeave] = useAddNewLeaveTypeMutation();
  const [{ data }] = useGetLeaveTypeByIdSubscription({
    variables: {
      edit_id: editId,
    },
    pause: !editId,
  });
  useEffect(() => {
    if (data && editId) {
      const values = data.organization_active_leave_types_by_pk;
      if (!values) return;
      setNoOfDays(values.annual_eligibility.toString());
      const limit = values.monthly_earning_amount;
      setMonthlyToggle(values.will_be_earned_monthly);
      setCarryToggle(values.will_carry_forward);
      setPerRequestLimit(values.limit_of_days_at_once?.toString() || '0');
      setMonthlyLimit(limit);
      setSelectedLeaveType(values.leave_type);
      setCarry(values.annual_carry_forward_amount?.toString());
    }
  }, [data, editId]);

  const hideModal = () => {
    setEditId(undefined);
    setNoOfDays('0');
    setMonthlyLimit('0');
    setCarry('0');
    setSelectedLeaveType(undefined);
    setPerRequestLimit('0');
    hidesModal();
  };
  const handleSubmit = async () => {
    try {
      const carryValue = parseInt(carry || '');
      const res = await addLeave({
        object: {
          annual_carry_forward_amount: carryValue > 0 ? carryValue : null,
          annual_eligibility: parseInt(noOfDays || '0'),
          monthly_earning: monthlyToggle
            ? parseFloat(monthlyLimit || '0')
            : null,
          org_id: organization?.id,
          will_be_earned: monthlyToggle,
          will_carry_forward: carryToggle,
          leave_type: selectedLeaveType,
          per_leave_limit: parseInt(perRequestLimit || '0'),
        },
      });
      if (res.data) {
        refetch({
          requestPolicy: 'cache-and-network',
        });
        notification({
          message: 'Leave added successfully',
          type: MessageBarType.success,
        });
      } else if (res.error) {
        notification({
          message: res.error.message + '',
          type: MessageBarType.error,
        });
      }
    } catch (e) {
      console.error(e);
    }
    hideModal();
  };
  const handleEdit = async () => {
    try {
      const carryValue = parseInt(carry || '');

      const res = await editLeave({
        object: {
          leave_type_id: editId,
          annual_carry_forward_amount: carryValue > 0 ? carryValue : null,
          annual_eligibility: parseInt(noOfDays || '0'),
          will_be_earned: monthlyToggle,
          monthly_earning: monthlyToggle
            ? parseFloat(monthlyLimit || '0')
            : null,
          will_carry_forward: carryToggle,
          leave_type: selectedLeaveType,
          per_leave_limit: parseInt(perRequestLimit || '0'),
        },
      });
      if (res.data) {
        refetch({
          requestPolicy: 'cache-and-network',
        });
        notification({
          message: 'Leave added successfully',
          type: MessageBarType.success,
        });
      } else if (res.error) {
        notification({
          message: res.error.message + '',
          type: MessageBarType.error,
        });
      }
    } catch (e) {
      console.error(e);
    }
    hideModal();
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      styles={{ main: { width: 300 } }}
    >
      <div className="py-3 px-5">
        <div className="flex justify-between">
          <Text className="text-lg font-semibold">
            {editId ? 'Edit' : 'Add'} Leave
          </Text>
          <IconButton iconProps={{ iconName: 'Cancel' }} onClick={hideModal} />
        </div>
        <div className="mt-5 flex flex-col gap-2">
          <TextField
            placeholder="Name of the leave"
            value={selectedLeaveType}
            onChange={(e, value) => setSelectedLeaveType(value)}
            label="Leave Type"
          />
          <TextField
            type="number"
            inputMode="numeric"
            value={String(noOfDays)}
            onChange={(e, value) => {
              setNoOfDays(value);
              setMonthlyLimit(() => {
                const y = parseFloat(value || '0') / 12;
                return y.toFixed(2);
              });
            }}
            label="Annual eligibility"
          />
          <div>
            <Toggle
              checked={monthlyToggle}
              label="Monthly earning"
              onChange={() => setMonthlyToggle(!monthlyToggle)}
            />
            {monthlyToggle && (
              <TextField
                type="number"
                onKeyDown={(e) => e.preventDefault()}
                value={monthlyLimit}
                onChange={(e, val) => setMonthlyLimit(val)}
              />
            )}
          </div>
          <div>
            <Toggle
              checked={carryToggle}
              label="Carry forward"
              onChange={() => setCarryToggle(!carryToggle)}
            />
            {carryToggle && (
              <TextField
                type="number"
                value={carry}
                onChange={(e, value) => setCarry(value)}
              />
            )}
          </div>
          <TextField
            type="number"
            label="Per request days limit"
            value={perRequestLimit}
            onChange={(e, value) => {
              setPerRequestLimit(value);
            }}
            description="Setting it to 0 will make it unlimited"
          />
        </div>
        <div className="mt-5 flex gap-5">
          <PrimaryButtonWithLoader
            text={editId ? 'Save' : 'Add leave'}
            onClick={editId ? handleEdit : handleSubmit}
          />
          <DefaultButton text="Cancel" onClick={hideModal} />
        </div>
      </div>
    </Modal>
  );
};
