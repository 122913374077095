import { IconButton } from '@fluentui/react';
import classNames from 'classnames';
import { addMonths, format, getMonth, getYear, subMonths } from 'date-fns';
import moment from 'moment';
import { useState } from 'react';
import { SArrowButton } from './styles';

interface IBtnType {
  btnType: 'next' | 'prev';
}

interface IMonthlyCellProps {
  noOfMonth: number;
  month: string;
  year: number;
  monthNumber: string;
  disabled?: boolean;
}
interface IWeeklyCalendar {
  onSelect: ({ noOfMonth, month, year }: IMonthlyCellProps) => void;
}

export const MonthlyCalendar = ({ onSelect }: IWeeklyCalendar) => {
  const [currentDay, setCurrentDay] = useState<Date>(new Date());
  const [selectedMonth, setSelectedMonth] = useState<number>(
    getMonth(currentDay),
  );

  const handleNext = () => {
    setCurrentDay(addMonths(currentDay, 1));
    // setSelectedMonth(selectedMonth + 1);
  };
  const handleBack = () => {
    setCurrentDay(subMonths(currentDay, 1));
    // setSelectedMonth(selectedMonth - 1);
  };

  const MonthlyCell = ({
    noOfMonth,
    month,
    year,
    monthNumber,
    disabled,
  }: IMonthlyCellProps) => {
    return (
      <div
        onClick={() => {
          setSelectedMonth(noOfMonth);
          onSelect({ noOfMonth, month, year, monthNumber });
        }}
        className={classNames(
          'flex flex-col px-2 py-3 rounded-md cursor-pointer',
          {
            'text-white bg-primary ': selectedMonth === noOfMonth,
          },
        )}
      >
        {/* <div className="text-center font-normal"> Month {noOfMonth + 1}</div> */}
        <div className="text-center text-xs font-normal">{month}</div>
        <div className="text-center text-xs font-normal">{year}</div>
      </div>
    );
  };

  const MonthlyCells = () =>
    [-4, -3, -2, -1, 0].map((i) => {
      if (i < 0) {
        const noOfMonth = subMonths(currentDay, Math.abs(i));
        return (
          <MonthlyCell
            noOfMonth={getMonth(noOfMonth)}
            monthNumber={format(noOfMonth, 'MM')}
            month={format(noOfMonth, 'MMMM')}
            year={getYear(noOfMonth)}
          />
        );
      } else if (i > 0) {
        const noOfMonth = addMonths(currentDay, i);
        return (
          <MonthlyCell
            noOfMonth={getMonth(noOfMonth)}
            monthNumber={format(noOfMonth, 'MM')}
            month={format(noOfMonth, 'MMMM')}
            year={getYear(noOfMonth)}
            disabled={true}
          />
        );
      }
      return (
        <MonthlyCell
          noOfMonth={getMonth(currentDay)}
          monthNumber={format(currentDay, 'MM')}
          month={format(currentDay, 'MMMM')}
          year={getYear(currentDay)}
        />
      );
    });

  return (
    <div className="flex bg-white rounded-md p-3 border items-center">
      <div className="pr-2">
        <IconButton
          iconProps={{ iconName: 'NavigateBack' }}
          styles={SArrowButton}
          onClick={handleBack}
        />
      </div>
      <div className="flex justify-between gap-2">{MonthlyCells()}</div>
      <div className="pl-2">
        <IconButton
          iconProps={{ iconName: 'NavigateBackMirrored' }}
          styles={SArrowButton}
          onClick={handleNext}
          disabled={moment().isBefore(currentDay)}
        />
      </div>
    </div>
  );
};
