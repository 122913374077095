const HorizontalLine = () => {
  return (
    <div>
      <svg
        width="86vw"
        height="1"
        viewBox="0 0 90vw 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="0.5"
          x2="1360"
          y2="0.5"
          stroke="url(#paint0_linear_242_5188)"
          stroke-opacity="0.4"
        />
        <defs>
          <linearGradient
            id="paint0_linear_242_5188"
            x1="1360"
            y1="1"
            x2="0"
            y2="1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#032167" stop-opacity="0.1" />
            <stop offset="0.52472" stop-color="#032167" stop-opacity="0.8" />
            <stop offset="1" stop-color="#032167" />
            <stop offset="1" stop-color="#00294D" stop-opacity="0.1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default HorizontalLine;
