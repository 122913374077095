import {
  IButton,
  IRefObject,
  ITextField,
  Link as LinkComponent,
  Stack,
  Text,
} from '@fluentui/react';
import {
  Form,
  PrimaryButtonWithLoader,
  STextFieldStyle,
  TextField,
} from '@worx.squad/shared-frontend';
import { createRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { loginSchema } from './login.schema';

const LoginForm = ({
  onSubmit,
  fetching,
}: {
  onSubmit: any;
  fetching: any;
}) => {
  const passwordFieldRef = createRef<ITextField>();
  const loginButtonRef = createRef<IButton>();
  const loginForm = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
    resolver: loginSchema,
  });

  return (
    <Form formHook={loginForm} onSubmit={onSubmit}>
      <Stack
        verticalAlign="center"
        verticalFill
        tokens={{ childrenGap: 10 }}
        className="w-[100vw] lg:w-96 px-6"
      >
        <Stack.Item className="pb-5 mx-auto">
          <Text variant="xxLargePlus">Organization Login</Text>
        </Stack.Item>
        <Stack.Item grow>
          <TextField
            required
            type="email"
            name="email"
            formHook={loginForm}
            styles={STextFieldStyle}
            label="Email ID"
            onKeyDown={async (e) => {
              if (e.code === 'Enter') {
                passwordFieldRef?.current?.focus();
              }
            }}
          />
        </Stack.Item>
        <Stack.Item grow>
          <TextField
            componentRef={passwordFieldRef}
            required
            type="password"
            name="password"
            formHook={loginForm}
            styles={STextFieldStyle}
            label="Password"
            revealPasswordAriaLabel="Reveal Password"
            canRevealPassword
            onKeyDown={async (e) => {
              if (e.code === 'Enter') {
                loginButtonRef?.current?.focus();
              }
            }}
          />
        </Stack.Item>
        <Stack.Item grow>
          <Stack horizontal horizontalAlign="end">
            <Stack.Item>
              <Link to="/forgot-password">
                <LinkComponent>
                  <Text className="text-blue-500">Forgot Password?</Text>
                </LinkComponent>
              </Link>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item grow>
          <Stack
            className="mt-5 flex flex-col sm:flex-row"
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack.Item>
              <Link to="/register">
                <LinkComponent>
                  <Text className="text-sm">Don't have an account?</Text>{' '}
                  <Text
                    className="text-sm font-semibold"
                    style={{ color: '#175e74' }}
                  >
                    Sign Up
                  </Text>
                </LinkComponent>
              </Link>
            </Stack.Item>
            <Stack.Item className="w-full sm:w-auto mt-10 sm:mt-0">
              <PrimaryButtonWithLoader
                loading={fetching}
                text="Login"
                onClick={loginForm.handleSubmit(onSubmit)}
                componentRef={loginButtonRef as IRefObject<IButton>}
                className="w-full sm:w-auto"
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Form>
  );
};

export default LoginForm;
