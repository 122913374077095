import { Modal, PrimaryButton, Stack } from '@fluentui/react';
import { VscClose } from 'react-icons/vsc';

interface IModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  children?: React.ReactNode;
  specificSkillInfo: any;
}

const SpecificSkillInfoPopup = (props: IModalProps) => {
  return (
    <Modal {...props}>
      <Stack
        className="p-6 pt-0 overflow-hidden"
        style={{ width: 700, height: 420 }}
      >
        <div>
          <div className="flex justify-between items-center py-3 sticky top-0 bg-white z-10">
            <span>
              <label className="text-[#201F1E] text-xl leading-7 font-semibold">
                {props.specificSkillInfo?.name}
              </label>
            </span>
            <span
              onClick={props.onDismiss}
              className="flex justify-end cursor-pointer"
            >
              <VscClose size={25} />
            </span>
          </div>
          <div className="flex flex-col gap-y-4 pb-3 h-[300px] overflow-y-auto">
            {props.specificSkillInfo?.skills_details?.map(
              (info: any, infoIdx: number) => (
                <div className="flex flex-col gap-y-1" key={infoIdx}>
                  <label className="text-sm font-semibold">
                    Rating {info.rating}
                  </label>
                  {info.description === '' ? (
                    <label className="font-normal text-sm text-[#605E5C]">
                      No description added
                    </label>
                  ) : (
                    <label className="font-normal text-sm">
                      {info.description}
                    </label>
                  )}
                </div>
              ),
            )}
          </div>
          <div className="bg-white pr-0 p-4 pb-6 absolute w-[92%] flex justify-end bottom-0">
            <PrimaryButton
              text="Close"
              onClick={props.onDismiss}
              style={
                {
                  // flexWrap: 'wrap',
                }
              }
            />
          </div>
        </div>
      </Stack>
    </Modal>
  );
};

export default SpecificSkillInfoPopup;
