import { PrimaryButton, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useHeader, useLayoutState } from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect } from 'react';
import ClientsList from '../../components/TaskManagement/ClientsList';
import NewClientModal from '../../components/TaskManagement/NewClientModal';
import NewProjectModal from '../../components/TaskManagement/NewProjectModal';
import ProjectsList from '../../components/TaskManagement/ProjectsList';

const ProjectsAndClients = () => {
  const [newProjectVisible, { toggle: toggleNewProjects }] = useBoolean(false);
  const [newClientVisible, { toggle: toggleNewClient }] = useBoolean(false);

  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();

  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Projects and Clients');
  }, [setTitle]);

  return (
    <div className="flex flex-col gap-6 max-w-4xl">
      <div className="flex flex-col gap-3 max-w-xl">
        <div className="flex justify-between w-full">
          <Text className="text-2xl font-semibold">Clients</Text>
          <PrimaryButton
            iconProps={{ iconName: 'Add' }}
            onClick={toggleNewClient}
          >
            Add new client
          </PrimaryButton>
          <NewClientModal
            isOpen={newClientVisible}
            onDismiss={toggleNewClient}
          />
        </div>
        <ClientsList dependencies={[newProjectVisible, newClientVisible]} />
      </div>
      <div className="flex flex-col gap-3">
        <hr />
        <div className="flex justify-between w-full">
          <Text className="text-2xl font-semibold">Projects</Text>
          <PrimaryButton
            iconProps={{ iconName: 'Add' }}
            onClick={toggleNewProjects}
          >
            Add new project
          </PrimaryButton>
          <NewProjectModal
            isOpen={newProjectVisible}
            onDismiss={toggleNewProjects}
          />
        </div>
        <ProjectsList dependencies={[newProjectVisible, newClientVisible]} />
      </div>
    </div>
  );
};

export default ProjectsAndClients;
