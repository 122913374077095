import { FC, useEffect, useState } from 'react';

interface IWorkingImage
  extends Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    'src'
  > {
  imageURL: string;
}

export const WorkingImage: FC<IWorkingImage> = ({ imageURL, ...props }) => {
  const [logoWorking, setLogoWorking] = useState(false);

  useEffect(() => {
    if (imageURL) {
      const request = new XMLHttpRequest();
      request.open('GET', imageURL, true);
      request.send();
      request.onload = function () {
        if (request.status === 200) {
          setLogoWorking(true);
        } else {
          setLogoWorking(false);
        }
      };
    }
  }, [imageURL]);

  if (logoWorking) {
    return <img {...props} src={imageURL} alt="Organization Logo" />;
  }
  return null;
};
