import { MessageBarType } from '@fluentui/react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import {
  Dialog,
  DialogFooter,
  DialogType,
  IDialogProps,
} from '@fluentui/react/lib/Dialog';
import {
  PrimaryButtonWithLoader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { useDeleteAnnouncementMutation } from './announcement.generated';

const modalProps = {
  isBlocking: true,
};
const dialogContentProps = {
  type: DialogType.normal,
  title: 'Delete Announcement?',
  subText: 'Are you sure you want to delete the following announcement?',
};

interface IAnnouncementDeleteProps
  extends Omit<IDialogProps, 'modalProps' | 'dialogContentProps'> {
  announcementId: string | undefined;
  announcementMessage: string | undefined;
}

const AnnouncementDelete = ({
  announcementId,
  announcementMessage,
  ...props
}: IAnnouncementDeleteProps) => {
  const [, deleteAnnouncement] = useDeleteAnnouncementMutation();
  const { notification } = useNotification();

  const [loading, setLoading] = useState(false);

  const deleteAction = async () => {
    try {
      setLoading(true);
      if (!announcementId) throw new Error('No announcement ID');
      const res = await deleteAnnouncement({
        announcementId,
      });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
        return;
      }
      notification({
        message: 'Announcement deleted!',
        type: MessageBarType.success,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      props.onDismiss?.();
      window.dispatchEvent(new CustomEvent('refetch-announcements'));
    }
  };

  return (
    <Dialog
      {...props}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      {announcementMessage}
      <DialogFooter>
        <PrimaryButtonWithLoader
          loading={loading}
          onClick={deleteAction}
          text="Delete"
        />
        <DefaultButton onClick={() => props.onDismiss?.()} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};

export default AnnouncementDelete;
