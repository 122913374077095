import { Link } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useLayoutState, useQueryString } from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect } from 'react';
import DownloadApp from '../components/Home/DownloadApp';

const OpenApp = () => {
  const { search } = useQueryString();

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  useEffect(() => {
    window.location?.replace(`worx-squad://open-app?${search.toString()}`);
  }, []);
  return (
    <div className="h-screen w-full flex flex-col justify-center align-middle items-center">
      <Link onClick={showModal}>Dont have Worx Squad? Download it here</Link>
      <DownloadApp
        showModal={showModal}
        isModalOpen={isModalOpen}
        hideModal={hideModal}
      />
    </div>
  );
};

export default OpenApp;
