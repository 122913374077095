import { ITextFieldProps, TextField as MainTextField } from '@fluentui/react';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

export interface TextFieldProps extends ITextFieldProps {
  formHook: UseFormReturn<any>;
  name: string;
}

export const TextField: FC<TextFieldProps> = ({ formHook, name, ...props }) => {
  const { control } = formHook;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <MainTextField
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          errorMessage={error && error.message}
          {...props}
        />
      )}
    />
  );
};
