import {
  IconButton,
  Link,
  MessageBarType,
  Modal,
  Stack,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useNotification } from '@worx.squad/shared-frontend';
import { useState } from 'react';
import NewProjectForm from './NewProjectForm';
import { ProjectsData } from './ProjectsList';
import { useUpdateProjectMutation } from './projects-and-clients.generated';

const EditProject = ({ project }: { project: ProjectsData }) => {
  const [, updateProject] = useUpdateProjectMutation();
  const { notification } = useNotification();
  const [editModalOpen, { toggle: toggleEditModal }] = useBoolean(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Link onClick={toggleEditModal}>Edit</Link>
      <Modal isOpen={editModalOpen}>
        <Stack className="p-4" style={{ minWidth: 400 }}>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Text variant="xLarge">Edit {project.title}</Text>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              onClick={toggleEditModal}
            />
          </Stack>
          <Stack>
            <NewProjectForm
              loading={loading}
              data={project}
              onSubmit={async (data) => {
                try {
                  setLoading(true);
                  const res = await updateProject({
                    projectId: project.id,
                    data: {
                      client_id: data?.client?.value,
                      description: data?.description,
                      is_billable: !data.nonBillable,
                      title: data?.projectTitle || '',
                    },
                  });

                  if (res.error) {
                    notification({
                      message: res.error.message
                        ?.replace('[GraphQL]', '')
                        .trim(),
                      type: MessageBarType.error,
                    });
                    setLoading(false);
                    return;
                  }
                  notification({
                    message: 'Project updated successfully',
                    type: MessageBarType.success,
                  });
                  toggleEditModal();
                  setLoading(false);
                } catch (error) {
                  console.error(error);
                  setLoading(false);
                }
              }}
              onCancel={toggleEditModal}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default EditProject;
