const ProjectManagement = ({ isWhite }: any) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3.23047C6 1.98783 7.00736 0.980469 8.25 0.980469H9.75C10.9926 0.980469 12 1.98783 12 3.23047V19.0005C10.0251 19.0005 8.0084 19.0005 6 19.0005V3.23047ZM4.5 8.00047H2.25C1.00736 8.00047 0 9.00782 0 10.2505V18.2505C0 18.6647 0.335786 19.0005 0.75 19.0005H4.5V8.00047ZM13.5 19.0005H17.25C17.6642 19.0005 18 18.6647 18 18.2505V7.25047C18 6.00782 16.9926 5.00047 15.75 5.00047H13.5V19.0005Z"
        fill={isWhite ? 'white' : '#185D74'}
      />
    </svg>
  );
};

export default ProjectManagement;
