import { Spinner, SpinnerSize } from '@fluentui/react';
import React, { useState } from 'react';

const FigmaEmbed = React.memo(() => {
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 7000);
  };

  return (
    <div className="w-full h-[100vh] m-0 p-0 relative">
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white">
          <Spinner
            size={SpinnerSize.large}
            label="Loading Figma Prototype..."
          />
        </div>
      )}

      <iframe
        className="border-0 w-full h-[100vh]"
        src="https://embed.figma.com/proto/fSkh9C5PPirehhhPiUYlaL/Main?page-id=0%3A1&node-id=1348-78680&node-type=frame&viewport=-8553%2C-20295%2C0.62&scaling=scale-down&content-scaling=fixed&starting-point-node-id=1348%3A78680&share=1&embed-host=share&hide-ui=1"
        allowFullScreen
        onLoad={handleIframeLoad}
      ></iframe>
    </div>
  );
});

export default FigmaEmbed;
