import { SVGProps } from 'react';

export const AppleLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#000',
        fillOpacity: 1,
      }}
      d="M16.125 1c-1.152.066-2.477.71-3.266 1.527-.71.743-1.27 1.848-1.043 2.918 1.254.032 2.512-.625 3.266-1.457.703-.781 1.234-1.867 1.043-2.988Zm.07 4.441c-1.812 0-2.566 1.114-3.82 1.114-1.29 0-2.469-1.04-4.027-1.04C6.227 5.516 3 7.48 3 12.11 3 16.324 6.816 21 8.973 21c1.308.012 1.625-.824 3.402-.832 1.777-.012 2.16.844 3.473.832 1.476-.012 2.629-1.633 3.468-2.918.606-.922.856-1.39 1.32-2.43-3.472-.879-4.16-6.48 0-7.64-.784-1.34-3.077-2.57-4.445-2.57Zm0 0"
    />
  </svg>
);
