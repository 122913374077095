import {
  DatePicker,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Separator,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';
import { Pagination } from '@fluentui/react-experiments';
import {
  CountCard,
  ICountCard,
  SearchableSelect,
} from '@worx.squad/shared-frontend';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHotDesk } from './HotDeskingHook';
import {
  GetLocationBookingsQuery,
  GetOrganizationLocationsDetailsQuery,
  useGetLocationBookingsQuery,
} from './hot-desking.generated';

interface ILocationOptions {
  value: GetOrganizationLocationsDetailsQuery['organization_locations'][0]['id'];
  label: GetOrganizationLocationsDetailsQuery['organization_locations'][0]['title'];
}

interface IBooking {
  index: number;
  id: GetLocationBookingsQuery['hotdesk_bookings'][0]['user']['id'];
  name: GetLocationBookingsQuery['hotdesk_bookings'][0]['user']['display_name'];
}

const bookingsColumn: IColumn[] = [
  {
    key: 'No',
    name: 'No',
    minWidth: 10,
    maxWidth: 30,
    isResizable: true,
    fieldName: 'index',
    className: 'text-black',
  },
  {
    key: 'employeeName',
    name: 'Employee name',
    minWidth: 100,
    isResizable: true,
    isRowHeader: true,
    fieldName: 'name',
    className: 'text-black',
  },
];

const SeatsAndBookings = () => {
  const { locationName, locationTitlesRaw, organization, setLocationName } =
    useHotDesk();

  const refSelectContainer = useRef(null);
  const PER_PAGE_COUNT = 10;
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);

  const [locationOptions, setLocationOptions] = useState<ILocationOptions[]>(
    [],
  );
  const [selectedLocation, setSelectedLocation] = useState<ILocationOptions>();
  const [date, setDate] = useState(moment());
  const [bookings, setBookings] = useState<IBooking[]>([]);

  const [{ data: locationBookingsRaw, fetching: fetchingLocationBookings }] =
    useGetLocationBookingsQuery({
      variables: {
        organizationId: organization?.id,
        date: date.format('YYYY-MM-DD'),
        locationId: selectedLocation?.value,
        limit: PER_PAGE_COUNT,
        offset: offset,
      },
      pause: !organization?.id,
      requestPolicy: 'cache-and-network',
    });

  const paginate = (index: number) => {
    setOffset(index * PER_PAGE_COUNT);
    setCurrentPage(index + 1);
  };

  useEffect(() => {
    if (locationBookingsRaw?.hotdesk_bookings) {
      setBookings(
        locationBookingsRaw.hotdesk_bookings.map((bookings, index) => ({
          id: bookings.user.id,
          index: index + 1 * currentPage,
          name: bookings.user.display_name,
        })),
      );
      setTotalPages(
        Math.ceil(
          (locationBookingsRaw?.organization_locations[0].booked_count.aggregate
            ?.count || 1) / PER_PAGE_COUNT,
        ),
      );
    }
  }, [locationBookingsRaw]);

  useEffect(() => {
    if (locationTitlesRaw?.organization_locations) {
      const data = locationTitlesRaw.organization_locations.map((location) => ({
        value: location.id,
        label: location.title,
      }));
      setLocationOptions(data);
    }
  }, [locationTitlesRaw]);

  const totalSeats =
    locationBookingsRaw?.organization_locations[0].total_seats || 0;
  const totalBookedSeats =
    locationBookingsRaw?.organization_locations[0].booked_count.aggregate
      ?.count || 0;

  interface ICardData extends ICountCard {
    key: string;
  }

  const cardData: ICardData[] = [
    {
      key: 'total',
      number: totalSeats,
      subText: 'Total seats',
      color: 'bg-green-500',
    },
    {
      key: 'active',
      number: totalBookedSeats,
      subText: 'Seats Booked',
      color: 'bg-blue-500',
    },
    {
      key: 'inactive',
      number: totalSeats - totalBookedSeats,
      subText: 'Seats Available',
      color: 'bg-yellow-500',
    },
  ];

  return (
    <Stack tokens={{ childrenGap: 30 }}>
      <Stack tokens={{ childrenGap: 10 }} className="w-96">
        <div ref={refSelectContainer}>
          <SearchableSelect
            id="location-select"
            defaultValue={locationOptions[0]}
            onInputChange={setLocationName}
            inputValue={locationName}
            options={locationOptions}
            value={selectedLocation}
            onChange={(value) => setSelectedLocation(value as ILocationOptions)}
            placeholder="Search or select office location"
            label="Office"
            menuShouldBlockScroll={true}
          />
        </div>
        <Stack.Item>
          <DatePicker
            value={date.toDate()}
            label="Date"
            onSelectDate={(date) => {
              setDate(moment(date));
            }}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 30 }}>
        {cardData.map((data, index) => (
          <Stack.Item key={index}>
            <CountCard
              color={data.color}
              number={data.number || 0}
              subText={data.subText}
            />
          </Stack.Item>
        ))}
      </Stack>
      {bookings.length > 0 ? (
        <>
          <ShimmeredDetailsList
            items={bookings}
            columns={bookingsColumn}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            enableShimmer={fetchingLocationBookings}
          />
          <hr className="my-2" />
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={totalPages}
            totalItemCount={totalBookedSeats}
            itemsPerPage={PER_PAGE_COUNT}
            onPageChange={paginate}
            format="buttons"
            numberOfPageButton={4}
          />
        </>
      ) : (
        <>
          <Separator className="font-black" />
          <Text className="text-2xl ">No bookings available</Text>
          <Separator />
        </>
      )}
    </Stack>
  );
};

export default SeatsAndBookings;
