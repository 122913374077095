import { Panel, PanelType } from '@fluentui/react';
import { FC } from 'react';
import AddLocationForm from './AddLocationForm';

interface IAddLocationPanel {
  isModalOpen: boolean;
  hideModal: () => void;
  edit?: boolean;
  locationId?: string;
}

const AddLocationPanel: FC<IAddLocationPanel> = ({
  isModalOpen,
  hideModal,
  edit,
  locationId,
}) => {
  const onCancel = () => {
    hideModal();
  };
  return (
    <Panel
      type={PanelType.medium}
      isOpen={isModalOpen}
      onDismiss={onCancel}
      headerText={edit ? 'Edit Location' : 'Add new location'}
    >
      <div className="p-5">
        <AddLocationForm
          edit={edit}
          onCancel={onCancel}
          locationId={locationId}
        />
      </div>
    </Panel>
  );
};

export default AddLocationPanel;
