import { IconButton, Modal, Text } from '@fluentui/react';
import { OperationContext } from '@urql/core';
import { FC } from 'react';
import EmployeeAddForm from './EmployeeAddForm';

interface IEmployeeAddModal {
  id?: string;
  isModalOpen: boolean;
  hideModal: () => void;
  refetch: (opts?: Partial<OperationContext>) => void;
}

const EmployeeAddModal: FC<IEmployeeAddModal> = ({
  id,
  isModalOpen,
  hideModal,
  refetch,
}) => {
  const onCancel = () => {
    hideModal();
    refetch();
  };
  return (
    <Modal isOpen={isModalOpen} onDismiss={onCancel}>
      <div className="p-5" style={{ minWidth: 600 }}>
        <div className="flex  justify-between mb-6">
          <div>
            <Text className="text-2xl">{id ? 'Edit' : 'Add new'} employee</Text>
          </div>
          <div>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={hideModal}
            />
          </div>
        </div>
        <div>
          <EmployeeAddForm id={id} onCancel={onCancel} />
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeAddModal;
