import {
  DatePickerField,
  Form,
  PrimaryButtonWithLoader,
  TextField,
} from '@worx.squad/shared-frontend';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { HolidayFormValues } from './holiday.types';

const HolidayForm: FC<{
  form: UseFormReturn<HolidayFormValues>;
  onSubmit: (values: HolidayFormValues) => void;
  loading: boolean;
  buttonText: string;
}> = ({ onSubmit, form, loading, buttonText }) => {
  return (
    <Form onSubmit={onSubmit} formHook={form}>
      <div className="flex flex-col gap-3">
        <TextField formHook={form} name="name" label="Name of Holiday" />
        <TextField
          formHook={form}
          name="description"
          label="Description"
          multiline
        />
        <DatePickerField formHook={form} name="date" label="Date" />
        <div className="flex justify-end">
          <PrimaryButtonWithLoader loading={loading} type="submit">
            {buttonText}
          </PrimaryButtonWithLoader>
        </div>
      </div>
    </Form>
  );
};

export default HolidayForm;
