import { Image, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import {
  PrimaryButtonWithLoader,
  dispatchListeners,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import { useInsertOrganizationAwardMutation } from './awards.generated';
interface IAwardsInitializeScreen {
  orgId: string | undefined | null;
  loading: boolean;
}
const AwardsInitializeScreen = ({
  orgId,
  loading,
}: IAwardsInitializeScreen) => {
  const listener = ['refetch-initialize-awards'];
  const org = useOrganizationContext();
  const { notification } = useNotification();
  const [{ fetching }, initializeAwards] = useInsertOrganizationAwardMutation();
  const handleInitialize = async () => {
    const res = await initializeAwards({
      object: { organization_id: orgId },
    });
    if (res.data) {
      notification({
        type: MessageBarType.success,
        message: 'Awards Interface Successfully initiated',
      });
      dispatchListeners(listener);
    }
    if (res.error) {
      notification({
        type: MessageBarType.error,
        message: 'Oops something went wrong!',
      });
    }
  };
  return (
    <>
      <div className="flex flex-col h-auto items-center mt-12">
        {loading && <Spinner size={SpinnerSize.medium} />}
        <Image src="assets/NoDataFound.png" />
        <p className="text-sm font-semibold text-black mt-4">
          No awards added yet!
        </p>
        <p className="text-sm font-normal text-black mt-2 mb-4 w-80 text-center">
          You need to initialize the awards interface to start assigning award
          types
        </p>
        <PrimaryButtonWithLoader
          loading={fetching}
          onClick={handleInitialize}
          text="Initialize"
        />
      </div>
    </>
  );
};

export default AwardsInitializeScreen;
