import { ISeparatorStyles, IStackStyles } from '@fluentui/react';

export const SeparatorStyles: Partial<ISeparatorStyles> = {
  root: {
    padding: '0px',
    margin: '5px 0 5px 0',
    fontSize: 1,
    background: '#ccc',
    minWidth: 110,
  },
};

export const TimeCardBorderRadius: Partial<IStackStyles> = {
  root: {
    borderRadius: '5px',
  },
};

export const TasksCardStyles: Partial<IStackStyles> = {
  root: {
    borderWidth: '1px',
    borderRadius: '10px',
    backgroundColor: '#F2F2F2',
    padding: '15px',
  },
};
