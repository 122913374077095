import {
  ActionButton,
  IContextualMenuProps,
  Persona,
  PersonaSize,
  Stack,
} from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import { useAuth } from '@worx.squad/hbp-react';
import { Logo, useGetUserByIdQuery } from '../..';
import { getFileUrl, getUserActivityStatus } from '../../function';
import { useLogout } from '../../hooks/logout.hook';

export const WelcomeLayout: React.FC = ({ children }) => {
  const { user } = useAuth();

  const logout = useLogout();

  // TODO: Resolve Status Change
  const menuProps = useConst<IContextualMenuProps>(() => ({
    shouldFocusOnMount: true,
    items: [
      {
        key: 'logout',
        iconProps: { iconName: 'SignOut' },
        text: 'Logout',
        onClick: () => {
          logout();
        },
      },
    ],
  }));

  const [{ data: userData }] = useGetUserByIdQuery({
    variables: { Id: user?.id },
    requestPolicy: 'cache-and-network',
    pause: !user?.id,
  });
  return (
    <Stack
      verticalFill
      className="bg-white m-3 rounded-md shadow-md relative"
      styles={{
        root: {
          minHeight: 'calc(100vh - 24px)',
        },
      }}
    >
      <Stack.Item className="p-6 w-full">
        <Stack horizontal className=" float-right" verticalAlign="center">
          <ActionButton onRenderMenuIcon={() => null} menuProps={menuProps}>
            <Persona
              size={PersonaSize.size40}
              text={user?.display_name}
              hidePersonaDetails
              imageUrl={getFileUrl(
                userData?.user?.profile?.[0]?.avatar_file?.file_url,
              )}
              {...getUserActivityStatus(
                userData?.user?.profile[0]?.activity_status,
              )}
            />
          </ActionButton>
        </Stack>
      </Stack.Item>
      <Stack.Item className="w-44 absolute bottom-4 left-16">
        <Logo />
      </Stack.Item>
      <Stack.Item>{children}</Stack.Item>
    </Stack>
  );
};
