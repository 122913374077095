import { useHeader, useLayoutState } from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect } from 'react';
import AdministratorsList from '../../components/Settings/Administrators/AdministratorsList';

const ManageAdministrators = () => {
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();

  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Manage Administrators');
  }, [setTitle]);

  return (
    <div className="flex flex-col gap-10">
      <div className="max-w-5xl">
        <AdministratorsList />
      </div>
    </div>
  );
};
export default ManageAdministrators;
