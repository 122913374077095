import ProjectManagement from '../Home/assets/ProjectManagement';
import ControlImg from './assets/ControlImg.png';
import GoalsImg from './assets/GoalsImg.png';
import InsightImg from './assets/InsightImg.png';

const GoalsAndPerformance = () => {
  return (
    <div className="px-20">
      <div className="flex gap-8">
        <div className="">
          <div className="flex items-center gap-2">
            <span>
              <ProjectManagement />
            </span>
            <span className="font-medium">Goals & Performance</span>
          </div>
          <div className="text-4xl font-medium mt-2">
            Track and achieve your performance goals
          </div>
          <div className="mt-8 text-base">
            Employees can easily fill timesheets with intuitive UX and Managers
            will be alert for approval
          </div>
        </div>
        <div className="w-[60%]">
          <img src={GoalsImg} alt="Goals img" />{' '}
        </div>
      </div>
      <div className="flex justify-between mt-8">
        <div className="w-[60%]">
          <img src={InsightImg} alt="InsightImg" />
        </div>
        <div className="w-[32%]">
          <img src={ControlImg} alt="ControlImg" />
        </div>
      </div>
    </div>
  );
};

export default GoalsAndPerformance;
