import { DetailedHTMLProps, FormHTMLAttributes } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

export interface FormProps
  extends DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  formHook: UseFormReturn<any>;
  onSubmit: (values: any) => void;
}

export const Form: React.FC<FormProps> = ({
  children,
  formHook,
  onSubmit,
  ...props
}) => {
  const { handleSubmit } = formHook;

  return (
    <FormProvider {...formHook}>
      <form onSubmit={handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};
