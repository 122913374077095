import {
  IconButton,
  Modal,
  TextField as NormalTextField,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import { useAuth } from '@worx.squad/hbp-react';
import {
  AddressForm,
  DatePickerField,
  DefaultButtonWithLoader,
  DropdownField,
  PrimaryButtonWithLoader,
  SCloseButton,
  SDatePickerStyle,
  SDatePickerTextFieldStyle,
  SDropdownStyle,
  STextAreaFieldStyle,
  STextFieldStyle,
  SUploadFieldStyle,
  TextField,
  TimezonePicker,
  UploadField,
} from '@worx.squad/shared-frontend';
import { RcFile } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import { uploadFile } from '../../../helpers/file-upload';
import { useInsertStorageFileMutation } from '../../../helpers/file.generated';
import {
  useGetOrganizationDetailsQuery,
  useUpdateAddressDetailsMutation,
  useUpdateOrganizationDetailsMutation,
} from './organization-edit.generated';
import editingOrganizationSchema from './organization-edit.schema';
import { IAddress } from './types';
import { hourPickerOptions, workingDays, workingHours } from './utils';

interface IOrganization {
  organization_name: string | undefined;
  organization_employee_no: number | undefined;
  logo_file?: RcFile[] | null;
  logo_file_id: string;
  org_start_time: string;
  org_end_time: string;
  working_hours: string | undefined;
  working_days: string | undefined;
  holiday_list_file?: RcFile[] | null;
  holiday_file_id: string;
  timezone_name: string | undefined;
  anniversary_date: Date | null;
  address: object | null | undefined;
}

export const OrganizationEditForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [newAddress, setNewAddress] = useState<IAddress>({} as IAddress);
  const [addressString, setAddressString] = useState<string>('');
  const [initialValues, setInitialValues] = useState<IOrganization>({
    organization_name: '',
    organization_employee_no: 0,
    logo_file_id: '',
    org_start_time: '',
    org_end_time: '',
    working_hours: '',
    working_days: '',
    holiday_list_file: null,
    holiday_file_id: '',
    timezone_name: '',
    anniversary_date: null,
    address: {},
  } as IOrganization);

  const { organization } = useOrganizationContext();
  const { user } = useAuth();

  const [{ data, fetching }, refetch] = useGetOrganizationDetailsQuery({
    variables: {
      organization_id: organization?.id,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  const orgDetails = data?.organization[0];

  const onAddressChange = (address: IAddress) => {
    setNewAddress(address);
    setVisible(false);
  };

  useEffect(() => {
    setAddressString(
      `${newAddress?.address_line},\n${newAddress?.state}, ${newAddress?.country},\nPinCode: ${newAddress?.pin_code}`,
    );
  }, [newAddress]);

  const organizationForm = useForm<any>({
    mode: 'all',
    resolver: editingOrganizationSchema,
    defaultValues: initialValues,
  });

  const { handleSubmit, reset } = organizationForm;

  useEffect(() => {
    const anniversaryDate = new Date(orgDetails?.anniversary_date);
    const address = orgDetails?.address;
    setAddressString(
      `${address?.address_line},\n${address?.state}, ${address?.country},\nPinCode: ${address?.pin_code}`,
    );
    setInitialValues({
      organization_name: orgDetails?.organization_name,
      organization_employee_no:
        orgDetails?.organization_employees_aggregate?.aggregate?.count,
      logo_file_id: orgDetails?.logo_file_id,
      org_start_time: orgDetails?.org_start_time,
      org_end_time: orgDetails?.org_end_time,
      working_hours: orgDetails?.working_hours.toString(),
      working_days: orgDetails?.working_days.toString(),
      holiday_file_id: orgDetails?.holiday_file_id,
      timezone_name: orgDetails?.timezone_name,
      anniversary_date: anniversaryDate,
      address: address,
    });
  }, [orgDetails]);

  useEffect(() => reset(initialValues), [initialValues]);

  const [, UpdateOrganizationDetailsDocument] =
    useUpdateOrganizationDetailsMutation();

  const [, insertFile] = useInsertStorageFileMutation();
  const [, UpdateAddressDetailsDocument] = useUpdateAddressDetailsMutation();

  const onUploadLogo = async (file: RcFile) => {
    if (file) {
      const { id } = await uploadFile({
        file,
        insertFile,
        path: `/public/organization/${organization?.id}/logo/logo_${uuid()}_${
          file?.name
        }`,
        data: { created_by: user?.id + '', type: 'IMAGE' },
      });
      return id;
    }
    return null;
  };
  const onUploadHolidayList = async (file: RcFile) => {
    if (file) {
      const { id } = await uploadFile({
        file,
        insertFile,
        path: `/public/organization/${
          organization?.id
        }/holiday/holiday_${uuid()}_${file?.name}`,
        data: { created_by: user?.id + '', type: 'IMAGE' },
      });
      return id;
    }
    return null;
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    const logo_file_id = await onUploadLogo(data?.logo_file?.[0]);
    const holiday_file_id = await onUploadHolidayList(
      data?.holiday_list_file?.[0],
    );
    try {
      const { data: addressData } = await UpdateAddressDetailsDocument({
        data: newAddress,
        addressID: orgDetails?.address?.id,
      });

      const { data: OrgData } = await UpdateOrganizationDetailsDocument({
        data: {
          organization_name: data.organization_name,
          logo_file_id: logo_file_id ? logo_file_id : orgDetails?.logo_file_id,
          org_start_time: data.org_start_time,
          org_end_time: data.org_end_time,
          working_hours: data.working_hours,
          working_days: data.working_days,
          holiday_file_id: holiday_file_id
            ? holiday_file_id
            : orgDetails?.holiday_file_id,
          timezone_name: data.timezone_name,
          anniversary_date: data.anniversary_date,
        },
        organizationID: organization?.id,
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onReset = () => {
    const anniversaryDate = new Date(orgDetails?.anniversary_date);
    const address = orgDetails?.address;
    setAddressString(
      `${address?.address_line},\n${address?.state}, ${address?.country},\nPinCode: ${address?.pin_code}`,
    );
    setInitialValues({
      organization_name: orgDetails?.organization_name,
      organization_employee_no:
        orgDetails?.organization_employees_aggregate?.aggregate?.count,
      logo_file_id: orgDetails?.logo_file_id,
      org_start_time: orgDetails?.org_start_time,
      org_end_time: orgDetails?.org_end_time,
      working_hours: orgDetails?.working_hours.toString(),
      working_days: orgDetails?.working_days.toString(),
      holiday_file_id: orgDetails?.holiday_file_id,
      timezone_name: orgDetails?.timezone_name,
      anniversary_date: anniversaryDate,
      address: orgDetails?.address,
    });
  };

  return (
    <Stack className="w-full my-2">
      <Stack verticalFill className="w-full">
        <Stack.Item>
          <Stack verticalFill tokens={{ childrenGap: 5 }}>
            <Stack.Item grow className="pt-2">
              <TextField
                required
                disabled={loading}
                formHook={organizationForm}
                placeholder="New organization name"
                name="organization_name"
                label="Organization Name"
                styles={STextFieldStyle}
              />
            </Stack.Item>

            <Stack.Item grow>
              <UploadField formHook={organizationForm} name="logo_file">
                <NormalTextField
                  disabled={loading}
                  value={organizationForm.watch('logo_file')?.[0]?.name}
                  label="Logo"
                  styles={SUploadFieldStyle}
                  suffix="Upload"
                  placeholder="Upload logo here"
                  description="Only jpg, png and svg files allowed"
                />
              </UploadField>
            </Stack.Item>

            <Stack horizontal tokens={{ childrenGap: 25 }}>
              <Stack.Item className="w-full">
                <DropdownField
                  className="w-full"
                  disabled={loading}
                  formHook={organizationForm}
                  required
                  name="org_start_time"
                  label="Office Start Hour"
                  placeholder="Select office start time"
                  ariaLabel="Select a date"
                  styles={SDropdownStyle}
                  calloutProps={{
                    calloutMaxHeight: 300,
                  }}
                  options={hourPickerOptions}
                />
              </Stack.Item>
              <Stack.Item className="w-full">
                <DropdownField
                  className="w-full"
                  disabled={loading}
                  formHook={organizationForm}
                  required
                  name="org_end_time"
                  label="Office End Hour"
                  placeholder="Select office end time"
                  ariaLabel="Select a date"
                  styles={SDropdownStyle}
                  options={hourPickerOptions}
                  calloutProps={{
                    calloutMaxHeight: 300,
                  }}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 25 }}>
              <Stack.Item className="w-full">
                <DropdownField
                  disabled={loading}
                  formHook={organizationForm}
                  name="working_hours"
                  required
                  label="Working Hours"
                  placeholder="Select office working hours"
                  options={workingHours}
                  styles={SDropdownStyle}
                  className="w-full"
                  calloutProps={{
                    calloutMaxHeight: 287,
                  }}
                />
              </Stack.Item>
              <Stack.Item className="w-full">
                <DropdownField
                  required
                  disabled={loading}
                  formHook={organizationForm}
                  name="working_days"
                  placeholder="Select office working days"
                  label="Working Days"
                  options={workingDays}
                  styles={SDropdownStyle}
                  className="w-full"
                  calloutProps={{
                    calloutMaxHeight: 287,
                  }}
                />
              </Stack.Item>
            </Stack>
            <Stack.Item grow>
              <TimezonePicker
                disabled={loading}
                formHook={organizationForm}
                placeholder="Select current timezone"
                name="timezone_name"
                label="Timezone"
                required
                styles={SDropdownStyle}
              />
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 25 }}>
              <Stack.Item grow>
                <UploadField
                  formHook={organizationForm}
                  name="holiday_list_file"
                >
                  <NormalTextField
                    disabled={loading}
                    value={
                      organizationForm.watch('holiday_list_file')?.[0]?.name
                    }
                    label="Holiday List"
                    placeholder="Upload holiday file here"
                    styles={SUploadFieldStyle}
                    suffix="Upload"
                    description="Only ics files allowed"
                  />
                </UploadField>
              </Stack.Item>
              <Stack.Item grow>
                <DatePickerField
                  disabled={loading}
                  formHook={organizationForm}
                  name="anniversary_date"
                  label="Organization anniversary"
                  placeholder="Select organization anniversary date"
                  ariaLabel="Select a date"
                  styles={SDatePickerStyle}
                  textField={{
                    styles: SDatePickerTextFieldStyle,
                  }}
                />
              </Stack.Item>
            </Stack>
            <Stack.Item grow>
              <NormalTextField
                label="HQ Address"
                disabled={loading}
                placeholder="Enter office address"
                styles={STextAreaFieldStyle}
                required
                multiline
                autoAdjustHeight
                value={addressString}
                onClick={() => setVisible(true)}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack
          horizontal
          horizontalAlign="start"
          verticalAlign="center"
          grow
          className="my-5"
        >
          <div className="space-x-2">
            <PrimaryButtonWithLoader
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              text="Save"
            />
            <DefaultButtonWithLoader
              loading={loading}
              onClick={() => onReset()}
              text="Reset"
            />
          </div>
        </Stack>
      </Stack>
      <Modal
        isOpen={visible}
        onDismiss={() => setVisible(false)}
        styles={{
          main: {
            borderRadius: 8,
          },
        }}
      >
        <>
          <Stack
            horizontal
            horizontalAlign="space-between"
            className="px-10 pt-10 pb-3"
          >
            <StackItem>
              <Text variant="xLarge">Enter New Address</Text>
            </StackItem>
            <StackItem>
              <IconButton
                iconProps={{ iconName: 'ChromeClose' }}
                styles={SCloseButton}
                onClick={() => setVisible(false)}
              />
            </StackItem>
          </Stack>
          <Stack verticalFill className="px-10 pb-10">
            <StackItem>
              <AddressForm onSubmit={onAddressChange} address={newAddress} />
            </StackItem>
          </Stack>
        </>
      </Modal>
    </Stack>
  );
};
