import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  Link,
  MessageBarType,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { Pagination } from '@fluentui/react-experiments';
import { useBoolean } from '@fluentui/react-hooks';
import {
  useHeader,
  useLayoutState,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDebounce } from 'use-debounce';
import AssessmentAddModal from '../components/AssessmentType/AssessmentAddModal';
import {
  useDeleteAssessmentTypeOrgAdminMutation,
  useLoadAssessmentTypeOrgAdminQuery,
  useUpdateAssessmentTypeMutation,
} from '../components/AssessmentType/assessmenttype.generated';
import { useOrganizationContext } from '../context/OrganizationContext/OrganizationContext';

interface AssessmentTypeData {
  assessmentType: string;
  metaTags: string;
  addedBy: string;
  active: string;
}

const AssessmentType = () => {
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();

  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Assessment Type');
  }, [setTitle]);

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const [hideEditDialog, { toggle: toggleEditHideDialog }] = useBoolean(true);

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Delete Assessment Type',
    closeButtonAriaLabel: 'Close',
    subText: 'Are you sure you want to delete this Assessment Type?',
  };

  const [assessmentTypeId, setAssessmentTypeId] = useState('');
  const [assessmentTypeStatus, setAssessmentTypeStatus] = useState('');
  const [
    addModalVisibility,
    { setTrue: showAddModal, setFalse: hideAddModal },
  ] = useBoolean(false);

  const dialogContentPropsEdit = {
    type: DialogType.normal,
    title: `${
      assessmentTypeStatus === 'Active' ? 'De-Activate' : 'Activate'
    } Assessment Type`,
    closeButtonAriaLabel: 'Close',
    subText: `Are you sure you want to ${
      assessmentTypeStatus === 'Active' ? 'De_Activate' : 'Activate'
    } this Assessment status?`,
  };

  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const [assessmentTypeData, setAssessmentTypeData] = useState<
    AssessmentTypeData[]
  >([]);

  const PER_PAGE_COUNT = 1;
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchText, setSearchText] = useState<string | undefined>('');
  const [searchTextLazy] = useDebounce(searchText, 500);

  const [, deleteTrainingType] = useDeleteAssessmentTypeOrgAdminMutation();

  const id: any = organization?.id;
  const [{ data: AssessmentType, fetching }, refetchTypes] =
    useLoadAssessmentTypeOrgAdminQuery({
      variables: {
        _eq: id,
        offset: offset,
        limit: PER_PAGE_COUNT,
        assessmentType: searchTextLazy ? `%${searchTextLazy}%` : undefined,
      },
      pause: !id,
      requestPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (AssessmentType?.assessment_type) {
      setAssessmentTypeData(
        AssessmentType?.assessment_type.map(
          (typeData, index) =>
            ({
              index: index + 1,
              addedBy: typeData.user?.display_name,
              metaTags: typeData.meta_tags,
              active: typeData.status === true ? 'Active' : 'InActive',
              assessmentType: typeData.name,
              id: typeData.id,
            }) as AssessmentTypeData,
        ),
      );
      setTotalPages(
        Math.ceil(
          (AssessmentType?.assessment_type_aggregate?.aggregate?.count || 1) /
            PER_PAGE_COUNT,
        ),
      );
    }
  }, [AssessmentType]);

  const [showSecondDiv, setShowSecondDiv] = useState(false);

  useEffect(() => {
    const today = new Date();
    const twoDaysAgo = new Date();
    twoDaysAgo.setMinutes(today.getMinutes() - 1);

    // Compare the date of twoDaysAgo with the current date
    // and set the state to show the second div accordingly
    if (today > twoDaysAgo) {
      setShowSecondDiv(true);
    } else {
      setShowSecondDiv(false);
    }
  }, []);

  const typesColumn: IColumn[] = [
    {
      name: 'Sr. No',
      key: 'srNo',
      minWidth: 40,
      maxWidth: 40,
      isResizable: true,
      fieldName: 'index',
      className: 'text-center text-black',
    },
    {
      name: 'Assessment Type',
      key: 'Assessment-type',
      minWidth: 200,
      maxWidth: 200,
      fieldName: `assessmentType`,
      isResizable: true,
      isRowHeader: true,
    },
    {
      name: 'Meta Tag',
      key: 'Meta-tag',
      minWidth: 100,
      fieldName: `metaTags`,
      isResizable: true,
      isRowHeader: true,
    },
    {
      name: 'Added By',
      key: 'added-by',
      minWidth: 100,
      fieldName: 'addedBy',
      isResizable: true,
      isRowHeader: true,
    },
    {
      name: 'Status',
      key: 'Status',
      minWidth: 100,
      fieldName: 'active',
      isResizable: true,
      className: 'text-black',
    },
    {
      key: 'action',
      name: 'Action',
      fieldName: 'id',
      minWidth: 180,
      maxWidth: 180,
      isResizable: true,
      data: 'number',
      isPadded: true,
      onRender: (item) => {
        return (
          <div className="flex justify-between items-center">
            <div>
              <Link
                className="px-1"
                onClick={() => {
                  setAssessmentTypeId(item.id);
                  toggleHideDialog();
                }}
              >
                Delete
              </Link>
              |
              <Link
                className="px-1"
                onClick={() => {
                  setAssessmentTypeId(item.id);
                  setAssessmentTypeStatus(item.active);
                  toggleEditHideDialog();
                }}
              >
                {item.active === 'Active' ? 'De-Activate' : 'Activate'}
              </Link>
            </div>
            {/* 2nd div */}
            {showSecondDiv && (
              <div className="bg-green-800 rounded-xl ml-1.5 px-1.5 py-0.5 ">
                <p className="text-white text-xs font-normal">New</p>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const [, editAssessmentTypeStatus] = useUpdateAssessmentTypeMutation();

  const paginate = (index: number) => {
    setOffset(index * PER_PAGE_COUNT);
    setCurrentPage(index + 1);
  };

  const EditStatus = async () => {
    try {
      const res = await editAssessmentTypeStatus({
        _eq: assessmentTypeId,
        status: assessmentTypeStatus === 'Active' ? false : true,
      });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      }
      toggleEditHideDialog();
    } catch (error) {
      console.error(error);
    }
  };
  const deleteType = async () => {
    try {
      const res = await deleteTrainingType({ _eq: assessmentTypeId });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: `Assessment Type deleted!`,
          type: MessageBarType.success,
        });
        refetchTypes();
      }
      toggleHideDialog();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Roles | Remotely</title>
      </Helmet>
      <Stack tokens={{ childrenGap: 30 }} className="mainContainer">
        <Stack.Item>
          <PrimaryButton
            onClick={showAddModal}
            iconProps={{ iconName: 'Add' }}
            text="Add new Assessment Type"
          />
          <AssessmentAddModal
            refetchTypes={refetchTypes}
            isOpen={addModalVisibility}
            onDismiss={hideAddModal}
          />
        </Stack.Item>
        <Stack.Item className="max-w-xs">
          <SearchBox
            placeholder="Search by Assessment Type"
            value={searchText || ''}
            onChange={(e, value) => setSearchText(value)}
          />
        </Stack.Item>
        <Stack.Item className="max-w-4xl">
          <ShimmeredDetailsList
            enableShimmer={fetching}
            items={assessmentTypeData}
            columns={typesColumn}
            selectionMode={SelectionMode.none}
          />
          <hr className="my-2" />
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={totalPages}
            totalItemCount={
              AssessmentType?.assessment_type_aggregate?.aggregate?.count
            }
            itemsPerPage={PER_PAGE_COUNT}
            onPageChange={paginate}
            format="buttons"
            numberOfPageButton={4}
          />
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteType} text="Delete" />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideEditDialog}
        onDismiss={toggleEditHideDialog}
        dialogContentProps={dialogContentPropsEdit}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={EditStatus}
            text={`${
              assessmentTypeStatus === 'Active' ? 'De-Activate' : 'Activate'
            }`}
          />
          <DefaultButton onClick={toggleEditHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default AssessmentType;
