import countries from './countries';
import states from './states';

export interface ICountry {
  name: string;
  code: string;
  dial_code: string;
}

const compare = function (country: ICountry, arg: string) {
  arg = arg.toLowerCase();
  return (
    country.code.toLowerCase() == arg ||
    country.name.toLowerCase() == arg ||
    country.dial_code.toLowerCase() == arg
  );
};

export function getCountries(): any {
  return countries;
}

export function getCountry(arg: string): any {
  const countries = getCountries();

  for (let i = 0; i < countries.length; i++) {
    if (compare(countries[i], arg)) {
      return countries[i];
    }
  }

  return null;
}

export function getFilteredCountries(args: any): any {
  const countries = getCountries();
  const filteredCountries = [];

  for (const arg of args) {
    for (let i = 0; i < countries.length; i++) {
      if (compare(countries[i], arg)) {
        filteredCountries.push(countries[i]);
        break;
      }
    }
  }

  return filteredCountries;
}

export function getStates(countryCode: string): any {
  try {
    return states[countryCode];
  } catch (error: any) {
    return 'Invalid country code';
  }
}
