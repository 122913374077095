import { MessageBarType, PrimaryButton, TextField } from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import {
  useEditSkillDetailsMutation,
  useGetSkillDetailsQuery,
} from './Skill.generated';
import useAutosizeTextArea from './useAutoSizeTextArea';

const EditSKill = (props: any) => {
  const {
    createSkill,
    setCreateSkill,
    listOfSkillsData,
    selectedID,
    setSelectedInfo,
  } = props;

  const { organization } = useOrganizationContext();
  const { notification } = useNotification();
  const textAreaRef1 = useRef<HTMLTextAreaElement>(null);
  const textAreaRef2 = useRef<HTMLTextAreaElement>(null);
  const textAreaRef3 = useRef<HTMLTextAreaElement>(null);
  const textAreaRef4 = useRef<HTMLTextAreaElement>(null);
  const textAreaRef5 = useRef<HTMLTextAreaElement>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useAutosizeTextArea(textAreaRef1.current, createSkill.rating1);
  useAutosizeTextArea(textAreaRef2.current, createSkill.rating2);
  useAutosizeTextArea(textAreaRef3.current, createSkill.rating3);
  useAutosizeTextArea(textAreaRef4.current, createSkill.rating4);
  useAutosizeTextArea(textAreaRef5.current, createSkill.rating5);

  const [, editSkill] = useEditSkillDetailsMutation();

  const handleSkill = (field: string, value: any) => {
    setCreateSkill({
      ...createSkill,
      [field]: value,
    });
  };

  const resestSkill = () => {
    setCreateSkill({
      show: false,
      edit: false,
      name: '',
      rating1: '',
      rating2: '',
      rating3: '',
      rating4: '',
      rating5: '',
    });
  };

  const editSkillHandler = async () => {
    const formattedRatings = [];

    for (let i = 1; i <= 5; i++) {
      formattedRatings.push({
        rating: i,
        description:
          createSkill[`rating${i}`] === undefined
            ? null
            : createSkill[`rating${i}`],
      });
    }
    if (!isEmpty(createSkill.name)) {
      setIsDisabled(true);
      const { data: responseData, error: responseError } = await editSkill({
        object: {
          orgId: organization?.id,
          skills_Id: selectedID,
          skillName: createSkill.name,
          subSkills: formattedRatings,
        },
      });
      if (responseData?.editSkillDetails?.response === 'Updated') {
        setIsDisabled(false);
        notification({
          message: `Skill updated successfully!`,
          type: MessageBarType.success,
        });
        resestSkill();
        listOfSkillsData();
        setSelectedInfo({
          index: -1,
          visible: false,
          collapsible: false,
          id: '',
        });
      }
      if (responseError) {
        setIsDisabled(false);
        notification({
          message: 'Something went wrong',
          type: MessageBarType.error,
        });
      }
    } else {
      notification({
        type: MessageBarType.info,
        message: 'Please enter name',
      });
    }
  };

  const [{ data: viewSkill }] = useGetSkillDetailsQuery({
    variables: {
      _eq: selectedID,
    },
    pause: !selectedID,
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (selectedID !== '') {
      const selectedSkill = viewSkill?.list_Of_Skills[0];
      setCreateSkill({
        ...createSkill,
        name: selectedSkill?.name,
        rating1: selectedSkill?.skills_details.find((obj) => obj.rating === 1)
          ?.description,
        rating2: selectedSkill?.skills_details.find((obj) => obj.rating === 2)
          ?.description,
        rating3: selectedSkill?.skills_details.find((obj) => obj.rating === 3)
          ?.description,
        rating4: selectedSkill?.skills_details.find((obj) => obj.rating === 4)
          ?.description,
        rating5: selectedSkill?.skills_details.find((obj) => obj.rating === 5)
          ?.description,
      });
    } else {
      setCreateSkill({
        ...createSkill,
        name: '',
        rating1: '',
        rating2: '',
        rating3: '',
        rating4: '',
        rating5: '',
      });
    }
  }, [viewSkill, selectedID]);

  return (
    <li className="px-8 py-3 gap-y-4 border rounded border-[#175E74] flex flex-col">
      <label className="text-lg font-semibold">Edit Skill</label>
      {/* <div className="grid grid-cols-2 gap-x-3"> */}
      <TextField
        autoFocus={false}
        label="Skill Name*"
        value={createSkill.name}
        placeholder={'Type skill here..'}
        onChange={(e, newValue) => {
          handleSkill('name', newValue);
        }}
      />
      {/* <div className="flex flex-col mt-1.5 gap-y-1">
          <label className="text-sm font-semibold">
            Select or Create Competency
          </label>
          <CreatableSelect
            isClearable
            options={competencyList}
            placeholder={'Select or create competencies'}
            formatCreateLabel={() => 'Create competency'}
            onCreateOption={showCompetency}
            value={createSkill.selectedCompetency}
            onChange={(newValue) => handleSkill('selectedCompetency', newValue)}
            hideSelectedOptions={true}
            styles={useCreateReactSelectFluentTheme(undefined, true)}
          />
        </div>
      </div> */}
      <div className="flex flex-col gap-y-1">
        <label className="text-sm font-semibold">Rating 1</label>
        <textarea
          rows={1}
          ref={textAreaRef1}
          className="border border-[#8A8886] px-2 pt-1 pb-2 hover:border-black focus:border-[#175E74] focus:border-2 outline outline-0 resize-none overflow-hidden"
          placeholder={'Type description here..'}
          value={createSkill.rating1}
          onChange={(e) => {
            handleSkill('rating1', e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <label className="text-sm font-semibold">Rating 2</label>
        <textarea
          rows={1}
          ref={textAreaRef2}
          className="border border-[#8A8886] px-2 pt-1 pb-2 hover:border-black focus:border-[#175E74] focus:border-2 outline outline-0 resize-none overflow-hidden"
          placeholder={'Type description here..'}
          value={createSkill.rating2}
          onChange={(e) => {
            handleSkill('rating2', e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <label className="text-sm font-semibold">Rating 3</label>
        <textarea
          rows={1}
          ref={textAreaRef3}
          className="border border-[#8A8886] px-2 pt-1 pb-2 hover:border-black focus:border-[#175E74] focus:border-2 outline outline-0 resize-none overflow-hidden"
          placeholder={'Type description here..'}
          value={createSkill.rating3}
          onChange={(e) => {
            handleSkill('rating3', e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <label className="text-sm font-semibold">Rating 4</label>
        <textarea
          rows={1}
          ref={textAreaRef4}
          className="border border-[#8A8886] px-2 pt-1 pb-2 hover:border-black focus:border-[#175E74] focus:border-2 outline outline-0 resize-none overflow-hidden"
          placeholder={'Type description here..'}
          value={createSkill.rating4}
          onChange={(e) => {
            handleSkill('rating4', e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <label className="text-sm font-semibold">Rating 5</label>
        <textarea
          rows={1}
          ref={textAreaRef5}
          className="border border-[#8A8886] px-2 pt-1 pb-2 hover:border-black focus:border-[#175E74] focus:border-2 outline outline-0 resize-none overflow-hidden"
          placeholder={'Type description here..'}
          value={createSkill.rating5}
          onChange={(e) => {
            handleSkill('rating5', e.target.value);
          }}
        />
      </div>
      <div className="flex justify-end items-center gap-x-4">
        <button
          type="button"
          onClick={resestSkill}
          className="px-5 py-1 bg-white border rounded-sm border-[#8A8886]"
        >
          Cancel
        </button>
        <PrimaryButton
          onClick={editSkillHandler}
          text="Save Changes"
          disabled={isDisabled}
          styles={{
            root: {
              selectors: {
                ':disabled': {
                  backgroundColor: '#F3F2F1 !important',
                  color: '#A19F9D',
                },
              },
            },
          }}
        />
      </div>
    </li>
  );
};

export default EditSKill;
