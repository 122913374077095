import ProjectManagement from '../Home/assets/ProjectManagement';
import FinanceImg from './assets/FinanceImg.png';

const FinancePlanner = () => {
  return (
    <div className="px-20">
      <div className="flex gap-8">
        <div>
          <div className="flex items-center gap-2">
            <span>
              <ProjectManagement />
            </span>
            <span className="font-medium ">Finance Planner</span>
          </div>
          <div className="text-4xl font-medium mt-2 ">
            Track your financial metrics to boost bottom line.
          </div>
          <div className="mt-8 text-base ">
            Measure revenue per person, SG&A and other key metrics through
            permutation and combination. Sell rate, cost rate, actual work
            hours.
          </div>
        </div>
        <div>
          <img src={FinanceImg} alt="FinanceImg" />
        </div>
      </div>
    </div>
  );
};

export default FinancePlanner;
