import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useEffect, useState } from 'react';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import AddNewAdminModal from './AddNewAdminModal';
import DeleteAdministrator from './DeleteAdministrator';
import TransferOwnership from './TransferOwnership';
import { useGetOrganizationAdminsByOrganizationIdQuery } from './administrators.generated';

export interface IAdminData {
  index: number;
  id: string;
  fullName: string;
  email: string;
  role: string;
  userId: string;
}

const AdministratorsList = () => {
  const { organization } = useOrganizationContext();
  const columns: IColumn[] = [
    {
      key: 'fullName',
      name: 'Full Name',
      fieldName: 'fullName',
      minWidth: 210,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'email',
      name: 'Email',
      fieldName: 'email',
      minWidth: 210,
      maxWidth: 350,
      isResizable: true,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'role',
      name: 'Role',
      fieldName: 'role',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'action',
      name: 'Action',
      fieldName: 'action',
      minWidth: 160,
      maxWidth: 160,
      isResizable: true,
      isCollapsible: true,
      data: 'number',
      onRender: (item: IAdminData) => {
        if (item.role === 'Admin') {
          return (
            <div className="flex gap-5">
              <DeleteAdministrator
                refetch={refetchAdmins}
                userId={item.userId}
              />
              <TransferOwnership userId={item.userId} />
            </div>
          );
        }
        return null;
      },
    },
  ];

  const [adminData, setAdminData] = useState<IAdminData[]>([]);
  const [count, setCount] = useState(0);
  const [modalOpen, { toggle: toggleModal }] = useBoolean(false);

  const [{ data, fetching }, refetchAdmins] =
    useGetOrganizationAdminsByOrganizationIdQuery({
      variables: {
        organizationId: organization?.id,
      },
      pause: !organization?.id,
      requestPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (data) {
      setAdminData(
        data.organization_admin
          .map(
            (admin, index) =>
              ({
                index: index + 1,
                id: admin.id,
                fullName: admin.userByUserId.display_name,
                email: admin.userByUserId.account?.email,
                userId: admin.userByUserId.id,
                role: admin.is_owner ? 'Owner' : 'Admin',
              }) as IAdminData,
          )
          .sort((a, b) => b.role.localeCompare(a.role)),
      );
      if (data.organization_admin_aggregate.aggregate) {
        setCount(data.organization_admin_aggregate.aggregate?.count);
      }
    }
  }, [data]);

  return (
    <>
      <div className="flex flex-col gap-10">
        <DetailsList
          items={adminData}
          columns={columns}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
        <Text className="text-sm text-gray-400">
          Maximum 5 Organization Admins are allowed
        </Text>
        <div>
          <DefaultButton
            text="Add Admin"
            iconProps={{ iconName: 'Add' }}
            allowDisabledFocus
            onClick={toggleModal}
            disabled={count >= 5 ? true : false}
          />
        </div>
      </div>
      <AddNewAdminModal
        isModalOpen={modalOpen}
        hideModal={toggleModal}
        refetch={refetchAdmins}
      />
    </>
  );
};

export default AdministratorsList;
