import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  Link,
  MessageBarType,
  Modal,
  SelectionMode,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  PrimaryButtonWithLoader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { get } from 'lodash';
import { FC, useState } from 'react';
import DepartmentAddModal from './DepartmentAddModal';
import DepartmentEmployees from './DepartmentEmployee';
import { useDeleteDepartmentMutation } from './department.generated';

interface IDetailsList {
  key: number;
  name: string;
  memberCount: number;
  action: any;
}

interface DepartmentProps {
  department: any[];
  refetch: any;
}

export const DepartmentList: FC<DepartmentProps> = ({
  department,
  refetch,
}) => {
  const { notification } = useNotification();
  const [id, setId] = useState('');
  const [edit, setEdit] = useState<{ name: any; id: any; manager: any }>();
  const [, deleteDepartment] = useDeleteDepartmentMutation();

  const columns: IColumn[] = [
    {
      key: 'ID',
      name: 'Sr. No',
      fieldName: 'id',
      minWidth: 20,
      maxWidth: 30,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      className: 'text-black one ',
      onRender: (item, count = 0) => {
        return count + 1;
      },
    },
    {
      key: 'Dept_name',
      name: 'Department name',
      fieldName: 'name',
      minWidth: 200,
      isResizable: true,
      data: 'number',
      isPadded: true,
      className: 'text-black  ',
    },
    {
      key: 'manager',
      name: 'Manager',
      onRender: (item) => get(item, 'manager_user.display_name'),
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      data: 'number',
      isPadded: true,
      className: 'text-black  ',
    },
    {
      key: 'Members',
      name: 'Total members',
      fieldName: 'memberCount',
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      data: 'number',
      isPadded: true,
      className: 'text-black  ',
      onRender: (item) => item?.memberCount?.aggregate?.count,
    },
    {
      key: 'action',
      name: 'Action',
      fieldName: 'id',
      minWidth: 170,
      isResizable: true,
      data: 'number',
      isPadded: true,
      onRender: (item) => {
        return (
          <div className="flex gap-5">
            <Link
              onClick={() => {
                setId(item.id);
                showModal();
              }}
            >
              View Members
            </Link>
            <Link
              onClick={() => {
                const editData = {
                  name: item.name,
                  id: item.id,
                  manager: {
                    value: item.manager_user.id,
                    label: item.manager_user.display_name,
                  },
                };
                setEdit(editData);
                showAddDepartmentModal();
              }}
            >
              Edit
            </Link>
            <Link
              onClick={() => {
                const count = item?.memberCount?.aggregate?.count;
                if (count > 0) {
                  notification({
                    message:
                      'Department cant be deleted !! Employees are still part of the department',
                    type: MessageBarType.error,
                  });
                } else {
                  setId(item.id);
                  showDeleteModal();
                }
              }}
            >
              Delete
            </Link>
          </div>
        );
      },
    },
  ];

  const onDelete = async () => {
    try {
      const res = await deleteDepartment({ departmentId: id });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: 'Successfully deleted department',
          type: MessageBarType.error,
        });
      }
    } catch (error) {
      notification({
        message: 'Could not delete department',
        type: MessageBarType.error,
      });
      console.error(error);
    }
    refetch();
    hideDeleteModal();
  };
  //

  const [
    addDepartmentModalVisible,
    { setTrue: showAddDepartmentModal, setFalse: hideAddDepartmentModal },
  ] = useBoolean(false);

  //
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [
    isDeleteModalOpen,
    { setTrue: showDeleteModal, setFalse: hideDeleteModal },
  ] = useBoolean(false);
  return (
    <Stack>
      <StackItem>
        <DetailsList
          items={department}
          columns={columns}
          compact={true}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          enterModalSelectionOnTouch={true}
        />
      </StackItem>
      <Modal isOpen={isModalOpen} onDismiss={hideModal}>
        <div className="p-5" style={{ minWidth: 900 }}>
          <div className="flex  justify-between mb-6">
            <div>
              <Text className="text-2xl">Member Details</Text>
            </div>
            <div>
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close popup modal"
                onClick={hideModal}
              />
            </div>
          </div>
          <div>
            <DepartmentEmployees id={id} />
          </div>
          <DefaultButton onClick={hideModal} text="Cancel" />
        </div>
      </Modal>
      {/* Delete Model */}
      <Modal isOpen={isDeleteModalOpen} onDismiss={hideDeleteModal}>
        <div className="p-5" style={{ minWidth: 400 }}>
          <div className="flex  justify-between mb-6">
            <div>
              <Text className="text-2xl">
                Do you want to delete this department?
              </Text>
            </div>
            <div>
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close popup modal"
                onClick={hideDeleteModal}
              />
            </div>
          </div>
          <div className="flex gap-5 mt-10">
            <PrimaryButtonWithLoader onClick={onDelete} text="Yes" />
            <DefaultButton onClick={hideDeleteModal} text="No" />
          </div>
        </div>
      </Modal>
      <DepartmentAddModal
        isModalOpen={addDepartmentModalVisible}
        hideModal={hideAddDepartmentModal}
        refetch={refetch}
        edit={edit}
      />
    </Stack>
  );
};
