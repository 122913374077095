import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  title: yup.string().required().label('Title'),
  totalSeats: yup
    .number()
    .typeError('Please enter a valid number')
    .min(1)
    .required()
    .label('Total seats'),
  timezone: yup.string().required().label('Timezone'),
});

export const addLocationResolver = yupResolver(schema);
