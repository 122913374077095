import { MessageBarType } from '@fluentui/react';
import {
  Enum_Storage_File_Type_Enum,
  OnBoardingFormLayout,
  OrganizationFormVector,
  useLayoutState,
  useNotification,
} from '@worx.squad/shared-frontend';
import { get, isEmpty, uniqueId } from 'lodash';
import { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { CombinedError } from 'urql';
import RegisterForm, {
  IRegisterFormProps,
} from '../components/Register/RegisterForm';
import { useCreateOrganizationOwnerMutation } from '../components/Register/register.generated';
import { uploadFile } from '../helpers/file-upload';
import { useInsertStorageFileMutation } from '../helpers/file.generated';
import { auth } from '../helpers/hbp-helper';
import OnboardHeader from './OnboardHeader';

const Register = () => {
  const navigate = useNavigate();
  const { notification } = useNotification();

  const [loading, setLoading] = useState(false);

  const [, createOrganizationOwner] = useCreateOrganizationOwnerMutation();
  const [, insertFile] = useInsertStorageFileMutation();

  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  const onSubmit = async (data: IRegisterFormProps) => {
    setLoading(true);
    try {
      await auth.logout();

      let avatarId: string | undefined = undefined;

      if (data.avatarImage) {
        const { id } = await uploadFile({
          file: data.avatarImage,
          insertFile,
          path: `/public/user/${uniqueId()}/avatar/${data.avatarImage?.name}`,
          data: {
            type: Enum_Storage_File_Type_Enum.Image,
          },
        });
        avatarId = id;
      }
      const { error } = await createOrganizationOwner({
        user: {
          email: data.email,
          password: data.password,
          firstName: data.fname,
          lastName: data.lname,
        },
        profile: {
          dateOfBirth: get(data, 'dateOfBirth'),
          avatarId,
          dateOfJoining: get(data, 'dateOfJoining') as string,
          maritalStatus: get(data, 'maritalStatus') as string,
          marriageAnniversary: get(data, 'marriageAnniversary') as string,
          gender: get(data, 'gender') as string,
        },
      });

      if (!isEmpty(error)) throw error;

      notification({
        message:
          'Account created successfully. We have send you an email check and confirm',
        type: MessageBarType.success,
      });
      navigate('/login');
      setLoading(false);
    } catch (error: any) {
      if (error instanceof CombinedError) {
        notification({
          message: error.message as string,
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: error.response.data.message as string,
          type: MessageBarType.error,
        });
      }

      setLoading(false);
    }
  };
  const title = 'Create your Account';
  const description =
    'Worx Squad is an enterprise productivity and employee engagement platform. Create, Collaborate, Discuss and Manage, Worx Squad handles all your daily work needs with ease. Experience the office vibes at Worx Squad.';

  return (
    <div className="h-screen">
      <OnboardHeader />
      <OnBoardingFormLayout
        title={title}
        description={description}
        vector={<OrganizationFormVector />}
      >
        <Helmet>
          <title>Register | Worx Squad</title>
        </Helmet>

        <RegisterForm loading={loading} onSubmit={onSubmit} />
      </OnBoardingFormLayout>
    </div>
  );
};

export default Register;
