import ProjectManagement from '../Home/assets/ProjectManagement';
import FeatureProductivityTwo from './FeatureProductivityTwo';

const ProductivityDashboard = () => {
  return (
    <div className="px-20">
      <div>
        <div className="flex items-center gap-2">
          <span>
            <ProjectManagement />
          </span>
          <span className="font-medium text-white">Productivity Dashboard</span>
        </div>
        <div className="text-4xl font-medium mt-2 text-white">
          Foster collaboration and boost engagement
        </div>
        <div>
          <FeatureProductivityTwo />
        </div>
      </div>
    </div>
  );
};

export default ProductivityDashboard;
