import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Link,
  MessageBarType,
  PrimaryButton,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useNotification } from '@worx.squad/shared-frontend';
import { OperationContext } from 'urql';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import { useHotDesk } from './HotDeskingHook';
import { useDeleteOrgLocationMutation } from './hot-desking.generated';

export const DeleteLocation = ({
  locationId,
  refetch,
}: {
  locationId: string;
  refetch: (opts?: Partial<OperationContext> | undefined) => void;
}) => {
  const { refetchOrganizationLocations } = useHotDesk();
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [, deleteOrgLocation] = useDeleteOrgLocationMutation();
  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Delete Location',
    closeButtonAriaLabel: 'Close',
    subText: 'Are you sure you want to delete this location?',
  };
  const deleteLocation = async () => {
    try {
      const res = await deleteOrgLocation({
        orgId: organization?.id,
        locationId,
      });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      } else if (res.data?.deleteOrgLocation?.status === 400) {
        notification({
          message: res.data.deleteOrgLocation.message + '',
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: `Location deleted!`,
          type: MessageBarType.success,
        });
        refetch({
          fetchPolicy: 'cache-and-network',
        });
        refetchOrganizationLocations({
          requestPolicy: 'cache-and-network',
        });
      }
      toggleHideDialog();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Link onClick={toggleHideDialog}>Delete</Link>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteLocation} text="Delete" />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
