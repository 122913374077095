import ProjectManagement from '../Home/assets/ProjectManagement';
import EmployeeWellnessImg from './assets/EmployeeWellnessImg.png';
import KeyStress from './assets/KeyStress.png';
import ScreenTime from './assets/ScreenTime.png';
import Wellness from './assets/Wellness.png';

const EmployeeWellness = () => {
  return (
    <div className="px-20">
      <div className="flex justify-between gap-8">
        <div>
          <div className="flex items-center gap-2">
            <span>
              <ProjectManagement />
            </span>
            <span className="font-medium text-white">Employee Wellness</span>
          </div>
          <div className="text-4xl font-medium mt-2 text-white">
            Employee Wellness & Stress Assessment
          </div>
          <div className="mt-8 text-base text-white">
            Manage employee wellness with our built in wellness tools like
            stress surveys, screen time reminders etc.
          </div>
        </div>
        <div className="w-[60%]">
          <img src={EmployeeWellnessImg} alt="EmployeeWellness" />
        </div>
      </div>
      <div className="flex justify-between gap-4 mt-8">
        <div className="w-[30%]">
          <img src={ScreenTime} alt="ScreenTime" />
        </div>
        <div className="w-[30%]">
          <img src={KeyStress} alt="KeyStress" />
        </div>
        <div className="w-[30%]">
          <img src={Wellness} alt="Wellness" />
        </div>
      </div>
    </div>
  );
};

export default EmployeeWellness;
