import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  ISearchBoxStyles,
  SearchBox,
  SelectionMode,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import { Pagination } from '@fluentui/react-experiments';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import {
  GetDepartmentEmployeeQuery,
  useGetDepartmentEmployeeLengthQuery,
  useGetDepartmentEmployeeQuery,
} from './department.generated';

interface IDetailsList {
  key: number;
  full_name: string;
  email: string;
  role: string;
  action: any;
}

type Props = {
  id: any;
};
const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 300 } };

const DepartmentEmployees = ({ id }: any) => {
  const { organization } = useOrganizationContext();
  const [items, setItems] = useState<
    GetDepartmentEmployeeQuery['department_employee']
  >([]);
  const [searchText, setSearchText] = useState<string | undefined>('');
  const [search, setSearch] = useState<string | undefined>('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState<any>();
  const [totalLength, setTotalLength] = useState<any>();
  const [itemsPerPage] = useState(10);
  const [searchLazy] = useDebounce(search, 500);
  const [offset, setOffset] = useState(0);

  const [{ data: GetDepartmentEmployee }] = useGetDepartmentEmployeeQuery({
    variables: {
      departmentID: id,
      organizationId: organization?.id,
      limit: itemsPerPage,
      offset: offset,
      search: searchLazy ? `${searchLazy}%` : undefined,
    },
    pause: !id,
    requestPolicy: 'cache-and-network',
  });
  const [
    { data: departmentEmployeeLength, fetching: fetchDepartmentEmployee },
  ] = useGetDepartmentEmployeeLengthQuery({
    variables: {
      departmentId: id,
      search: search ? `%${search}%` : undefined,
    },
    pause: !id,
    requestPolicy: 'cache-and-network',
  });
  const paginate = (index: number) => {
    setOffset(index * itemsPerPage);
    setCurrentPage(index + 1);
  };
  const columns: IColumn[] = [
    {
      key: 'column1',
      name: 'Sr. No',
      fieldName: 'key',
      minWidth: 20,
      maxWidth: 30,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      isPadded: true,
      className: 'text-black',
      onRender: (item, counts = 0) => {
        return (counts = counts + offset + 1);
      },
    },
    {
      key: 'column2',
      name: 'Full Name',
      minWidth: 122,
      maxWidth: 135,
      isResizable: true,
      data: 'number',
      isPadded: true,
      className: 'text-black',
      onRender: (item) => item.user.display_name,
    },
    {
      key: 'column3',
      name: 'Email',
      onRender: (item) => get(item, 'user.account.email'),
      minWidth: 180,
      maxWidth: 200,
      isResizable: true,
      data: 'number',
      isPadded: true,
      className: 'text-black',
    },
    {
      key: 'column4',
      name: 'Role',
      fieldName: 'organization_role',
      minWidth: 130,
      maxWidth: 180,
      isResizable: true,
      data: 'number',
      isPadded: true,
      className: 'text-black',
      onRender: (item) =>
        get(item, 'user.profile[0]?.organization_role.role_title'),
    },
  ];

  useEffect(() => {
    if (GetDepartmentEmployee) {
      const item = GetDepartmentEmployee?.department_employee;
      setItems(item);
    }
  }, [GetDepartmentEmployee]);
  useEffect(() => {
    if (departmentEmployeeLength) {
      const itemLength =
        departmentEmployeeLength?.department_employee_aggregate.aggregate
          ?.count;
      const total = departmentEmployeeLength.department_employee.length;
      if (itemLength) {
        setTotalPage(Math.ceil(itemLength / itemsPerPage));
      }
      if (total) {
        setTotalLength(total);
      }
    }
  }, [departmentEmployeeLength, itemsPerPage]);

  useEffect(() => {
    const temp = setTimeout(() => {
      setSearch(searchText);
    }, 500);
    return () => {
      clearTimeout(temp);
    };
  }, [searchText]);

  const handleSearch = (value: string) => {
    const newValue = GetDepartmentEmployee?.department_employee;
    const searchResult: any = value
      ? newValue?.filter((item: any) => {
          return item?.user?.display_name
            ?.toLowerCase()
            ?.includes(value.toLowerCase() || '');
        })
      : GetDepartmentEmployee;
    setItems(searchResult);
  };
  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <StackItem>
        <SearchBox
          onClear={() => setSearchText(undefined)}
          styles={searchBoxStyles}
          value={searchText || ''}
          onChange={(e, value) => {
            setSearchText(value);
          }}
          placeholder="Search by name"
        />
      </StackItem>

      <StackItem>
        {items.length >= 1 ? (
          <>
            <DetailsList
              items={items}
              columns={columns}
              compact={true}
              selectionMode={SelectionMode.none}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              enterModalSelectionOnTouch={true}
            />
            <Pagination
              selectedPageIndex={currentPage - 1}
              pageCount={
                search ? Math.ceil(totalLength / itemsPerPage) : totalPage
              }
              itemsPerPage={itemsPerPage}
              format={'buttons'}
              previousPageAriaLabel={'previous page'}
              nextPageAriaLabel={'next page'}
              firstPageAriaLabel={'first page'}
              lastPageAriaLabel={'last page'}
              pageAriaLabel={'page'}
              selectedAriaLabel={'selected'}
              onPageChange={paginate}
            />
          </>
        ) : (
          <div className="mb-5">
            <Text className="text-xl">No results found</Text>
          </div>
        )}
      </StackItem>
    </Stack>
  );
};

export default DepartmentEmployees;
