import { DefaultButton, IButtonProps, Spinner } from '@fluentui/react';

interface IPrimaryButtonWithLoader extends IButtonProps {
  loading?: boolean;
}

export const DefaultButtonWithLoader = ({
  loading,
  children,
  text,
  ...props
}: IPrimaryButtonWithLoader) => {
  return (
    <DefaultButton disabled={loading} {...props}>
      {loading ? <Spinner /> : children || text}
    </DefaultButton>
  );
};
