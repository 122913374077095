export const AppLogo = () => {
  return (
    <img
      style={{ width: 'inherit' }}
      className="bg-gray-200 p-1 rounded-xl"
      src="assets/logo.png"
      alt="Logo"
    />
  );
};
