import { DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useNavigate } from 'react-router-dom';
import DownloadApp from './DownloadApp';
import BlackLinkSvg from './assets/BlackLinkSvg';
import LinkSvg from './assets/LinkSvg';

const BoostYourProductivity = () => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col items-center gap-12 py-16">
          <div className="text-4xl font-medium text-center">
            Boost Your Team’s Productivity – Discover How Worx Squad Can
            <br /> Streamline Your Workflow Today
          </div>
          <div className="w-3/4 text-center flex justify-center items-center gap-4">
            <DefaultButton
              onClick={() => navigate('/contact-us')}
              className="w-1/3 border border-white bg-[#185D74] hover:bg-[#2b92b4] text-white hover:text-white px-6 py-6 ease-in-out duration-300"
            >
              <span className="flex gap-4 justify-center items-center">
                <span className="text-[1rem] text-white font-semibold">
                  Get Started
                </span>
                <span>
                  <LinkSvg />
                </span>
              </span>
            </DefaultButton>
            <DefaultButton
              onClick={showModal}
              className="w-1/2 border border-[#FF9900] text-white bg-[#FF9900] hover:bg-[#e19a30] hover:text-white px-6 py-6 ease-in-out duration-300"
            >
              <span className="flex gap-4 justify-center items-center">
                <span className="text-[1rem] text-black font-semibold">
                  Download Squad For Free
                </span>
                <span>
                  <BlackLinkSvg />
                </span>
              </span>
            </DefaultButton>
            <DownloadApp
              showModal={showModal}
              isModalOpen={isModalOpen}
              hideModal={hideModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoostYourProductivity;
