const LogoSvg = () => {
  return (
    <svg
      width="133"
      height="46"
      viewBox="0 0 133 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3333_12999)">
        <path
          d="M8.70229 22.9933C8.70229 20.5952 6.75069 18.6436 4.35253 18.6436C1.95436 18.6436 0 20.5952 0 22.9933C0 25.3915 1.95161 27.3458 4.34977 27.3458C6.74793 27.3458 8.69954 25.3942 8.69954 22.9933H8.70229Z"
          fill="white"
        />
        <path
          d="M20.8836 10.2074C23.2818 10.2074 25.2334 8.25581 25.2334 5.85765C25.2334 3.45949 23.2818 1.50513 20.8836 1.50513C18.4855 1.50513 16.5311 3.45673 16.5311 5.8549C16.5311 8.25306 18.4827 10.2047 20.8836 10.2047L20.8836 10.2074Z"
          fill="white"
        />
        <path
          d="M30.9393 38.0667L36.535 32.4682L31.4824 22.9941L36.535 13.5393L30.9393 7.95459L21.4652 13.0073L12.0104 7.95459L6.42847 13.542L11.4811 23.0161L6.42847 32.471L12.0104 38.0694L21.4845 33.0168L30.9393 38.0694V38.0667ZM14.6153 16.1442C15.6765 16.7258 17.6337 17.7926 21.275 19.7745L21.4652 19.8792L28.3455 16.1331C27.7666 17.1916 26.6998 19.1488 24.7096 22.8039L24.6049 22.9941L28.3592 29.8854C27.3035 29.3065 25.3436 28.2397 21.6747 26.2413L21.4845 26.1365L14.6098 29.8799C15.1886 28.8241 16.2554 26.8642 18.2511 23.2008L18.3559 23.0106L14.6153 16.1442Z"
          fill="white"
        />
        <path
          d="M38.6381 27.3458C41.0363 27.3458 42.9879 25.3942 42.9879 22.9933C42.9879 20.5924 41.0363 18.6436 38.6381 18.6436C36.2399 18.6436 34.2883 20.5952 34.2883 22.9933C34.2883 25.3915 36.2399 27.3458 38.6381 27.3458Z"
          fill="white"
        />
        <path
          d="M16.5311 40.1452C16.5311 42.5433 18.4827 44.4949 20.8836 44.4949C23.2845 44.4949 25.2334 42.5433 25.2334 40.1452C25.2334 37.747 23.2818 35.7954 20.8836 35.7954C18.4855 35.7954 16.5311 37.747 16.5311 40.1452Z"
          fill="white"
        />
        <path
          d="M50.9878 14.929H52.6991L54.8194 26.2843H54.8596L56.8238 14.929H58.885L60.8492 26.2843H60.8894L63.0097 14.929H64.721L61.8633 28.8111H59.7832L57.878 17.5716H57.8402L55.8381 28.8111H53.7581L50.9972 14.929H50.9878Z"
          fill="white"
        />
        <path
          d="M68.6008 18.1184C71.6145 18.1184 72.3543 20.6665 72.3543 23.6022C72.3543 26.538 71.5956 29.0861 68.6008 29.0861C65.6059 29.0861 64.8472 26.538 64.8472 23.6022C64.8472 20.6665 65.6059 18.1184 68.6008 18.1184ZM70.643 23.6022C70.643 21.3472 70.3121 19.4019 68.5629 19.4019C66.9107 19.4019 66.5609 21.3472 66.5609 23.6022C66.5609 25.8572 66.8918 27.8026 68.5629 27.8026C70.2341 27.8026 70.643 25.8572 70.643 23.6022Z"
          fill="white"
        />
        <path
          d="M73.6558 18.3902H75.2891V19.9456H75.3293C75.7949 18.9528 76.4189 18.1184 77.5843 18.1184C77.9152 18.1184 78.1492 18.1586 78.3028 18.2342V19.8297C78.1468 19.8108 77.953 19.7517 77.4283 19.7517C76.6104 19.7517 75.2891 20.4916 75.2891 22.2218V28.8142H73.6558V18.3926V18.3902Z"
          fill="white"
        />
        <path
          d="M78.875 18.3918H80.6643L82.5506 22.1052L84.4557 18.3918H86.245L83.522 23.3887L86.342 28.8135H84.5526L82.4726 24.6912L80.4705 28.8135H78.6812L81.5389 23.3887L78.875 18.3918Z"
          fill="white"
        />
        <path
          d="M96.6855 19.9274V17.9821C96.6855 17.3013 96.4704 16.9137 95.8299 16.9137C95.1302 16.9137 94.9742 17.3013 94.9742 17.9821C94.9742 20.3151 99.912 20.9958 99.912 25.2742C99.912 27.879 98.5127 29.0845 95.8086 29.0845C93.7474 29.0845 91.7454 28.3848 91.7454 25.9927V23.4257H94.9718V25.8178C94.9718 26.6357 95.2248 26.8673 95.8275 26.8673C96.3522 26.8673 96.6832 26.6333 96.6832 25.8178C96.6832 22.6883 91.7454 22.6292 91.7454 18.157C91.7454 15.6491 93.3976 14.6965 95.8866 14.6965C98.0636 14.6965 99.912 15.4364 99.912 17.7505V19.9274H96.6832H96.6855Z"
          fill="white"
        />
        <path
          d="M108.019 31.3009H104.946V27.3535H104.906C104.634 28.3652 103.856 28.949 102.729 28.949C101.173 28.949 100.589 27.8995 100.589 26.538V20.3947C100.589 18.9954 101.095 17.5559 102.788 17.5559C103.915 17.5559 104.752 17.9837 104.908 19.1514H104.948V17.693H108.021V31.3033L108.019 31.3009ZM103.662 25.6611C103.662 26.148 103.934 26.4198 104.303 26.4198C104.671 26.4198 104.943 26.148 104.943 25.6611V20.8391C104.943 20.3522 104.671 20.0804 104.303 20.0804C103.934 20.0804 103.662 20.3522 103.662 20.8391V25.6611Z"
          fill="white"
        />
        <path
          d="M113.133 27.4316H113.093C112.802 28.403 112.062 28.9491 110.857 28.9491C109.477 28.9491 108.777 27.9965 108.777 26.401V17.6907H111.85V25.4106C111.85 25.8975 111.89 26.4222 112.471 26.4222C113.053 26.4222 113.133 25.8975 113.133 25.4106V17.6907H116.206V28.812H113.133V27.4316Z"
          fill="white"
        />
        <path
          d="M121.233 27.5095H121.193C120.824 28.3061 120.259 28.949 119.035 28.949C117.692 28.949 116.876 28.1312 116.876 26.6751V25.0229C116.876 22.1061 121.233 22.4559 121.233 20.3947C121.233 19.948 121.096 19.5769 120.592 19.5769C120.089 19.5769 119.952 19.9456 119.952 20.3947V21.6191H116.879V20.5886C116.879 18.3525 118.746 17.5559 120.592 17.5559C122.438 17.5559 124.306 18.3525 124.306 20.5886V28.8143H121.233V27.5119V27.5095ZM119.949 25.9542C119.949 26.3418 120.221 26.538 120.514 26.538C121.155 26.538 121.233 26.1102 121.233 25.1174V23.1934C120.63 23.6212 119.949 23.8741 119.949 24.7487V25.9542Z"
          fill="white"
        />
        <path
          d="M129.383 27.4126H129.345C129.189 28.5212 128.352 28.949 127.225 28.949C125.532 28.949 125.029 27.5095 125.029 26.1693V20.026C125.029 18.6077 125.613 17.5559 127.168 17.5559C128.295 17.5559 129.073 18.1964 129.345 19.2081H129.383V14.97H132.456V28.8143H129.383V27.4149V27.4126ZM128.099 25.6634C128.099 26.1504 128.371 26.4222 128.74 26.4222C129.109 26.4222 129.38 26.1504 129.38 25.6634V20.8414C129.38 20.3545 129.109 20.0827 128.74 20.0827C128.371 20.0827 128.099 20.3545 128.099 20.8414V25.6634Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3333_12999">
          <rect
            width="132.456"
            height="44.9898"
            fill="white"
            transform="translate(0 0.505127)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoSvg;
