import Lottie from 'react-lottie';
import animationData from './assets/tree.json';

const LottieAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie options={defaultOptions} height={800} width={700} />;
};

export default LottieAnimation;
