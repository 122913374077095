import { IColumn } from '@fluentui/react';
import DeleteHoliday from './DeleteHoliday';
import EditHoliday from './EditHoliday';
import { Holiday } from './holiday.types';

export const columns: IColumn[] = [
  {
    key: 'index',
    minWidth: 40,
    maxWidth: 40,
    name: 'Sl.No',
    onRender: (item, index) => (index || 0) + 1,
  },
  { key: 'name', minWidth: 100, name: 'Name', fieldName: 'name' },
  {
    key: 'description',
    minWidth: 300,
    name: 'Description',
    fieldName: 'description',
  },
  { key: 'date', minWidth: 100, name: 'Date', fieldName: 'date' },
  {
    key: 'action',
    minWidth: 100,
    name: 'Action',
    onRender(item: Holiday) {
      return (
        <div className="flex gap-2">
          <DeleteHoliday item={item} />
          <EditHoliday item={item} />
        </div>
      );
    },
  },
];
