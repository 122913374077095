import {
  MessageBarType,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { VscClose } from 'react-icons/vsc';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import {
  useUpdateCompetnecyNameMutation,
  useViewCompetencyQuery,
} from './Competency.generated';

interface IModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  children?: React.ReactNode;
  selectedID: string;
  listOfCompetenciesData: () => void;
}

const RenameCompetencyPopup = (props: IModalProps) => {
  const { notification } = useNotification();
  const { organization } = useOrganizationContext();

  const [name, setName] = useState<any>('');
  const [isDisabled, setIsDisabled] = useState(false);

  const [, renameCompetence] = useUpdateCompetnecyNameMutation();

  const handleRename = async () => {
    if (!isEmpty(name)) {
      setIsDisabled(true);
      const { data: responseData, error: responseError } =
        await renameCompetence({
          id: props.selectedID,
          name: name,
        });
      if (responseError) {
        setIsDisabled(false);
        notification({
          message: 'Something went wrong',
          type: MessageBarType.error,
        });
      } else {
        setIsDisabled(false);
        notification({
          message: `Competency updated successfully!`,
          type: MessageBarType.success,
        });
        props.onDismiss();
        props.listOfCompetenciesData();
      }
    } else {
      notification({
        type: MessageBarType.info,
        message: 'Please enter name',
      });
    }
  };

  const [{ data: viewCompetency }] = useViewCompetencyQuery({
    variables: {
      _eq: props.selectedID,
      _eq1: organization?.id,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setName(viewCompetency?.main_skills[0].name);
  }, [viewCompetency]);

  return (
    <Modal {...props}>
      <Stack className="p-6 pt-0" style={{ width: 518, height: 219 }}>
        <div>
          <div className="flex justify-between items-center pb-3  pt-6 bg-white z-10">
            <span>
              <label className="text-[#201F1E] text-xl leading-7 font-semibold">
                Rename Competency
              </label>
            </span>
            <span
              onClick={props.onDismiss}
              className="flex justify-end cursor-pointer"
            >
              <VscClose size={25} />
            </span>
          </div>
          <TextField
            autoFocus={false}
            label="Name*"
            value={name}
            placeholder={'Enter a name for your group'}
            onChange={(e, newValue: any) => {
              setName(newValue);
            }}
          />
          <div className="mt-10 flex justify-end">
            <PrimaryButton
              text="Save Changes"
              onClick={handleRename}
              disabled={isDisabled}
              styles={{
                root: {
                  selectors: {
                    ':disabled': {
                      backgroundColor: '#F3F2F1 !important',
                      color: '#A19F9D',
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </Stack>
    </Modal>
  );
};

export default RenameCompetencyPopup;
