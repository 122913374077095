import { SVGProps } from 'react';
export const MoneyLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 3.85A3.85 3.85 0 0 1 3.85 0h29.7a3.85 3.85 0 0 1 3.85 3.85v18.7a3.85 3.85 0 0 1-3.85 3.85H3.85A3.85 3.85 0 0 1 0 22.55V3.85Zm6.6-1.1v2.2A1.65 1.65 0 0 1 4.95 6.6h-2.2v3.3h2.2A4.95 4.95 0 0 0 9.9 4.95v-2.2H6.6ZM18.7 18.7a4.95 4.95 0 1 0 0-9.9 4.95 4.95 0 0 0 0 9.9ZM2.75 19.8h2.2c.911 0 1.65.739 1.65 1.65v2.2h3.3v-2.2a4.95 4.95 0 0 0-4.95-4.95h-2.2v3.3Zm28.05 1.65c0-.911.739-1.65 1.65-1.65h2.2v-3.3h-2.2a4.95 4.95 0 0 0-4.95 4.95v2.2h3.3v-2.2Zm0-16.5v-2.2h-3.3v2.2a4.95 4.95 0 0 0 4.95 4.95h2.2V6.6h-2.2a1.65 1.65 0 0 1-1.65-1.65ZM5.283 29.7A6.597 6.597 0 0 0 11 33h22.55C39.321 33 44 28.321 44 22.55V11a6.597 6.597 0 0 0-3.3-5.717V22.55a7.15 7.15 0 0 1-7.15 7.15H5.283Z"
      fill="#579C43"
    />
  </svg>
);
