import { IButtonProps, PrimaryButton, Spinner } from '@fluentui/react';

interface IPrimaryButtonWithLoader extends IButtonProps {
  loading?: boolean;
}

export const PrimaryButtonWithLoader = ({
  loading,
  children,
  text,
  ...props
}: IPrimaryButtonWithLoader) => {
  return (
    <PrimaryButton disabled={loading} {...props}>
      {loading ? <Spinner /> : children || text}
    </PrimaryButton>
  );
};
