import { DefaultButton } from '@fluentui/react';
import {
  Form,
  PrimaryButtonWithLoader,
  TextField,
} from '@worx.squad/shared-frontend';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ClientsData } from './ClientsList';
import { newClientSchema } from './new-client.schema';

interface INewClientFormData {
  name: string;
}

const NewClientForm = ({
  onSubmit,
  onCancel,
  data,
  loading,
}: {
  onSubmit: (data: INewClientFormData) => void;
  onCancel: () => void;
  data?: ClientsData;
  loading: boolean;
}) => {
  const newClientForm = useForm<INewClientFormData>({
    mode: 'all',
    resolver: newClientSchema,
  });

  useEffect(() => {
    if (data) newClientForm.setValue('name', data.client_name);
  }, [data]);

  return (
    <Form
      formHook={newClientForm}
      onSubmit={onSubmit}
      className="flex flex-col gap-6"
    >
      <TextField
        formHook={newClientForm}
        name="name"
        label="Client name"
        maxLength={50}
      />
      <div className="flex gap-4">
        <PrimaryButtonWithLoader loading={loading} type="submit" text="Add" />
        <DefaultButton onClick={onCancel} text="Cancel" />
      </div>
    </Form>
  );
};

export default NewClientForm;
