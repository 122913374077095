import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddSkillInfoMutationVariables = Types.Exact<{
  object: Types.AddSkillDetailsInput;
}>;

export type AddSkillInfoMutation = {
  __typename?: 'mutation_root';
  addSkillInfo?: { __typename?: 'AddSkillDetailsOutput'; id: any } | null;
};

export type GetListOfSkillsQueryVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
  _ilike?: Types.InputMaybe<Types.Scalars['String']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetListOfSkillsQuery = {
  __typename?: 'query_root';
  list_Of_Skills: Array<{
    __typename?: 'list_Of_Skills';
    id: any;
    name: string;
    skills_details: Array<{
      __typename?: 'skills_detail';
      rating?: number | null;
      description?: string | null;
    }>;
    sub_skills: Array<{
      __typename?: 'sub_skills';
      main_skill: { __typename?: 'main_skills'; id: any; name: string };
    }>;
  }>;
  list_Of_Skills_aggregate: {
    __typename?: 'list_Of_Skills_aggregate';
    aggregate?: {
      __typename?: 'list_Of_Skills_aggregate_fields';
      count: number;
    } | null;
  };
};

export type EditSkillDetailsMutationVariables = Types.Exact<{
  object: Types.EditSkillDetailsInput;
}>;

export type EditSkillDetailsMutation = {
  __typename?: 'mutation_root';
  editSkillDetails?: {
    __typename?: 'EditSkillDetailsOutput';
    response: string;
  } | null;
};

export type DeleteSkillsMutationVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
}>;

export type DeleteSkillsMutation = {
  __typename?: 'mutation_root';
  delete_sub_skills?: {
    __typename?: 'sub_skills_mutation_response';
    affected_rows: number;
  } | null;
  delete_skills_detail?: {
    __typename?: 'skills_detail_mutation_response';
    affected_rows: number;
  } | null;
  delete_list_Of_Skills?: {
    __typename?: 'list_Of_Skills_mutation_response';
    returning: Array<{ __typename?: 'list_Of_Skills'; id: any }>;
  } | null;
};

export type GetSkillDetailsQueryVariables = Types.Exact<{
  _eq: Types.Scalars['uuid'];
}>;

export type GetSkillDetailsQuery = {
  __typename?: 'query_root';
  list_Of_Skills: Array<{
    __typename?: 'list_Of_Skills';
    id: any;
    name: string;
    skills_details: Array<{
      __typename?: 'skills_detail';
      rating?: number | null;
      description?: string | null;
    }>;
  }>;
};

export function useAddSkillInfoMutation() {
  return Urql.useMutation<
    Operations.AddSkillInfoMutation,
    Operations.AddSkillInfoMutationVariables
  >(Operations.AddSkillInfoDocument);
}

export function useGetListOfSkillsQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetListOfSkillsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetListOfSkillsQuery,
    Operations.GetListOfSkillsQueryVariables
  >({ query: Operations.GetListOfSkillsDocument, ...options });
}

export function useEditSkillDetailsMutation() {
  return Urql.useMutation<
    Operations.EditSkillDetailsMutation,
    Operations.EditSkillDetailsMutationVariables
  >(Operations.EditSkillDetailsDocument);
}

export function useDeleteSkillsMutation() {
  return Urql.useMutation<
    Operations.DeleteSkillsMutation,
    Operations.DeleteSkillsMutationVariables
  >(Operations.DeleteSkillsDocument);
}

export function useGetSkillDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetSkillDetailsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetSkillDetailsQuery,
    Operations.GetSkillDetailsQueryVariables
  >({ query: Operations.GetSkillDetailsDocument, ...options });
}
