import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetManagerQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetManagerQuery = {
  __typename?: 'query_root';
  users: Array<{ __typename?: 'users'; id: any; display_name?: string | null }>;
};

export type InsertDepartmentMutationVariables = Types.Exact<{
  created_by: Types.Scalars['uuid'];
  manager_id: Types.Scalars['uuid'];
  organization_id: Types.Scalars['uuid'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type InsertDepartmentMutation = {
  __typename?: 'mutation_root';
  insert_department_one?: { __typename?: 'department'; id: any } | null;
};

export type GetDepartmentQueryVariables = Types.Exact<{
  organization_id: Types.Scalars['uuid'];
}>;

export type GetDepartmentQuery = {
  __typename?: 'query_root';
  department: Array<{
    __typename?: 'department';
    id: any;
    name: string;
    manager_user?: {
      __typename?: 'users';
      display_name?: string | null;
      id: any;
    } | null;
    memberCount: {
      __typename?: 'department_employee_aggregate';
      aggregate?: {
        __typename?: 'department_employee_aggregate_fields';
        count: number;
      } | null;
    };
  }>;
};

export type GetDepartmentEmployeeQueryVariables = Types.Exact<{
  departmentID: Types.Scalars['uuid'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  organizationId: Types.Scalars['uuid'];
}>;

export type GetDepartmentEmployeeQuery = {
  __typename?: 'query_root';
  department_employee: Array<{
    __typename?: 'department_employee';
    user: {
      __typename?: 'users';
      display_name?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
      account?: { __typename?: 'auth_accounts'; email?: any | null } | null;
    };
  }>;
};

export type GetDepartmentEmployeeLengthQueryVariables = Types.Exact<{
  departmentId: Types.Scalars['uuid'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetDepartmentEmployeeLengthQuery = {
  __typename?: 'query_root';
  department_employee_aggregate: {
    __typename?: 'department_employee_aggregate';
    aggregate?: {
      __typename?: 'department_employee_aggregate_fields';
      count: number;
    } | null;
  };
  department_employee: Array<{
    __typename?: 'department_employee';
    user_id: any;
  }>;
};

export type DeleteDepartmentMutationVariables = Types.Exact<{
  departmentId: Types.Scalars['uuid'];
}>;

export type DeleteDepartmentMutation = {
  __typename?: 'mutation_root';
  delete_department?: {
    __typename?: 'department_mutation_response';
    affected_rows: number;
  } | null;
};

export type EditDepartmentMutationVariables = Types.Exact<{
  manager_id: Types.Scalars['uuid'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
  departmentId: Types.Scalars['uuid'];
}>;

export type EditDepartmentMutation = {
  __typename?: 'mutation_root';
  update_department?: {
    __typename?: 'department_mutation_response';
    affected_rows: number;
  } | null;
};

export function useGetManagerQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetManagerQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetManagerQuery,
    Operations.GetManagerQueryVariables
  >({ query: Operations.GetManagerDocument, ...options });
}

export function useInsertDepartmentMutation() {
  return Urql.useMutation<
    Operations.InsertDepartmentMutation,
    Operations.InsertDepartmentMutationVariables
  >(Operations.InsertDepartmentDocument);
}

export function useGetDepartmentQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetDepartmentQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetDepartmentQuery,
    Operations.GetDepartmentQueryVariables
  >({ query: Operations.GetDepartmentDocument, ...options });
}

export function useGetDepartmentEmployeeQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetDepartmentEmployeeQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetDepartmentEmployeeQuery,
    Operations.GetDepartmentEmployeeQueryVariables
  >({ query: Operations.GetDepartmentEmployeeDocument, ...options });
}

export function useGetDepartmentEmployeeLengthQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetDepartmentEmployeeLengthQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetDepartmentEmployeeLengthQuery,
    Operations.GetDepartmentEmployeeLengthQueryVariables
  >({ query: Operations.GetDepartmentEmployeeLengthDocument, ...options });
}

export function useDeleteDepartmentMutation() {
  return Urql.useMutation<
    Operations.DeleteDepartmentMutation,
    Operations.DeleteDepartmentMutationVariables
  >(Operations.DeleteDepartmentDocument);
}

export function useEditDepartmentMutation() {
  return Urql.useMutation<
    Operations.EditDepartmentMutation,
    Operations.EditDepartmentMutationVariables
  >(Operations.EditDepartmentDocument);
}
