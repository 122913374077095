import { SVGProps } from 'react';
export const WindowsLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="m19.852 7.761-15 2.25A1 1 0 0 0 4 11v12a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V8.75a1.001 1.001 0 0 0-1.148-.989zm25.8-3.519a1.013 1.013 0 0 0-.801-.231l-21 3.15A1 1 0 0 0 23 8.15V23a1 1 0 0 0 1 1h21a1 1 0 0 0 1-1V5c0-.291-.127-.567-.348-.758zM20 26H5a1 1 0 0 0-1 1v12a1 1 0 0 0 .852.989l15 2.25A.999.999 0 0 0 21 41.25V27a1 1 0 0 0-1-1zm25 0H24a1 1 0 0 0-1 1v14.85a1 1 0 0 0 .852.989l21 3.15A.999.999 0 0 0 46 45V27a1 1 0 0 0-1-1z" />
  </svg>
);
