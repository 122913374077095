import { Link } from '@fluentui/react';
import TopFiveEOne from '../../../../assets/TopFiveEvolving/TopFiveEOne.jpg';
import TopFiveEThree from '../../../../assets/TopFiveEvolving/TopFiveEThree.jpg';
import TopFiveETwo from '../../../../assets/TopFiveEvolving/TopFiveETwo.jpg';
import { BookADemo } from '../../Home/Utils';
const MRecentBContainOne = () => {
  return (
    <>
      <div
        className="pt-1 place-content-center py-14 bg-cover bg-no-repeat bg-center sm:h-screen"
        style={{
          backgroundImage:
            'url(../../../../assets/MostRecentBlogBanner/EvolvingTools.jpg)',
        }}
      ></div>
      <div className="text-[28px] sm:text-[3rem] font-normal sm:font-light mx-[20px] sm:mx-[40px] my-[24px]">
        <div className="sm:px-[6px] md-[10px] lg:px-[120px]">
          <div className="text-[44px] font-bold leading-[50px]">
            Top five evolving tools and software for employee productivity
          </div>
          <div className="text-[24px] font-medium my-[12px]">
            How do companies track employee productivity?
          </div>
          <div className="text-[24px] font-semibold my-[24px] italic">
            Are we still using punch cards or computers and spreadsheets like we
            did in the 1940s or are we evolving with the pace of modern
            technological advancements?
          </div>
          <div className="text-[24px] font-medium my-[24px]">
            Traditional methods evolved with the advent of new-age Employee
            Productivity Management tools that provide real-time tracking, task
            management, and collaboration capabilities.
          </div>
          <div className="text-[24px] font-medium my-[24px]">
            Research data indicates that the
            <b>
              {' '}
              demand for such tools increased by 35% as we walked into the
              remote and hybrid work culture post-pandemic
            </b>
            , however many employees believe that these tools lead to close
            monitoring of their daily work habits.
          </div>
          <div className="my-[24px]">
            <img src={TopFiveETwo} alt="one" />
          </div>
          <div className="text-[24px] font-medium my-[24px]">
            This is why we require tools that not only observe an employee’s
            work progress, but focus on the employee’s well-being while being
            mindful of not being invasive These tools offer a wide range of
            benefits that may improve employee experience, help to determine
            their work challenges, and prepare them for the work culture of
            tomorrow. Moreover, these solutions contribute to a positive work
            culture by enhancing employee satisfaction, innovative features, and
            reward systems.
          </div>
          <div className="text-[24px] font-medium my-[24px]">
            Currently, multiple products have taken place in the ecosystem. Each
            of the tools is continuously evolving as per the user's needs. Some
            of the products with their unique capabilities have been mentioned.
          </div>
          <div className="my-[24px]">
            <img src={TopFiveEThree} alt="one" />
          </div>
          <div>
            <div className="text-[24px] font-medium">
              1. <b>Asana</b>- With Asana, you can drive clarity and impact at
              scale by connecting work and workflows to company-wide goals.
            </div>
            <div className="text-[24px] font-medium my-[24px]">
              Asana, a leading project management platform, revolutionizes the
              way teams collaborate and organize their work with its exceptional
              user-friendly interface, along with multiple features. One
              standout feature is its versatile task management system, allowing
              users to create, assign, and prioritize tasks effortlessly and its
              customizable project views, such as boards, lists, and timelines,
              provide teams with flexibility in organizing and visualizing their
              projects with the integration of communication tools including
              comments, attachments, and real-time updates, foster seamless
              collaboration, ensuring that team members stay connected and
              informed throughout the project lifecycle. Additionally, it offers
              integration with multiple apps like Microsoft Teams, Splunk,
              Salesforce, etc. which enhances its usage further.
            </div>
            <div className="text-[24px] font-medium">
              2. <b>Jira</b>- Easy, done right!
            </div>
            <div className="text-[24px] font-medium my-[24px]">
              Jira, a flagship project management tool, and issue tracking tool
              by Atlassian, is a platform that enables teams to efficiently
              manage multiple projects and workflows at a given time, it offers
              a comprehensive set of features to streamline collaboration and
              customize workflows as per project needs.
              <br /> The platform is enabled with a unique feature of issue
              tracking which provides real-time access to project progress and
              issues keeping the stakeholders informed. Its extensive reporting
              and analysis tools help analyze performance and identify
              improvement areas, which enhances the user experience. Jira
              emerges as a powerful solution that not only simplifies project
              management but also enhances team collaboration and project
              visibility.
            </div>
            <div className="text-[24px] font-medium">
              3. <b>Worx Squad</b>- Everything under one roof for productivity
              and engagement.
            </div>
            <div className="text-[24px] font-medium my-[24px]">
              Worx Squad follows the base rule of everything under one roof, one
              license.
              <br /> Squad addresses four pillars – Collaboration, Productivity,
              Engagement, and Wellness. All of it is in one place, always ON,
              with a single license. The platform also provides an integrated
              leave and timesheet management system for a seamless experience
              for both employees and the enterprise. It has been developed with
              a unique feature of custom assessments and training which enables
              employees to stay updated as per industry requirements. To ensure
              the physical and mental well-being of employees the platform
              includes a dashboard for stress and burnout rates graphs and
              wellness nudges. With these dynamic features, the platform aims to
              serve as an all-in-one tool for enterprises.
              <br /> To explore the product features, book a demo call with us!
            </div>
            <div className="text-[24px] font-medium">
              4. <b>Monday.com</b>- A new way of working!
            </div>
            <div className="text-[24px] font-medium my-[24px]">
              Monday.com is a dynamic platform that offers a wide range of
              services with highly customizable workspace options where teams
              can design their workflows based on their specific needs. The
              platform's strength lies in its visual project tracking, employing
              color-coded boards and timelines that provide a clear overview of
              tasks and progress. The platform's
              <b>
                {' '}
                communication tools, such as comments, file sharing, and
                @mentions, facilitate seamless collaboration
              </b>
              . What sets Monday.com apart is its ability to adapt to various
              project management methodologies, making it suitable for teams of
              all sizes and industries.
            </div>
            <div className="text-[24px] font-medium">
              5. <b>ClickUp</b>- Simple and Powerful
            </div>
            <div className="text-[24px] font-medium my-[24px]">
              The platform excels in its versatility, offering customizable
              views such as lists, boards, and timelines to accommodate diverse
              project management preferences. Its comprehensive task management
              capabilities include features like priorities, due dates, and
              dependencies, ensuring teams can organize and execute tasks
              efficiently. The platform's unique feature,{' '}
              <b>
                ClickApps, allows users to tailor their workspace by integrating
                various apps directly into ClickUp
              </b>
              , enhancing functionality and adaptability. Reporting and
              analytics tools provide valuable insights into project
              performance, enabling teams to make data-driven decisions. With
              features like chat, comments, and document sharing, ClickUp
              fosters seamless communication and collaboration within teams.
              Whether for small teams or large enterprises, ClickUp stands out
              as a comprehensive project management solution that combines
              flexibility, functionality, and user-friendly design to elevate
              team collaboration and project execution.
            </div>
            <div className="my-[24px]">
              <img src={TopFiveEOne} alt="one" />
            </div>
            <div className="text-[24px] font-bold underline">Conclusion : </div>
            <div className="text-[24px] font-medium my-[24px]">
              Collaboration tools serve as strategic enablers that facilitate
              continuous growth and development. Beyond improving individual
              engagement, these tools contribute to heightened company
              productivity by providing actionable insights into employee
              performance. To summarize, a Project management tool becomes a
              catalyst for a culture of continuous improvement, shaping a
              workforce that is not only engaged but also actively contributing
              to the organization's overall success.
            </div>
            <div className="text-[24px] font-medium my-[24px]">
              <div>
                <Link
                  className={`font-semibold underline`}
                  rel="noopener noreferrer"
                  aria-label="Worxwide"
                >
                  Worx Squad
                </Link>{' '}
                is a part of{' '}
                <Link
                  className={`font-semibold underline`}
                  href="https://www.worxwide.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Worxwide"
                >
                  About Worxwide
                </Link>
                <span>
                  , based out of US, UK, and India. Worxwide Consulting is a
                  digital growth consulting firm offering bid consulting, sales
                  transformation, user experience, and customer experience
                  design services.
                </span>
              </div>
              <div className="my-[24px]">
                To explore the product features,{' '}
                <span
                  className="underline text-[#185D74] cursor-pointer"
                  onClick={BookADemo}
                >
                  book
                </span>{' '}
                a demo call with us!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MRecentBContainOne;
