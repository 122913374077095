import './RadarAnimation.scss';

const OurMission = () => {
  return (
    <div className="bg-[#185D74] py-[40px] px-[20px] sm:px-[40px] flex flex-col lg:flex-row items-center justify-between gap-8 absolute w-[80%] left-[180px] right-0 transform translate-[-10%, -10%] top-[90%] rounded-xl drop-shadow-lg">
      <div className="text-white w-1/3">
        <div className="text-3xl font-medium">
          Discover how Squad can boost your bottom line
        </div>
        <div className="text-xl my-4">
          Watch our showcase to see how our all-in-one platform streamlines
          operations, enhances teamwork, and boosts productivity effortlessly.
        </div>
      </div>
      <div className="drop-shadow-lg">
        <iframe
          width="750"
          height="400"
          src="https://www.youtube.com/embed/NjTZsfbdMdk?rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="rounded-3xl"
        ></iframe>
      </div>
    </div>
  );
};

export default OurMission;
