import { useHeader, useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import Holidays from '../components/Holidays';

const HolidaysPage = () => {
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();
  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useLayoutEffect(() => {
    setTitle?.('Holidays');
  }, [setTitle]);
  return <Holidays />;
};

export default HolidaysPage;
