import { Stack } from '@fluentui/react';

export const NormalLayout: React.FC = ({ children }) => {
  return (
    <Stack verticalFill>
      <Stack.Item styles={{ root: { minHeight: '100vh' } }}>
        {children}
      </Stack.Item>
    </Stack>
  );
};
