import ProjectManagement from '../Home/assets/ProjectManagement';
import Approve from './assets/Approve.png';
import Calender from './assets/Calender.png';
import TimeSheet from './assets/TimeSheet.png';
import TimelyAlert from './assets/TimelyAlert.png';

const TimeSheetAndLeave = () => {
  return (
    <div className="px-20">
      <div className="flex justify-between gap-8">
        <div>
          <div className="flex items-center gap-2">
            <span>
              <ProjectManagement />
            </span>
            <span className="font-medium">Timesheet and leave</span>
          </div>
          <div className="text-4xl font-medium mt-2">
            Smart leave and timesheet management
          </div>
          <div className="mt-8 text-base">
            Employees can apply for leaves or timesheets on the platform and
            managers can approve or reject.
          </div>
        </div>
        <div className="w-[60%]">
          <img src={TimeSheet} alt="TimeSheet" />
        </div>
      </div>
      <div className="flex justify-between mt-8 gap-4">
        <div className="w-[30%]">
          <img src={Approve} alt="Approve" />
        </div>
        <div className="w-[30%]">
          <img src={Calender} alt="Calender" />
        </div>
        <div className="w-[30%]">
          <img src={TimelyAlert} alt="TimelyAlert" />
        </div>
      </div>
    </div>
  );
};

export default TimeSheetAndLeave;
