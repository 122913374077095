import { DefaultButton } from '@fluentui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlackLinkSvg from '../Home/assets/BlackLinkSvg';
import LinkSvg from '../Home/assets/LinkSvg';
import HorizontalLine from './assets/HorizontalLine';

const ForAdmin = () => {
  const navigate = useNavigate();
  const [linkIconOne, setLinkIconOne] = useState(true);
  return (
    <div className="lg:mx-[6rem] my-[2rem]">
      <div className="my-[2rem]">
        <div className="flex justify-between items-start flex-col lg:flex-row my-[2rem]">
          <div>
            <div className="text-[#185D74] font-medium">01</div>
            <div className="text-[1.5rem] sm:text-[2rem]">
              Sign up as org admin
            </div>
            <div className="text-[#7E7E7E] text-[14px] sm:text-[1rem]">
              The organisation admin first has to sign up and enter some basic
              details of <br /> their organisation.
            </div>
            <div className="py-6 sm:w-[50%]">
              <DefaultButton
                onClick={() => navigate('/login')}
                onMouseEnter={() => setLinkIconOne(false)}
                onMouseLeave={() => setLinkIconOne(true)}
                className="border w-full border-[#FF9900] text-black hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
              >
                <span className="flex gap-4 justify-center items-center">
                  <span className="text-[1rem]">Sign Up As Org Admin</span>
                  <span>{linkIconOne ? <BlackLinkSvg /> : <LinkSvg />}</span>
                </span>
              </DefaultButton>
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/OrgAdmin.png'}
              alt="Credential"
              height={'312px'}
              width={'552px'}
            />
          </div>
        </div>

        <div>
          <HorizontalLine />
        </div>
      </div>
      <div className="my-[2rem]">
        <div className="flex justify-between flex-col lg:flex-row items-start mt-[2rem]">
          <div>
            <div className="text-[#185D74] font-medium">02</div>
            <div className="text-[1.5rem] sm:text-[2rem]">
              Receive email to activate org admin account
            </div>
          </div>
          <div className="mt-[1rem] sm:mt-[0rem]">
            <img
              src={'../../../assets/ReceiveEmail.png'}
              alt="Credential"
              height={'312px'}
              width={'552px'}
            />
          </div>
        </div>
        <div>
          <HorizontalLine />
        </div>
      </div>
      <div className="my-[2rem]">
        <div className="flex justify-between flex-col lg:flex-row items-start my-[2rem]">
          <div>
            <div className="text-[#185D74] font-medium">03</div>
            <div className="text-[1.5rem] sm:text-[2rem]">
              Login to org admin web page (worxsquad.com)
            </div>
          </div>
          <div className="mt-[1rem] sm:mt-[0rem]">
            <img
              src={'../../../assets/LoginOrgAdmin.png'}
              alt="Credential"
              height={'312px'}
              width={'552px'}
            />
          </div>
        </div>
        <div>
          <HorizontalLine />
        </div>
      </div>
      <div className="my-[2rem]">
        <div className="flex justify-between flex-col lg:flex-row items-start mt-[2rem]">
          <div>
            <div className="text-[#185D74] font-medium">04</div>
            <div className="text-[1.5rem] sm:text-[2rem] hidden sm:block">
              <div>Setup basic org details like departments,</div>
              <div>employees, leaves, holidays etc</div>
            </div>
            <div className="text-[1.5rem] sm:text-[2rem] block sm:hidden">
              <div>
                Setup basic org details like departments, employees,
                <br />
                leaves, holidays etc
              </div>
            </div>
          </div>
          <div className="mt-[1rem] sm:mt-[0rem]">
            <img
              src={'../../../assets/Setup.png'}
              alt="Credential"
              height={'375px'}
              width={'552px'}
            />
          </div>
        </div>
        <div>
          <HorizontalLine />
        </div>
      </div>
      <div className="my-[2rem]">
        <div className="flex justify-between flex-col lg:flex-row items-start my-[2rem]">
          <div>
            <div className="text-[#185D74] font-medium">05</div>
            <div className="text-[1.5rem] sm:text-[2rem]">
              Invite employees by adding each one of them
            </div>
          </div>
          <div className="mt-[1rem] sm:mt-[0rem] shadow-lg">
            <img
              src={'../../../assets/InviteEmployee.png'}
              alt="Credential"
              height={'375px'}
              width={'552px'}
            />
          </div>
        </div>
        <div>
          <HorizontalLine />
        </div>
      </div>
      <div className="my-[2rem]">
        <div className="flex justify-between flex-col lg:flex-row items-center sm:items-start my-[2rem]">
          <div>
            <div className="text-[#185D74] font-medium">06</div>
            <div className="text-[1.5rem] sm:text-[2rem]">
              Create password during invite
            </div>
            <div className="text-[#7E7E7E] text-[14px] sm:text-[1rem] w-[100%] sm:w-[50%]">
              Either create a manual password during invite or let system create{' '}
              a password and users will have to activate account as they receive
              an invite. If org admin will create and share password manually
              with employees then accounts get auto activated.
            </div>
          </div>
          <div className="mt-[2rem] sm:mt-[0rem]">
            <img
              src={'../../../assets/Download_Squad_App.png'}
              alt="Credential"
              height={'146px'}
              width={'268px'}
            />
          </div>
        </div>
        <div className="sm:my-4">
          <HorizontalLine />
        </div>
      </div>
    </div>
  );
};
export default ForAdmin;
