import {
  IconButton,
  IModalProps,
  MessageBarType,
  Modal,
  Stack,
  Text,
} from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { useState } from 'react';
import NewProjectForm from './NewProjectForm';
import { useCreateProjectByAdminMutation } from './projects-and-clients.generated';

const NewProjectModal = (props: IModalProps) => {
  const { notification } = useNotification();
  const [, createProject] = useCreateProjectByAdminMutation();
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    props.onDismiss && props.onDismiss();
  };

  return (
    <Modal {...props}>
      <Stack className="p-4" style={{ minWidth: 400 }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Text variant="xLarge">Add new project</Text>
          <IconButton iconProps={{ iconName: 'Cancel' }} onClick={onClose} />
        </Stack>
        <Stack>
          <NewProjectForm
            loading={loading}
            onSubmit={async (data) => {
              try {
                setLoading(true);
                const res = await createProject({
                  client: {
                    clientName: data?.client?.label,
                    clientId: data?.client?.value,
                  },
                  project: {
                    title: data?.projectTitle || '',
                    description: data?.description,
                    isBillable: !data.nonBillable,
                    createChatGroup: false,
                    slug:
                      data.projectTitle?.replace(/\s/g, '-').toUpperCase() ||
                      '',
                  },
                });
                if (res.error) {
                  notification({
                    message: res.error.message?.replace('[GraphQL]', '').trim(),
                    type: MessageBarType.error,
                  });
                  setLoading(false);
                  return;
                }
                notification({
                  message: 'Project created successfully',
                  type: MessageBarType.success,
                });
                setLoading(false);
                onClose();
              } catch (error) {
                console.error(error);
                setLoading(false);
              }
            }}
            onCancel={onClose}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default NewProjectModal;
