import { FC } from 'react';
import Select, { Props } from 'react-select';
import { useCreateReactSelectFluentTheme } from '../../hooks';
import { ISelect, SelectLayout } from './SelectLayout';

export interface ISearchableSelect extends ISelect, Props<any> {
  fixedWidth?: boolean;
  refFunc?: (reference: any) => void;
}

export const SearchableSelect: FC<ISearchableSelect> = ({
  label,
  required,
  error,
  fixedWidth = true,
  refFunc,
  ...props
}) => {
  return (
    <SelectLayout required={required} error={error} label={label}>
      <Select
        ref={refFunc}
        menuPortalTarget={document.body}
        styles={useCreateReactSelectFluentTheme(error, fixedWidth)}
        {...props}
      />
    </SelectLayout>
  );
};
