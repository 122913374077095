import {
  IconButton,
  IModalProps,
  Modal,
  Text,
  useTheme,
} from '@fluentui/react';
import moment from 'moment';
import { FC } from 'react';
import { useFrontend_GetUserByIdQuery } from './employee.generated';

interface IEmployeeViewModal extends IModalProps {
  id: string;
}

const EmployeeViewModal: FC<IEmployeeViewModal> = ({ id, ...props }) => {
  const theme = useTheme();
  const [{ data }] = useFrontend_GetUserByIdQuery({
    variables: { id },
    pause: !id,
    requestPolicy: 'cache-and-network',
  });
  const info = [
    { text: 'Full name', value: data?.user?.display_name },
    { text: 'Email id', value: data?.user?.account?.email },
    {
      text: 'IP Address',
      value: data?.user?.ip_address || 'Not available yet',
    },
    {
      text: 'Account status',
      value: data?.user?.account?.active ? 'Active' : 'Inactive',
    },
    { text: 'Status', value: data?.user?.profile[0]?.activity_status },
    {
      text: 'Last signed in',
      value: data?.user?.last_sign_date_time
        ? moment(data?.user?.last_sign_date_time).format('MMM Do YY hh:mm A')
        : 'Never',
    },
  ];
  const details = [
    { text: 'Total projects completed', value: '0' },
    { text: 'Total holidays taken (In that month)', value: '0' },
    { text: 'Total hours (working hours in that month)', value: '0' },
    {
      text: 'Department name',
      value: data?.user?.department_employees?.[0]?.department.name,
    },
  ];
  return (
    <Modal {...props}>
      <div className="p-5" style={{ width: 800 }}>
        <div className="flex justify-between mb-5">
          <div>
            <Text className="text-2xl">Employee Details</Text>
          </div>
          <div>
            <IconButton
              onClick={() => props?.onDismiss && props?.onDismiss()}
              iconProps={{ iconName: 'Cancel' }}
            />
          </div>
        </div>
        <div className="mb-5">
          <div>
            <div className="flex flex-wrap">
              {info.map((info, index) => (
                <div key={index} className="w-1/2 p-3">
                  <Text className="text-base text-gray-600">{info.text}</Text> :{' '}
                  <Text className="text-base">{info.value}</Text>
                </div>
              ))}
            </div>
            <hr className="bg-gray-800" />
            <div className="flex flex-wrap">
              {details.map((detail, index) => (
                <div key={index} className="w-1/2 p-6">
                  <div>
                    <Text className="text-base text-gray-600">
                      {detail.text}
                    </Text>
                  </div>
                  <div className="my-3">
                    <Text
                      className="text-2xl font-semibold"
                      style={{ color: theme.palette.themePrimary }}
                    >
                      {detail.value}
                    </Text>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeViewModal;
