import { ChoiceGroup, TextField } from '@fluentui/react';

interface IProps {
  state: any;
  handleState: (field: string, value: any) => void;
  handleRating: (field: string, index: number, value: any) => void;
}

const RatingScale = (props: IProps) => {
  const { state, handleState, handleRating } = props;
  const ratingOptions = [
    { key: 'Rating Scale 1-5', text: 'Rating Scale 1-5' },
    { key: 'Rating Scale 1-10', text: 'Rating Scale 1-10' },
  ];
  return (
    <div className="pt-12 flex flex-col gap-y-4">
      <div className="flex flex-col gap-y-1">
        <label className="text-sm text-[#000000] font-semibold">
          Rating Scale
        </label>
        <label className="text-sm text-[#5C5F60] font-normal">
          You can choose between the below 2 rating scales for score
          calculation.
        </label>
      </div>
      <ChoiceGroup
        options={ratingOptions}
        onChange={(e, option) => handleState('ratingScale', option?.key)}
        selectedKey={state.ratingScale}
        styles={{
          flexContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            gap: '24px',
          },
        }}
      />
      <div className="flex gap-x-4 items-center text-sm text-[#201F1E] font-semibold">
        <label>Rating</label>
        <label>Name Your Rating</label>
      </div>
      <div className="flex flex-col gap-y-4">
        {state.rating.map((data: any, index: number) => (
          <div
            className={`flex items-center ${index === 9 ? 'gap-x-9' : 'gap-x-11'}`}
            key={index}
          >
            <label>{data.rating}</label>
            <TextField
              placeholder="Eg. Below expectrations"
              className="w-[25%] ml-1"
              value={data.description}
              onChange={(e, val) => handleRating('description', index, val)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingScale;
