import OpEfficiencySvg from '../Home/assets/OpEfficiencySvg';
import EngagementSvg from './assets/EngagementSvg';
import TalentSvg from './assets/TalentSvg';

export const productivityItems = [
  {
    title: 'Employee Report Cards',
    content:
      'Employees can easily fill timesheets with intuitive UX and Managers will be alert for approval.',
  },
  {
    title: 'Manage Employee Productivity',
    content:
      'Monitor real-time progress of quarterly or annual goals and KPIs on dashboards.',
  },
  {
    title: 'Track Goals & Employee Performance',
    content:
      'Analyse time spent grid behaviours like active time, burnout time, meetings time, tasks time, social time.',
  },
  {
    title: 'Manage Activity & Employee Capacity',
    content:
      'Analyse skill deviations for a role through assessments and suggest trainings to upskill. Remember, less skills equals less productivity.',
  },
];

export const productivityItemsTwo = [
  {
    title: 'Employee Report Cards',
    content:
      'Employees can easily fill timesheets with intuitive UX and Managers will be alert for approval.',
  },
  {
    title: 'Custom Emoji’s & Status',
    content:
      'Employees can easily fill timesheets with intuitive UX and Managers will be alert for approval.',
  },
  {
    title: 'Group Calls & Meetings',
    content:
      'Monitor real-time progress of quarterly or annual goals and KPIs on dashboards.',
  },
  {
    title: 'Guest Access',
    content:
      'Analyse time spent grid behaviours like active time, burnout time, meetings time, tasks time, social time.',
  },
];

export const cxoInsightList = [
  {
    title: 'Operational Efficiency',
    content: 'Manage employee activity with active and away hours ',
    icon: <OpEfficiencySvg />,
  },
  {
    title: 'Talent Outlook',
    content: 'Content Needed',
    icon: <TalentSvg />,
  },
  {
    title: 'Employee Engagement',
    content: 'Content Needed ',
    icon: <EngagementSvg />,
  },
];
