import { Auth } from '@worx.squad/hbp-sdk';
import { AuthAsync } from '@worx.squad/shared';
import { Client as WSClient, createClient as createWSClient } from 'graphql-ws';
import React, { useEffect, useRef, useState } from 'react';
import {
  Client,
  Provider,
  createClient,
  defaultExchanges,
  subscriptionExchange,
} from 'urql';
import { useAuth } from './AuthContext';
import SpinnerCustom from './SpinnerCustom';

const createGQLClient = async (url: string, auth: Auth | AuthAsync) => {
  const token = await auth.getJWTToken();

  const subscriptionClient = createWSClient({
    url: url?.replace('http://', 'ws://')?.replace('https://', 'wss://'),
    connectionParams: async () => {
      return {
        headers: token ? { authorization: `Bearer ${token}` } : undefined,
      };
    },
  });

  return {
    client: createClient({
      url,
      exchanges: [
        ...defaultExchanges,
        subscriptionExchange({
          forwardSubscription: (operation) => {
            return {
              subscribe: (sink) => ({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                unsubscribe: subscriptionClient.subscribe(operation, sink),
              }),
            };
          },
        }),
      ],
      fetchOptions: () => {
        return {
          headers: token ? { authorization: `Bearer ${token}` } : undefined,
        };
      },
    }),
    _sub: subscriptionClient,
  };
};

export interface GraphQLProviderProps {
  children: React.ReactNode;
  auth: Auth | AuthAsync;
  url: string;
}

export const GraphQLProvider = ({
  children,
  auth,
  url,
}: GraphQLProviderProps) => {
  const ref = useRef<{ client: Client; _sub: WSClient }>();
  const { client } = ref.current || ({} as any);
  const [, render] = useState({});
  const { signedIn } = useAuth();

  useEffect(() => {
    let __sub: WSClient;
    const func = async () => {
      const r = await createGQLClient(url, auth);
      ref.current = r;
      const { _sub } = r;
      __sub = _sub;
      render({});
    };
    func();
    auth.onTokenChanged(func);

    return () => {
      __sub?.dispose();
    };
  }, [signedIn, auth, url]);

  if (!client) return <SpinnerCustom />;

  return <Provider value={client}>{children}</Provider>;
};
