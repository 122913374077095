import {
  DefaultButton,
  Dropdown,
  MessageBarType,
  Pivot,
  PivotItem,
  Text,
} from '@fluentui/react';
import {
  useHeader,
  useLayoutState,
  useNotification,
} from '@worx.squad/shared-frontend';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import { EmployeeLeave } from '../components/Leaves/EmployeeLeave';
import ExportLeave from '../components/Leaves/ExportLeave';
import {
  useCreateLeaveCycleMutation,
  useGetLeaveCycleSubscription,
} from '../components/Leaves/leaves.generated';
import { useOrganizationContext } from '../context/OrganizationContext/OrganizationContext';

const monthNames = moment.months();
const year = new Date().getFullYear();

const months = monthNames.map((x, i) => ({
  key: new Date(new Date().setFullYear(year, i, 1)).setHours(0, 0, 0, 0),
  text: `${x} ${year}`,
}));
const monthsCycleEnd = monthNames.map((x, i) => ({
  key: new Date(new Date().setFullYear(year, i, 1)).setHours(0, 0, 0, 0),
  text: `${x === 'January' ? 'December' : monthNames[--i]} ${
    x === 'January' ? year : year + 1
  }`,
}));
export const Leaves = () => {
  const { notification } = useNotification();
  const { organization } = useOrganizationContext();
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();
  const [startDate, setStartDate] = useState<number | null | undefined>();

  const [, setLeaveCycle] = useCreateLeaveCycleMutation();
  const [{ data }, refetch] = useGetLeaveCycleSubscription({
    variables: {
      org_id: organization?.id,
    },
    pause: !organization?.id,
  });

  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    if (data) {
      setStartDate(
        moment(data?.organization_leave_cycles?.[0]?.starting_date)
          .toDate()
          .getTime(),
      );
    }
  }, [data]);

  useEffect(() => {
    setTitle?.('Leaves and Holidays');
  }, [setTitle]);

  const onSubmit = async () => {
    const res = await setLeaveCycle({
      org_id: organization?.id,
      starting_date: moment(
        new Date(startDate! - new Date().getTimezoneOffset() * 60 * 1000),
      ).toISOString(),
    });
    refetch();
    if (res.data?.createLeaveCycle?.leave_cycle_id) {
      notification({
        message: 'Leave Cycle Updated',
        type: MessageBarType.success,
      });
    } else if (res.error) {
      notification({
        message: res.error?.message,
        type: MessageBarType.error,
      });
    }
  };
  const leaveCycleStartMonth =
    data &&
    monthNames[
      moment(data?.organization_leave_cycles[0]?.starting_date).month()
    ];

  return (
    <div className="flex gap-6 flex-col">
      {!isEmpty(data?.organization_leave_cycles) && (
        <div
          className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 p-4"
          role="alert"
        >
          <span className="text-base">
            <strong>Current Cycle: </strong> Your have set{' '}
            <strong>
              1 {leaveCycleStartMonth} {moment().year()}
            </strong>{' '}
            to{' '}
            <strong>
              {moment(data?.organization_leave_cycles[0]?.ending_date)
                .subtract(1, 'day')
                .endOf('month')
                .format('DD MMM YYYY')}
            </strong>{' '}
            as your leave calendar cycle that means the organization's leave
            cycle will renew on{' '}
            <strong>
              {' '}
              1<sup>st</sup> {leaveCycleStartMonth}
            </strong>{' '}
            next year, any left over leaves will be carry forwarded as per
            defined limit to next year
          </span>
        </div>
      )}
      <div className="flex justify-between items-center p-5 border rounded-sm">
        {!isEmpty(data?.organization_leave_cycles) ? (
          <div className="flex gap-5 items-center">
            <Text className="text-base font-bold">Annual Leave Cycle:</Text>
            <div className="flex gap-5 items-center">
              {'Starts from'}
              <Dropdown
                selectedKey={startDate}
                onChange={(_, option) => {
                  setStartDate(option?.key as number);
                }}
                style={{ minWidth: '6rem' }}
                options={months}
              />
              {'To'}
              <Dropdown
                selectedKey={moment(startDate).toDate().setFullYear(year)}
                disabled
                style={{ minWidth: '6rem' }}
                options={monthsCycleEnd}
              />
            </div>
          </div>
        ) : (
          <div className="flex gap-5 items-center">
            <Text className="text-base font-bold">Leave Cycle:</Text>
            <div className="flex gap-5 items-center">
              {'Starts from'}
              <Dropdown
                selectedKey={startDate}
                onChange={(_, option) => {
                  setStartDate(option?.key as number);
                }}
                style={{ minWidth: '6rem' }}
                options={months}
              />
              {'To'}
              <Dropdown
                selectedKey={moment(startDate)
                  .subtract(1, 'day')
                  .toDate()
                  .setFullYear(year)}
                disabled
                style={{ minWidth: '6rem' }}
                options={monthsCycleEnd}
              />
            </div>
          </div>
        )}

        <DefaultButton
          text={!isEmpty(data?.organization_leave_cycles) ? 'Save' : 'Save'}
          onClick={onSubmit}
        />
      </div>
      <Pivot>
        <PivotItem headerText="Annual Employee Leave">
          <EmployeeLeave />
        </PivotItem>
      </Pivot>
      <ExportLeave />
    </div>
  );
};
