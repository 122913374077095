const DeepDiv = () => {
  return (
    <svg
      width="58"
      height="57"
      viewBox="0 0 58 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.9999 57C36.752 57 44.0401 54.0334 49.5036 48.6647C60.8126 37.5449 60.8126 19.4561 49.5036 8.33533C44.0322 2.95536 36.7525 0 28.9999 0C21.2473 0 13.9596 2.96658 8.49606 8.33533C3.03599 13.7041 0 20.8809 0 28.4962C0 36.1112 3.01704 43.2773 8.49606 48.6571C13.9675 54.037 21.2473 56.9924 28.9999 56.9924V57ZM49.4621 14.1984C55.6471 22.7529 55.6471 34.2443 49.4621 42.7987L48.1367 44.6329L38.5079 35.1651L39.1951 33.9213C41.0605 30.5278 41.0605 26.4697 39.1951 23.0762L38.5079 21.8324L48.1367 12.3647L49.4621 14.1984ZM14.4507 8.38039C18.7327 5.38786 23.7585 3.80985 28.9999 3.80985C34.2412 3.80985 39.2667 5.38786 43.549 8.38039L45.4144 9.6836L35.7856 19.1514L34.5206 18.4756C31.0694 16.6414 26.9422 16.6414 23.4911 18.4756L22.2261 19.1514L12.5973 9.6836L14.4627 8.38039H14.4507ZM34.4711 33.884C33.0098 35.3208 31.069 36.1079 28.9997 36.1079C26.9303 36.1079 24.9896 35.3208 23.5282 33.884C20.5111 30.9174 20.5111 26.0981 23.5282 23.1349C26.5452 20.1683 31.454 20.1683 34.4708 23.1349C37.4878 26.1014 37.4882 30.9207 34.4711 33.884ZM8.51442 14.1803L9.83981 12.3535L19.4762 21.8288L18.789 23.0726C16.3724 27.4834 17.1956 33.0233 20.7903 36.561C22.9766 38.7107 25.8954 39.8989 28.9956 39.8989C30.91 39.8989 32.8244 39.4236 34.5047 38.5288L35.7697 37.8531L45.3985 47.3208L43.5331 48.624C39.2323 51.6278 34.195 53.2131 28.9579 53.2131C22.2667 53.2131 15.9756 50.6364 11.2177 45.9693C6.47126 41.3022 3.85074 35.0948 3.85074 28.5002C3.85074 23.3208 5.45559 18.368 8.49902 14.1763L8.51442 14.1803Z"
        fill="white"
      />
    </svg>
  );
};
export default DeepDiv;
