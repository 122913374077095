import {
  ComboBox,
  IDropdownOption,
  IDropdownStyleProps,
  IDropdownStyles,
  IStyleFunctionOrObject,
} from '@fluentui/react';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ICountry, getCountries } from '..';
export interface ICountryFieldProps {
  formHook: UseFormReturn<any>;
  name: string;
  label: string;
  styles?: Partial<
    IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>
  >;
  placeholder?: string;
  required?: boolean;
}

export const CountryPicker: FC<ICountryFieldProps> = ({
  formHook,
  name,
  label,
  styles,
  placeholder,
  required = false,
  ...props
}) => {
  const { control } = formHook;
  const countries: IDropdownOption[] = getCountries()?.map(
    (country: ICountry) => {
      return {
        key: country.code,
        text: country.name,
        data: country.dial_code,
      };
    },
  );
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <ComboBox
          {...props}
          required={required}
          placeholder={placeholder || ''}
          label={label}
          onChange={(e, option) => {
            onChange(option?.key);
          }}
          onBlur={onBlur}
          selectedKey={value}
          errorMessage={error && error.message}
          options={countries}
          styles={styles}
          calloutProps={{
            calloutMaxHeight: 300,
          }}
          allowFreeform
          autoComplete="on"
        />
      )}
    />
  );
};
