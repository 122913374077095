import ProjectManagement from '../Home/assets/ProjectManagement';
import AiAssistanceImg from './assets/AiAssistanceImg.png';

const AiAssistance = () => {
  return (
    <div className="px-20 flex gap-2">
      <div className="w-1/2">
        <div className="flex items-center gap-2">
          <span>
            <ProjectManagement />
          </span>
          <span className="font-medium text-white">AI Assistance</span>
        </div>
        <div className="text-4xl font-medium mt-2 text-white">
          AI assistant to ease out your <br /> work & tracking
        </div>
        <div className="mt-8 text-base text-white">
          Missed a meeting or want to see how you did last week,
          <br /> AI partner works with you to assist when required.
        </div>
      </div>
      <div className="w-[60%]">
        <img src={AiAssistanceImg} alt="AI Assistance" />
      </div>
    </div>
  );
};

export default AiAssistance;
