import {
  IconButton,
  MessageBarType,
  Modal,
  TextField as NormalTextField,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import { useAuth } from '@worx.squad/hbp-react';
import {
  AddressForm,
  DatePickerField,
  DropdownField,
  PrimaryButtonWithLoader,
  SCloseButton,
  SDatePickerStyle,
  SDatePickerTextFieldStyle,
  SDropdownStyle,
  STextAreaFieldStyle,
  STextFieldStyle,
  SUploadFieldStyle,
  TextField,
  TimezonePicker,
  UploadField,
  useNotification,
} from '@worx.squad/shared-frontend';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { RcFile } from 'rc-upload/lib/interface';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { uploadFile } from '../../helpers/file-upload';
import { useInsertStorageFileMutation } from '../../helpers/file.generated';
import { auth } from '../../helpers/hbp-helper';
import {
  useCreateOrganizationMutation,
  useInsertAddressMutation,
} from './organization-register.generated';
import onBoardingOrganizationSchema from './organization-register.schema';
import { IAddress } from './types';
import { hourPickerOptions, workingDays, workingHours } from './utils';

export interface IOrganization {
  organization_name: string;
  organization_seat_no: string;
  organization_employee_no: string;
  logo_file?: RcFile[] | null;
  logo_file_id: string;
  org_start_time: string;
  org_end_time: string;
  working_hours: string;
  working_days: string;
  holiday_list_file?: RcFile[] | null;
  holiday_file_id: string;
  timezone_name: string;
  anniversary_date: string | null;
}

const initialValues = {
  organization_name: '',
  organization_seat_no: '',
  organization_employee_no: '',
  logo_file: null,
  logo_file_id: '',
  org_start_time: '08:00',
  org_end_time: '17:00',
  working_hours: '8',
  working_days: '5',
  holiday_list_file: null,
  holiday_file_id: '',
  timezone_name: moment.tz.guess(),
  anniversary_date: '',
};

export const OrganizationRegisterForm = () => {
  const { notification } = useNotification();
  const navigate = useNavigate();
  const { user } = useAuth();
  const organizationId = uuid();
  const [visible, setVisible] = useState<boolean>(false);
  const [address, setAddress] = useState<IAddress>({} as IAddress);
  const [, insertFile] = useInsertStorageFileMutation();
  const [, createOrganization] = useCreateOrganizationMutation();
  const [, insertAddress] = useInsertAddressMutation();

  const [loading, setLoading] = useState<boolean>(false);

  const organizationForm = useForm<IOrganization>({
    mode: 'all',
    resolver: onBoardingOrganizationSchema,
    defaultValues: initialValues,
  });
  const { handleSubmit } = organizationForm;

  const onAddressChange = (address: IAddress) => {
    setAddress(address);
    setVisible(false);
  };

  const onUploadLogo = async (file: RcFile) => {
    if (file) {
      const { id } = await uploadFile({
        file,
        insertFile,
        path: `/public/organization/${organizationId}/logo/logo_${uuid()}_${
          file?.name
        }`,
        data: { created_by: user?.id + '', type: 'IMAGE' },
      });
      return id;
    }
    return null;
  };
  const onUploadHolidayList = async (file: RcFile) => {
    if (file) {
      const { id } = await uploadFile({
        file,
        insertFile,
        path: `/public/organization/${organizationId}/holiday/holiday_${uuid()}_${
          file?.name
        }`,
        data: { created_by: user?.id + '', type: 'IMAGE' },
      });
      return id;
    }
    return null;
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const logo_file_id = await onUploadLogo(data?.logo_file?.[0]);
      const holiday_file_id = await onUploadHolidayList(
        data?.holiday_list_file?.[0],
      );
      const { data: addressData } = await insertAddress({ object: address });

      const { data: createOrganizationQuery } = await createOrganization({
        object: {
          addressId: get(addressData, 'insert_address_one.id', ''),
          employeeNo: data.organization_employee_no,
          endTime: data.org_end_time,
          holidayFileId: holiday_file_id,
          logoFileId: logo_file_id,
          organizationName: data.organization_name,
          seatNo: data.organization_seat_no,
          startTime: data.org_start_time,
          timezoneName: data.timezone_name,
          workingDays: data.working_days,
          workingHours: data.working_hours,
          anniversaryDate: data.anniversary_date,
        },
      });

      if (createOrganizationQuery) {
        notification({
          message: 'Organization details saved successfully',
          type: MessageBarType.success,
        });
        setAddress({} as IAddress);

        await auth.refreshSession();

        navigate('/dashboard');
      } else {
        notification({
          message: 'Organization  details failed to saved',
          type: MessageBarType.error,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const newAddress = address?.address_line
    ? `${address?.address_line},\n${address?.landmark}, ${address?.state}, ${address?.country},\nPinCode: ${address?.pin_code}`
    : '';

  return (
    <Stack className="w-full my-2">
      <Stack verticalFill className="w-full">
        <Stack.Item>
          <Text variant={'xLarge'}>Enter your details below:</Text>
        </Stack.Item>
        <Stack.Item>
          <Stack verticalFill tokens={{ childrenGap: 5 }}>
            <Stack.Item grow className="pt-2">
              <TextField
                required
                disabled={loading}
                formHook={organizationForm}
                placeholder="Enter organization name"
                name="organization_name"
                label="Organization Name"
                styles={STextFieldStyle}
              />
            </Stack.Item>

            <Stack.Item grow>
              <UploadField formHook={organizationForm} name="logo_file">
                <NormalTextField
                  disabled={loading}
                  value={organizationForm.watch('logo_file')?.[0]?.name}
                  label="Logo"
                  styles={SUploadFieldStyle}
                  suffix="Upload"
                  placeholder="Upload logo here"
                  description="Only jpg, png and svg files allowed"
                />
              </UploadField>
            </Stack.Item>
            <Stack.Item grow>
              <Stack horizontal tokens={{ childrenGap: 25 }}>
                <Stack.Item grow>
                  <TextField
                    disabled={loading}
                    required
                    formHook={organizationForm}
                    name="organization_seat_no"
                    label="Number of Seats"
                    styles={STextFieldStyle}
                    placeholder="Enter total seat count"
                  />
                </Stack.Item>
                <Stack.Item grow>
                  <TextField
                    disabled={loading}
                    required
                    formHook={organizationForm}
                    name="organization_employee_no"
                    placeholder="Enter total employee count"
                    label="Number of Employees"
                    styles={STextFieldStyle}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 25 }}>
              <Stack.Item className="w-full">
                <DropdownField
                  className="w-full"
                  disabled={loading}
                  formHook={organizationForm}
                  required
                  name="org_start_time"
                  label="Office Start Hour"
                  placeholder="Select office start time"
                  ariaLabel="Select a date"
                  styles={SDropdownStyle}
                  calloutProps={{
                    calloutMaxHeight: 300,
                  }}
                  options={hourPickerOptions}
                />
              </Stack.Item>
              <Stack.Item className="w-full">
                <DropdownField
                  className="w-full"
                  disabled={loading}
                  formHook={organizationForm}
                  required
                  name="org_end_time"
                  label="Office End Hour"
                  placeholder="Select office end time"
                  ariaLabel="Select a date"
                  styles={SDropdownStyle}
                  options={hourPickerOptions}
                  calloutProps={{
                    calloutMaxHeight: 300,
                  }}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 25 }}>
              <Stack.Item className="w-full">
                <DropdownField
                  disabled={loading}
                  formHook={organizationForm}
                  name="working_hours"
                  required
                  label="Working Hours"
                  placeholder="Select office working hours"
                  options={workingHours}
                  styles={SDropdownStyle}
                  className="w-full"
                  calloutProps={{
                    calloutMaxHeight: 287,
                  }}
                />
              </Stack.Item>
              <Stack.Item className="w-full">
                <DropdownField
                  required
                  disabled={loading}
                  formHook={organizationForm}
                  name="working_days"
                  placeholder="Select office working days"
                  label="Working Days"
                  options={workingDays}
                  styles={SDropdownStyle}
                  className="w-full"
                  calloutProps={{
                    calloutMaxHeight: 287,
                  }}
                />
              </Stack.Item>
            </Stack>
            <Stack.Item grow>
              <TimezonePicker
                disabled={loading}
                formHook={organizationForm}
                placeholder="Select current timezone"
                name="timezone_name"
                label="Timezone"
                required
                styles={SDropdownStyle}
              />
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 25 }}>
              <Stack.Item grow>
                <UploadField
                  formHook={organizationForm}
                  name="holiday_list_file"
                >
                  <NormalTextField
                    disabled={loading}
                    value={
                      organizationForm.watch('holiday_list_file')?.[0]?.name
                    }
                    label="Holiday List"
                    placeholder="Upload holiday file here"
                    styles={SUploadFieldStyle}
                    suffix="Upload"
                    description="Only ics files allowed"
                  />
                </UploadField>
              </Stack.Item>
              <Stack.Item grow>
                <DatePickerField
                  disabled={loading}
                  formHook={organizationForm}
                  name="anniversary_date"
                  label="Organization anniversary"
                  placeholder="Select organization anniversary date"
                  ariaLabel="Select a date"
                  styles={SDatePickerStyle}
                  textField={{
                    styles: SDatePickerTextFieldStyle,
                  }}
                />
              </Stack.Item>
            </Stack>
            <Stack.Item grow>
              <NormalTextField
                label="HQ Address"
                disabled={loading}
                placeholder="Enter office address"
                styles={STextAreaFieldStyle}
                required
                multiline
                autoAdjustHeight
                value={newAddress}
                onClick={() => setVisible(true)}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack
          horizontal
          horizontalAlign="end"
          verticalAlign="center"
          grow
          className="my-5"
        >
          <PrimaryButtonWithLoader
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            text="Save & Proceed"
          />
        </Stack>
      </Stack>
      <Modal
        isOpen={visible}
        onDismiss={() => setVisible(false)}
        styles={{
          main: {
            borderRadius: 8,
          },
        }}
      >
        <>
          <Stack
            horizontal
            horizontalAlign="space-between"
            className="px-10 pt-10 pb-3"
          >
            <StackItem>
              <Text variant="xLarge">Address Form</Text>
            </StackItem>
            <StackItem>
              <IconButton
                iconProps={{ iconName: 'ChromeClose' }}
                styles={SCloseButton}
                onClick={() => setVisible(false)}
              />
            </StackItem>
          </Stack>
          <Stack verticalFill className="px-10 pb-10">
            <StackItem>
              <AddressForm onSubmit={onAddressChange} address={address} />
            </StackItem>
          </Stack>
        </>
      </Modal>
    </Stack>
  );
};
