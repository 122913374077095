import { MessageBarType } from '@fluentui/react';
import {
  AuthLayout,
  ForgotForm,
  LoginVector,
  useLayoutState,
  useNotification,
} from '@worx.squad/shared-frontend';
import { get, has } from 'lodash';
import { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { CombinedError } from 'urql';
import { auth } from '../helpers/hbp-helper';

export const ForgotPassword = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);
  const { notification } = useNotification();

  const navigate = useNavigate();

  const handleSubmit = async ({ email }: { email: string }) => {
    try {
      await auth.requestPasswordChange(email);

      notification({
        message: 'We send you an email with reset password link',
        type: MessageBarType.success,
      });

      navigate('/login');
    } catch (error) {
      if (error instanceof CombinedError) {
        notification({
          message: error.message,
          type: MessageBarType.error,
        });
      } else if (has(error, 'response')) {
        notification({
          message: get(error, 'response.data.message', ''),
          type: MessageBarType.error,
        });
      }
    }
  };

  return (
    <AuthLayout vector={<LoginVector />} route="home">
      <Helmet>
        <title>Forgot Password | Worx Squad</title>
      </Helmet>
      <ForgotForm
        onSubmit={handleSubmit}
        heading="Forgot Password"
        subHeading="Enter your registered Email ID and the link to change your password will be sent to you shortly"
      />
    </AuthLayout>
  );
};
