import { Stack, Text } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import {
  Form,
  PrimaryButtonWithLoader,
  STextFieldStyle,
  TextField,
} from '../..';
import { forgotSchema } from './forgotform.schema';

interface IForgotForm {
  onSubmit: (props: any) => void;
  heading: string;
  subHeading: string;
}

export const ForgotForm = ({ onSubmit, heading, subHeading }: IForgotForm) => {
  const forgotForm = useForm({
    defaultValues: { email: '' },
    resolver: forgotSchema,
  });

  return (
    <div className="p-4">
      <Form formHook={forgotForm} onSubmit={onSubmit}>
        <Stack verticalAlign="center" verticalFill tokens={{ childrenGap: 30 }}>
          <Stack.Item className="pb-10">
            <Stack>
              <Text className="pb-2" variant="xxLarge">
                {heading}
              </Text>
              <Text>{subHeading}</Text>
            </Stack>
          </Stack.Item>
          <Stack.Item grow>
            <TextField
              name="email"
              formHook={forgotForm}
              styles={STextFieldStyle}
              label="Registered Email ID"
            />
          </Stack.Item>
          <Stack.Item grow className="flex gap-4">
            <Stack horizontalAlign="center" verticalAlign="center">
              <PrimaryButtonWithLoader
                onClick={forgotForm.handleSubmit(onSubmit)}
                size={500}
                text="Send"
                className="w-[92vw] lg:w-auto"
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </Form>
    </div>
  );
};
