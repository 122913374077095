import { DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useNavigate } from 'react-router-dom';
import DownloadApp from './DownloadApp';
import LottieAnimation from './LottieAnimation';
import OurMission from './OurMission';
import BlackLinkSvg from './assets/BlackLinkSvg';
import LinkSvg from './assets/LinkSvg';

export const Starter = () => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const navigate = useNavigate();

  return (
    <div className="relative">
      <div className="text-lg sm:h-screen bg-[#1A1A1A] px-20">
        <div className="mx-[20px] sm:mx-[40px] md:flex justify-between">
          <div className="flex flex-col w-full xl:w-2/3 self-center pt-[50px] sm:pt-[180px]">
            <div className="text-white font-semibold font-poppins text-[48px] sm:text-[72px] leading-[3.5rem] sm:leading-[5rem]">
              20% productivity boost <br /> assured.
            </div>
            <div className="text-black text-[14px] mt-5">
              <div>
                <p className="text-[#FF9900] text-[22px] pl-[2px]">
                  Enterprise Productivity & Digital Workplace Software
                </p>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 mb-[6rem] sm:pt-[76px] md:pb-[282px]">
              <div>
                <DefaultButton
                  onClick={() => navigate('/contact-us')}
                  className="bg-[#1A1A1A] w-full border border-[#FF9900] text-white hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
                >
                  <span className="flex gap-4 justify-center items-center">
                    <span className="text-[1rem] font-semibold">
                      Get Started
                    </span>
                    <span>
                      <LinkSvg />
                    </span>
                  </span>
                </DefaultButton>
              </div>
              <div>
                <DefaultButton
                  onClick={showModal}
                  className="w-full border border-[#FF9900] text-white bg-[#FF9900] hover:bg-[#e19a30] hover:text-white px-6 py-6 ease-in-out duration-300"
                >
                  <span className="flex gap-4 justify-center items-center">
                    <span className="text-[1rem] text-black font-semibold">
                      Download Squad For Free
                    </span>
                    <span>
                      <BlackLinkSvg />
                    </span>
                  </span>
                </DefaultButton>
                <DownloadApp
                  showModal={showModal}
                  isModalOpen={isModalOpen}
                  hideModal={hideModal}
                />
              </div>
            </div>
          </div>
          <div className="mt-[-24px] hidden xl:block">
            <LottieAnimation />
          </div>
        </div>
      </div>
      <OurMission />
    </div>
  );
};
