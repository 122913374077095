import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrganizationEmployeeWithFilterQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  roleId?: Types.InputMaybe<Types.Scalars['uuid']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetOrganizationEmployeeWithFilterQuery = {
  __typename?: 'query_root';
  organization_employee: Array<{
    __typename?: 'organization_employee';
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      last_sign_date_time?: any | null;
      account?: {
        __typename?: 'auth_accounts';
        active: boolean;
        email?: any | null;
      } | null;
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
      department_employees: Array<{
        __typename?: 'department_employee';
        department: { __typename?: 'department'; id: any; name: string };
      }>;
    };
  }>;
  filtered_total: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
  active: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
  inactive: {
    __typename?: 'organization_employee_aggregate';
    aggregate?: {
      __typename?: 'organization_employee_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetDeletedUsersOfOrganizationQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
}>;

export type GetDeletedUsersOfOrganizationQuery = {
  __typename?: 'query_root';
  organization_employee: Array<{
    __typename?: 'organization_employee';
    user: {
      __typename?: 'users';
      id: any;
      display_name?: string | null;
      last_sign_date_time?: any | null;
      account?: {
        __typename?: 'auth_accounts';
        active: boolean;
        email?: any | null;
      } | null;
      profile: Array<{
        __typename?: 'profile';
        organization_role?: {
          __typename?: 'organization_roles';
          id: any;
          role_title: string;
        } | null;
      }>;
      department_employees: Array<{
        __typename?: 'department_employee';
        department: { __typename?: 'department'; id: any; name: string };
      }>;
    };
  }>;
};

export type SaveEmployeeMutationVariables = Types.Exact<{
  object: Types.SaveEmployeeInput;
}>;

export type SaveEmployeeMutation = {
  __typename?: 'mutation_root';
  saveEmployee?: { __typename?: 'SaveEmployeeOutput'; userId: string } | null;
};

export type GetDepartmentByOrgIdForAdminQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  departmentText?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetDepartmentByOrgIdForAdminQuery = {
  __typename?: 'query_root';
  department: Array<{ __typename?: 'department'; id: any; name: string }>;
};

export type GetOrganizationRolesByOrgIdQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  roleText?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetOrganizationRolesByOrgIdQuery = {
  __typename?: 'query_root';
  organization_roles: Array<{
    __typename?: 'organization_roles';
    id: any;
    role_title: string;
  }>;
};

export type GetManagersByOrgIdQueryVariables = Types.Exact<{
  orgId: Types.Scalars['uuid'];
  managerText?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetManagersByOrgIdQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    my_organization?: {
      __typename?: 'organization_user';
      role_title?: string | null;
    } | null;
  }>;
};

export type Frontend_GetUserByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;

export type Frontend_GetUserByIdQuery = {
  __typename?: 'query_root';
  user?: {
    __typename?: 'users';
    id: any;
    display_name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    last_sign_date_time?: any | null;
    ip_address?: any | null;
    account?: {
      __typename?: 'auth_accounts';
      email?: any | null;
      active: boolean;
    } | null;
    profile: Array<{
      __typename?: 'profile';
      activity_status?: string | null;
      organization_role?: {
        __typename?: 'organization_roles';
        id: any;
        role_title: string;
      } | null;
    }>;
    department_employees: Array<{
      __typename?: 'department_employee';
      department: { __typename?: 'department'; id: any; name: string };
    }>;
    organization_employees: Array<{
      __typename?: 'organization_employee';
      manager_id?: any | null;
    }>;
  } | null;
};

export type DeleteEmployeeMutationVariables = Types.Exact<{
  userId: Types.Scalars['String'];
}>;

export type DeleteEmployeeMutation = {
  __typename?: 'mutation_root';
  deleteEmployee?: {
    __typename?: 'DeleteEmployeeOutput';
    success?: boolean | null;
  } | null;
};

export function useGetOrganizationEmployeeWithFilterQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetOrganizationEmployeeWithFilterQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetOrganizationEmployeeWithFilterQuery,
    Operations.GetOrganizationEmployeeWithFilterQueryVariables
  >({
    query: Operations.GetOrganizationEmployeeWithFilterDocument,
    ...options,
  });
}

export function useGetDeletedUsersOfOrganizationQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetDeletedUsersOfOrganizationQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetDeletedUsersOfOrganizationQuery,
    Operations.GetDeletedUsersOfOrganizationQueryVariables
  >({ query: Operations.GetDeletedUsersOfOrganizationDocument, ...options });
}

export function useSaveEmployeeMutation() {
  return Urql.useMutation<
    Operations.SaveEmployeeMutation,
    Operations.SaveEmployeeMutationVariables
  >(Operations.SaveEmployeeDocument);
}

export function useGetDepartmentByOrgIdForAdminQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetDepartmentByOrgIdForAdminQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetDepartmentByOrgIdForAdminQuery,
    Operations.GetDepartmentByOrgIdForAdminQueryVariables
  >({ query: Operations.GetDepartmentByOrgIdForAdminDocument, ...options });
}

export function useGetOrganizationRolesByOrgIdQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetOrganizationRolesByOrgIdQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetOrganizationRolesByOrgIdQuery,
    Operations.GetOrganizationRolesByOrgIdQueryVariables
  >({ query: Operations.GetOrganizationRolesByOrgIdDocument, ...options });
}

export function useGetManagersByOrgIdQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetManagersByOrgIdQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetManagersByOrgIdQuery,
    Operations.GetManagersByOrgIdQueryVariables
  >({ query: Operations.GetManagersByOrgIdDocument, ...options });
}

export function useFrontend_GetUserByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.Frontend_GetUserByIdQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.Frontend_GetUserByIdQuery,
    Operations.Frontend_GetUserByIdQueryVariables
  >({ query: Operations.Frontend_GetUserByIdDocument, ...options });
}

export function useDeleteEmployeeMutation() {
  return Urql.useMutation<
    Operations.DeleteEmployeeMutation,
    Operations.DeleteEmployeeMutationVariables
  >(Operations.DeleteEmployeeDocument);
}
