import {
  DefaultButton,
  IconButton,
  MessageBarType,
  Stack,
} from '@fluentui/react';
import { OperationContext } from '@urql/core';
import {
  Form,
  PrimaryButtonWithLoader,
  TextField,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import { trainingTypeAddSchema } from './training-type.schema';
import {
  useCreateTriningTypeOrgAdminMutation,
  useGetUserQuery,
} from './trainingtype.generated';

interface IFormValues {
  trainingType: string;
  metaTags: string;
}

const TrainingTypeAddForm = ({
  onCancel,
  refetch,
}: {
  onCancel: () => void;
  refetch?: (opts?: Partial<OperationContext> | undefined) => void;
}) => {
  const roleAddForm = useForm<IFormValues>({ resolver: trainingTypeAddSchema });
  const { handleSubmit } = roleAddForm;
  const { notification } = useNotification();

  const { organization } = useOrganizationContext();

  const [{ data: user_id }] = useGetUserQuery({
    variables: { id: organization?.id },
  });

  const [{ fetching: loading }, insertTrainingType] =
    useCreateTriningTypeOrgAdminMutation();

  const [metaOption, setMeta] = useState<Array<any>>([]);
  const [addedMeta, setAddedMeta] = useState<Array<any>>([]);
  const [tag, setTag] = useState<string>('');

  const [error, setError] = useState('');
  const [input, setInput] = useState<string>('');

  const isFormValid = (assessmentType: string, metaTags: string) => {
    return metaTags !== '' && assessmentType !== '';
  };

  const onSubmit = async (data: IFormValues) => {
    if (input !== '') return;

    let metaTags = '';

    addedMeta.map((tag) => {
      metaTags = metaTags + tag.label + ',';
    });

    metaTags = metaTags.substring(0, metaTags.length - 1);

    try {
      if (isFormValid(data.trainingType, metaTags)) {
        const res = await insertTrainingType({
          objects: {
            organization_id: organization?.id,
            name: data.trainingType,
            meta_tags: metaTags,
            user_id: user_id?.organization_by_pk?.user.id,
            status: true,
          },
        });
        if (res.error) {
          notification({
            message: res.error.message,
            type: MessageBarType.error,
          });
        } else {
          notification({
            message: `Training Type created!`,
            type: MessageBarType.success,
          });
          refetch && refetch();
          onCancel();
        }
      } else {
        if (metaTags === '') setError('Please add meta tag');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChange = (e: any) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e: any) => {
    const { key } = e;
    const trimmedInput = input.trim();
    if (
      key === 'Enter' &&
      trimmedInput.length &&
      addedMeta.filter((item) => item.value === trimmedInput).length === 0
    ) {
      e.preventDefault();
      setAddedMeta((prevState) => [
        ...prevState,
        { label: trimmedInput, value: trimmedInput },
      ]);
      setInput('');
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} formHook={roleAddForm}>
      <div className="h-auto">
        <div>
          <TextField
            placeholder="Enter Training Type"
            formHook={roleAddForm}
            name="trainingType"
            label="Training Type"
            required
          />
        </div>

        <div>
          <label className="py-1 text-[#201F1E] text-sm font-normal leading-5">
            Add Tags <span className="text-[#A4262C]">*</span>
          </label>
          <div className="container">
            {addedMeta.map((tag) => (
              <div className="tag  bg-[#F3F2F1]">
                {tag.value}{' '}
                <span
                  onClick={() => {
                    setAddedMeta(
                      addedMeta.filter((item) => item.value !== tag.value),
                    );
                  }}
                  className="cursor-pointer px-1 flex  justify-center"
                >
                  <IconButton
                    className="h-6"
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="remove tag"
                    style={{
                      color: 'grey',
                      backgroundColor: '#F3F2F1',
                      width: 18,
                    }}
                  />
                </span>
              </div>
            ))}
            <TextField
              placeholder="Press enter to add the tag"
              formHook={roleAddForm}
              name="metaTags"
              borderless
              value={input}
              onKeyDown={onKeyDown}
              onChange={onChange}
            ></TextField>
          </div>
          <span className="text-[#A4262C]">{error}</span>
        </div>
        <Stack tokens={{ childrenGap: 20 }} horizontal className="mt-4">
          <Stack.Item>
            <PrimaryButtonWithLoader loading={loading} type="submit">
              Add
            </PrimaryButtonWithLoader>
          </Stack.Item>
          <Stack.Item>
            <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
          </Stack.Item>
        </Stack>
      </div>
    </Form>
  );
};

export default TrainingTypeAddForm;
