import { FAQ } from './FAQ';

const WhatYouGet = () => {
  return (
    <div className="bg-white w-full flex justify-center p-20">
      <div className="w-[80%]">
        <div className="text-[28px] sm:text-[3rem] my-[2rem]">
          What all will you get with Worx.Squad?
        </div>
        <div className="border border-[#FF9900] mb-6">
          <FAQ />
        </div>
      </div>
    </div>
  );
};

export default WhatYouGet;
