import { Link, Modal } from '@fluentui/react';
import { AppVersion } from '../../../configs';
import appDownload from './app-download';
import { AppleLogo, LinuxLogo, WindowsLogo } from './assets';

interface Props {
  isModalOpen: boolean;
  hideModal: () => void;
  showModal: () => void;
}

export default function DownloadApp({ isModalOpen, hideModal }: Props) {
  return (
    <div>
      <Modal isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false}>
        <div className="p-5 flex flex-col ">
          <div className="text-center font-light">
            <div className="text-2xl">Download Worx Squad {AppVersion}</div>
          </div>
          <div className="flex justify-around gap-2 mt-6 mb-4">
            <div
              className={
                'flex flex-col gap-1 justify-center align-middle items-center'
              }
            >
              <div
                onClick={() => {
                  window.open(appDownload.windowsStore, '_blank');
                  hideModal();
                }}
                className="text-center hover:bg-gray-300 rounded-md p-2 cursor-pointer"
              >
                <WindowsLogo className="text-9xl" />
                <div>Windows x64</div>
              </div>
              <div>
                <Link
                  onClick={() => {
                    window.open(appDownload.windows, '_blank');
                    hideModal();
                  }}
                >
                  Unsigned Windows EXE
                </Link>
              </div>
            </div>
            <div
              className={
                'flex flex-col gap-1 justify-center align-middle items-center'
              }
            >
              <div
                onClick={() => {
                  window.open(appDownload.macSilicon, '_blank');
                  hideModal();
                }}
                className="text-center hover:bg-gray-300 rounded-md p-2 cursor-pointer"
              >
                <AppleLogo className="text-9xl" />
                <div>Mac OS X</div>
              </div>
              <div>
                <Link
                  onClick={() => {
                    window.open(appDownload.mac, '_blank');
                    hideModal();
                  }}
                >
                  Mac Intel
                </Link>
              </div>
            </div>
            <div
              onClick={() => {
                window.open(appDownload.linux, '_blank');
                hideModal();
              }}
              className="text-center hover:bg-gray-300 rounded-md p-2 cursor-pointer"
            >
              <LinuxLogo className="text-9xl" />
              <div>Linux</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
