import ProjectManagement from '../Home/assets/ProjectManagement';
import ControlImg from './assets/ControlImg.png';
import InsightImg from './assets/InsightImg.png';
import ResourceMngImg from './assets/ResourceMngImg.png';

const ResourceManagement = () => {
  return (
    <div className="px-20">
      <div className="flex gap-8">
        <div>
          <div className="flex items-center gap-2">
            <span>
              <ProjectManagement />
            </span>
            <span className="font-medium text-white">Resource Management</span>
          </div>
          <div className="text-4xl font-medium mt-2 text-white">
            Efficient resource management at your fingertips.
          </div>
          <div className="mt-8 text-base text-white">
            Allocate and monitor workforce resources across projects.
          </div>
        </div>
        <div className="w-[60%]">
          <img src={ResourceMngImg} alt="ResourceMngImg" />
        </div>
      </div>
      <div className="flex justify-between mt-8 ">
        <div className="w-[60%]">
          <img src={InsightImg} alt="InsightImg" />
        </div>
        <div className="w-[32%]">
          <img src={ControlImg} alt="ControlImg" />
        </div>
      </div>
    </div>
  );
};

export default ResourceManagement;
