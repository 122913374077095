import { IconButton, IModalProps, Modal, Text } from '@fluentui/react';
import { OperationContext } from '@urql/core';
import RoleAddForm from './RoleAddForm';

interface IRoleAddModal extends IModalProps {
  refetchRoles?: (opts?: Partial<OperationContext> | undefined) => void;
}

const RoleAddModal = ({ refetchRoles, ...props }: IRoleAddModal) => {
  return (
    <Modal {...props}>
      <div className="p-5 w-96">
        <div className="flex  justify-between mb-6">
          <div>
            <Text className="text-2xl">Add new role</Text>
          </div>
          <div>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={() => props?.onDismiss && props?.onDismiss()}
            />
          </div>
        </div>
        <div>
          <RoleAddForm
            refetch={refetchRoles}
            onCancel={() => props?.onDismiss && props?.onDismiss()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RoleAddModal;
