import { useLayoutState, useQueryString } from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect } from 'react';
import AuthScreen from '../components/AuthScreen/AuthScreen';

const DropboxAuth = () => {
  const { search, hash } = useQueryString();

  const dropboxCode = search.get('code');
  const error = hash.get('#error');

  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  useEffect(() => {
    if (dropboxCode) {
      navigator.clipboard.writeText(dropboxCode);
      window.location?.replace(
        `worx-squad://open-app?dropboxCode=${dropboxCode}`,
      );
    }
  }, [dropboxCode]);

  return <AuthScreen error={!!error} />;
};

export default DropboxAuth;
