import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export const CustomModal: React.FC<{
  show: boolean;
  onClose: () => void;
}> = ({ show, onClose, children }) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        open={show}
        className="fixed inset-0  overflow-y-auto"
        style={{ zIndex: 99999 }}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-[100%] sm:w-auto p-2 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded ">
              <Dialog.Description as="div">
                <div>{children}</div>
              </Dialog.Description>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
