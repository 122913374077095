import { DatePicker, Text } from '@fluentui/react';
import { PrimaryButtonWithLoader } from '@worx.squad/shared-frontend';
import moment from 'moment';
import Papa from 'papaparse';
import { useState } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import { useClientSDK } from '../../context/SDKContext/SDKContext';

const ExportLeave = () => {
  const [startDate, setStartDate] = useState<Date | undefined>(
    moment().startOf('month').toDate(),
  );
  const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
  const [loading, setLoading] = useState(false);
  const sdk = useClientSDK();
  const { organization } = useOrganizationContext();

  const exportData = async () => {
    try {
      setLoading(true);
      const rawData = await sdk?.getOrganizationLeaveData({
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        organizationId: organization?.id,
      });
      const data =
        rawData?.organization_user_leave_dates.map((data, index) => {
          const user = data.leave_application?.user;
          const leaveApplication = data.leave_application;
          return {
            'Serial No.': index + 1,
            'User Name': user?.first_name + ' ' + user?.last_name,
            'Leave Application Type': leaveApplication?.leave_type,
            'Date of Leave': data.date,
            'Status of Leave': leaveApplication?.leave_application_status,
            'Reason for Leave': leaveApplication?.leave_reason,
          };
        }) || [];
      const csv = Papa.unparse(data);
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(csvData);
      link.setAttribute(
        'download',
        `leaves-${moment(startDate).format('YYYY-MM-DD')}-${moment(
          endDate,
        ).format('YYYY-MM-DD')}`,
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Text variant="xLarge">Export Employees leaves data</Text>
      <div className="flex flex-col gap-4 w-96">
        <DatePicker
          value={startDate}
          onSelectDate={(date) => setStartDate(date || undefined)}
          showMonthPickerAsOverlay
          label="Start date"
        />
        <DatePicker
          value={endDate}
          onSelectDate={(date) => setEndDate(date || undefined)}
          showMonthPickerAsOverlay
          label="End date"
        />
        <div>
          <PrimaryButtonWithLoader onClick={exportData} loading={loading}>
            Export CSV
          </PrimaryButtonWithLoader>
        </div>
      </div>
    </div>
  );
};

export default ExportLeave;
