import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Link,
  MessageBarType,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  PrimaryButtonWithLoader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { ClientsData } from './ClientsList';
import {
  useDeleteClientMutation,
  useDeleteClientSoftMutation,
} from './projects-and-clients.generated';

const DeleteClient = ({ client }: { client: ClientsData }) => {
  const [dialogHidden, { toggle: toggleDialog }] = useBoolean(true);
  const { notification } = useNotification();
  const [, deleteClient] = useDeleteClientMutation();
  const [, deleteClientSoft] = useDeleteClientSoftMutation();

  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    try {
      setLoading(true);
      const res = await deleteClient({ clientId: client.id });
      if (res.error) {
        const responseSoft = await deleteClientSoft({ clientId: client.id });
        if (responseSoft.error) {
          notification({
            message: responseSoft.error.message,
            type: MessageBarType.error,
          });
          setLoading(false);
          return;
        }
      } else {
        notification({
          message: 'Client deleted successfully',
          type: MessageBarType.success,
        });
        setLoading(false);
        toggleDialog();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  return (
    <>
      <Link onClick={toggleDialog}>Delete</Link>
      <Dialog
        hidden={dialogHidden}
        onDismiss={toggleDialog}
        dialogContentProps={{
          title: `Are you sure?`,
          subText: `Are you sure you want to delete the client ${client.client_name}. This action cannot be undone. If you delete the client all connected projects will be deleted as well.`,
          type: DialogType.close,
        }}
      >
        <DialogFooter>
          <PrimaryButtonWithLoader onClick={onSubmit} loading={loading}>
            Delete
          </PrimaryButtonWithLoader>
          <DefaultButton onClick={toggleDialog}>Cancel</DefaultButton>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DeleteClient;
