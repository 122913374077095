import { Link } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import LinkSvg from '../Home/assets/LinkSvg';
import People from './Assets/Peopls';
import Share from './Assets/Share';

const MostRecentBlogCard = ({ cardData }: any) => {
  const navigate = useNavigate();

  return (
    <div
      className="border w-[100vw] md:w-[48%] lg:w-[30vw] cursor-pointer"
      onClick={() => navigate(`/blog/${cardData?.id}`)}
    >
      <div className="w-full ">{cardData?.fieldData?.thumbnail?.url}</div>
      <div className="m-4">
        <div className="text-[1.5rem] font-semibold">
          {cardData?.fieldData?.title}
        </div>
        <div className="flex items-center gap-4 text-[#0000008F]">
          <span>{cardData?.fieldData?.read}</span>
          <span className="flex items-center justify-center gap-1">
            <span>{cardData?.fieldData?.people}</span>
            <span>
              <People />
            </span>
          </span>
          <span className="flex items-center gap-1">
            <span>{cardData?.fieldData?.share}</span>
            <span>
              <Share />
            </span>
          </span>
        </div>
      </div>
      <div className="flex items-center gap-2 text-[#FF9900] m-4">
        <Link className="flex text-[14px] items-center justify-center gap-4 hover:no-underline text-[#FF9900] hover:text-[#FF9900]">
          Read More <LinkSvg feature={true} />
        </Link>
      </div>
    </div>
  );
};

export default MostRecentBlogCard;
