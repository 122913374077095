import { Auth } from '@worx.squad/hbp-sdk';
import { AuthAsync } from '@worx.squad/shared';
import { useUnmountedRef } from 'ahooks';
import constate from 'constate';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { AuthUser, AuthUserType } from '..';
import SpinnerCustom from './SpinnerCustom';

interface AuthContextProps {
  auth: Auth | AuthAsync;
}

function useAuthContext({ auth }: AuthContextProps) {
  const { current } = useUnmountedRef();
  const [authState, setAuthState] = useState({
    signedIn: false,
    user: null as AuthUserType,
    loading: true,
  });

  useEffect(() => {
    const resetUser = async (signedIn: any) => {
      const user = await auth.getUser();
      document.cookie = `userId=${user?.id}`;

      if (!current) {
        setAuthState((oldValue) => ({
          ...oldValue,
          user,
          signedIn: signedIn == null ? !isEmpty(user) : signedIn,
          loading: false,
        }));
      }
    };

    auth.onAuthStateChanged((data) => {
      resetUser(data);
    });

    auth.onTokenChanged(() => {
      resetUser(null);
    });
    auth.isAuthenticatedAsync().then(resetUser);
  }, [auth]);

  const setUser = (user: AuthUser) => {
    setAuthState((oldValue) => ({ ...oldValue, user }));
  };

  return { setUser, ...authState, auth };
}

const [AuthProviderRaw, useAuthHook] = constate(useAuthContext);

interface AuthProviderLoaderProps {
  children: React.ReactNode;
}

function AuthProviderLoader({ children }: AuthProviderLoaderProps) {
  const { loading } = useAuthHook();
  return <> {!loading ? children : <SpinnerCustom />}</>;
}

interface AuthProviderProps {
  children: React.ReactNode;
  auth: Auth | AuthAsync;
}

export const AuthProvider = ({ children, auth }: AuthProviderProps) => {
  return (
    <AuthProviderRaw auth={auth}>
      <AuthProviderLoader children={children} />
    </AuthProviderRaw>
  );
};

export const useAuth = useAuthHook;
