import ProjectManagement from '../Home/assets/ProjectManagement';
import FeatureProductivity from './FeatureProductivity';

const ProductivityInsights = () => {
  return (
    <div className="px-20">
      <div>
        <div className="flex items-center gap-2">
          <span>
            <ProjectManagement />
          </span>
          <span className="font-medium">Productivity Insights</span>
        </div>
        <div className="text-4xl font-medium mt-2">
          Insights to supercharge your productivity
        </div>
        <div>
          <FeatureProductivity />
        </div>
      </div>
    </div>
  );
};

export default ProductivityInsights;
