import { Link, Stack, Text } from '@fluentui/react';
import { Logo } from '@worx.squad/shared-frontend';
import { useNavigate } from 'react-router-dom';

interface IOnBoardingFormLayout {
  vector?: JSX.Element;
  title: string;
  description: string;
}

export const OnBoardingFormLayout: React.FC<IOnBoardingFormLayout> = ({
  vector,
  description,
  title,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <Stack horizontal>
      <Stack.Item
        styles={{
          root: {
            width: 600,
            minHeight: '100vh',
          },
        }}
        className="bg-white hidden lg:block"
      >
        <Stack
          verticalAlign="center"
          horizontalAlign="start"
          className="md:px-14 lg:px-16 h-24"
        >
          <Stack.Item className="w-44">
            <Link onClick={() => navigate('/')}>
              <Logo />
            </Link>
          </Stack.Item>
        </Stack>
        <Stack
          verticalAlign="space-between"
          horizontalAlign="start"
          className="md:ml-14 lg:ml-16 px-8 mb-8"
          styles={{
            root: {
              background: '#F1F8F9',
              minHeight: 'calc(100vh - 128px)',
            },
          }}
        >
          <Stack.Item className="pt-5">
            <Stack tokens={{ childrenGap: 16 }}>
              <Text variant="xxLargePlus">{title}</Text>
              <Text variant="mediumPlus">{description}</Text>
            </Stack>
          </Stack.Item>
          <Stack.Item align="center" className="pb-8">
            {vector}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item
        // styles={{
        //   root: {
        //     width: '80%',
        //   },
        // }}
        className="bg-white md:w-[100%]"
      >
        <Stack verticalAlign="center" horizontalAlign="center" verticalFill>
          <Stack.Item
            styles={{
              root: { height: '100%' },
            }}
            className="px-4 pt-2 sm:pt-24 pb-20 sm:pb-14 2xl:w-3/4 overflow-y-auto scroll-smooth sm:w-[100%] xl:w-3/4"
          >
            {children}
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
