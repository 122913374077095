import { Route, Routes } from 'react-router-dom';
import BlogDetailView from './components/Blog/BlogDetailView';
import ThankYou from './components/CommonComponent/ThankYou';
import ProtectedRoute from './components/ProtectedRoute';
import Activate from './pages/Activate';
import Announcements from './pages/Announcements';
import AssessmentType from './pages/AssessmentType';
import Awards from './pages/Awards';
import Blog from './pages/Blog';
import ChangePassword from './pages/ChangePassword';
import Closing from './pages/Closing';
import { Competencies } from './pages/Competencies';
import ContactUs from './pages/ContactUs';
import Dashboard from './pages/Dashboard';
import DepartmentPage from './pages/Department';
import DropboxAuth from './pages/DropboxAuth';
import Employees from './pages/Employees';
import Feature from './pages/Feature';
import { ForgotPassword } from './pages/ForgotPassword';
import GoogleDriveAuth from './pages/GoogleDriveAuth';
import HolidaysPage from './pages/Holidays';
import Home from './pages/Home';
import HotDesking from './pages/HotDesking';
import HowItWorks from './pages/HowItWorks';
import { Leaves } from './pages/Leaves';
import Login from './pages/Login';
import OneDriveAuth from './pages/OneDriveAuth';
import OpenApp from './pages/OpenApp';
import OrganizationRegister from './pages/OrganizationRegister';
import PerformanceReview from './pages/PerformanceReview';
import Pricing from './pages/Pricing';
import Register from './pages/Register';
import Roles from './pages/Roles';
import TrainingType from './pages/TraingType';
import ManageAdministrators from './pages/settings/ManageAdministrators';
import OrganizationSettings from './pages/settings/OrganizationSettings';
import { AcceptableUsePolicy } from './pages/static-pages/AcceptableUsePolicy';
import { CookiePolicy } from './pages/static-pages/CookiePolicy';
import { PrivacyPolicy } from './pages/static-pages/PrivacyPolicy';
import { TermsOfService } from './pages/static-pages/TermsOfService';
import ProjectsAndClients from './pages/task-management/ProjectsAndClients';

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="how-it-works" element={<HowItWorks />} />
      <Route path="feature" element={<Feature />} />
      <Route path="thank-you" element={<ThankYou />} />
      <Route path="blog" element={<Blog />} />
      <Route path="blog/:detailView" element={<BlogDetailView />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="register" element={<Register />} />
      <Route path="activate/:type/:ticket" element={<Activate />} />
      <Route path="change-password/:ticket" element={<ChangePassword />} />
      <Route path="pricing" element={<Pricing />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="open-app" element={<OpenApp />} />
      <Route path="onedrive-auth" element={<OneDriveAuth />} />
      <Route path="googledrive-auth" element={<GoogleDriveAuth />} />
      <Route path="dropbox-auth" element={<DropboxAuth />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="cookie-policy" element={<CookiePolicy />} />
      <Route path="acceptable-use-policy" element={<AcceptableUsePolicy />} />
      <Route path="terms-of-service" element={<TermsOfService />} />
      <Route
        path="organization/register"
        element={
          <ProtectedRoute>
            <OrganizationRegister />
          </ProtectedRoute>
        }
      />
      <Route
        path="dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="department"
        element={
          <ProtectedRoute>
            <DepartmentPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="announcements"
        element={
          <ProtectedRoute>
            <Announcements />
          </ProtectedRoute>
        }
      />
      <Route
        path="leaves"
        element={
          <ProtectedRoute>
            <Leaves />
          </ProtectedRoute>
        }
      />
      <Route
        path="awards"
        element={
          <ProtectedRoute>
            <Awards />
          </ProtectedRoute>
        }
      />
      <Route
        path="competencies"
        element={
          <ProtectedRoute>
            <Competencies />
          </ProtectedRoute>
        }
      />
      <Route
        path="employees"
        element={
          <ProtectedRoute>
            <Employees />
          </ProtectedRoute>
        }
      />
      <Route
        path="closing"
        element={
          <ProtectedRoute>
            <Closing />
          </ProtectedRoute>
        }
      />
      <Route
        path="roles"
        element={
          <ProtectedRoute>
            <Roles />
          </ProtectedRoute>
        }
      />
      <Route
        path="trainingtype"
        element={
          <ProtectedRoute>
            <TrainingType />
          </ProtectedRoute>
        }
      />
      <Route
        path="Assessmenttype"
        element={
          <ProtectedRoute>
            {' '}
            <AssessmentType />{' '}
          </ProtectedRoute>
        }
      />
      <Route
        path="PerformanceReview"
        element={
          <ProtectedRoute>
            {' '}
            <PerformanceReview />{' '}
          </ProtectedRoute>
        }
      />
      <Route
        path="hot-desking"
        element={
          <ProtectedRoute>
            <HotDesking />
          </ProtectedRoute>
        }
      />
      <Route
        path="task-management/projects-and-clients"
        element={
          <ProtectedRoute>
            <ProjectsAndClients />
          </ProtectedRoute>
        }
      />
      <Route
        path="holidays"
        element={
          <ProtectedRoute>
            <HolidaysPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/*"
        element={
          <Routes>
            <Route
              path="organization-settings"
              element={
                <ProtectedRoute>
                  <OrganizationSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="manage-administrators"
              element={
                <ProtectedRoute ownerOnly>
                  <ManageAdministrators />
                </ProtectedRoute>
              }
            />
          </Routes>
        }
      />
    </Routes>
  );
};

export default Router;
