import { Link } from '@fluentui/react';
import futureOfWork1 from '../../../../assets/FutureOfWork/futureOfWork1.jpg';
import futureOfWork2 from '../../../../assets/FutureOfWork/futureOfWork2.jpg';
import futureOfWork3 from '../../../../assets/FutureOfWork/futureOfWork3.jpg';
import futureOfWork4 from '../../../../assets/FutureOfWork/futureOfWork4.jpg';
import futureOfWork5 from '../../../../assets/FutureOfWork/futureOfWork5.jpg';

import { BookADemo } from '../../Home/Utils';
const FutureOfWork = () => {
  return (
    <>
      <div
        className="pt-1 place-content-center py-14 bg-cover bg-no-repeat bg-center sm:h-screen"
        style={{
          backgroundImage:
            'url(../../../../assets/FutureOfWork/futureOfWorkBanner.jpg)',
        }}
      ></div>
      <div className="text-[28px] sm:text-[3rem] font-normal sm:font-light mx-[20px] sm:mx-[40px] my-[24px]">
        <div className="sm:px-[6px] md-[10px] lg:px-[120px]">
          <div className="text-[44px] font-bold leading-[50px]">
            Future of Work: The Digital Way - How Companies Drive Employee
            Productivity and Engagement Using Technology
          </div>
          <div className="text-[24px] font-medium my-[12px] italic">
            A decade ago, the concept of saying 'I am WFH' wasn't on our radar.
            Today, it's a widespread practice—talk about the evolution of work
            culture!
          </div>
          <div className="text-[24px] font-medium my-[24px]">
            The global workforce has witnessed a drastic shift in its
            traditional approach, propelled by an unprecedented surge in remote
            work. As the world grappled with unforeseen challenges,
            organizations were compelled to adapt swiftly to a new paradigm.
            This accelerated transition to remote work, driven by external
            factors such as the ongoing global health crisis, has become a
            catalyst for the digital evolution of the workplace. This blog aims
            to delve into the intricate relationship between the surge in remote
            work and the rapid digital transformation it has instigated,
            shedding light on the innovative solutions and strategies that are
            restructuring the future of work as we know it.
          </div>
          <div className="text-[24px] font-medium my-[24px]">
            Recognizing the need for a digital workplace, organizations are
            taking action to facilitate seamless collaboration between onsite
            and remote workers to achieve business goals. However, some leaders
            express concerns. They fear that distractions and a lack of
            supervision may hinder collaboration, leading to dissatisfaction and
            turnover. Building and sustaining relationships, especially for new
            employees, pose challenges in a virtual setting, potentially
            impacting team dynamics. Additionally, there is a concern that
            workers may find it challenging to develop skills and professional
            networks necessary for career advancement in a virtual environment.
            In response to these concerns, companies are actively utilizing
            digital tools and software to enhance the employee experience and
            streamline task management.
          </div>
          <div>
            <div className="text-[40px] font-semibold">
              The Trends of the future of work:
            </div>
            <div className="text-[24px] font-medium my-[24px]">
              <ul style={{ listStyleType: 'disc', marginLeft: '1.5rem' }}>
                <li>
                  <span className="font-semibold">
                    AI will actively enhance work potential :
                  </span>
                  <span>
                    {' '}
                    AI actively enhances work potential by automating repetitive
                    tasks, enabling human workers to focus on more complex and
                    creative aspects of their jobs. It excels in processing vast
                    amounts of data, providing valuable insights for strategic
                    decision-making, and fostering increased efficiency and
                    productivity across various industries. AI contributes to
                    personalized learning and development, supports innovation
                    and creativity, and facilitates seamless collaboration in
                    remote work settings. Moreover, it monitors employee
                    well-being, suggests interventions for a healthy work
                    environment, and continuously optimizes processes by
                    identifying inefficiencies. Together, these aspects create a
                    dynamic and productive work environment, showcasing the
                    transformative impact of AI on the workforce.
                  </span>{' '}
                </li>
                <div className="my-[24px]">
                  <img src={futureOfWork1} alt="futureOfWork1" />
                </div>
                <li>
                  <span className="font-semibold">
                    Climate Change Protection will become the New In-Demand
                    Employee Benefit :
                  </span>
                  <span>
                    {' '}
                    Climate change protection is emerging as a highly
                    sought-after employee benefit as organizations increasingly
                    recognize the importance of environmental sustainability.
                    Companies are incorporating green initiatives, such as
                    offering eco-friendly commuting options, implementing carbon
                    offset programs, creating sustainable workspaces, and
                    promoting flexible work arrangements to reduce carbon
                    footprints. This shift reflects a growing understanding that
                    climate protection is not just a global imperative but an
                    essential aspect of a forward-thinking and responsible
                    workplace.
                  </span>{' '}
                </li>

                <div className="my-[24px]">
                  <img src={futureOfWork2} alt="futureOfWork2" />
                </div>
                <li>
                  <span className="font-semibold">
                    DEI doesn’t Disappear, It Becomes the Way We Work :
                  </span>
                  <span>
                    {' '}
                    DEI (Diversity, Equity, and Inclusion) shapes the way we
                    work, influencing hiring practices, talent development,
                    communication strategies, and leadership approaches.
                    Companies increasingly recognize the benefits of a diverse
                    and inclusive workforce, transforming DEI from a checklist
                    into a fundamental principle that defines the ethos of
                    organizations. This active integration fosters innovation,
                    builds resilience, and cultivates a more equitable work
                    environment.
                  </span>{' '}
                </li>

                <div className="my-[24px]">
                  <img src={futureOfWork3} alt="futureOfWork3" />
                </div>
                <li>
                  <span className="font-semibold">
                    Career Stereotypes Collapse in the Face of Workforce Change
                    :
                  </span>
                  <span>
                    {' '}
                    Historical notions about career paths, job stability, and
                    linear progression are giving way to a reality where
                    flexibility, adaptability, and skill diversification are
                    valued. The evolving nature of work, driven by technological
                    advancements and changing societal expectations, challenges
                    preconceived ideas about professions and paves the way for
                    individuals to craft unique and multidimensional career
                    trajectories. The emphasis is shifting from rigid career
                    stereotypes to embracing a mindset of continuous learning,
                    agility, and the ability to navigate diverse opportunities.
                    In this changing paradigm, individuals are empowered to
                    redefine success on their terms, fostering a more inclusive
                    and equitable representation across various industries and
                    professions.
                  </span>{' '}
                </li>
                <div className="my-[24px]">
                  <img src={futureOfWork4} alt="futureOfWork4" />
                </div>
                <li>
                  <span className="font-semibold">
                    Algorithmic bias concerns lead to more transparency in
                    recruiting tech :
                  </span>
                  <span>
                    {' '}
                    Growing concerns about algorithmic bias in recruiting
                    technology are steering the industry towards greater
                    transparency. As organizations increasingly rely on
                    AI-driven tools for talent acquisition, there is a
                    heightened awareness of potential biases in these algorithms
                    that could adversely impact diversity and inclusion. In
                    response, there is a notable shift towards transparency in
                    the development, deployment, and outcomes of recruiting
                    technologies. Companies are becoming more accountable in
                    disclosing the criteria used by algorithms, ensuring
                    fairness in the evaluation of candidates, and actively
                    addressing any biases identified. This push for transparency
                    not only addresses ethical concerns but also fosters a more
                    equitable and inclusive recruitment process, aligning with
                    the broader goals of creating diverse and representative
                    workplaces.
                  </span>{' '}
                  <div className="my-[24px]">
                    <img src={futureOfWork5} alt="futureOfWork5" />
                  </div>
                  <span>
                    Digital tools are pivotal in seamlessly integrating a
                    diverse array of transformations within the evolving
                    workplace. As organizations navigate the dynamic landscape
                    of modern work, these tools catalyze implementing change,
                    ensuring efficiency, and fostering innovation. From the
                    adoption of remote work solutions to the implementation of
                    advanced collaboration platforms, digital tools empower
                    businesses to embrace flexibility, enhance communication,
                    and adapt to evolving industry trends. They facilitate the
                    transition to agile work environments, supporting processes
                    like project management, data analytics, and employee
                    engagement. Moreover, digital tools enable organizations to
                    harness the power of emerging technologies such as
                    artificial intelligence, automation, and cloud computing,
                    catalyzing a more productive and responsive workplace. In
                    essence, these tools play a central role in devising the
                    numerous transformations required for businesses to thrive
                    in the ever-changing landscape of the contemporary
                    workplace.
                  </span>
                </li>
              </ul>
            </div>
            <div className="text-[24px] font-medium my-[24px]">
              <div>
                <Link
                  className={`font-semibold underline`}
                  rel="noopener noreferrer"
                  aria-label="Worxwide"
                >
                  Worx Squad
                </Link>{' '}
                is a part of{' '}
                <Link
                  className={`font-semibold underline`}
                  href="https://www.worxwide.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Worxwide"
                >
                  About Worxwide
                </Link>
                <span>
                  , based out of US, UK, and India. Worxwide Consulting is a
                  digital growth consulting firm offering bid consulting, sales
                  transformation, user experience, and customer experience
                  design services.
                </span>
              </div>
              <div className="my-[24px]">
                To explore the product features,{' '}
                <span
                  className="underline text-[#185D74] cursor-pointer"
                  onClick={BookADemo}
                >
                  book
                </span>{' '}
                a demo call with us!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FutureOfWork;
