import { Icon } from '@fluentui/react';
import { coreFeatureList } from './Utils';

const CoreFeature = () => {
  return (
    <div className="my-8">
      {coreFeatureList.map((item) => (
        <div>
          <div className="font-bold text-2xl text-[#FF9900] py-4">
            {item.label}
          </div>
          <div className="my-4 h-[0.5px] bg-[#7d7c7c]"></div>
          <div>
            {item.coreFeature.map((coreFeat) => (
              <>
                <div className="flex justify-between items-center">
                  {coreFeat.map((featValue, index) => (
                    <div
                      className={`text-white text-base font-semibold py-4 w-1/4 ${index !== 0 && 'text-center'}`}
                    >
                      {featValue === true ? (
                        <Icon
                          iconName="Accept"
                          aria-label="Accept"
                          className="AcceptIcon font-bold text-green-500"
                        />
                      ) : featValue === false ? (
                        <Icon
                          iconName="Accept"
                          aria-label="Accept"
                          className="AcceptIcon font-bold text-[#333333]"
                        />
                      ) : (
                        featValue
                      )}
                    </div>
                  ))}
                </div>
                <div className="my-4 h-[0.5px] bg-[#7d7c7c]"></div>
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CoreFeature;
