import {
  DefaultButton,
  IconButton,
  MessageBarType,
  Modal,
  TextField as NormalTextField,
  Stack,
  StackItem,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  AddressForm,
  Form,
  PrimaryButtonWithLoader,
  TextField,
  TimezonePicker,
  useNotification,
} from '@worx.squad/shared-frontend';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import {
  useInsertAddressMutation,
  useInsertOrganizationLocationMutation,
} from '../OrganizationRegister/organization-register.generated';
import { IAddress } from '../OrganizationRegister/types';
import { addLocationResolver } from './add-location.schema';
import {
  useGetOrganizationLocationByIdQuery,
  useUpdateAddressMutation,
  useUpdateOrganizationLocationMutation,
} from './hot-desking.generated';

interface IAddLocationForm {
  onCancel: () => void;
  edit?: boolean;
  locationId?: string;
}

interface IAddLocationFormData {
  title: string;
  timezone: string;
  totalSeats: number;
}

const AddLocationForm: FC<IAddLocationForm> = ({
  onCancel,
  edit,
  locationId,
}) => {
  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const addLocationForm = useForm<IAddLocationFormData>({
    mode: 'all',
    resolver: addLocationResolver,
    defaultValues: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  const { handleSubmit } = addLocationForm;
  const [address, setAddress] = useState<IAddress>({} as IAddress);
  const [loading, setLoading] = useState(false);
  const [
    addressModalVisible,
    { setTrue: openAddressModal, setFalse: closeAddressModal },
  ] = useBoolean(false);

  const [{ data: organizationLocationRaw }] =
    useGetOrganizationLocationByIdQuery({
      variables: { id: locationId },
      pause: !locationId || !edit,
      requestPolicy: 'cache-and-network',
    });

  const [, insertOrganizationLocation] =
    useInsertOrganizationLocationMutation();
  const [, insertAddress] = useInsertAddressMutation();

  const [, updateOrganizationLocation] =
    useUpdateOrganizationLocationMutation();
  const [, updateAddress] = useUpdateAddressMutation();

  const onAddressChange = (address: IAddress) => {
    setAddress(address);
    closeAddressModal();
  };

  const onAdd = async (data: IAddLocationFormData) => {
    setLoading(true);
    try {
      const addressRes = await insertAddress({ object: address });
      const organizationLocationRes = await insertOrganizationLocation({
        object: {
          title: data.title,
          timezone_name: data.timezone,
          total_seats: data.totalSeats,
          organization_id: organization?.id,
          address_id: addressRes?.data?.insert_address_one?.id || '',
        },
      });
      if (organizationLocationRes.error) {
        console.error(organizationLocationRes.error);
        notification({
          message: 'Error adding location',
          type: MessageBarType.error,
        });
      } else {
        onCancel();
        notification({
          message: 'Location added successfully',
          type: MessageBarType.success,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onEdit = async (data: IAddLocationFormData) => {
    try {
      await updateAddress({
        id: organizationLocationRaw?.location?.address.id || '',
        data: {
          address_line: address.address_line,
          additional_fields: address.additional_fields,
          country: address.country,
          landmark: address.landmark,
          pin_code: address.pin_code,
          state: address.state,
        },
      });
      const organizationLocationRes = await updateOrganizationLocation({
        id: locationId,
        data: {
          title: data.title,
          timezone_name: data.timezone,
          total_seats: data.totalSeats,
          organization_id: organization?.id,
        },
      });
      if (organizationLocationRes.error) {
        console.error(organizationLocationRes.error);
        notification({
          message: 'Location update failed',
          type: MessageBarType.error,
        });
      } else {
        onCancel();
        notification({
          message: 'Location updated successfully',
          type: MessageBarType.success,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const newAddress = address?.address_line
    ? `${address?.address_line},\n${address?.landmark}, ${address?.state}, ${address?.country},\nPinCode: ${address?.pin_code}`
    : '';

  useEffect(() => {
    if (organizationLocationRaw?.location) {
      setAddress(organizationLocationRaw.location.address as IAddress);
      addLocationForm.setValue('title', organizationLocationRaw.location.title);
      addLocationForm.setValue(
        'timezone',
        organizationLocationRaw.location.timezone_name,
      );
      addLocationForm.setValue(
        'totalSeats',
        organizationLocationRaw.location.total_seats,
      );
    }
  }, [addLocationForm, organizationLocationRaw]);

  return (
    <>
      <Form
        onSubmit={edit ? handleSubmit(onEdit) : handleSubmit(onAdd)}
        formHook={addLocationForm}
      >
        <div className="h-auto">
          <div>
            <TextField
              formHook={addLocationForm}
              name="title"
              label="Location name"
              required
            />
          </div>
          <div>
            <TextField
              formHook={addLocationForm}
              name="totalSeats"
              label="Total seats"
              type="number"
              required
            />
          </div>
          <div>
            <TimezonePicker
              formHook={addLocationForm}
              name="timezone"
              label="Office timezone"
              required
            />
          </div>
          <div>
            <NormalTextField
              label="Office address"
              placeholder="Enter office address"
              required
              multiline
              autoAdjustHeight
              value={newAddress}
              onClick={openAddressModal}
            />
          </div>
          <Stack tokens={{ childrenGap: 20 }} horizontal className="mt-4">
            <Stack.Item>
              <PrimaryButtonWithLoader loading={loading} type="submit">
                {edit ? 'Save' : 'Add'}
              </PrimaryButtonWithLoader>
            </Stack.Item>
            <Stack.Item>
              <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
            </Stack.Item>
          </Stack>
        </div>
      </Form>

      <Modal
        isOpen={addressModalVisible}
        onDismiss={closeAddressModal}
        isBlocking
      >
        <div className="p-3">
          <Stack horizontal horizontalAlign="space-between">
            <StackItem>
              <Text variant="xLarge">Address Form</Text>
            </StackItem>
            <StackItem>
              <IconButton
                iconProps={{ iconName: 'ChromeClose' }}
                onClick={closeAddressModal}
              />
            </StackItem>
          </Stack>
          <Stack verticalFill>
            <StackItem>
              <AddressForm onSubmit={onAddressChange} address={address} />
            </StackItem>
          </Stack>
        </div>
      </Modal>
    </>
  );
};

export default AddLocationForm;
