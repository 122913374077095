import { useAuth } from '@worx.squad/hbp-react';
import constate from 'constate';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { auth } from './../../helpers/hbp-helper';
import {
  GetOrganizationByUserIdQuery,
  useGetOrganizationByUserIdQuery,
} from './organization.generated';

const useOrganization = () => {
  const [organization, setOrganization] =
    useState<
      GetOrganizationByUserIdQuery['organization_user'][0]['organization']
    >();
  const { user } = useAuth();

  const [{ data: organizationRaw }, refetchOrganization] =
    useGetOrganizationByUserIdQuery({
      variables: {
        userId: user?.id,
      },
      pause: !user?.id,
      requestPolicy: 'cache-and-network',
    });

  useEffect(() => {
    auth.onTokenChanged(() => {
      refetchOrganization();
    });
  }, [refetchOrganization]);

  useEffect(() => {
    if (!isEmpty(organizationRaw?.organization_user[0].organization))
      setOrganization(organizationRaw?.organization_user[0].organization);
  }, [organizationRaw]);

  return {
    organization,
  };
};
export const [OrganizationProvider, useOrganizationContext] =
  constate(useOrganization);
