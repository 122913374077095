import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  Link,
  MessageBarType,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { Pagination } from '@fluentui/react-experiments';
import { useBoolean } from '@fluentui/react-hooks';
import {
  useHeader,
  useLayoutState,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDebounce } from 'use-debounce';
import RoleAddModal from '../components/Roles/RoleAddModal';
import {
  GetOrganizationRolesQuery,
  useDeletRoleMutation,
  useGetOrganizationRolesQuery,
} from '../components/Roles/roles.generated';
import { ROLES_MAP } from '../constants';
import { useOrganizationContext } from '../context/OrganizationContext/OrganizationContext';

interface RoleData {
  role: GetOrganizationRolesQuery['organization_roles'][0]['role_title'];
  systemRole: GetOrganizationRolesQuery['organization_roles'][0]['system_role'];
  id: GetOrganizationRolesQuery['organization_roles'][0]['id'];
}

const Roles = () => {
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();

  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Roles');
  }, [setTitle]);

  const rolesColumn: IColumn[] = [
    {
      name: 'Sr. No',
      key: 'srNo',
      minWidth: 40,
      maxWidth: 40,
      isResizable: true,
      fieldName: 'index',
      className: 'text-center text-black',
    },
    {
      name: 'Role',
      key: 'Role',
      minWidth: 100,
      fieldName: 'role',
      isResizable: true,
      isRowHeader: true,
    },
    {
      name: 'System role',
      key: 'sys-role',
      minWidth: 200,
      fieldName: 'systemRole',
      isResizable: true,
      className: 'text-black',
      onRender: (item: RoleData) =>
        ROLES_MAP.find((role) => role.role === item.systemRole)?.name,
    },
    {
      key: 'action',
      name: 'Action',
      fieldName: 'id',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'number',
      isPadded: true,
      onRender: (item) => {
        return (
          <Link
            onClick={() => {
              setRoleId(item.id);
              toggleHideDialog();
            }}
          >
            Delete
          </Link>
        );
      },
    },
  ];
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Delete Role',
    closeButtonAriaLabel: 'Close',
    subText: 'Are you sure you want to delete this role?',
  };
  const [roleId, setRoleId] = useState('');
  const [
    addModalVisibility,
    { setTrue: showAddModal, setFalse: hideAddModal },
  ] = useBoolean(false);

  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const [organizationRoles, setOrganizationRoles] = useState<RoleData[]>([]);

  const PER_PAGE_COUNT = 10;
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchText, setSearchText] = useState<string | undefined>('');
  const [searchTextLazy] = useDebounce(searchText, 500);
  const [, deleteRoleMutation] = useDeletRoleMutation();

  const [{ data: orgRoles, fetching }, refetchRoles] =
    useGetOrganizationRolesQuery({
      variables: {
        orgId: organization?.id,
        offset,
        limit: PER_PAGE_COUNT,
        roleTitle: searchTextLazy ? `%${searchTextLazy}%` : undefined,
      },
      pause: !organization?.id,
      requestPolicy: 'cache-and-network',
    });

  const paginate = (index: number) => {
    setOffset(index * PER_PAGE_COUNT);
    setCurrentPage(index + 1);
  };

  const deleteRole = async () => {
    try {
      const res = await deleteRoleMutation({ role_id: roleId });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      } else if (res.data?.deleteOrganizationRole?.status === 400) {
        notification({
          message: res.data.deleteOrganizationRole.message + '',
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: `Role deleted!`,
          type: MessageBarType.success,
        });
        refetchRoles();
      }
      toggleHideDialog();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (orgRoles?.organization_roles) {
      setOrganizationRoles(
        orgRoles.organization_roles.map(
          (role, index) =>
            ({
              index: index + 1,
              role: role.role_title,
              systemRole: role.system_role,
              id: role.id,
            }) as RoleData,
        ),
      );
      setTotalPages(
        Math.ceil(
          (orgRoles.totalFiltered.aggregate?.count || 1) / PER_PAGE_COUNT,
        ),
      );
    }
  }, [orgRoles]);

  return (
    <>
      <Helmet>
        <title>Roles | Worx Squad</title>
      </Helmet>
      <Stack tokens={{ childrenGap: 30 }} className="mainContainer">
        <Stack.Item>
          <PrimaryButton
            onClick={showAddModal}
            iconProps={{ iconName: 'Add' }}
            text="Add new role"
          />
          <RoleAddModal
            refetchRoles={refetchRoles}
            isOpen={addModalVisibility}
            onDismiss={hideAddModal}
          />
        </Stack.Item>
        <Stack.Item className="max-w-xs">
          <SearchBox
            placeholder="Search by role"
            value={searchText || ''}
            onChange={(e, value) => setSearchText(value)}
          />
        </Stack.Item>
        <Stack.Item className="max-w-4xl">
          <ShimmeredDetailsList
            enableShimmer={fetching}
            items={organizationRoles}
            columns={rolesColumn}
            selectionMode={SelectionMode.none}
          />
          <hr className="my-2" />
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={totalPages}
            totalItemCount={orgRoles?.totalFiltered.aggregate?.count}
            itemsPerPage={PER_PAGE_COUNT}
            onPageChange={paginate}
            format="buttons"
            numberOfPageButton={4}
          />
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteRole} text="Delete" />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Roles;
