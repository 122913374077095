import { EditorConfig } from 'lexical';

export function patchLinkNode(LinkNode: any) {
  const _electronHandle = (e: any) => {
    (window as any).electron.openInBrowser(e.currentTarget.href);
  };
  const prev = LinkNode.prototype.createDOM;
  LinkNode.prototype.createDOM = function (
    config: EditorConfig,
  ): HTMLAnchorElement {
    const element: HTMLAnchorElement = prev.call(this, config);
    element.target = '_blank';

    if ((window as any).electron) element.onclick = _electronHandle;
    return element;
  };
}
