import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  SelectionMode,
  TooltipHost,
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import AnnouncementDelete from './AnnouncementDelete';
import EditAnnouncement from './EditAnnouncement';
import { useGetPostsByOrgIdQuery } from './announcement.generated';

const announcementColumns: IColumn[] = [
  {
    key: 'sl.no',
    minWidth: 50,
    name: 'S.No',
    fieldName: 'index',
    maxWidth: 50,
  },
  {
    key: 'message',
    minWidth: 300,
    maxWidth: 700,
    name: 'Message',
    fieldName: 'message',
  },
  {
    key: 'actions',
    minWidth: 100,
    name: 'Actions',
    fieldName: 'actions',
  },
];

interface Announcement {
  id: string;
  message: string;
}

const AnnouncementsList = () => {
  const { organization } = useOrganizationContext();
  const [editingAnnouncement, setEditingAnnouncement] =
    useState<Announcement>();
  const [deletingAnnouncement, setDeletingAnnouncement] =
    useState<Announcement>();
  const [editWindowVisible, setEditWindowVisible] = useState(false);
  const [deleteWindowVisible, setDeleteWindowVisible] = useState(false);

  const [{ data }, refetchAnnouncement] = useGetPostsByOrgIdQuery({
    variables: {
      orgId: organization?.id,
    },
    requestPolicy: 'cache-and-network',
  });
  const parsedData = data?.posts.map((post, index) => {
    return {
      index: index + 1,
      message: post.message,
      id: post.id,
      actions: (
        <div className="flex gap-2 justify-start">
          <TooltipHost title="Edit">
            <IconButton
              onClick={() => {
                setEditingAnnouncement({
                  id: post.id,
                  message: post.message,
                });
                setEditWindowVisible(true);
              }}
              iconProps={{ iconName: 'Edit' }}
            />
          </TooltipHost>
          <TooltipHost title="Delete">
            <IconButton
              onClick={() => {
                setDeletingAnnouncement({
                  id: post.id,
                  message: post.message,
                });
                setDeleteWindowVisible(true);
              }}
              iconProps={{ iconName: 'Delete' }}
            />
          </TooltipHost>
        </div>
      ),
    };
  });

  useEffect(() => {
    window.addEventListener('refetch-announcements', refetchAnnouncement);
    return () =>
      window.removeEventListener('refetch-announcements', refetchAnnouncement);
  }, [refetchAnnouncement]);

  return (
    <>
      <DetailsList
        items={parsedData || []}
        selectionMode={SelectionMode.none}
        columns={announcementColumns}
        layoutMode={DetailsListLayoutMode.fixedColumns}
      />
      <EditAnnouncement
        isOpen={editWindowVisible}
        onDismiss={() => setEditWindowVisible(false)}
        announcementId={editingAnnouncement?.id}
        announcementMessage={editingAnnouncement?.message}
      />
      <AnnouncementDelete
        hidden={!deleteWindowVisible}
        onDismiss={() => setDeleteWindowVisible(false)}
        announcementId={deletingAnnouncement?.id}
        announcementMessage={deletingAnnouncement?.message}
      />
    </>
  );
};

export default AnnouncementsList;
