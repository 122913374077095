import {
  DefaultButton,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';
import { Pagination } from '@fluentui/react-experiments';
import { useBoolean } from '@fluentui/react-hooks';
import { useEffect, useState } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import AddLocationPanel from './AddLocationPanel';
import { DeleteLocation } from './DeleteLocation';
import EditLocationActions from './EditLocationActions';
import {
  GetOrganizationLocationsDetailsQuery,
  useGetOrganizationLocationsDetailsQuery,
} from './hot-desking.generated';

interface IOrganizationLocations {
  id: GetOrganizationLocationsDetailsQuery['organization_locations'][0]['id'];
  location: GetOrganizationLocationsDetailsQuery['organization_locations'][0]['title'];
  totalSeats: GetOrganizationLocationsDetailsQuery['organization_locations'][0]['total_seats'];
  timezone: GetOrganizationLocationsDetailsQuery['organization_locations'][0]['timezone_name'];
  address: string;
}

const Locations = () => {
  const locationsColumns: IColumn[] = [
    {
      key: 'No',
      name: 'No',
      minWidth: 30,
      maxWidth: 30,
      isResizable: true,
      onRender: (item, index) => (index || 0) + 1,
      className: 'text-black',
    },
    {
      key: 'location',
      name: 'Location name',
      minWidth: 100,
      isResizable: true,
      isRowHeader: true,
      fieldName: 'location',
      className: 'text-black',
    },
    {
      key: 'totalSeats',
      name: 'Total seats',
      minWidth: 100,
      isResizable: true,
      fieldName: 'totalSeats',
      className: 'text-black',
    },
    {
      key: 'timezone',
      name: 'Timezone',
      minWidth: 100,
      isResizable: true,
      fieldName: 'timezone',
      className: 'text-black',
    },
    {
      key: 'address',
      name: 'Address',
      minWidth: 150,
      isResizable: true,
      fieldName: 'address',
      className: 'text-black',
    },
    {
      key: 'action',
      name: 'Action',
      minWidth: 100,
      isResizable: true,
      onRender: (item: IOrganizationLocations) => (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <EditLocationActions locationId={item.id} />
          <DeleteLocation refetch={refetchLocations} locationId={item.id} />
        </Stack>
      ),
    },
  ];

  const { organization } = useOrganizationContext();

  const [
    addLocationModalVisible,
    { setTrue: showAddLocationModal, setFalse: hideAddLocationModal },
  ] = useBoolean(false);

  const PER_PAGE_COUNT = 10;
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [organizationLocations, setOrganizationLocations] = useState<
    IOrganizationLocations[]
  >([]);

  const [
    { data: organizationLocationsRaw, fetching: fetchingOrganizationLocations },
    refetchLocations,
  ] = useGetOrganizationLocationsDetailsQuery({
    variables: {
      organization_id: organization?.id,
      limit: PER_PAGE_COUNT,
      offset: offset,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });
  const totalLocations =
    organizationLocationsRaw?.organization_locations_aggregate.aggregate?.count;

  const paginate = (index: number) => {
    setOffset(index * PER_PAGE_COUNT);
    setCurrentPage(index + 1);
  };

  useEffect(() => {
    if (organizationLocationsRaw?.organization_locations)
      setOrganizationLocations(
        organizationLocationsRaw.organization_locations.map((location) => ({
          id: location.id,
          location: location.title,
          totalSeats: location.total_seats,
          timezone: location.timezone_name,
          address: `${location.address.address_line || ''}, ${
            location.address.additional_fields || ''
          }, ${location.address.landmark || ''}, ${
            location.address.state || ''
          }, ${location.address.country || ''}, ${
            location.address.pin_code || ''
          }`,
        })),
      );
    setTotalPages(
      Math.ceil(
        (organizationLocationsRaw?.organization_locations_aggregate.aggregate
          ?.count || 1) / PER_PAGE_COUNT,
      ),
    );
  }, [organizationLocationsRaw]);

  return (
    <>
      <Stack.Item>
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item>
            <Text className="text-xl font-semibold flex">
              <Icon iconName="MapPin" className="mr-2" />
              Total Office Locations: {totalLocations}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <DefaultButton
              iconProps={{ iconName: 'Add' }}
              text="Add new location"
              onClick={showAddLocationModal}
            />
            <AddLocationPanel
              isModalOpen={addLocationModalVisible}
              hideModal={hideAddLocationModal}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <ShimmeredDetailsList
          items={organizationLocations}
          columns={locationsColumns}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          enableShimmer={fetchingOrganizationLocations}
        />
        <hr className="my-2" />
        <Pagination
          selectedPageIndex={currentPage - 1}
          pageCount={totalPages}
          totalItemCount={totalLocations}
          itemsPerPage={PER_PAGE_COUNT}
          onPageChange={paginate}
          format="buttons"
          numberOfPageButton={4}
        />
      </Stack.Item>
    </>
  );
};

export default Locations;
