import { DefaultButton } from '@fluentui/react';
import { useBoolean } from 'ahooks';
import { useState } from 'react';
import DownloadApp from '../Home/DownloadApp';
import BlackLinkSvg from '../Home/assets/BlackLinkSvg';
import LinkSvg from '../Home/assets/LinkSvg';
import HorizontalLine from './assets/HorizontalLine';

const ForEmployee = () => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [linkIconTwo, setLinkIconTwo] = useState(true);

  return (
    <div className="lg:mx-[6rem] my-[2rem]">
      <div className="my-[2rem]">
        <div className="flex justify-between flex-col lg:flex-row items-center sm:items-start my-[2rem]">
          <div>
            <div className="text-[#185D74] font-medium">01</div>
            <div className="text-[1.5rem] sm:text-[2rem]">
              Receives squad credentials
            </div>
            <div className="text-[#7E7E7E] text-[14px] sm:text-[1rem]">
              The organisation admin first has to sign up and enter some basic
              details of <br /> their organisation.
            </div>
          </div>
          <div className="mt-[2rem] sm:mt-[0rem] h-[146px] w-[268px]">
            <img src={'../../../assets/Squad.png'} alt="Credential" />
          </div>
        </div>
        <div>
          <HorizontalLine />
        </div>
      </div>
      <div className="my-[2rem]">
        <div className="flex justify-between flex-col lg:flex-row items-center sm:items-start my-[2rem]">
          <div>
            <div className="text-[#185D74] font-medium">02</div>
            <div className="text-[1.5rem] sm:text-[2rem]">
              Download Worx Squad app
            </div>
            <div className="text-[#7E7E7E] text-[14px] sm:text-[1rem]">
              The organisation admin first has to sign up and enter some basic
              details of <br /> their organisation.
            </div>
            <div className="py-6 sm:w-[50%]">
              <DefaultButton
                onClick={showModal}
                onMouseEnter={() => setLinkIconTwo(false)}
                onMouseLeave={() => setLinkIconTwo(true)}
                className="border w-full border-[#FF9900] text-black hover:bg-[#FF9900] hover:text-white px-6 py-6 ease-in-out duration-300"
              >
                <span className="flex gap-4 justify-center items-center">
                  <span className="text-[1rem]">Download Squad For FREE</span>
                  <span>
                    <span className=" ease-in-out duration-300">
                      {linkIconTwo ? <BlackLinkSvg /> : <LinkSvg />}
                    </span>
                  </span>
                </span>
              </DefaultButton>
              <DownloadApp
                showModal={showModal}
                isModalOpen={isModalOpen}
                hideModal={hideModal}
              />
            </div>
          </div>
          <div>
            <img
              src={'../../../assets/Download_Squad_App.png'}
              alt="Credential"
              height={'146px'}
              width={'268px'}
            />
          </div>
        </div>

        <div>
          <HorizontalLine />
        </div>
      </div>
      <div className="my-[2rem]">
        <div className="flex justify-between flex-col lg:flex-row items-center sm:items-start">
          <div>
            <div className="text-[#185D74] font-medium">03</div>
            <div className="text-[1.5rem] sm:text-[2rem]">
              Sign in with your credentials
            </div>
            <div className="text-[#7E7E7E] text-[14px] sm:text-[1rem]">
              The organisation admin first has to sign up and enter some basic
              details of <br /> their organisation.
            </div>
          </div>
          <div className="pt-6 sm:pb-6 my-[2rem] sm:my-[0rem]">
            <img
              src={'../../../assets/SignIn_Credential.png'}
              alt="Credential"
              height={'146px'}
              width={'268px'}
            />
          </div>
        </div>
        <div className="sm:my-4">
          <HorizontalLine />
        </div>
      </div>
    </div>
  );
};
export default ForEmployee;
