import { MessageBarType } from '@fluentui/react';
import {
  AuthLayout,
  LoginVector,
  useLayoutState,
  useNotification,
} from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/Login/LoginForm';
import { useLoginMutation } from '../components/Login/login.generated';
import { auth } from '../helpers/hbp-helper';

const Login = () => {
  const navigate = useNavigate();
  const { notification } = useNotification();
  const [{ fetching }, login] = useLoginMutation();

  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  const onSubmit = async ({
    email,
    password,
    remember,
  }: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    try {
      await auth.logout();
      const loginResponse = await login({
        object: { email, password, login_type: 'ORG_ADMIN_APP' },
      });

      if (loginResponse.error) {
        notification({
          message: loginResponse.error.message,
          type: MessageBarType.error,
        });
        throw loginResponse.error;
      }

      await auth.refreshToken(loginResponse.data?.login?.refresh_token);

      if (loginResponse.data?.login?.setup_complete) navigate('/dashboard');
      else navigate('/organization/register');
    } catch (error: any) {
      notification({
        message: error?.response?.data?.message || error.message,
        type: MessageBarType.error,
      });

      console.error(error.response);
    }
  };

  return (
    <AuthLayout vector={<LoginVector />} route="/">
      <Helmet>
        <title>Login | Worx Squad</title>
      </Helmet>
      <LoginForm onSubmit={onSubmit} fetching={fetching} />
    </AuthLayout>
  );
};

export default Login;
