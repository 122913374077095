import { IButtonStyles, ITextStyles } from '@fluentui/react';

export const SArrowButton: Partial<IButtonStyles> = {
  rootHovered: {
    backgroundColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: 'transparent',
  },
  rootExpanded: {
    backgroundColor: 'transparent',
  },
  icon: {
    lineHeight: 'unset',
    fontSize: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const STextHoverStyle: Partial<ITextStyles> = {
  root: {
    selectors: {
      '.weeklyCell:hover &': {
        color: '#fff',
      },
      '.selected &': {
        color: '#fff',
      },
    },
  },
};
