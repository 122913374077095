import {
  Callout,
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  IColumn,
  Icon,
  IconButton,
  MessageBarType,
  PrimaryButton,
  SelectionMode,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useNotification } from '@worx.squad/shared-frontend';
import { useEffect, useState } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import { LeaveTypeModal } from './LeaveTypeModal';
import {
  useDeleteOrgActiveLeaveTypeMutation,
  useGetOrgActiveLeaveTypesSubscription,
} from './leaves.generated';

export const EmployeeLeave = () => {
  const { notification } = useNotification();
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const { organization } = useOrganizationContext();
  const [isModalOpen, { setTrue: showModal, setFalse: hidesModal }] =
    useBoolean(false);
  const [id, setId] = useState<string>('');
  const [leaveData, setLeaveData] = useState<any[]>([]);
  const [editId, setEditId] = useState<any>(undefined);
  const [, deleteLeave] = useDeleteOrgActiveLeaveTypeMutation();

  const [{ data }, refetchLeaves] = useGetOrgActiveLeaveTypesSubscription({
    variables: {
      org_id: organization?.id,
    },
    pause: !organization?.id,
  });
  useEffect(() => {
    if (data) {
      const leaveData =
        data.organization_active_leave_types_aggregate.nodes.map(
          (leave, key) => {
            return {
              key: leave.id,
              srNo: key + 1,
              annual_eligibility: leave.annual_eligibility,
              id: leave.id,
              leave_type: leave.leave_type,
              monthly_earning: leave.monthly_earning_amount,
              carry: leave.annual_carry_forward_amount,
              leave_limit: leave.limit_of_days_at_once,
            };
          },
        );
      setLeaveData(leaveData);
    }
  }, [data]);

  const deleteLeaveType = async (id: string) => {
    const res = await deleteLeave({
      object: {
        will_be_earned: false,
        leave_type_id: id,
        delete: true,
      },
    });
    if (res.error) {
      notification({
        message: res.error.message + '',
        type: MessageBarType.error,
      });
    } else {
      notification({
        message: 'Leave deleted successfully',
        type: MessageBarType.success,
      });
      refetchLeaves({
        requestPolicy: 'cache-and-network',
      });
    }
  };
  const columns: IColumn[] = [
    {
      key: 'key',
      name: 'S.No',
      fieldName: 'key',
      minWidth: 40,
      maxWidth: 50,
      isResizable: true,
      onRender: (item: any) => {
        return <Text>{item.srNo}</Text>;
      },
    },
    {
      key: 'leaveType',
      name: 'Leave type',
      fieldName: 'leave_type',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => {
        return <Text>{item.leave_type}</Text>;
      },
    },
    {
      key: 'annual_eligibility',
      name: 'Annual eligibility',
      fieldName: 'annual_eligibility',
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      onRender: (item: any) => {
        return <Text>{item.annual_eligibility} days</Text>;
      },
    },
    {
      key: 'monthly_earning',
      name: 'Monthly earning',
      fieldName: 'monthly_earning',
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Text>
            {!item.monthly_earning ? '-' : item.monthly_earning + ' days'}
          </Text>
        );
      },
    },
    {
      key: 'annual_forward',
      name: 'Annual carry forward',
      fieldName: 'annual_forward',
      minWidth: 100,
      maxWidth: 130,
      isResizable: true,
      onRender(item: any) {
        return <Text>{!item.carry ? '-' : item.carry + ' per year'}</Text>;
      },
    },
    {
      key: 'leave_limit',
      name: 'Number of leaves that can be applied once',
      fieldName: 'leave_limit',
      minWidth: 100,
      maxWidth: 160,
      isResizable: true,
      onRender(item: any) {
        return <Text>{item.leave_limit}</Text>;
      },
    },
    {
      key: 'action',
      name: 'Action',
      fieldName: 'action',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender(item: any) {
        return (
          <div className="" key={item.id}>
            <IconButton
              id={item.id}
              iconProps={{ iconName: 'MoreVertical' }}
              onClick={() => {
                setId(item.id + '');
                toggleIsCalloutVisible();
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-2/3 mt-5">
      <div className="flex justify-between items-center">
        <Text className="text-lg font-semibold">
          Annual Employee Leaves (
          {data?.organization_active_leave_types_aggregate.aggregate?.sum
            ?.annual_eligibility || 0}
          )
        </Text>
        <PrimaryButton text="Add new" onClick={showModal} />
      </div>
      <div>
        <DetailsList
          items={leaveData}
          columns={columns}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selectionMode={SelectionMode.none}
        />
        {leaveData.length === 0 && (
          <div className="mt-5">
            <Text variant="medium">No Leaves added yet</Text>
          </div>
        )}
      </div>
      {isCalloutVisible && (
        <Callout
          target={`#${CSS.escape(id)}`}
          isBeakVisible={false}
          onDismiss={toggleIsCalloutVisible}
          directionalHint={DirectionalHint.bottomCenter}
          setInitialFocus
        >
          <div>
            <button
              type="button"
              className="py-2 w-full px-4 cursor-pointer flex gap-2 on hover:bg-emerald-100"
              onClick={() => console.log()}
            >
              <Icon iconName="AlertSolid" style={{ color: '#175e74' }} />
              <Text variant="medium">View details</Text>
            </button>
            <button
              className="py-2 w-full px-4 cursor-pointer flex gap-2 on hover:bg-emerald-100"
              onClick={() => {
                setEditId(id);
                showModal();
              }}
            >
              <Icon iconName="Upload" style={{ color: '#175e74' }} />
              <Text variant="medium">Edit</Text>
            </button>
            <button
              type="button"
              className="py-2 w-full px-4 cursor-pointer flex gap-2 on hover:bg-emerald-100"
              onClick={() => {
                deleteLeaveType(id);
                toggleIsCalloutVisible();
              }}
            >
              <Icon iconName="Delete" style={{ color: '#175e74' }} />
              <Text variant="medium">Delete</Text>
            </button>
          </div>
        </Callout>
      )}
      <LeaveTypeModal
        refetch={refetchLeaves}
        isModalOpen={isModalOpen}
        hidesModal={hidesModal}
        editId={editId}
        setEditId={setEditId}
      />
    </div>
  );
};
