import { MessageBarType } from '@fluentui/react';
import {
  AuthLayout,
  LoginVector,
  useLayoutState,
  useNotification,
} from '@worx.squad/shared-frontend';
import delay from 'delay';
import { isEmpty } from 'lodash';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ChangePasswordForm from '../components/ChangePassword/ChangePasswordForm';
import { auth } from '../helpers/hbp-helper';

const ChangePassword = () => {
  const navigate = useNavigate();
  const { ticket } = useParams<{ ticket: string }>();
  const { notification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  const onSubmit = useCallback(
    async ({ password }: { password: string }) => {
      try {
        setLoading(true);

        await auth.logout();

        await auth.confirmPasswordChange(password, ticket as string);

        notification({
          message: 'Password changed successfully.',
          type: MessageBarType.success,
        });

        await delay(200);

        navigate('/login');
      } catch (error: any) {
        notification({
          message: error?.responce?.data?.message || error?.message,
          type: MessageBarType.error,
        });
      }
      setLoading(false);
    },
    [navigate, notification, ticket],
  );

  if (isEmpty(ticket)) {
    navigate('/');
    return null;
  }

  return (
    <AuthLayout vector={<LoginVector />} route="home">
      <ChangePasswordForm loading={loading} onSubmit={onSubmit} />
    </AuthLayout>
  );
};

export default ChangePassword;
