const LinkSvg = ({ feature, disabledState }: any) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25904 3.3162L1.80227 9.77297L0.741607 8.71231L7.19838 2.25554L1.25868 2.25554L1.27194 0.757359H9.75722V9.24264L8.25904 9.2559V3.3162Z"
        fill={disabledState ? '#525252' : feature ? '#FF9900' : 'white'}
      />
    </svg>
  );
};

export default LinkSvg;
