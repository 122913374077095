import React from 'react';

interface ResultProps {
  icon: React.ReactChild;
  title?: React.ReactChild;
  extra?: React.ReactChild;
  subTitle?: React.ReactChild;
  color?: string;
}

export default function Result({
  icon,
  title,
  extra,
  subTitle,
  color,
}: ResultProps) {
  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <div
        className={`rounded-full w-20 h-20 bg-${color}-500 flex items-center justify-center`}
      >
        {icon}
      </div>
      <div className="flex flex-col items-center gap-2">
        {title ? <div className="font-semibold text-3xl">{title}</div> : null}
        {subTitle ? <div className="text-xl">{subTitle}</div> : null}
      </div>
      {extra ? <div>{extra}</div> : null}
    </div>
  );
}
