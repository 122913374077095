import {
  ActionButton,
  IContextualMenuProps,
  Persona,
  PersonaSize,
  Text,
} from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import { useAuth } from '@worx.squad/hbp-react';
import {
  getFileUrl,
  useGetUserByIdQuery,
  useHeader,
} from '@worx.squad/shared-frontend';

const AppHeader = () => {
  const { title } = useHeader();
  const { user, auth } = useAuth();

  const [{ data: userDetails }] = useGetUserByIdQuery({
    variables: {
      Id: user?.id,
    },
    pause: !user?.id,
  });

  const menuProps = useConst<IContextualMenuProps>(() => ({
    shouldFocusOnMount: true,
    items: [
      {
        key: 'logout',
        iconProps: { iconName: 'SignOut' },
        text: 'Logout',
        onClick: () => {
          auth.logout();
        },
      },
    ],
  }));

  return (
    <div className="w-full flex justify-between items-center align-middle">
      <div className="ml-2">
        <Text variant="xLarge" className="font-semibold">
          {title}
        </Text>
      </div>
      <div>
        <ActionButton onRenderMenuIcon={() => null} menuProps={menuProps}>
          <Persona
            size={PersonaSize.size40}
            text={userDetails?.user?.display_name || ''}
            imageUrl={getFileUrl(
              userDetails?.user?.profile[0]?.avatar_file?.file_url,
            )}
            hidePersonaDetails
          />
        </ActionButton>
      </div>
    </div>
  );
};

export default AppHeader;
