const CloseIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#FF9900" />
      <g clip-path="url(#clip0_847_7383)">
        <path
          d="M26.2997 13.7107C25.9097 13.3207 25.2797 13.3207 24.8897 13.7107L19.9997 18.5907L15.1097 13.7007C14.7197 13.3107 14.0897 13.3107 13.6997 13.7007C13.3097 14.0907 13.3097 14.7207 13.6997 15.1107L18.5897 20.0007L13.6997 24.8907C13.3097 25.2807 13.3097 25.9107 13.6997 26.3007C14.0897 26.6907 14.7197 26.6907 15.1097 26.3007L19.9997 21.4107L24.8897 26.3007C25.2797 26.6907 25.9097 26.6907 26.2997 26.3007C26.6897 25.9107 26.6897 25.2807 26.2997 24.8907L21.4097 20.0007L26.2997 15.1107C26.6797 14.7307 26.6797 14.0907 26.2997 13.7107Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_847_7383">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIcon;
