const HowItWorksAnimation = () => {
  return (
    <div>
      <div className="relative">
        <img
          src={'../../../assets/HowItWorks.png'}
          alt="radarIcon"
          width={'350px'}
          height={'414px'}
          className="vert-move"
        />
      </div>
    </div>
  );
};

export default HowItWorksAnimation;
