import { Link, StackItem } from '@fluentui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BookADemo } from '../Home/Utils';
import LogoSvg from '../Home/assets/LogoSvg';

export function Footer() {
  const navigate = useNavigate();
  const url = useLocation();
  return (
    <div className="bg-[#1A1A1A]">
      <StackItem
        className="py-4 px-10 sm:px-20"
        style={{ borderTop: '1px solid #185D74' }}
      >
        <div className="gap-[60px] flex justify-between items-start lg:items-center">
          <Link style={{ width: 176 }} onClick={() => navigate('/')}>
            <LogoSvg />
          </Link>
          <StackItem className="text-base gap-2 lg:gap-6 flex flex-wrap flex-col lg:flex-row lg:flex items-start lg:items-center text-black mr-[0rem] lg:mr-[11rem]">
            <Link
              className="text-white font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline"
              href="https://www.worxwide.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Worxwide"
            >
              About Worxwide
            </Link>
            <Link
              className={`text-white font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline ${
                url.pathname === '/how-it-works' ? 'text-[#FF9900]' : ''
              }`}
              onClick={() => navigate('/how-it-works')}
            >
              How it works
            </Link>
            <Link
              className={`text-white font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline ${
                url.pathname === '/pricing' ? 'text-[#FF9900]' : ''
              }`}
              onClick={() => navigate('/pricing')}
            >
              Pricing
            </Link>
            <Link
              className={`text-white font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline ${
                url.pathname === '/blog' ? 'text-[#FF9900]' : ''
              }`}
              onClick={() => navigate('/blog')}
            >
              Blog
            </Link>
            <Link
              className={`text-white font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline ${
                url.pathname === '/contact-us' ? 'text-[#FF9900]' : ''
              }`}
              onClick={() => navigate('/contact-us')}
            >
              Contact Us
            </Link>
            <Link
              className="text-white font-semibold hover:text-[#FF9900] hover:ease-in-out duration-300 hover:no-underline"
              onClick={BookADemo}
            >
              Book a Demo
            </Link>
          </StackItem>
        </div>
      </StackItem>
    </div>
  );
}
