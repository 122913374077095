import { Icon } from '@fluentui/react';
import Result from '../Activate/Result';

export interface AuthScreenProps {
  error: boolean;
}

const AuthScreen = ({ error }: AuthScreenProps) => {
  return (
    <div className="container m-auto h-screen flex flex-col justify-center items-center">
      {error ? (
        <Result
          icon={<Icon iconName="Cancel" className="text-5xl text-white" />}
          color="blue"
          title="Activation Failed"
          subTitle={"Well that didn't work! Please try again later."}
        />
      ) : (
        <Result
          icon={<Icon iconName="Accept" className="text-5xl text-white" />}
          color="green"
          title="OAuth Success"
          subTitle="You can close this window now."
        />
      )}
    </div>
  );
};

export default AuthScreen;
