import { IconButton, MessageBarType, Modal } from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { useBoolean } from 'ahooks';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import HolidayForm from './HolidayForm';
import { holidaySchema } from './holiday.schema';
import { Holiday, HolidayFormValues } from './holiday.types';
import { useUpdateHolidayMutation } from './holidays.generated';

const EditHoliday: FC<{ item: Holiday }> = ({ item }) => {
  const [isModalOpen, { toggle: toggleModalOpen }] = useBoolean(false);
  const editHolidayForm = useForm<HolidayFormValues>({
    mode: 'all',
    resolver: holidaySchema,
  });

  const [loading, setLoading] = useState(false);

  const [, updateHoliday] = useUpdateHolidayMutation();
  const { notification } = useNotification();

  useEffect(() => {
    editHolidayForm.reset({
      name: item.name,
      date: moment(item.date).toDate(),
      description: item.description || undefined,
    });
  }, [editHolidayForm, item.date, item.description, item.name]);

  const onSubmit = async (data: HolidayFormValues) => {
    setLoading(true);
    try {
      const res = await updateHoliday({
        data: {
          date: moment(data.date).format('YYYY-MM-DD'),
          description: data.description,
          name: data.name,
        },
        id: item.id,
      });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
      } else {
        notification({
          message: 'Holiday updated successfully',
          type: MessageBarType.success,
        });
        window.dispatchEvent(new CustomEvent('refresh-holidays'));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      toggleModalOpen();
    }
  };
  return (
    <>
      <IconButton onClick={toggleModalOpen} iconProps={{ iconName: 'Edit' }} />
      <Modal onDismiss={toggleModalOpen} isOpen={isModalOpen}>
        <div className="p-4 w-96">
          <div className="flex justify-between">
            <h1>Update Holiday</h1>
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              onClick={toggleModalOpen}
            />
          </div>
          <HolidayForm
            buttonText="Update"
            form={editHolidayForm}
            loading={loading}
            onSubmit={onSubmit}
          />
        </div>
      </Modal>
    </>
  );
};

export default EditHoliday;
