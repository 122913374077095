import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Link,
  MessageBarType,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  PrimaryButtonWithLoader,
  useNotification,
} from '@worx.squad/shared-frontend';
import { OperationContext } from 'urql';
import { useOrganizationContext } from '../../../context/OrganizationContext/OrganizationContext';
import { useDeleteOrganizationAdminMutation } from './administrators.generated';

const DeleteAdministrator = ({
  userId,
  refetch,
}: {
  userId: string;
  refetch: (opts?: Partial<OperationContext> | undefined) => void;
}) => {
  const { organization } = useOrganizationContext();
  const { notification } = useNotification();

  const [{ fetching }, deleteAdministrator] =
    useDeleteOrganizationAdminMutation();
  const [dialogHidden, { toggle: toggleDialog }] = useBoolean(true);

  const onSubmit = async () => {
    try {
      const res = await deleteAdministrator({
        object: {
          org_id: organization?.id,
          user_id: userId,
        },
      });
      refetch({ requestPolicy: 'cache-and-network' });
      if (res.error) {
        notification({
          message: res.error.message,
          type: MessageBarType.error,
        });
        return;
      }
      notification({
        message: 'Administrator deleted',
        type: MessageBarType.success,
      });
      toggleDialog();
    } catch (error) {
      console.error(error);
      toggleDialog();
    }
  };

  const dialogContentProps = {
    type: DialogType.close,
    title: 'Delete Administrator?',
    subText: 'Are you sure you want to delete this administrator?',
  };

  return (
    <>
      <Link onClick={toggleDialog}>Delete</Link>
      <Dialog
        hidden={dialogHidden}
        onDismiss={toggleDialog}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButtonWithLoader
            loading={fetching}
            onClick={onSubmit}
            text="Delete"
          />
          <DefaultButton onClick={toggleDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DeleteAdministrator;
