import {
  IconButton,
  IModalProps,
  MessageBarType,
  Modal,
  Stack,
  Text,
} from '@fluentui/react';
import { useNotification } from '@worx.squad/shared-frontend';
import { useState } from 'react';
import { useOrganizationContext } from '../../context/OrganizationContext/OrganizationContext';
import NewClientForm from './NewClientForm';
import { useCreateClientMutation } from './projects-and-clients.generated';

const NewClientModal = (props: IModalProps) => {
  const { notification } = useNotification();
  const [, createClient] = useCreateClientMutation();
  const { organization } = useOrganizationContext();
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    props.onDismiss && props.onDismiss();
  };

  return (
    <Modal {...props}>
      <Stack className="p-4" style={{ minWidth: 400 }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Text variant="xLarge">Add new client</Text>
          <IconButton iconProps={{ iconName: 'Cancel' }} onClick={onClose} />
        </Stack>
        <Stack>
          <NewClientForm
            loading={loading}
            onSubmit={async (data) => {
              try {
                setLoading(true);
                const res = await createClient({
                  object: {
                    client_name: data.name,
                    organization_id: organization?.id,
                  },
                });
                if (res.error) {
                  notification({
                    message: res.error.message?.replace('[GraphQL]', '').trim(),
                    type: MessageBarType.error,
                  });
                  setLoading(false);
                  return;
                }
                notification({
                  message: 'Client created successfully',
                  type: MessageBarType.success,
                });
                onClose();
                setLoading(false);
              } catch (error) {
                console.error(error);
                setLoading(false);
              }
            }}
            onCancel={onClose}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default NewClientModal;
