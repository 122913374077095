import { useHeader, useLayoutState } from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect } from 'react';
import { Department } from '../components/Department';

const DepartmentPage = () => {
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();
  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Departments');
  }, [setTitle]);

  return <Department />;
};

export default DepartmentPage;
