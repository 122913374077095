import { DefaultButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import DownloadApp from '../Home/DownloadApp';
import BlackLinkSvg from '../Home/assets/BlackLinkSvg';
import StarterSvg from './assets/StarterSvg.svg';

export const Starter = () => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  return (
    <div className="text-lg sm:h-screen p-20 bg-[#101010]">
      <div className="mx-[20px] sm:mx-[40px] md:flex justify-between">
        <div className="flex flex-col w-full xl:w-[40%] self-center pt-[50px] sm:pt-[100px]">
          <div className="text-white font-semibold font-poppins text-[48px] sm:text-[72px] leading-[3.5rem] sm:leading-[5rem]">
            Maximize <br />
            Productivity with <br />
            strategic insights
          </div>
          <div className="text-black text-[14px] mt-5">
            <div>
              <p className="text-[#FF9900] text-[22px] pl-[2px]">
                Resource utilization, project deviations, skill deviations and
                much more.
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 mb-[6rem] sm:pt-[76px] md:pb-[282px]">
            <div>
              <DefaultButton
                onClick={showModal}
                className="w-full border border-[#FF9900] text-white bg-[#FF9900] hover:bg-[#e19a30] hover:text-white px-6 py-6 ease-in-out duration-300"
              >
                <span className="flex gap-4 justify-center items-center">
                  <span className="text-[1rem] text-black font-semibold">
                    Download Squad For Free
                  </span>
                  <span>
                    <BlackLinkSvg />
                  </span>
                </span>
              </DefaultButton>
              <DownloadApp
                showModal={showModal}
                isModalOpen={isModalOpen}
                hideModal={hideModal}
              />
            </div>
          </div>
        </div>
        <div className="mt-20 hidden xl:block">
          <img src={StarterSvg} alt="Starter Icon" />
        </div>
      </div>
    </div>
  );
};
