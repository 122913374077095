import { useHeader, useLayoutState } from '@worx.squad/shared-frontend';
import { useEffect, useLayoutEffect } from 'react';
import PerformanceReviewHome from '../components/PerformanceReview/PerformanceReviewHome';

const PerformanceReview = () => {
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();
  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Performance Review');
  }, [setTitle]);

  return (
    <div className="w-full px-8 py-3.5">
      <PerformanceReviewHome />
    </div>
  );
};

export default PerformanceReview;
