export const pricingFeatures = [
  {
    title: 'Collaboration',
    subTitleOne: 'Tap & Talk',
    contentOne: 'Instant immersive in-office experience.',
    subTitleTwo: 'Group Broadcast',
    contentTwo: 'Instant broadcast to everyone in groups.',
    subTitleThree: '3D Immersive Rooms',
    contentThree: 'Dive into a new dimension of collaboration.',
    subTitleFour: 'Documents Integration',
    contentFour:
      'Seamlessly incorporate your files from Onedrive, Dropbox or Google Drive.',
    subTitleFive: 'Chat',
    contentFive:
      'Real time communication with chat, docs and tasks at one place.',
    subTitleSix: 'Groups ( Internal & External )',
    contentSix: 'Collaboration among employees and clients',
  },
  {
    title: 'Productivity',
    subTitleOne: 'Project Management',
    contentOne: 'Streamline your projects effortlessly.',
    subTitleTwo: 'Org Insights',
    contentTwo: 'Unlock data-driven organization wisdom at your fingertips.',
    subTitleThree: 'Upskilling',
    contentThree: `Elevate your team's skills and knowledge.`,
    subTitleFour: 'Performance Review',
    contentFour: 'Define and manage performance KPIs and goals seamlessly.',
    subTitleFive: 'Timesheets',
    contentFive: 'Simplify time tracking and reporting.',
    subTitleSix: 'Leave Management',
    contentSix: 'Manage leaves with ease.',
    subTitleSeven: 'Asset Management',
    contentSeven: 'Assign and manage hardware & software assets at one place.',
  },
  {
    title: 'Engagement',
    subTitleOne: 'Wall of Moments',
    contentOne: 'Your personal social space.',
    subTitleTwo: 'Birthdays/Anniversaries',
    contentTwo: 'Birthdays/Anniversaries',
    subTitleThree: 'Kudos & Rewards',
    contentThree: 'Celebrate achievements and achievers.',
    subTitleFour: 'Company Announcements',
    contentFour: 'Organization wide announcements made easy.',
  },
  {
    title: 'Wellness',
    subTitleOne: 'Online Health Camps',
    contentOne: 'Prioritize employee well-being.',
    subTitleTwo: 'Stress & Burnouts',
    contentTwo: 'Monitor, measure and address stress(surveys) and burnouts.',
    subTitleThree: 'Health reminders',
    contentThree: 'Frequent nudges for screen time, hydration and others.',
  },
];

export const planDetails = [
  {
    label: '',
    planName: 'Experience Empowered ',
    planDes:
      'For firms looking to enhance employee experience and manage basic operations ',
    planType: 'Free',
    planTag: 'What’s Included:',
    planFeature: [
      {
        feature: 'Projects (up to 5)',
        featureDes:
          'Projects  helps teams to organize and track projects work. Its key features include task creation, gantt chart, sprints and time time tracking .',
      },
      {
        feature: 'Goals ( up to 5)',
        featureDes:
          'The Goals module enables organizations to set, track, and manage goals at both the company and individual levels. ',
      },
      {
        feature: 'Upskilling (Trainings & Assessment)',
        featureDes: `Upskilling caters to employee's skill development , training and assessment needs and provides org level customization for creating training and assessment as well.`,
      },
      {
        feature: 'Leaves',
        featureDes:
          'The leaves module is used to apply and track the leaves. It also has list of holidays for the year in it and managers can approve and reject leave applications as per their requirements. ',
      },
      {
        feature: 'Timesheet',
        featureDes:
          'Ski is an AI enabled bot which helps users to take quick actions like scheduling a meeting or adding tasks in a project and assign them to the indiviual in a chat based inerface. ',
      },
      {
        feature: 'Calendar',
        featureDes:
          'The calendar keeps up with all the scheduling of meetings and events. It’s simple and clear which makes scheduling and tracking the meetings easier ',
      },
      {
        feature: 'Wall of moments',
        featureDes:
          'The Home module caters as an employee engagement and everyday reminder loaded with unique features like Wall of moments, Ask squad, reminders, Tasks and wall of fame ',
      },
      {
        feature: 'Live office',
        featureDes:
          ' Live Office module is crucial for real time collaboration among employees of an organization. It has features like audio call, 3D virtual conference rooms chat and others.',
      },
      {
        feature: 'Stress survey',
        featureDes:
          'It enables the managers to track the stress levels of its employees at work by providing stress graph as per stress level marked by each employee',
      },
    ],
  },
  {
    label: 'MOST POPULAR',
    planName: 'Productivity Plus',
    planDes:
      'For firms looking to boost productivity & tracking organization health holistically ',
    planType: 8,
    planTag: 'Everything from Experience Empowered and...',
    planFeature: [
      {
        feature: 'Projects (unlimited)',
        featureDes:
          'Projects  helps teams to organize and track projects work. Its key features include task creation, gantt chart, sprints and time time tracking .',
      },
      {
        feature: 'Goals (unlimited)',
        featureDes:
          'The Goals module enables organizations to set, track, and manage goals at both the company and individual levels. ',
      },
      {
        feature: 'Skill matrix',
        featureDes:
          'This matrix provides an employee vs skills table  that shows list of employees with a searched completency  along with their skill expertise level. facilitating project based resource management. ',
      },
      {
        feature: 'AI magic planner',
        featureDes:
          'An AI powered plan optimizer which enables the users to automatically  optimize gantt charts and help in tasks prioritization for projects.',
      },
      {
        feature: 'AI ask Squad',
        featureDes:
          'The AI powered personalized assistant to provide an employee with its  work insights, help navigating through work related queries, and assists an employee in providing answers for his professional growth related queries.',
      },
      {
        feature: 'AI assistant (Ski)',
        featureDes:
          'Ski is an AI enabled bot which helps users to take quick actions like scheduling a meeting or adding tasks in a project and assign them to the individual in a chat based interface. ',
      },
      {
        feature: 'Manager dashboards',
        featureDes:
          'This Dashboard facilitates managers to track kPIs , get an project status overview, identify project trends and provides data driven project reports.',
      },
      {
        feature: 'Resource management',
        featureDes:
          'Resource management helps managers  allocate resources for different projects by providing matrices for  availability and skill based competency of employees to choose from.',
      },
      {
        feature: 'Customer portal',
        featureDes:
          'The customer portal allows organizations to include clients in their projects with controlled visibility so that they have real time updates on activities.',
      },
      {
        feature: 'Activity tracker',
        featureDes: `Activity tracker helps organizations in  keeping the employee's track of login time and its overall dedicated working hours everyday .`,
      },
      {
        feature: 'Free data migration',
        featureDes:
          'Under this plan, free data migration is available for organizations to migrate their data to their Worxsquad. ',
      },
      {
        feature: 'Free implementation',
        featureDes:
          'With this plan Squad implementation would be entirely on us. ',
      },
    ],
  },
  {
    label: '',
    planName: 'Growth Genius',
    planDes:
      'For firms looking to optimize business processes and manage talent at scale ',
    planType: 15,
    planTag: 'Everything from Productivity Plus and...',
    planFeature: [
      {
        feature: 'Capacity planning',
        featureDes:
          'It provides predictive resource allocation and employee availability forecasting  to achieve optimal team capacity across projects.',
      },
      {
        feature: 'Financial planning',
        featureDes:
          'Financial planning section enables managers in accurate budgeting and tracking expense to optimize project profitability.',
      },
      {
        feature: 'Custom integrations',
        featureDes:
          'Customized intgeration allow users to  connect with other softwares seamlessly, ',
      },
      {
        feature: '12*6 customer support',
        featureDes:
          'Squad provides personalized assistance for issue resolution and customer support.',
      },
      {
        feature: 'Quality control',
        featureDes:
          'Through projects module, monitoring, testing and validation is easily achievable ensuring high quality deliverables',
      },
      {
        feature: 'Workflow Automation',
        featureDes:
          ' Projects module has a feature to create automated workflow basis the tasks created in projects, which are also customizable for better understanding of project WBS visually',
      },
      {
        feature: 'CRM integration (Zoho & Salesforce)',
        featureDes:
          'CRM integration for seamless syncing of customer data and contacts with Squad to enhance project alignment',
      },
      {
        feature: 'Invoice Integration (Zoho)',
        featureDes:
          'Squad also enables users to integrate their invoicing tools with Squad for successful budget tracking',
      },
      {
        feature: 'Resource Management',
        featureDes:
          'Resource management helps managers  allocate resources for different projects by providing matrices for  availability and skill based competency of employees to choose from.',
      },
      {
        feature: 'Human Capital consulting support',
        featureDes:
          'Squad support to provide  guidance on talent management and optimize workforce structure.',
      },
      {
        feature: '99% uptime guarantee',
        featureDes: 'This plan covers a 99% uptime garantee for Squad',
      },
    ],
  },
];

export const coreFeatureList = [
  {
    label: 'Organizational & Employee Growth',
    coreFeature: [
      ['Goals', 'Up to 5', true, 'Unlimited'],
      ['Upskilling (Training & Assessment)', true, 'Unlimited', true],
      ['Manager Dashboard', false, true, true],
      ['Skill Matrix', false, true, true],
    ],
  },
  {
    label: 'Workforce Management',
    coreFeature: [
      ['Leaves', true, true, true],
      ['Timesheet', true, true, true],
      ['Calender', true, true, true],
      ['Resource Management', false, true, true],
      ['Activity Tracker', false, true, true],
      ['Stress Survey', true, true, true],
      ['Wall of Moments', true, true, true],
      ['AI Ask Squad', false, true, true],
    ],
  },
  {
    label: 'Project & Operations Management',
    coreFeature: [
      ['Projects', 'Up to 5', 'Unlimited', 'Unlimited'],
      ['AI Magic Planner', false, true, true],
      ['AI Assistant Ski', false, true, true],
      ['Financial Planner', false, false, true],
      ['Capacity Planning', false, false, true],
      ['Workflow Automation', false, false, true],
      ['Invoice Integration (Zoho)', false, false, true],
      ['CRM Integration (Zoho & Salesforce)', false, false, true],
      ['Live Office (Audio/Video/Chat/Groups)', true, true, true],
    ],
  },
  {
    label: 'Worxwide Team Support',
    coreFeature: [
      ['Free Data Migration', false, true, true],
      ['Free Data Implementation', false, true, true],
      ['Customer Portal', false, true, true],
      ['12*6 Customer Support', false, false, true],
      ['Custom Integration', false, false, true],
      ['Quality Control', false, false, true],
      ['99% Uptime Guarantee', false, false, true],
      ['Human Capital Consulting', false, false, true],
    ],
  },
];

export const calculatePrice = (planType: any, option: string) => {
  if (option === 'Monthly') {
    return planType;
  } else if (option === 'Yearly') {
    const totalCost = planType * 12;
    return Math.floor(totalCost - totalCost / 100);
  } else if (option === 'Life Time') {
    const totalCost = planType * 120;
    return Math.floor(totalCost - totalCost / 100);
  }
};
