import Address from './Address';
import { TryUs } from './TryUs';

const Contact = () => {
  return (
    <div className="flex flex-col lg:flex-row">
      <div className="w-full px-[20px] sm:px-[40px] flex justify-center items-center pt-[20px] bg-[#F1F8F9]">
        <TryUs />
      </div>
      <div
        className="w-[100%] md:w-[100%] lg:w-[35%]  bg-[#185D74] flex pt-[20px] mt-[3rem] sm:mt-0"
        style={{ height: 'calc(100vh - 86px)' }}
      >
        <Address />
      </div>
    </div>
  );
};

export default Contact;
