const HambergIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="white" />
      <g clip-path="url(#clip0_847_7109)">
        <path
          d="M11 26H29V24H11V26ZM11 21H29V19H11V21ZM11 14V16H29V14H11Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_847_7109">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HambergIcon;
