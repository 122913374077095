import { Icon, Separator, Stack, StackItem, Text } from '@fluentui/react';
import { CalendarLogo, MoneyLogo } from './Svg';
import { SeparatorStyles, TimeCardBorderRadius } from './style';

export const TimeSummaryCards = () => {
  return (
    <div className="grid grid-cols-3 justify-start gap-5 2xl:flex">
      <Stack
        className="p-6 border border-gray-200"
        tokens={{ childrenGap: 20 }}
        horizontal
        styles={TimeCardBorderRadius}
      >
        <Icon
          iconName="SkypeCircleClock"
          className="text-5xl"
          styles={{ root: { color: '#0078D4' } }}
        />
        <StackItem>
          <Stack horizontal tokens={{ childrenGap: 10 }} className="items-end">
            <Text className="text-3xl font-semibold">08:21 </Text>
            <Text className="text-lg font-semibold">Hrs </Text>
          </Stack>
          <Separator styles={SeparatorStyles} />
          <Text className="text-gray-600">Productive time</Text>
        </StackItem>
      </Stack>
      <Stack
        className="p-6 border border-gray-200"
        tokens={{ childrenGap: 20 }}
        horizontal
        styles={TimeCardBorderRadius}
      >
        <Icon
          iconName="SkypeCircleClock"
          className="text-5xl"
          styles={{ root: { color: '#EABB34' } }}
        />
        <StackItem>
          <Stack horizontal tokens={{ childrenGap: 10 }} className="items-end">
            <Text className="text-3xl font-semibold">02:34 </Text>
            <Text className="text-lg font-semibold">Hrs </Text>
          </Stack>
          <Separator styles={SeparatorStyles} />
          <Text className="text-gray-600">Break time</Text>
        </StackItem>
      </Stack>
      <Stack
        className="p-6 border border-gray-200"
        tokens={{ childrenGap: 20 }}
        horizontal
        styles={TimeCardBorderRadius}
      >
        <MoneyLogo className="text-5xl" />
        <StackItem>
          <Stack horizontal tokens={{ childrenGap: 10 }} className="items-end">
            <Text className="text-3xl font-semibold">242 </Text>
            <Text className="text-lg font-semibold">Hrs </Text>
          </Stack>
          <Separator styles={SeparatorStyles} />
          <Text className="text-gray-600">Meetings time</Text>
        </StackItem>
      </Stack>
      <Stack
        className="p-6 border  border-gray-200"
        tokens={{ childrenGap: 20 }}
        horizontal
        styles={TimeCardBorderRadius}
      >
        <CalendarLogo className="text-5xl" />
        <StackItem>
          <Stack horizontal tokens={{ childrenGap: 10 }} className="items-end">
            <Text className="text-3xl font-semibold">22 </Text>
            <Text className="text-lg font-semibold">Days </Text>
          </Stack>
          <Separator styles={SeparatorStyles} />
          <Text className="text-gray-600">Remote time</Text>
        </StackItem>
      </Stack>
    </div>
  );
};
