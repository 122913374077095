import * as Types from '@worx.squad/app-sdk/base-types';

import * as Operations from '@worx.squad/app-sdk/base-type-documents';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateLeaveCycleMutationVariables = Types.Exact<{
  org_id: Types.Scalars['uuid'];
  starting_date: Types.Scalars['String'];
}>;

export type CreateLeaveCycleMutation = {
  __typename?: 'mutation_root';
  createLeaveCycle?: {
    __typename?: 'LeaveCycleOutput';
    leave_cycle_id: string;
  } | null;
};

export type GetLeaveCycleSubscriptionVariables = Types.Exact<{
  org_id: Types.Scalars['uuid'];
}>;

export type GetLeaveCycleSubscription = {
  __typename?: 'subscription_root';
  organization_leave_cycles: Array<{
    __typename: 'organization_leave_cycles';
    starting_date?: string | null;
    ending_date?: string | null;
  }>;
};

export type AddNewLeaveTypeMutationVariables = Types.Exact<{
  object: Types.AddNewLeaveTypeInput;
}>;

export type AddNewLeaveTypeMutation = {
  __typename?: 'mutation_root';
  addNewLeaveType?: {
    __typename?: 'AddNewLeaveTypeOutput';
    new_leave_type_id?: string | null;
  } | null;
};

export type UpdateLeaveTypeByIdMutationVariables = Types.Exact<{
  object: Types.EditLeaveTypeInput;
}>;

export type UpdateLeaveTypeByIdMutation = {
  __typename?: 'mutation_root';
  editLeaveType?: {
    __typename?: 'EditLeaveTypeOutput';
    leave_type_id: string;
  } | null;
};

export type DeleteOrgActiveLeaveTypeMutationVariables = Types.Exact<{
  object: Types.EditLeaveTypeInput;
}>;

export type DeleteOrgActiveLeaveTypeMutation = {
  __typename?: 'mutation_root';
  editLeaveType?: {
    __typename?: 'EditLeaveTypeOutput';
    leave_type_id: string;
  } | null;
};

export type ApplyForLeaveMutationVariables = Types.Exact<{
  org_id: Types.Scalars['String'];
  leave_type_id: Types.Scalars['String'];
  leave_start_date?: Types.InputMaybe<Types.Scalars['String']>;
  leave_end_date?: Types.InputMaybe<Types.Scalars['String']>;
  leave_dates:
    | Array<Types.InputMaybe<Types.Scalars['String']>>
    | Types.InputMaybe<Types.Scalars['String']>;
}>;

export type ApplyForLeaveMutation = {
  __typename?: 'mutation_root';
  submitLeave: {
    __typename?: 'SubmitLeaveOutput';
    new_employee_leave_id: string;
  };
};

export type GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscriptionVariables =
  Types.Exact<{
    orgId: Types.Scalars['uuid'];
    starting_date: Types.Scalars['String'];
  }>;

export type GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription = {
  __typename?: 'subscription_root';
  organization_leave_cycles: Array<{
    __typename?: 'organization_leave_cycles';
    id: any;
  }>;
};

export type GetEmployeeLeaveApplicationByIdSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;

export type GetEmployeeLeaveApplicationByIdSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_application_by_pk?: {
    __typename?: 'organization_user_leave_application';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    leave_type: string;
    is_approved: boolean;
    leave_start_date?: string | null;
    leave_end_date?: string | null;
    leave_application_status: Types.Enum_Leave_Status_Type_Enum;
    leave_type_id?: any | null;
    leaveDates: Array<{
      __typename?: 'organization_user_leave_dates';
      id: any;
      created_at: any;
      updated_at: any;
      leave_application_id: any;
      date: string;
    }>;
    user_leave_data?: {
      __typename?: 'organization_user_leave_data';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      user_role: string;
      leave_type?: string | null;
      leave_type_id?: any | null;
      earned_leaves_amount?: any | null;
    } | null;
  } | null;
};

export type GetOrgActiveLeaveTypesSubscriptionVariables = Types.Exact<{
  org_id: Types.Scalars['uuid'];
}>;

export type GetOrgActiveLeaveTypesSubscription = {
  __typename?: 'subscription_root';
  organization_active_leave_types_aggregate: {
    __typename?: 'organization_active_leave_types_aggregate';
    nodes: Array<{
      __typename?: 'organization_active_leave_types';
      id: any;
      annual_eligibility: number;
      leave_type: string;
      monthly_earning_amount?: any | null;
      annual_carry_forward_amount?: number | null;
      limit_of_days_at_once?: number | null;
    }>;
    aggregate?: {
      __typename?: 'organization_active_leave_types_aggregate_fields';
      sum?: {
        __typename?: 'organization_active_leave_types_sum_fields';
        annual_eligibility?: number | null;
      } | null;
    } | null;
  };
};

export type GetLeaveTypeByIdSubscriptionVariables = Types.Exact<{
  edit_id: Types.Scalars['uuid'];
}>;

export type GetLeaveTypeByIdSubscription = {
  __typename?: 'subscription_root';
  organization_active_leave_types_by_pk?: {
    __typename?: 'organization_active_leave_types';
    annual_carry_forward_amount?: number | null;
    leave_type: string;
    annual_eligibility: number;
    monthly_earning_amount?: any | null;
    limit_of_days_at_once?: number | null;
    will_carry_forward: boolean;
    will_be_earned_monthly: boolean;
  } | null;
};

export type GetUserLeavesDataSubscriptionVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserLeavesDataSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
    }> | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
    }> | null;
  }>;
};

export type GetLeaveDataOfOrganizationByLeaveIdSubscriptionVariables =
  Types.Exact<{
    leave_cycle_id: Types.Scalars['uuid'];
  }>;

export type GetLeaveDataOfOrganizationByLeaveIdSubscription = {
  __typename?: 'subscription_root';
  organization_user_leave_data: Array<{
    __typename?: 'organization_user_leave_data';
    id: any;
    created_at: any;
    updated_at: any;
    org_id: any;
    org_user_id: any;
    user_role: string;
    leave_type?: string | null;
    leave_type_id?: any | null;
    earned_leaves_amount?: any | null;
    used_leave_amount: number;
    used_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Types.Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    rejected_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Types.Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    pending_leaves?: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_amount_in_days: number;
      leave_application_status: Types.Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      uploaded_files_for_leave: Array<{
        __typename?: 'organization_user_leave_files';
        id: any;
        storage_file_id: any;
        file_url: string;
      }>;
    }> | null;
    leave_type_data?: {
      __typename?: 'organization_active_leave_types';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      leave_type: string;
      will_carry_forward: boolean;
      will_be_earned_monthly: boolean;
      monthly_earning_amount?: any | null;
      annual_eligibility: number;
      annual_carry_forward_amount?: number | null;
      followed_leave_cycle?: any | null;
      organization_leave_cycle?: {
        __typename?: 'organization_leave_cycles';
        id: any;
        starting_date?: string | null;
        ending_date?: string | null;
        current_term_years?: string | null;
      } | null;
    } | null;
    leave_applications: Array<{
      __typename?: 'organization_user_leave_application';
      id: any;
      created_at: any;
      updated_at: any;
      org_id: any;
      org_user_id: any;
      leave_type: string;
      is_approved: boolean;
      leave_start_date?: string | null;
      leave_end_date?: string | null;
      leave_application_status: Types.Enum_Leave_Status_Type_Enum;
      leave_type_id?: any | null;
      leave_amount_in_days: number;
    }>;
  }>;
};

export type GetOrganizationLeaveDataQueryVariables = Types.Exact<{
  startDate: Types.Scalars['String'];
  endDate: Types.Scalars['String'];
  organizationId: Types.Scalars['uuid'];
}>;

export type GetOrganizationLeaveDataQuery = {
  __typename?: 'query_root';
  organization_user_leave_dates: Array<{
    __typename?: 'organization_user_leave_dates';
    date: string;
    leave_application?: {
      __typename?: 'organization_user_leave_application';
      leave_type: string;
      leave_application_status: Types.Enum_Leave_Status_Type_Enum;
      leave_reason?: string | null;
      user: {
        __typename?: 'users';
        first_name?: string | null;
        last_name?: string | null;
      };
    } | null;
  }>;
};

export function useCreateLeaveCycleMutation() {
  return Urql.useMutation<
    Operations.CreateLeaveCycleMutation,
    Operations.CreateLeaveCycleMutationVariables
  >(Operations.CreateLeaveCycleDocument);
}

export function useGetLeaveCycleSubscription<
  TData = Operations.GetLeaveCycleSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<Operations.GetLeaveCycleSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    Operations.GetLeaveCycleSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    Operations.GetLeaveCycleSubscription,
    TData,
    Operations.GetLeaveCycleSubscriptionVariables
  >({ query: Operations.GetLeaveCycleDocument, ...options }, handler);
}

export function useAddNewLeaveTypeMutation() {
  return Urql.useMutation<
    Operations.AddNewLeaveTypeMutation,
    Operations.AddNewLeaveTypeMutationVariables
  >(Operations.AddNewLeaveTypeDocument);
}

export function useUpdateLeaveTypeByIdMutation() {
  return Urql.useMutation<
    Operations.UpdateLeaveTypeByIdMutation,
    Operations.UpdateLeaveTypeByIdMutationVariables
  >(Operations.UpdateLeaveTypeByIdDocument);
}

export function useDeleteOrgActiveLeaveTypeMutation() {
  return Urql.useMutation<
    Operations.DeleteOrgActiveLeaveTypeMutation,
    Operations.DeleteOrgActiveLeaveTypeMutationVariables
  >(Operations.DeleteOrgActiveLeaveTypeDocument);
}

export function useApplyForLeaveMutation() {
  return Urql.useMutation<
    Operations.ApplyForLeaveMutation,
    Operations.ApplyForLeaveMutationVariables
  >(Operations.ApplyForLeaveDocument);
}

export function useGetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription<
  TData = Operations.GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<Operations.GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    Operations.GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    Operations.GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscription,
    TData,
    Operations.GetOrganizationLeaveCycleByOrgIdAndStartingDateSubSubscriptionVariables
  >(
    {
      query:
        Operations.GetOrganizationLeaveCycleByOrgIdAndStartingDateSubDocument,
      ...options,
    },
    handler,
  );
}

export function useGetEmployeeLeaveApplicationByIdSubscription<
  TData = Operations.GetEmployeeLeaveApplicationByIdSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<Operations.GetEmployeeLeaveApplicationByIdSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    Operations.GetEmployeeLeaveApplicationByIdSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    Operations.GetEmployeeLeaveApplicationByIdSubscription,
    TData,
    Operations.GetEmployeeLeaveApplicationByIdSubscriptionVariables
  >(
    { query: Operations.GetEmployeeLeaveApplicationByIdDocument, ...options },
    handler,
  );
}

export function useGetOrgActiveLeaveTypesSubscription<
  TData = Operations.GetOrgActiveLeaveTypesSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<Operations.GetOrgActiveLeaveTypesSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    Operations.GetOrgActiveLeaveTypesSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    Operations.GetOrgActiveLeaveTypesSubscription,
    TData,
    Operations.GetOrgActiveLeaveTypesSubscriptionVariables
  >({ query: Operations.GetOrgActiveLeaveTypesDocument, ...options }, handler);
}

export function useGetLeaveTypeByIdSubscription<
  TData = Operations.GetLeaveTypeByIdSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<Operations.GetLeaveTypeByIdSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    Operations.GetLeaveTypeByIdSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    Operations.GetLeaveTypeByIdSubscription,
    TData,
    Operations.GetLeaveTypeByIdSubscriptionVariables
  >({ query: Operations.GetLeaveTypeByIdDocument, ...options }, handler);
}

export function useGetUserLeavesDataSubscription<
  TData = Operations.GetUserLeavesDataSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<Operations.GetUserLeavesDataSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    Operations.GetUserLeavesDataSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    Operations.GetUserLeavesDataSubscription,
    TData,
    Operations.GetUserLeavesDataSubscriptionVariables
  >({ query: Operations.GetUserLeavesDataDocument, ...options }, handler);
}

export function useGetLeaveDataOfOrganizationByLeaveIdSubscription<
  TData = Operations.GetLeaveDataOfOrganizationByLeaveIdSubscription,
>(
  options: Omit<
    Urql.UseSubscriptionArgs<Operations.GetLeaveDataOfOrganizationByLeaveIdSubscriptionVariables>,
    'query'
  > = {},
  handler?: Urql.SubscriptionHandler<
    Operations.GetLeaveDataOfOrganizationByLeaveIdSubscription,
    TData
  >,
) {
  return Urql.useSubscription<
    Operations.GetLeaveDataOfOrganizationByLeaveIdSubscription,
    TData,
    Operations.GetLeaveDataOfOrganizationByLeaveIdSubscriptionVariables
  >(
    {
      query: Operations.GetLeaveDataOfOrganizationByLeaveIdDocument,
      ...options,
    },
    handler,
  );
}

export function useGetOrganizationLeaveDataQuery(
  options: Omit<
    Urql.UseQueryArgs<Operations.GetOrganizationLeaveDataQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    Operations.GetOrganizationLeaveDataQuery,
    Operations.GetOrganizationLeaveDataQueryVariables
  >({ query: Operations.GetOrganizationLeaveDataDocument, ...options });
}
