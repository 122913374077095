import { Icon, Spinner } from '@fluentui/react';
import { useLayoutState } from '@worx.squad/shared-frontend';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Resend from '../components/Activate/Resend';
import Result from '../components/Activate/Result';
import { useActivateAccountMutation } from '../components/Activate/activate.generated';
import { auth } from '../helpers/hbp-helper';

export default function Activate() {
  const navigate = useNavigate();
  const { ticket, type } = useParams<{ ticket: string; type: string }>();
  const [loading, setLoading] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [tokenDetails, setTokenDetails] = useState<string>();

  const [, activateAccount] = useActivateAccountMutation();
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  const activate = useCallback(async () => {
    setLoading(true);

    await auth.logout();

    const activateQuery = await activateAccount({ ticket });
    setTokenDetails(ticket);

    if (activateQuery.error) {
      setLoading(false);
      setHasError(true);
      return;
    }

    setIsActivated(true);
    if (type === 'organization-admin') {
      await auth.refreshToken(
        activateQuery.data?.activateAccount?.refreshToken,
      );
      setTimeout(() => {
        navigate(`/organization/register`);
      }, 500);
    } else if (
      type === 'employee' ||
      type === 'organization-admin-existing-org'
    ) {
      setTimeout(() => {
        navigate(
          `/change-password/${activateQuery.data?.activateAccount?.resetPassword}`,
        );
      }, 500);
    }
    setLoading(false);
  }, [ticket]);

  useEffect(() => {
    if (isEmpty(ticket)) {
      setHasError(true);
      return;
    }
    const t = setTimeout(() => {
      activate();
    }, 1000);
    return () => clearTimeout(t);
  }, []);

  let actionResult;

  if (loading) {
    actionResult = (
      <Result icon={<Spinner />} title="Account activation in progress" />
    );
  } else if (isActivated) {
    actionResult = (
      <Result
        icon={<Icon iconName="Accept" className="text-5xl text-white" />}
        color="green"
        title="Account activated successfully"
      />
    );
  } else if (hasError) {
    actionResult = (
      <Result
        icon={<Icon iconName="Cancel" className="text-5xl text-white" />}
        color="blue"
        title="Activation Failed"
        subTitle={
          isEmpty(tokenDetails)
            ? 'Invalid access token!'
            : 'Access token expired'
        }
        extra={<Resend />}
      />
    );
  }

  return (
    <div className="container m-auto h-screen flex flex-col justify-center items-center">
      {actionResult}
    </div>
  );
}
