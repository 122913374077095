import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ContactUs from '../CommonComponent/ContactUs';
import { Footer } from '../CommonComponent/Footer';
import { Header } from '../CommonComponent/Header';
import FutureOfWork from './BlogContent/FutureOfWork';
import MRecentBContentFour from './BlogContent/MRecentBContentFour';
import MRecentBContentOne from './BlogContent/MRecentBContentOne';
import MRecentBContentThree from './BlogContent/MRecentBContentThree';
import MRecentBContentTwo from './BlogContent/MRecentBContentTwo';

const BlogDetailView = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);

  const data = useLocation();
  const blogId = data?.pathname?.split('/')[2];
  const blogIdArray = [
    '652b9bb5722f7ce55da8a802',
    '652b9bb5722f7ce55da8a803',
    '652b9bb5722f7ce55da8a804',
    '652b9bb5722f7ce55da8a805',
  ];

  return (
    <div className="relative">
      <div className="sticky bg-[#000000] top-0 z-[9999]">
        <Header />
      </div>
      {blogIdArray.includes(blogId) ? (
        <>
          <div>
            {blogId === '652b9bb5722f7ce55da8a802' && <MRecentBContentOne />}
            {blogId === '652b9bb5722f7ce55da8a803' && <MRecentBContentTwo />}
            {blogId === '652b9bb5722f7ce55da8a804' && <MRecentBContentThree />}
            {blogId === '652b9bb5722f7ce55da8a805' && <MRecentBContentFour />}
          </div>
          <div className="pt-1 bg-[#000000]">
            <ContactUs />
            <Footer />
          </div>
        </>
      ) : (
        <div>
          <FutureOfWork />
          <div className="pt-1 bg-[#000000]">
            <ContactUs />
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogDetailView;
