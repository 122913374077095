import { DefaultButton, Stack } from '@fluentui/react';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AvatarSelect, AvatarWebCam } from '.';
import { PrimaryButtonWithLoader } from '..';
import { getFileUrl } from '../../..';
import AvatarPlaceholder from '../../../assets/images/avatar-placeholder.jpg';

export function AvatarMaker({
  formHook,
  name,
  imageUrl,
  urlModal,
  onSubmit,
  setIsDelete,
  profile,
}: {
  profile?: boolean;
  setIsDelete?: Dispatch<SetStateAction<boolean>>;
  formHook: UseFormReturn<any>;
  name: string;
  imageUrl?: string;
  urlModal?: {
    hideModal: () => void;
  };

  onSubmit?: () => void;
}) {
  const [imageSrc, setImageURL] = useState<string>(imageUrl ? imageUrl : '');
  useEffect(() => {
    (async () => {
      if (isEmpty(imageSrc)) return;

      const blob = await fetch(imageSrc).then((r) => r.blob());

      const myFile = new File([blob], 'avatar-file.jpeg', {
        type: blob.type,
      });

      formHook.setValue(name, myFile);
    })();
  }, [formHook, imageSrc, name]);
  return (
    <div
      className={
        profile
          ? `grid grid-cols-1 place-items-center`
          : `flex mt-4 space-x-2 flex-1 flex-col items-center sm:flex-row`
      }
    >
      <div className="w-32 h-32 overflow-hidden rounded-md shadow-inner bg-gray-300">
        <div className="text-7xl flex justify-center items-center h-full text-gray-400 ">
          <img
            src={
              imageUrl && imageSrc === imageUrl
                ? getFileUrl(imageSrc)
                : imageSrc
                  ? imageSrc
                  : AvatarPlaceholder
            }
            alt=""
          />
        </div>
      </div>
      <div
        className={profile ? 'grid grid-cols-1' : 'flex flex-col gap-3 py-10'}
      >
        <div
          className={
            profile ? 'mt-5' : 'flex justify-between sm:justify-start gap-5'
          }
        >
          <AvatarWebCam setImageURL={setImageURL} />
          <br />
          <AvatarSelect setImageURL={setImageURL} />
          {profile && (
            <>
              <DefaultButton
                className="mt-5 w-full"
                text="Remove Photo"
                onClick={() => {
                  setIsDelete && setIsDelete(true);
                  setImageURL('');
                }}
              />
              <Stack horizontal tokens={{ childrenGap: 20 }} className="mt-14">
                <PrimaryButtonWithLoader
                  text="Save"
                  onClick={() => {
                    onSubmit && onSubmit();
                    urlModal?.hideModal();
                  }}
                />
                <DefaultButton
                  text="Cancel"
                  onClick={() => {
                    urlModal?.hideModal();
                  }}
                />
              </Stack>
            </>
          )}
        </div>
        <div className={profile ? `hidden` : ` text-gray-600`}>
          By capturing an image you agree to give Worx Squad access to your
          camera.
        </div>
      </div>
    </div>
  );
}
