const BlackLinkSvg = ({ linkIconOne }: any) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.74439 3.3162L1.28762 9.77297L0.226959 8.71231L6.68373 2.25554L0.744031 2.25554L0.757289 0.757359H9.24257V9.24264L7.74439 9.2559V3.3162Z"
        fill={linkIconOne ? 'white' : 'black'}
      />
    </svg>
  );
};

export default BlackLinkSvg;
