const TeamActivity = () => {
  return (
    <svg
      width="44"
      height="57"
      viewBox="0 0 44 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="kudosNRewards"
    >
      <path
        d="M40.4412 6.70582H37.0883V3.35287C37.0883 1.50406 35.5841 -7.62939e-05 33.7353 -7.62939e-05H3.55876C1.70994 -7.62939e-05 0.205811 1.50406 0.205811 3.35287V46.9412C0.205811 48.79 1.70994 50.2942 3.55876 50.2942H6.91171V53.6471C6.91171 55.4959 8.41584 57.0001 10.2647 57.0001H40.4412C42.29 57.0001 43.7942 55.4959 43.7942 53.6471V10.0588C43.7942 8.20996 42.29 6.70582 40.4412 6.70582ZM41.5589 53.6471C41.5589 54.2634 41.0575 54.7648 40.4412 54.7648H10.2647C9.64839 54.7648 9.14701 54.2634 9.14701 53.6471V50.2942H33.7353C35.5841 50.2942 37.0883 48.79 37.0883 46.9412V34.0882C37.0883 33.4711 36.5878 32.9706 35.9706 32.9706C35.3534 32.9706 34.853 33.4711 34.853 34.0882V46.9412C34.853 47.5575 34.3516 48.0589 33.7353 48.0589H3.55876C2.94249 48.0589 2.44111 47.5575 2.44111 46.9412V3.35287C2.44111 2.7366 2.94249 2.23522 3.55876 2.23522H33.7353C34.3516 2.23522 34.853 2.7366 34.853 3.35287V25.147C34.853 25.7643 35.3534 26.2647 35.9706 26.2647C36.5878 26.2647 37.0883 25.7643 37.0883 25.147V8.94112H40.4412C41.0575 8.94112 41.5589 9.4425 41.5589 10.0588V53.6471Z"
        fill="#FF9900"
      />
      <path
        d="M31.5178 11.1584H25.4389C25.1135 11.1584 24.8042 11.3003 24.5919 11.5469L11.9695 26.2097C10.7406 25.6433 9.23481 25.8658 8.22345 26.877C7.18783 27.9128 6.9833 29.4493 7.58169 30.6797L5.47593 32.7855C4.38778 33.8753 4.38801 35.6474 5.47649 36.736L7.05785 38.3174C8.14633 39.4059 9.91859 39.406 11.009 38.3174L13.1154 36.2109C14.3687 36.8168 15.8998 36.5871 16.917 35.5702C17.9359 34.5508 18.1493 33.044 17.5845 31.8239L32.2471 19.2021C32.4937 18.9897 32.6356 18.6805 32.6356 18.355V12.276C32.6355 11.6587 32.1351 11.1584 31.5178 11.1584ZM9.42906 36.7361C9.21078 36.954 8.85615 36.9543 8.63854 36.7367L7.05718 35.1553C6.83957 34.9377 6.8398 34.5831 7.05718 34.3655L9.03307 32.3896L11.4044 34.7609L9.42906 36.7361ZM15.3364 33.9896C14.9112 34.4148 14.2194 34.4149 13.7965 33.9919C10.0333 30.2285 12.2347 32.4299 9.82113 30.0164C9.81274 30.0041 9.48449 29.7297 9.48449 29.2284C9.48449 28.9374 9.59816 28.6636 9.80425 28.4575C10.2292 28.0323 10.9206 28.0325 11.3453 28.4575C12.7772 29.8893 14.1634 31.2756 15.3353 32.4475C15.7641 32.8774 15.7593 33.5667 15.3364 33.9896ZM30.4002 17.8424L16.1545 30.1056L13.6883 27.6394L25.9515 13.3937H30.4002V17.8424Z"
        fill="#FF9900"
      />
      <path
        d="M5.79465 6.70558H15.8535C16.4707 6.70558 16.9712 6.2051 16.9712 5.58793C16.9712 4.97077 16.4707 4.47028 15.8535 4.47028H5.79465C5.17737 4.47028 4.677 4.97077 4.677 5.58793C4.677 6.2051 5.17737 6.70558 5.79465 6.70558Z"
        fill="#FF9900"
      />
      <path
        d="M5.79465 11.1768H11.3829C12.0001 11.1768 12.5006 10.6763 12.5006 10.0591C12.5006 9.44198 12.0001 8.9415 11.3829 8.9415H5.79465C5.17737 8.9415 4.677 9.44198 4.677 10.0591C4.677 10.6763 5.17737 11.1768 5.79465 11.1768Z"
        fill="#FF9900"
      />
      <path
        d="M31.5011 43.5883H21.4422C20.825 43.5883 20.3246 44.0888 20.3246 44.7059C20.3246 45.3231 20.825 45.8236 21.4422 45.8236H31.5011C32.1183 45.8236 32.6187 45.3231 32.6187 44.7059C32.6187 44.0888 32.1183 43.5883 31.5011 43.5883Z"
        fill="#FF9900"
      />
      <path
        d="M31.5017 39.1179H25.9134C25.2963 39.1179 24.7958 39.6184 24.7958 40.2356C24.7958 40.8527 25.2963 41.3532 25.9134 41.3532H31.5017C32.1188 41.3532 32.6193 40.8527 32.6193 40.2356C32.6193 39.6184 32.1188 39.1179 31.5017 39.1179Z"
        fill="#FF9900"
      />
      <path
        d="M35.9694 30.7354C36.5867 30.7354 37.087 30.235 37.087 29.6177C37.087 29.0005 36.5867 28.5001 35.9694 28.5001C35.3521 28.5001 34.8517 29.0005 34.8517 29.6177C34.8517 30.235 35.3521 30.7354 35.9694 30.7354Z"
        fill="#FF9900"
      />
    </svg>
  );
};

export default TeamActivity;
