import { useLayoutState } from '@worx.squad/shared-frontend';
import { useLayoutEffect } from 'react';
import Address from '../Contact/Address';
import ThankYouMail from '../Home/assets/ThankYouMail';
import ThankYouSvg from '../Home/assets/ThankYouSvg';
import { Header } from './Header';

const ThankYou = () => {
  const [, setLayoutState] = useLayoutState();
  useLayoutEffect(() => {
    setLayoutState?.('normal');
  }, [setLayoutState]);
  return (
    <>
      <div
        className="sticky bg-white top-0 z-[9999]"
        style={{ borderBottom: '1px solid #E0E0E0' }}
      >
        <Header />
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full flex flex-col justify-center items-center pt-[20px] px-[20px] sm:px-[40px]">
          <div>
            <ThankYouSvg />{' '}
          </div>
          <div className="pt-[30px]">
            <ThankYouMail />{' '}
          </div>
          <div className="text-[48px] font-light py-[12px]">
            Thank You<span className="text-[#FF9900]">!</span>
          </div>
          <div className="text-[16px] font-normal text-center pb-[30px]">
            Thank you for reaching out to us. We will get in touch with you
            shortly.
          </div>
        </div>
        <div className="w-[100%] md:w-[100%] lg:w-[35%]  bg-[#185D74] flex pt-[20px] mt-[3rem] sm:mt-0">
          <Address />
        </div>
      </div>
    </>
  );
};

export default ThankYou;
