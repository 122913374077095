import {
  ComboBox,
  IColumn,
  IconButton,
  IDropdownOption,
  Pivot,
  PivotItem,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
} from '@fluentui/react';
import { Pagination } from '@fluentui/react-experiments';
import { useBoolean } from '@fluentui/react-hooks';
import {
  CountCard,
  ICountCard,
  useHeader,
  useLayoutState,
} from '@worx.squad/shared-frontend';
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDebounce } from 'use-debounce';
import {
  GetOrganizationEmployeeWithFilterQuery,
  useGetDeletedUsersOfOrganizationQuery,
  useGetOrganizationEmployeeWithFilterQuery,
  useGetOrganizationRolesByOrgIdQuery,
} from '../components/Employees/employee.generated';
import EmployeeActions from '../components/Employees/EmployeeActions';
import EmployeeAddModal from '../components/Employees/EmployeeAddModal';
import { useOrganizationContext } from '../context/OrganizationContext/OrganizationContext';

interface EmployeeData {
  id: GetOrganizationEmployeeWithFilterQuery['organization_employee'][0]['user']['id'];
  name: GetOrganizationEmployeeWithFilterQuery['organization_employee'][0]['user']['display_name'];
  role: GetOrganizationEmployeeWithFilterQuery['organization_employee'][0]['user']['profile'][0]['organization_role'];
  account: GetOrganizationEmployeeWithFilterQuery['organization_employee'][0]['user']['account'];
  department:
    | GetOrganizationEmployeeWithFilterQuery['organization_employee'][0]['user']['department_employees'][0]['department']
    | undefined;
  lastSignedIn: GetOrganizationEmployeeWithFilterQuery['organization_employee'][0]['user']['last_sign_date_time'];
}

const Employees = () => {
  const [, setLayoutState] = useLayoutState();
  const { setTitle } = useHeader();

  useLayoutEffect(() => {
    setLayoutState?.('app');
  }, [setLayoutState]);

  useEffect(() => {
    setTitle?.('Employees');
  }, [setTitle]);
  const { organization } = useOrganizationContext();

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const [orgEmployees, setOrgEmployees] = useState<EmployeeData[]>([]);
  const [orgRoles, setOrgRoles] = useState<IDropdownOption[]>([]);
  const [selectedRole, setSelectedRole] = useState<string | undefined>('');
  const [searchText, setSearchText] = useState<string | undefined>('');
  const [search] = useDebounce(searchText, 500);

  const PER_PAGE_COUNT = 10;
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);

  const [
    { data: orgEmployeesRaw, fetching: fetchingOrgEmployee },
    refetchEmployees,
  ] = useGetOrganizationEmployeeWithFilterQuery({
    variables: {
      orgId: organization?.id,
      roleId:
        selectedRole && selectedRole.length > 0 ? selectedRole : undefined,
      search: search && search.length > 0 ? `%${search}%` : undefined,
      limit: PER_PAGE_COUNT,
      offset: offset,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  const [
    { data: deletedUsersRaw, fetching: fetchingDeletedUsers },
    refetchDeletedUsers,
  ] = useGetDeletedUsersOfOrganizationQuery({
    variables: {
      orgId: organization?.id,
    },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  const refetchAll = () => {
    refetchDeletedUsers();
    refetchEmployees();
  };

  const [{ data: orgRolesRaw }] = useGetOrganizationRolesByOrgIdQuery({
    variables: { orgId: organization?.id },
    pause: !organization?.id,
    requestPolicy: 'cache-and-network',
  });

  const paginate = (index: number) => {
    setOffset(index * PER_PAGE_COUNT);
    setCurrentPage(index + 1);
  };

  useEffect(() => {
    if (orgEmployeesRaw?.organization_employee) {
      const employee = orgEmployeesRaw?.organization_employee.map(
        (employee, index) => {
          const user = employee.user;
          return {
            index: index + offset + 1,
            id: user.id,
            name: user.display_name,
            account: user.account,
            role: user.profile[0]?.organization_role,
            department: user?.department_employees
              ? user?.department_employees[0]?.department
              : undefined,
            lastSignedIn: user.last_sign_date_time,
          } as EmployeeData;
        },
      );
      setOrgEmployees(employee || []);
      setTotalPages(
        Math.ceil(
          (orgEmployeesRaw?.filtered_total.aggregate?.count || 1) /
            PER_PAGE_COUNT,
        ),
      );
    }
  }, [orgEmployeesRaw]);

  useEffect(() => {
    if (orgRolesRaw?.organization_roles) {
      setOrgRoles(
        orgRolesRaw.organization_roles.map((role) => ({
          key: role.id,
          text: role.role_title,
          styles: {
            optionText: {
              overflow: 'visible',
              whiteSpace: 'normal',
            },
          },
        })),
      );
    }
  }, [orgRolesRaw]);

  interface ICardData extends ICountCard {
    key: string;
  }

  const cardData: ICardData[] = [
    {
      key: 'total',
      number:
        (orgEmployeesRaw?.active?.aggregate?.count || 0) +
        (orgEmployeesRaw?.inactive?.aggregate?.count || 0),
      subText: 'Total employees',
      color: 'bg-green-500',
    },
    {
      key: 'active',
      number: orgEmployeesRaw?.active.aggregate?.count,
      subText: 'Active employees',
      color: 'bg-blue-500',
    },
    {
      key: 'inactive',
      number: orgEmployeesRaw?.inactive.aggregate?.count,
      subText: 'Inactive employees',
      color: 'bg-yellow-500',
    },
  ];

  const detailsColumn: IColumn[] = [
    {
      name: 'Sr. No',
      key: 'srNo',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      fieldName: 'index',
      className: 'text-center text-black',
    },
    {
      name: 'Name',
      key: 'name',
      minWidth: 150,
      maxWidth: 300,
      fieldName: 'name',
      isResizable: true,
      isRowHeader: true,
      className: 'text-black',
    },
    {
      name: 'Email',
      key: 'email',
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      className: 'text-black',
      onRender: (item: EmployeeData) => item.account?.email,
    },
    {
      name: 'Role',
      key: 'role',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      className: 'text-black',
      onRender: (item: EmployeeData) => item.role?.role_title,
    },
    {
      name: 'Department',
      key: 'department',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      className: 'text-black',
      onRender: (item: EmployeeData) => item.department?.name,
    },
    {
      name: 'Status',
      key: 'status',
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      className: 'text-black',
      onRender: (item: EmployeeData) =>
        item.account?.active ? 'Active' : 'Inactive',
    },
    {
      name: 'Last signed in',
      key: 'lastSignedIn',
      minWidth: 110,
      maxWidth: 110,
      className: 'text-black',
      isResizable: true,
      onRender: (item: EmployeeData) =>
        item.lastSignedIn
          ? moment(item.lastSignedIn).format('MMM Do YY hh:mm A')
          : 'Never',
    },
    {
      name: 'Actions',
      key: 'actions',
      minWidth: 150,
      maxWidth: 250,
      className: 'text-center',
      isResizable: true,
      onRender: (item: EmployeeData) => (
        <EmployeeActions refetch={refetchAll} id={item.id} />
      ),
    },
  ];

  const deletedTableColumn = Array.from(detailsColumn).splice(
    0,
    detailsColumn.length - 1,
  );

  const deletedUsers =
    deletedUsersRaw?.organization_employee.map((employee, index) => {
      const user = employee.user;
      return {
        index: index + offset + 1,
        id: user.id,
        name: user.display_name,
        account: user.account,
        role: user.profile[0]?.organization_role,
        department: user?.department_employees
          ? user?.department_employees[0]?.department
          : undefined,
        lastSignedIn: user.last_sign_date_time,
      } as EmployeeData;
    }) || [];

  return (
    <>
      <Helmet>
        <title>Employees | Worx Squad</title>
      </Helmet>
      <Stack tokens={{ childrenGap: 30 }} className="mainContainer">
        <Stack.Item>
          <Stack horizontal tokens={{ childrenGap: 30 }}>
            {cardData.map((data) => (
              <Stack.Item>
                <CountCard
                  color={data.color}
                  number={data.number || 0}
                  subText={data.subText}
                />
              </Stack.Item>
            ))}
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <PrimaryButton
            onClick={showModal}
            iconProps={{ iconName: 'Add' }}
            text="Add new employee"
          />
          <EmployeeAddModal
            refetch={refetchAll}
            hideModal={hideModal}
            isModalOpen={isModalOpen}
          />
        </Stack.Item>
        <Pivot>
          <PivotItem headerText="Active Users">
            <Stack.Item>
              <Stack
                verticalAlign="end"
                horizontal
                tokens={{ childrenGap: 20 }}
                className="max-w-3xl"
              >
                <Stack.Item grow>
                  <SearchBox
                    value={searchText || ''}
                    onChange={(e, value) => {
                      setSearchText(value);
                    }}
                    placeholder="Search by name"
                  />
                </Stack.Item>
                <Stack.Item>
                  <Stack
                    horizontal
                    verticalAlign="end"
                    tokens={{ childrenGap: 5 }}
                  >
                    <ComboBox
                      dropdownWidth={215}
                      allowFreeform
                      selectedKey={
                        selectedRole === undefined ? '' : selectedRole
                      }
                      onChange={(e, option) => {
                        setSelectedRole(option?.key + '');
                      }}
                      label="Filter by role"
                      placeholder="Search or select role"
                      options={orgRoles}
                    />
                    <IconButton
                      hidden={!(selectedRole && selectedRole?.length > 0)}
                      iconProps={{ iconName: 'Cancel' }}
                      onClick={() => {
                        setSelectedRole(undefined);
                      }}
                    />
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              {orgEmployees.length > 0 ? (
                <>
                  <ShimmeredDetailsList
                    enableShimmer={fetchingOrgEmployee}
                    items={orgEmployees}
                    columns={detailsColumn}
                    selectionMode={SelectionMode.none}
                  />
                  <hr className="my-2" />
                  <Pagination
                    selectedPageIndex={currentPage - 1}
                    pageCount={totalPages}
                    totalItemCount={
                      orgEmployeesRaw?.filtered_total.aggregate?.count
                    }
                    itemsPerPage={PER_PAGE_COUNT}
                    onPageChange={paginate}
                    format="buttons"
                    numberOfPageButton={4}
                  />
                </>
              ) : (
                <div className="place-items-center">
                  <Text className="text-xl">No employees added yet</Text>
                </div>
              )}
            </Stack.Item>
          </PivotItem>
          <PivotItem headerText="Deleted Users">
            <ShimmeredDetailsList
              enableShimmer={fetchingDeletedUsers}
              items={deletedUsers}
              columns={deletedTableColumn}
              selectionMode={SelectionMode.none}
            />
          </PivotItem>
        </Pivot>
      </Stack>
    </>
  );
};

export default Employees;
